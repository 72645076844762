import { Fragment, useCallback, useEffect } from "react";
import { Observer } from "mobx-react";
import { Outlet } from "react-router-dom";
import { useMainStore } from "src/container";

import SinglePastStream from './singlePastStream';
import SmallLoader from "src/components/common/smallLoader";

const PastStreams = () => {
    const mainStore = useMainStore();
    const { streamsStore } = mainStore;

    // useEffect(() => {
    //     const { fetchAllStreamsByType } = streamsStore;
    //     fetchAllStreamsByType('past');
    //     return () => { }
    // }, [streamsStore]);

    const downloadStream = useCallback(() => {
        const { downloadStreamSuccess, downloadStreamData } = streamsStore;

        if (downloadStreamSuccess) {
            const { download_url } = downloadStreamData;
            window.open(download_url, '_blank');
        }
    }, [streamsStore]);

    const viewStream = useCallback(() => {
        const { viewStreamSuccess, viewStreamData } = streamsStore;

        if (viewStreamSuccess) {
            const { download_url } = viewStreamData;
            window.open(download_url, '_blank');
            // window.open(`${url}?title=${title}&view=${download_url}`, '_blank');
        }
    }, [streamsStore]);

    return (
        <Observer>
            {() => {
                const { viewStreamSuccess, downloadStreamSuccess, allPastStreamList = [], isPastStreamLoading, fetchDownloadStream, fetchViewStream, downloadStreamLoading, viewStreamLoading } = streamsStore;

                if (isPastStreamLoading) {
                    return (
                        <div className="d-flex justify-content-center align-items-center">
                            <SmallLoader color={'default'} />
                        </div>
                    )
                }

                if (downloadStreamSuccess) {
                    downloadStream();
                }

                if (viewStreamSuccess) {
                    viewStream();
                }

                return (
                    <Fragment>
                        <div className="content active" id="past">
                            <div className="row">
                                <div className="col-12 mb-3">
                                    {allPastStreamList.length ? allPastStreamList.map((stream, i) => {
                                        return (
                                            <SinglePastStream
                                                key={i}
                                                {...stream}
                                                fetchViewStream={fetchViewStream}
                                                fetchDownloadStream={fetchDownloadStream}
                                                viewStreamLoading={viewStreamLoading}
                                                downloadStreamLoading={downloadStreamLoading}
                                            />
                                        )
                                    }) : <p className="fs-1 text-manual-white">No Streams Found.</p>}
                                </div>
                            </div>
                        </div>
                        <Outlet />
                    </Fragment>
                )
            }}
        </Observer>
    )
}

export default PastStreams;