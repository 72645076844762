import { Fragment } from "react";
import { Observer } from "mobx-react";
import { useMainStore } from "src/container";
import { isValidArray } from 'src/utils/utilities';

import PreviewModeWrapper from "src/components/common/previewModeWrapper";
import ToolTip from "./fieldToolTip";
import { toJS } from "mobx";

const FormSubmitField = ({ onOpenOrCloseEditFieldModal }) => {
    const mainStore = useMainStore();
    const { streamFormStore } = mainStore;

    return (
        <Observer>
            {() => {
                const { submitButtonColor, submitButtonText, selectedBgImage} = streamFormStore;

                const selectedStyle = selectedBgImage ? { background: `url(${selectedBgImage})`, backgroundRepeat: "no-repeat", backgroundSize: "2000px auto"} : { color: "white",backgroundColor: submitButtonColor };


                return (
                    <Fragment>
                        <PreviewModeWrapper
                            offPreviewMode={
                                <div className="reg_btn"  >
                                    <div
                                        className="btn  d-block"
                                        style={{ ...selectedStyle }}
                                    >
                                        {submitButtonText}
                                    </div>
                                    <div className="reg_edit_icon">
                                        <ToolTip
                                            type='reg'
                                            onPencilClick={onOpenOrCloseEditFieldModal}
                                        />
                                    </div>
                                </div>
                            }
                            onPreviewMode={
                                <div className="reg_btn_hover_off"  >
                                    <div
                                        className="btn d-block"
                                        style={{ ...selectedStyle }}
                                    >
                                        {submitButtonText}
                                    </div>
                                </div>
                            }
                        />
                    </Fragment>
                )
            }}
        </Observer>
    )
}

FormSubmitField.defaultProps = {
    onOpenOrCloseEditFieldModal: () => { }
}

export default FormSubmitField;