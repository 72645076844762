import { Observer } from "mobx-react";
import { Fragment } from "react";

const EditSelectField = ({ handleSelectEditModal, isEditSelectModalOrderByIndex }) => {

    return (
        <Observer>
            {() => {
                return (
                    <Fragment>
                        <div
                            className="modal fade modal-open show d-block"
                            id="reg_popup"
                        >
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content rounded shadow border-0">
                                    <div className="modal-header border-0">
                                        <div className="d-flex align-items-center p-2">
                                            <h5 className="modal-title me-3" id="reg_popup-title">Edit Input</h5>
                                        </div>
                                    </div>
                                    <div className="modal-body pt-0">
                                        <div className="p-2 rounded box-shadow">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="mb-5">
                                                        Sorry, This page is under development.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-12">
                                                <button
                                                    type="button"
                                                    className="btn btn-footers btn-primary"
                                                >
                                                    Update
                                                </button>
                                                <button
                                                    onClick={handleSelectEditModal}
                                                    type="button"
                                                    className="btn btn-footers btn-dark-footer"
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                )
            }}
        </Observer>
    )

}

export default EditSelectField;