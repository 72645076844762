import { Fragment } from "react";
import { Observer, useLocalObservable } from "mobx-react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useMainStore } from "src/container";
import { handleInput, handleKeyPress, trimString } from "src/utils/utilities";
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from "react-hook-form";
import { changePasswordSchema } from "./changePassword.schema";

import SmallLoader from "src/components/common/smallLoader";

const PassForm = () => {
    let navigate = useNavigate();

    const mainStore = useMainStore();
    let { pathname } = useLocation();

    const { changePasswordStore } = mainStore;

    const goToBack = () => {
        navigate(-1);
    }

    const localStore = useLocalObservable(() => ({
        isOldPassword: true,
        oldPasswordEye: true,

        inNewPassword: true,
        newPasswordEye: true,

        changeToText({ field }) {
            if(field === 'oldPassword'){
                this.isOldPassword = !this.isOldPassword;
                this.oldPasswordEye = !this.oldPasswordEye;
            } else {
                this.inNewPassword = !this.inNewPassword;
                this.newPasswordEye = !this.newPasswordEye;
            }
        }
    }))

    const { register, formState: { errors }, handleSubmit, control } = useForm({
        resolver: yupResolver(changePasswordSchema)
    });

    const handleChangePassword = (data) => {
        const { handleChangePassword } = changePasswordStore;
        handleChangePassword(data);
    }

    const changeType = ({ field }) => {
        const { changeToText } = localStore;
        changeToText( { field });
    }

    return (
        <Observer>
            {() => {

                const { changePasswordSuccess, changePasswordLoading } = changePasswordStore;
                const { isOldPassword, oldPasswordEye, inNewPassword, newPasswordEye } = localStore;

                if (changePasswordSuccess) {
                    const latestPathName = trimString(pathname, 'change-password', 1);
                    return <Navigate to={latestPathName} replace={true} />
                }

                if (changePasswordLoading) {
                    return <SmallLoader color={'default'} />
                }

                return (
                    <Fragment>
                        <form onSubmit={handleSubmit(handleChangePassword)}>
                            <div className="row">
                                <div className="col-md-12" >
                                    <div className="mb-3 password">
                                        <label className="form-label" htmlFor="old_pass">Old Password</label>
                                        <Controller
                                            control={control}
                                            name='old_pass'
                                            render={({ field: { value, onChange } }) =>
                                                <input
                                                    autoFocus
                                                    name="old_pass"
                                                    id="old_pass"
                                                    type={isOldPassword ? "password" : "text"}
                                                    onChange={(e) => onChange(handleInput(e))}
                                                    onKeyPress={(e) => handleKeyPress(e)}
                                                    value={value}
                                                    className="form-control form-control-lg ps-2 inputPassword"
                                                    placeholder="Enter old password"
                                                    autoComplete="off"
                                                // {...register("old_pass")}
                                                />
                                            }
                                        />
                                        <i className={`bi ${oldPasswordEye ? "bi-eye" : "bi-eye-slash"}`} onClick={() => changeType({ field : 'oldPassword'})}></i>
                                        <p className="text-danger">{errors.old_pass?.message}</p>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="mb-3 password">
                                        <label className="form-label" htmlFor="new_pass">New Password</label>
                                        <Controller
                                            control={control}
                                            name="new_pass"
                                            render={({ field: { value, onChange } }) =>
                                                <input
                                                    name="new_pass"
                                                    id="new_pass"
                                                    type={inNewPassword ? "password" : "text"}
                                                    onChange={(e) => onChange(handleInput(e))}
                                                    onKeyPress={(e) => handleKeyPress(e)}
                                                    value={value}
                                                    className="form-control form-control-lg ps-2 inputPassword"
                                                    placeholder="Enter new password"
                                                    autoComplete="off"
                                                // {...register("new_pass")}
                                                />
                                            }
                                        />
                                        <i className={`bi ${newPasswordEye ? "bi-eye" : "bi-eye-slash"}`} onClick={() => changeType({ field: 'newPassword'})}></i>
                                        <p className="text-danger">{errors.new_pass?.message}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="row text-right">
                                <div className="col-md-12">
                                    <button
                                        type="submit"
                                        className="btn btn-footers btn-primary"
                                    >
                                        Save
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-footers btn-dark-footer"
                                        onClick={goToBack}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </form>
                    </Fragment>
                )
            }}
        </Observer>
    )
}

export default PassForm;