import { observable, makeObservable, runInAction, toJS, action } from 'mobx';
import { getRequest, patchRequest } from 'src/utils/api';
import {
    getLocalStorageItems,
    getValidDataFromResponse, isBase64, isTimeGone, isValidArray, isValidObject, isValidURL, sendNotification, setLocalStorageItems
} from 'src/utils/utilities';

import { arrayMoveImmutable } from 'array-move';
import produce from 'immer';
import { getMinutes, getDays, getHours, getSeconds } from "src/utils/utilities";

class StreamFormStore {

    streamSeconds = 0;
    streamMinutes = 0;
    streamDays = 0;
    streamHours = 0;

    selectedColor = 'gray';

    submitButtonText = 'Register Now'
    submitButtonColor="#3192D4"

    previousTextOfSubmitButton = ''
    previousColorOfSubmitButton = ''

    isPreviewMode = false;
    extraFields = [{}];

    streamFormDetailsData = [{}];
    streamDetailsData = [{}];

    formHeaderSection = {};
    formMainSection = {};
    formRegisterNowSection = {};
    formSocialMediaSection = {};
    formRegisterNowSubmitSection = {};
    formRegisterNowTitleSection = {};

    getStreamFormDetailsSuccess = false;
    getStreamFormDetailsLoading = false;

    saveFullFormLoading = false;
    saveFullFormSuccess = false;

    isStreamEnded = false;

    userUploadedImageAcceptedFormats = ["image/jpeg", "image/jpg", "image/png"];
    selectedBgImage = null;

    constructor() {
        makeObservable(this, {
            streamFormDetailsData: observable,
            getStreamFormDetailsSuccess: observable,
            getStreamFormDetailsLoading: observable,
            getStreamFormDetailsById: action,
            resetStreamFormDetails: action,
            selectedBgImage: observable,
            formHeaderSection: observable,
            formMainSection: observable,
            formRegisterNowSection: observable,
            formSocialMediaSection: observable,
            setDefaultValuesInForm: action,
            streamDetailsData: observable,
            setFormHeaderSection: action,
            setFormMainSection: action,
            setFormRegisterNowSection: action,
            setFormSocialMediaSection: action,
            updateFormHeaderSection: action,
            updateFormMainSection: action,
            updateFormRegisterNowSection: action,
            updateFormSocialMediaSection: action,
            setPreviewMode: action,
            handlePreviewMode: action,
            removeFormRegisterNowSection: action,
            isPreviewMode: observable,
            sortFormRegisterNowSection: action,
            addNewFieldToFormRegisterNowSection: action,
            extraFields: observable,
            setRegisterNowSubmitSection: action,
            setRegisterNowTitleSection: action,
            formRegisterNowTitleSection: observable,
            updateFormRegisterNowTitleSection: action,
            formRegisterNowSubmitSection: observable,
            updateRegisterNowSubmitSection: action,
            streamSeconds: observable,
            streamMinutes: observable,
            streamDays: observable,
            streamHours: observable,
            setStreamTime: action,
            setExtraFieldsSection: action,
            editSelectionBox: action,
            selectedColor: observable,
            handleChangeComplete: action,
            handleFullFormForSave: action,
            saveFullFormLoading: observable,
            saveFullFormSuccess: observable,
            handleSocialMediaUrl: observable,
            isStreamEnded: observable,
            getStreamTime: action,
            handleSpeakersUpload: action,
            
            submitButtonColor: observable,
            submitButtonText: observable,
            handleSubmitButtonColorChange:action,
            handleSubmitButtonTextChange:action,
            resetToPrevious: action,
        });
    }

    setPreviewMode = () => {
        const { isPreview } = getLocalStorageItems({ key: 'isPreview', get: true });
        if (isPreview === null) {
            setLocalStorageItems({ set: true, item: { key: "isPreview", value: true } });
        }
        runInAction(() => {
            this.isPreviewMode = isPreview;
        });
    }

    handlePreviewMode = (value) => {
        runInAction(() => {
            setLocalStorageItems({ set: true, item: { key: "isPreview", value: value } });
            this.isPreviewMode = value;
        });
    }

    getStreamFormDetailsById = async (fid, sid) => {
        try {
            runInAction(() => {
                this.getStreamFormDetailsLoading = true;
            });

            const { response } = await getRequest(`/stream/form/info?s_id=${sid}&f_id=${fid}`);
            const { data, error } = getValidDataFromResponse(response, false);

            if (!error && isValidArray(data)) {
                const [{ stream_doc, ...formData }] = data;

                runInAction(() => {
                    this.streamFormDetailsData = toJS([{ ...formData }]);
                    this.streamDetailsData = toJS([...stream_doc]);
                    this.getStreamFormDetailsSuccess = true;
                    this.setFormHeaderSection(formData);
                    this.setFormMainSection(formData);
                    this.setFormRegisterNowSection(formData);
                    this.setFormSocialMediaSection(formData);
                    this.setRegisterNowSubmitSection(formData);
                    this.setExtraFieldsSection(formData);
                    this.setRegisterNowTitleSection(formData);
                });
            }
            runInAction(() => {
                this.getStreamFormDetailsLoading = false;
                this.getStreamFormDetailsSuccess = false;
            });
        } catch (error) {
            runInAction(() => {
                this.getStreamFormDetailsLoading = false;
                this.getStreamFormDetailsSuccess = false;
            });
        }
    }

    resetStreamFormDetails = () => {
        runInAction(() => {
            this.streamFormDetailsData = [];
        });
    }

    // check image size
    bytesToSize = (bytes, maxSizeAccepted = 5) => {
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) return '0 Byte';
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        if (sizes[i] === 'MB') {
            return Math.round(bytes / Math.pow(1024, i), 2) < maxSizeAccepted;
        } else if (sizes[i] === 'KB') {
            return Math.round(bytes / Math.pow(1024, i), 2) < maxSizeAccepted * 1024;
        }
        return false;
    }

    // get base 64 form
    previewFile = (file, callback) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            callback(reader.result);
        };
        reader.readAsDataURL(file);
    }

    // remove first image from uploaded file form
    removeFileInputValue = () => {
        document.getElementById('file-input').value = '';
    }

    // When user upload latest form bg image
    handleFileUpload = (file) => {
        try {
            if (isValidObject(file?.target?.files)) {
                const { type, size } = file.target.files['0'];
                if (!this.userUploadedImageAcceptedFormats.includes(type)) {
                    sendNotification({ notificationType: 'warning', message: 'Unsupported file type. Only JPG, JPEG, PNG are available.', duration: 3000 });
                } else if (!this.bytesToSize(size)) {
                    sendNotification({ notificationType: 'warning', message: 'File size too Small or Bigger than 5 MB.', duration: 3000 });
                } else {
                    this.previewFile(file.target.files['0'], (f) => {
                        runInAction(() => {
                            this.selectedBgImage = f;
                        });
                    })
                }
            } else {
                throw new Error('Something went wrong.');
            }
            this.removeFileInputValue();
        } catch (error) {
            this.removeFileInputValue();
            sendNotification({ notificationType: 'error', message: 'Something wrong' });
        }
    }

    removeUploadedBgImage = () => {
        runInAction(() => {
            this.selectedBgImage = null;
        });
    }

    resetToPrevious = () => {
        runInAction(() => {
            this.setSubmitButtonText(this.previousTextOfSubmitButton)
            this.setSubmitButtonColor(this.previousColorOfSubmitButton)
        })
    }

    setDefaultValuesInForm = () => {
        const streamDetails = toJS([...this.streamDetailsData]);
        const streamFormDetails = toJS([...this.streamFormDetailsData]);

        const [{ description, start_time, title }] = streamDetails;

        const updatedFormDetails = streamFormDetails.map((fd) => {
            const { sections = [] } = fd;
            const updatedSections = sections.map((s, i) => {
                if (i === 0) {
                    const { fields = [] } = s;
                    const updatedFields = fields.map((f) => {
                        const { name } = f;
                        if (name === "stream_title") {
                            return { ...f, value: title };
                        } else if (name === "stream_description") {
                            return { ...f, value: description };
                        } else if (name === "stream_date") {
                            return { ...f, value: start_time };
                        }
                        return { ...f };
                    });
                    return { ...s, fields: updatedFields };
                }
                return { ...s }
            });
            return { ...fd, sections: updatedSections };
        });

        return updatedFormDetails;
    }

    setFormHeaderSection = (formData) => {
        runInAction(() => {
            this.formHeaderSection = toJS(formData?.sections[0]);
        });
    }
    setFormMainSection = (formData) => {
        runInAction(() => {
            this.formMainSection = toJS(formData?.sections[2]);
        });
    }
    setFormRegisterNowSection = (formData) => {
        runInAction(() => {
            this.formRegisterNowSection = toJS(formData?.sections[1]);
        });
    }
    setFormSocialMediaSection = (formData) => {
        runInAction(() => {
            this.formSocialMediaSection = toJS(formData?.sections[3]);
        });
    }
    setRegisterNowSubmitSection = (formData) => {
        const [buttonText, buttonColor ] = toJS(formData?.sections[4]?.fields)
        runInAction(() => {
            this.formRegisterNowSubmitSection = toJS(formData?.sections[4]);
            this.setSubmitButtonText(buttonText.value)
            this.setSubmitButtonColor(buttonColor.value)

            this.previousTextOfSubmitButton = buttonText.value
            this.previousColorOfSubmitButton = buttonColor.value
        });
    }
    setExtraFieldsSection = (formData) => {
        runInAction(() => {
            this.extraFields = toJS(formData?.sections[5]);
        });
    }
    setRegisterNowTitleSection = (formData) => {
        runInAction(() => {
            this.formRegisterNowTitleSection = toJS(formData?.sections[6]);
        });
    }

    updateFormSocialMediaSection = () => { }
    updateFormRegisterNowSection = () => { }

    updateFormRegisterNowTitleSection = (value) => {
        this.formRegisterNowTitleSection = produce(this.formRegisterNowTitleSection, draft => {
            draft['fields'][0].value = value;
        })
    }

    // register form field drag drop sort
    sortFormRegisterNowSection = ({ destinationIndex, sourceIndex }) => {
        const newFields = arrayMoveImmutable([...toJS(this.formRegisterNowSection.fields)], sourceIndex, destinationIndex);
        this.formRegisterNowSection = { ...toJS(this.formRegisterNowSection), fields: [...newFields] };
    }

    // register form field delete
    removeFormRegisterNowSection = (order_by_index) => {
        runInAction(() => {
            let deletedField = {};

            const deletedFormRegisterNowSection = produce(this.formRegisterNowSection, draft => {
                const index = draft['fields'].findIndex(f => f.order_by === order_by_index);
                if (index !== -1) {
                    deletedField = draft['fields'][index];
                    draft['fields'].splice(index, 1);
                }
            });

            this.formRegisterNowSection = { ...deletedFormRegisterNowSection };

            const updatedExtraFields = produce(this.extraFields, draft => {
                draft['fields'].push(deletedField)
            })

            this.extraFields = { ...updatedExtraFields };

        });
    }

    // add form extra field to register form 
    addNewFieldToFormRegisterNowSection = (order_by_index) => {
        runInAction(() => {
            // eslint-disable-next-line no-unused-vars
            let deletedField = {};

            const deletedExtraFields = produce(this.extraFields, draft => {
                const index = draft['fields'].findIndex(f => f.order_by === order_by_index);
                if (index !== -1) {
                    deletedField = draft['fields'][index];
                    draft['fields'].splice(index, 1);
                }
            })

            this.extraFields = { ...deletedExtraFields };

            const updatedRegisterNowSection = produce(this.formRegisterNowSection, draft => {
                draft['fields'].push(deletedField)
            })

            this.formRegisterNowSection = { ...updatedRegisterNowSection };

        });
    }

    // main section will be save
    updateFormMainSection = ({ value, index }) => {
        runInAction(() => {
            const updatedFormMainSection = produce(this.formMainSection, draft => {
                draft["fields"][index].value = value;
            });
            this.formMainSection = { ...updatedFormMainSection };
        });
    }

    updateRegisterNowSubmitSection = (data = []) => {
        runInAction(() => {
            const updatedFormRegisterNowSubmitSection = produce(this.formRegisterNowSubmitSection, draft => {
                data.map((field) => (
                    draft["fields"][field.index].value = field.value
                ))
            });
            this.formRegisterNowSubmitSection = { ...updatedFormRegisterNowSubmitSection };
        });
    }

    setStreamTime = () => {
        runInAction(() => {
            const [{ end_time, timezone }] = this.streamDetailsData;
            this.streamSeconds = getSeconds(end_time, timezone);
            this.streamMinutes = getMinutes(end_time, timezone);
            this.streamDays = getDays(end_time, timezone);
            this.streamHours = getHours(end_time, timezone);
        });
    }

    editSelectionBox = (order_by_index, label, options) => {
        runInAction(() => {

        });
    }

    updateInputTextFieldPlaceHolder = (placeHolderValue, order_by_index) => {
        runInAction(() => {
            const updatedFormRegisterNowSection = produce(this.formRegisterNowSection, draft => {
                const index = draft['fields'].findIndex(d => d.order_by === order_by_index);
                draft["fields"][index].placeholder = placeHolderValue;
            });
            this.formRegisterNowSubmitSection = { ...updatedFormRegisterNowSection };
        });
    }

    updateFormHeaderSection = () => { }

    handleChangeComplete = (color) => {
        runInAction(() => {
            this.selectedColor = color.hex;
        });
    }
    handleSubmitButtonColorChange = (color) => {
        runInAction(() => {
            this.setSubmitButtonColor(color.hex);
        });
    }

    handleSubmitButtonTextChange = (text) => { 
        runInAction(() => {
            this.setSubmitButtonText(text)
        })
    }

    setSubmitButtonColor = (color) => {
        this.submitButtonColor = color;
    }
    setSubmitButtonText = (text) => {
        this.submitButtonText = text;
    }

    
    setBgImageOrBgColor = () => {
        this.formHeaderSection = produce(this.formHeaderSection, draft => {
            draft['fields'][0].value = this.selectedBgImage || this.selectedColor;
        });
    }

    defaultSetBgImageAndBgColor = () => {
        runInAction(() => {
            this.selectedBgImage = isValidURL(this.formHeaderSection['fields'][0].value) || isBase64(this.formHeaderSection['fields'][0].value) ? this.formHeaderSection['fields'][0].value : null;
            this.selectedColor = !isValidURL(this.formHeaderSection['fields'][0].value) && !isBase64(this.formHeaderSection['fields'][0].value) && this.formHeaderSection['fields'][0].value !== null ? this.formHeaderSection['fields'][0].value : '#393B3C';
        });
    }

    handleSocialMediaUrl = ({ value, index }) => {
        runInAction(() => {
            const updatedSocialSection = produce(this.formSocialMediaSection, draft => {
                draft['fields'][index].value = value;
            });

            this.formSocialMediaSection = { ...updatedSocialSection };
        });
    }

    // save user edited form
    handleFullFormForSave = async (sid, fid) => {
        try {
            runInAction(() => {
                this.setBgImageOrBgColor();
                this.saveFullFormLoading = true;
            });

            const setData = {
                form_id: fid,
                stream_id: sid,
                sections: [
                    toJS(this.formHeaderSection),
                    toJS(this.formRegisterNowSection),
                    toJS(this.formMainSection),
                    toJS(this.formSocialMediaSection),
                    toJS(this.formRegisterNowSubmitSection),
                    toJS(this.extraFields),
                    toJS(this.formRegisterNowTitleSection)
                ]
            };

            const { response } = await patchRequest(`/stream/form/update`, setData);
            const { data, error } = getValidDataFromResponse(response, true);

            if (!error && isValidArray(data)) {
                runInAction(() => {
                    this.saveFullFormLoading = false;
                    this.saveFullFormSuccess = true;
                })
            }
            runInAction(() => {
                this.saveFullFormSuccess = false;
                this.saveFullFormLoading = false;
            })
        } catch (err) {
            runInAction(() => {
                this.saveFullFormSuccess = false;
                this.saveFullFormLoading = false;
            })
        }
    }

    setIsRequiredField = (is_required, order_by_index) => {
        runInAction(() => {
            const updateFormRegisterNowSection = produce(this.formRegisterNowSection, draft => {
                const index = draft['fields'].findIndex(d => d.order_by === order_by_index);
                draft["fields"][index].is_required = is_required;
            });
            this.formRegisterNowSection = { ...updateFormRegisterNowSection };
        });
    }

    getStreamTime = () => {
        runInAction(() => {
            const [{ start_time, timezone }] = this.streamDetailsData;
            this.isStreamEnded = isTimeGone(start_time, timezone);
        });
    }

    handleSpeakersUpload = (file, index) => {
        try {
            if (isValidObject(file?.target?.files)) {
                const { type, size } = file.target.files['0'];
                if (!this.userUploadedImageAcceptedFormats.includes(type)) {
                    sendNotification({ notificationType: 'warning', message: 'Unsupported file type. Only JPG, JPEG, PNG are available.', duration: 3000 });
                } else if (!this.bytesToSize(size)) {
                    sendNotification({ notificationType: 'warning', message: 'File size too Small or Bigger than 5 MB.', duration: 3000 });
                } else {
                    this.previewFile(file.target.files['0'], (f) => {
                        runInAction(() => {
                            this.handleSpeakersUploadFiles(f, index);
                        });
                    })
                }
            } else {
                throw new Error('Something went wrong.');
            }
            this.removeFileInputValue();
        } catch (error) {
            this.removeFileInputValue();
            sendNotification({ notificationType: 'error', message: 'Something wrong' });
        }
    }

    handleSpeakersUploadFiles = (data, index) => {
        runInAction(() => {
            const updatedFormMainSection = produce(this.formMainSection, draft => {
                draft["fields"][2].speakers[index].profile = data;
            });
            this.formMainSection = { ...updatedFormMainSection };
        });
    }

    handleSpeakersFullName = (value, index) => {
        runInAction(() => {
            const updatedFormMainSection = produce(this.formMainSection, draft => {
                draft["fields"][2].speakers[index].full_name = value;
            });
            this.formMainSection = { ...updatedFormMainSection };
        });
    }

    handleSpeakersDesignation = (value, index) => {
        runInAction(() => {
            const updatedFormMainSection = produce(this.formMainSection, draft => {
                draft["fields"][2].speakers[index].designation = value;
            });
            this.formMainSection = { ...updatedFormMainSection };
        });
    }

}

export default StreamFormStore;
