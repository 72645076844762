import { action, makeObservable, observable, runInAction } from 'mobx';
import { getRequest } from 'src/utils/api';
import { getValidDataFromResponse, isValidArray, isValidObject } from 'src/utils/utilities';

import moment from 'moment';

class StreamDetailsStore {
    streamUsersList = [];
    streamUsersLoading = false;

    registeredUsersLoading = false;
    registeredUsers = [];

    streamImpressionLoading = false;
    streamImpressions = [];

    streamAttendanceLoading = false;
    streamAttendance = [];

    streamAdsClickedAnalyticsLoading = false;
    streamAdsClickedAnalytics = [];

    streamAdsNetRevenueLoading = false;
    streamAdsNetRevenue = [];

    constructor() {
        makeObservable(this, {
            streamUsersList: observable,
            streamUsersLoading: observable,
            registeredUsersLoading: observable,
            registeredUsers: observable,
            fetchRegisteredAttendeeUsers: action,
            fetchStreamImpression: action,
            streamImpressionLoading: observable,
            streamImpressions: observable,
            fetchStreamAttendanceRate: action,
            streamAttendance: observable,
            streamAttendanceLoading: observable,
            streamAdsClickedAnalyticsLoading: observable,
            streamAdsClickedAnalytics: observable,
            streamAdsNetRevenueLoading: observable,
            streamAdsNetRevenue: observable,
        });
    }

    fetchSingleStreamUser = async (stream_id) => {
        try {
            runInAction(() => {
                this.streamUsersLoading = true;
            })

            const { response } = await getRequest(`/stream/user/${stream_id}`);
            const { data, error } = getValidDataFromResponse(response, false, false, false);

            if (!error && isValidArray(data)) {
                runInAction(() => {
                    this.setStreamUsersData(data);
                    this.streamUsersLoading = false;
                });
            }

            runInAction(() => {
                this.streamUsersLoading = false;
            });
        } catch (error) {
            runInAction(() => {
                this.streamUsersLoading = false;
            });
        }
    }

    resetStreamUserListData = () => {
        this.streamUsersList = [];
    }

    setStreamUsersData = (data) => {
        let newUsersList = [];
        data.forEach((user = {}) => {
            const { users_histories_doc = [], email, full_name, created_at: signed_up, profile_image = null, click_count = 0 } = user;
            const { os_name = null, browser = null, country = null, city = null } = users_histories_doc[0] || {};
            newUsersList.push({
                full_name,
                email,
                profile_image,
                country,
                city,
                os_name,
                browser,
                click_count,
                signed_up: moment(signed_up).format('MM/DD/YYYY hh:mm A')
            });
        });
        this.streamUsersList = [...newUsersList];
    }

    fetchRegisteredAttendeeUsers = async (sid) => {
        try {
            runInAction(() => {
                this.registeredUsersLoading = true;
            });

            const { response } = await getRequest(`/stream/user/registered/attendee/${sid}`);
            const { data, error } = getValidDataFromResponse(response, false, false, false);

            if (!error && isValidArray(data)) {
                runInAction(() => {
                    this.registeredUsers = [...data];
                    this.registeredUsersLoading = false;
                });
            }

            runInAction(() => {
                this.registeredUsersLoading = false;
            });
        } catch (error) {
            runInAction(() => {
                this.registeredUsersLoading = false;
            });
        }
    };

    resetRegisteredUsersData = () => {
        runInAction(() => {
            this.registeredUsers = [];
        });
    }

    fetchStreamImpression = async (sid) => {
        try {
            runInAction(() => {
                this.streamImpressionLoading = true;
            });

            const { response } = await getRequest(`/advertise/clicked/impression/${sid}`);
            const { data, error } = getValidDataFromResponse(response, false, true, false);

            if (!error && isValidArray(data)) {
                runInAction(() => {
                    this.streamImpressions = [...data];
                    this.streamImpressionLoading = false;
                });
            }

            runInAction(() => {
                this.streamImpressionLoading = false;
            });
        } catch (error) {
            runInAction(() => {
                this.streamImpressionLoading = false;
            });
        }
    };

    resetStreamImpression = () => {
        runInAction(() => {
            this.streamImpressions = [];
        });
    }

    fetchStreamAttendanceRate = async (sid) => {
        try {
            runInAction(() => {
                this.streamAttendanceLoading = true;
            });

            const { response } = await getRequest(`/stream/avg_attendance_rate/${sid}`);
            const { data, error } = getValidDataFromResponse(response, false, true, false);

            if (!error && isValidArray(data)) {
                runInAction(() => {
                    this.streamAttendance = [...data];
                    this.streamAttendanceLoading = false;
                });
            }

            runInAction(() => {
                this.streamAttendanceLoading = false;
            });
        } catch (error) {
            runInAction(() => {
                this.streamAttendanceLoading = false;
            });
        }
    };

    resetStreamAttendanceRate = () => {
        runInAction(() => {
            this.streamAttendance = [];
        });
    }

    fetchStreamAdsClickedAnalytics = async (stream_id) => {
        try {
            runInAction(() => {
                this.streamAdsClickedAnalyticsLoading = true;
            });

            const { response } = await getRequest(`stream/ads_analytics/${stream_id}`);
            const { data, error } = getValidDataFromResponse(response, false, true, false);

            if (!error && isValidObject(data)) {
                runInAction(() => {
                    this.streamAdsClickedAnalytics = [data];
                    this.streamAdsClickedAnalyticsLoading = false;
                });
            }

            runInAction(() => {
                this.streamAdsClickedAnalyticsLoading = false;
            });
        } catch (error) {
            runInAction(() => {
                this.streamAdsClickedAnalyticsLoading = false;
            });
        }
    };

    resetStreamAdsNetRevenue = () => {
        runInAction(() => {
            this.streamAdsNetRevenue = [];
        });
    }

    fetchStreamAdsNetRevenue = async (stream_id) => {
        try {
            runInAction(() => {
                this.streamAdsNetRevenueLoading = true;
            });

            const { response } = await getRequest(`/stream/monthly_revenue/${stream_id}`);
            const { data, error } = getValidDataFromResponse(response, false, true, false);

            if (!error && isValidObject(data)) {
                runInAction(() => {
                    this.streamAdsNetRevenue = [...data];
                    this.streamAdsNetRevenueLoading = false;
                });
            }

            runInAction(() => {
                this.streamAdsNetRevenueLoading = false;
            });
        } catch (error) {
            runInAction(() => {
                this.streamAdsNetRevenueLoading = false;
            });
        }
    };

}

export default StreamDetailsStore;
