import { Fragment } from 'react';
import { Observer } from 'mobx-react';
import { toJS } from 'mobx';
import { useMainStore } from 'src/container';

import Chart from 'react-apexcharts';
import SmallLoader from 'src/components/common/smallLoader';
import { isValidObject } from 'src/utils/utilities';
import { chartOptions } from './chartOptions';

const FooterChart = () => {
    const mainStore = useMainStore();
    const { dashboardStore } = mainStore;

    const getKeysArray = (obj) => {
        return isValidObject(obj) ? Object.keys(obj) : [];
    }

    const getValuesArray = (obj) => {
        return isValidObject(obj) ? Object.values(obj) : [];
    }

    const getMaxFromArray = (obj) => {
        let values = isValidObject(obj) ? Object.values(obj) : [];
        return Math.max(...values)
    }
    return (
        <Observer>
            {() => {
                const { streamAdsClickedAnalytics = [], streamAdsClickedAnalyticsLoading } = dashboardStore;

                return (
                    <Fragment>
                        <div className="card shadow border-0 pb-0 rounded">
                            <div className="d-flex justify-content-between align-items-center p-3 bg-white shadow rounded-top border-bottom">
                                <h6 className="text-uppercase mb-0 text-manual-white">Ads Analytics</h6>

                                {/* <ul className="list-unstyled mb-0">
                                    <li className="dropdown dropdown-primary list-inline-item">
                                        <button
                                            type="button"
                                            className="btn btn-icon btn-soft-light dropdown-toggle p-0"
                                            data-bs-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            <i className="mdi mdi-dots-horizontal"></i>
                                        </button>
                                        <div className="dropdown-menu dd-menu dropdown-menu-end bg-dropdown shadow border-0 mt-3 py-3">
                                            <Link to={'/'} className="dropdown-item text-dark" > Edit</Link>
                                            <Link to={'/'} className="dropdown-item text-dark" > Delete</Link>
                                        </div>
                                    </li>
                                </ul> */}
                            </div>

                            {!streamAdsClickedAnalyticsLoading ? (
                                <>
                                    {/* <Chart
                                        options={{
                                            chart: {
                                                id: 'apexchart-example'
                                            },
                                            xaxis: {
                                                categories: getKeysArray(toJS(streamAdsClickedAnalytics[0] || {}))
                                            }
                                        }} series={[{
                                            name: 'Ads Clicked',
                                            data: getValuesArray(toJS(streamAdsClickedAnalytics[0] || {}))
                                        }]}
                                        type="area"
                                        height={370}
                                    /> */}
                                    <Chart
                                        options={{
                                            xaxis: {
                                                categories: getKeysArray(toJS(streamAdsClickedAnalytics[0] || {}))
                                            },
                                            yaxis: {
                                                tickAmount: 5,
                                                min: 0,
                                                max: getMaxFromArray(toJS(streamAdsClickedAnalytics[0] || {}))
                                            },
                                            ...chartOptions
                                        }} 
                                        series={[{
                                            name: 'Ads Clicked',
                                            type: 'column',
                                            data: getValuesArray(toJS(streamAdsClickedAnalytics[0] || {}))
                                        }]}
                                        type="line"
                                        height={370}
                                    />
                                </>
                            ) : (
                                <div className="loader_center">
                                    <SmallLoader />
                                </div>
                            )}
                        </div>
                    </Fragment>
                )
            }}
        </Observer>
    )
}
export default FooterChart;