import { Fragment } from "react";
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <Fragment>
            <footer className="bg-white shadow py-2">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col">
                            <div className="text-sm-start text-center">
                                <p className="mb-0 text-muted small">
                                     © Adtendees. Developed By
                                     &nbsp;<i className="mdi mdi-heart text-danger me-1"></i>
                                    <Link to={"/"} className="text-reset">iTechNotion Pvt. Ltd.</Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </Fragment>
    )
}
export default Footer;