import { Fragment, useEffect } from "react";
import { Observer } from "mobx-react";
import { autorun, toJS } from "mobx";
import { Link, Outlet, useLocation } from "react-router-dom";
import { useMainStore } from "src/container";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { settingProfileSchema } from "./profile.schema";

import Avatar from 'react-avatar';
import { isValidArray } from "src/utils/utilities";

const ProfileSection = () => {
    const { pathname } = useLocation();

    const mainStore = useMainStore();
    const { settingStore, profileStore } = mainStore;

    useEffect(() => {
        const { getUserProfileData } = settingStore;
        getUserProfileData();
        return () => { }
    }, [settingStore]);

    const { register, formState: { errors }, handleSubmit, setValue } = useForm({
        resolver: yupResolver(settingProfileSchema)
    });

    const handleProfileRegister = (data) => {
        const { handleUserProfileFormForSave } = settingStore;
        handleUserProfileFormForSave(data);
    }

    autorun(() => {
        const { userProfileDataSuccess, userProfileData } = settingStore;
        if (userProfileDataSuccess) {
            const { full_name = null, email = null, title = null, bio = null } = userProfileData[0];
            setValue('full_name', full_name);
            setValue('email', email);
            setValue('bio', bio);
            setValue('title', title);
        }
    });

    return (
        <Observer>
            {() => {

                const { updateSuccess, handleFileUpload, removeUploadedImage, uploadedProfileImage, userProfileData, getUserProfileData } = settingStore;
                const { full_name = null } = isValidArray(toJS(userProfileData)) ? userProfileData[0] : {};

                const { userProfileDataSuccess } = profileStore;

                if (userProfileDataSuccess) {
                    getUserProfileData();
                }

                if (updateSuccess) {
                    getUserProfileData();
                }

                return (
                    <Fragment>
                        <div className="content active" id="profile">
                            <div className="row">
                                <div className="col-lg-4 mb-3">

                                    <form onSubmit={handleSubmit(handleProfileRegister)}>

                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="mb-3 d-flex align-items-start">
                                                    <Avatar
                                                        name={full_name}
                                                        src={uploadedProfileImage}
                                                        className="img-fluid avatar avatar-medium me-2 rounded"
                                                        alt="Profile"
                                                    />
                                                    <div className="edit_profile_remove">
                                                        <div className="file-input2">
                                                            <input
                                                                type="file"
                                                                name="file-input"
                                                                id="file-input"
                                                                className="file-input__input2"
                                                                onChange={e => handleFileUpload(e)}
                                                            />
                                                            <label className="file-input__label2 manual-pointer" for="file-input">
                                                                {uploadedProfileImage ? <span>Replace</span> : <span>Upload</span>}
                                                            </label>
                                                        </div>

                                                        <div className="file-input__label2 manual-pointer" onClick={removeUploadedImage}>
                                                            Remove
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="mb-3">
                                                    <label className="form-label" for="full_name">Name</label>
                                                    <input
                                                        name="full_name"
                                                        id="full_name"
                                                        type="text"
                                                        className="form-control form-control-lg ps-2"
                                                        placeholder="e.g Ray Cooper"
                                                        {...register("full_name")}
                                                    />
                                                    <p className="text-danger">{errors.full_name?.message}</p>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="mb-3">
                                                    <label className="form-label" for="title">Title</label>
                                                    <input
                                                        name="title"
                                                        id="title"
                                                        type="text"
                                                        className="form-control form-control-lg ps-2"
                                                        placeholder="e.g Marketing Manager"
                                                        {...register("title")}
                                                    />
                                                    <p className="text-danger">{errors.title?.message}</p>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="mb-4">
                                                    <label className="form-label">Bio</label>
                                                    <div className="form-icon position-relative">
                                                        <textarea
                                                            name="bio"
                                                            id="bio"
                                                            rows="4"
                                                            className="form-control ps-2"
                                                            placeholder="e.g Event organizer, community builder, influencer, lover of dogs "
                                                            {...register("bio")}
                                                        >
                                                        </textarea>
                                                        <p className="text-danger">{errors.bio?.message}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <p className="text-muted2 text-uppercase">Security & Login</p>
                                                <div className="mb-4">
                                                    <label className="form-label" for="email">Email</label>
                                                    <input
                                                        name="email"
                                                        id="email"
                                                        type="text"
                                                        disabled={true}
                                                        className="form-control form-control-lg ps-2 bg-color-gray"
                                                        placeholder="e.g ray@copper.com"
                                                        {...register("email")}
                                                    />
                                                    <p className="text-danger">{errors.email?.message}</p>
                                                </div>
                                            </div>

                                            <div className="col-md-12 mb-4">
                                                <Link to={`${pathname}/change-password`} className="text-primary manual-pointer">
                                                    Change Password
                                                </Link>
                                            </div>

                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <button
                                                    type="submit"
                                                    className="btn btn-footers btn-primary"
                                                >
                                                    Update Profile
                                                </button>
                                            </div>
                                        </div>

                                    </form>

                                </div>
                            </div>
                        </div>
                        <Outlet />
                    </Fragment>
                )
            }}
        </Observer>
    );
}

export default ProfileSection;