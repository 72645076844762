import { configure, runInAction, observable, makeObservable } from 'mobx';
import { getRequest, patchRequest } from 'src/utils/api';
import {
    getLocalStorageItems, getValidDataFromResponse, isValidArray, isValidObject, sendNotification, validBodyFieldsForUpdate
} from 'src/utils/utilities';
import updateHelper from 'immutability-helper';
import * as yup from 'yup';

configure({ useProxies: 'never' });
class ProfileStore {
    // data comes from api
    userProfileData = [{}];
    userProfileDataLoading = false;
    userProfileDataSuccess = false;
    userProfileUpdateSuccessRedirect = false;

    userUploadedImageAcceptedFormats = ["image/jpeg", "image/jpg", "image/png"];

    inValidSchemaErrorMessage = null;
    profileEditSchema = yup.object().shape({
        full_name: yup.string().trim().min(3, "Minimum three character required!")
            .max(25, "Maximum twenty five character required!")
            .required("Full Name is required!"),
        title: yup.string().trim().min(0, "Minimum three character required!")
            .max(25, "Maximum twenty five character required!"),
        profile_image: yup.lazy((value) =>
            /^data/.test(value)
                ? yup.string()
                    .trim()
                    .matches(
                        /^data:([a-z]+\/[a-z0-9-+.]+(;[a-z-]+=[a-z0-9-]+)?)?(;base64)?,([a-z0-9!$&',()*+;=\-._~:@/?%\s]*)$/i,
                        'Must be a valid data URI',
                    )
                    .required()
                : yup.string().nullable().optional(),
        ),
    });

    constructor() {
        makeObservable(this, {
            userProfileData: observable,
            userProfileDataLoading: observable,
            inValidSchemaErrorMessage: observable,
            userProfileUpdateSuccessRedirect: observable,
            userProfileDataSuccess: observable,
        });
    }

    // check image size
    bytesToSize = (bytes, maxSizeAccepted = 5) => {
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) return '0 Byte';
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        if (sizes[i] === 'MB') {
            return Math.round(bytes / Math.pow(1024, i), 2) < maxSizeAccepted;
        } else if (sizes[i] === 'KB') {
            return Math.round(bytes / Math.pow(1024, i), 2) < maxSizeAccepted * 1024;
        }
        return false;
    }

    // get base 64 form
    previewFile = (file, callback) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            callback(reader.result);
        };
        reader.readAsDataURL(file);
    }

    // remove first image from uploaded file form
    removeFileInputValue = () => {
        document.getElementById('file-input-profile').value = '';
    }

    // When user upload latest image
    handleFileUpload = (file) => {
        try {
            if (isValidObject(file?.target?.files)) {
                const { type, size } = file.target.files['0'];
                if (!this.userUploadedImageAcceptedFormats.includes(type)) {
                    sendNotification({ notificationType: 'warning', message: 'Unsupported file type. Only JPG,JPEG,PNG are available.', duration: 3000 });
                } else if (!this.bytesToSize(size)) {
                    sendNotification({ notificationType: 'warning', message: 'File size too Small or Bigger than 5 MB.', duration: 3000 });
                } else {
                    this.previewFile(file.target.files['0'], (f) => {
                        const uploadUserProfileImage = updateHelper(this.userProfileData[0], {
                            profile_image: { $set: f }
                        });

                        this.userProfileData = updateHelper([], {
                            $push: [uploadUserProfileImage]
                        })
                    })
                }
            } else {
                throw new Error('Something went wrong.');
            }
            this.removeFileInputValue();
        } catch (error) {
            this.removeFileInputValue();
            sendNotification({ notificationType: 'error', message: 'Something wrong' });
        }
    }

    // When user change any field value
    handleUserProfileForm = (value, key) => {
        const updatedUserProfileData = updateHelper(this.userProfileData[0], {
            [key]: {
                $set: value
            }
        });

        this.userProfileData = updateHelper([], {
            $push: [updatedUserProfileData]
        })

    }

    // When user click on remove image button
    removeUploadedImage = () => {
        const updatedUserProfileData = updateHelper(this.userProfileData[0], {
            profile_image: {
                $set: null
            }
        });

        this.userProfileData = updateHelper([], {
            $push: [updatedUserProfileData]
        })

        this.removeFileInputValue();
    }

    // When user click on cancel button
    resetUserProfileForm = () => {
        const resetUserProfileData = updateHelper(this.userProfileData[0], {
            $set: {}
        });

        this.userProfileData = updateHelper([], {
            $push: [resetUserProfileData]
        })
    }

    // When user comes on the profile page
    getUserProfileData = async (user_id) => {
        try {
            this.userProfileDataLoading = true;
            const { response } = await getRequest(`/user/info/${user_id}`);
            const { data, error } = getValidDataFromResponse(response, false);

            if (!error && isValidArray(data)) {
                runInAction(() => {
                    this.userProfileData = updateHelper(this.userProfileData, {
                        $set: data
                    });
                })
            }
            runInAction(() => {
                this.userProfileDataLoading = false;
            })
        } catch (error) {
            runInAction(() => {
                this.userProfileDataLoading = false;
            })
        }
    }

    // When user click on save button
    handleUserProfileFormForSave = async () => {
        try {
            await this.profileEditSchema.validate(this.userProfileData[0]);
            const updatedUserProfileData = updateHelper(this.userProfileData[0], {
                $set: this.profileEditSchema.cast(this.userProfileData[0])
            });

            this.userProfileData = updateHelper([], {
                $push: [updatedUserProfileData]
            })

            this.userProfileDataLoading = true;

            const { uid } = getLocalStorageItems({ get: true, key: "uid" });
            const { full_name, password = null, title = null, profile_image } = this.userProfileData[0];
            const setData = validBodyFieldsForUpdate({ full_name, password, title, profile_image, user_id: uid }, ['profile_image']);

            const { response } = await patchRequest(`/user/save/profile`, setData);
            const { data, error } = getValidDataFromResponse(response, true);

            if (!error && isValidArray(data)) {
                runInAction(() => {
                    this.userProfileData = updateHelper(this.userProfileData, {
                        $set: data
                    });
                    this.userProfileDataSuccess = true;
                    this.resetUserProfileUpdateSuccessRedirect(true);
                })
            }
            runInAction(() => {
                this.userProfileDataSuccess = false;
                this.userProfileDataLoading = false;
                this.resetUserProfileUpdateSuccessRedirect(false);
            })
        } catch (err) {
            runInAction(() => {
                this.inValidSchemaErrorMessage = err?.errors[0];
                this.userProfileDataSuccess = false;
                this.userProfileDataLoading = false;
            })

            if (this.inValidSchemaErrorMessage) {
                sendNotification({ message: this.inValidSchemaErrorMessage, notificationType: 'warning' });
            }

        }
    }

    resetUserProfileUpdateSuccessRedirect = (value = false) => {
        runInAction(() => {
            this.userProfileUpdateSuccessRedirect = value;
        })
    }

    setUserProfileDataSuccess = () => {
        runInAction(() => {
            this.userProfileDataSuccess = true;
        });
    }

    resetUserProfileDataSuccess = () => {
        runInAction(() => {
            this.userProfileDataSuccess = false;
        });
    }

}

export default ProfileStore;
