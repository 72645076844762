import { Fragment } from 'react';
import { Observer } from 'mobx-react';
import { useMainStore } from 'src/container';
import { useQueryParam } from 'src/utils/utilities';
import SmallLoader from 'src/components/common/smallLoader';

const FormHeader = () => {
    const mainStore = useMainStore();
    const { streamFormStore } = mainStore;

    const [sid] = useQueryParam('sid');
    const [fid] = useQueryParam('fid');

    return (
        <Observer>
            {() => {
                const { streamDetailsData, isPreviewMode, handlePreviewMode, handleFullFormForSave, saveFullFormLoading } = streamFormStore;

                const [{ title = null }] = streamDetailsData;

                return (
                    <Fragment>
                        <header id="topnav" className="defaultscroll sticky bg-white">
                            <div className="container-lg">
                                {/*<a className="logo" href="index.html">
                                    {title}
                                </a>*/}

                                <ul className="buy-button list-inline mb-0">
                                    <li className="list-inline-item mb-0">
                                        {!isPreviewMode ?
                                            <div
                                                onClick={() => handlePreviewMode(true)}
                                                className="btn btn-secondary2"
                                            >
                                                Preview Mode
                                            </div>
                                            :
                                            <div
                                                onClick={() => handlePreviewMode(false)}
                                                className="btn btn-secondary2"
                                            >
                                                Edit Mode | Preview Mode
                                            </div>
                                        }
                                    </li>

                                    <li className="list-inline-item ps-1 mb-0">
                                        <div
                                            onClick={() => handleFullFormForSave(sid, fid)}
                                            className="btn btn-primary"
                                        >
                                            {saveFullFormLoading ? <SmallLoader color={'black'} /> : 'Save'}
                                        </div>
                                    </li>
                                </ul>

                            </div>
                        </header>
                    </Fragment>
                )
            }}
        </Observer>
    );
}

export default FormHeader;