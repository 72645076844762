import { Fragment } from 'react';
import { Link, useLocation } from "react-router-dom";
import logoIcon from "src/assets/images/logo-icon.png";
import { useQueryParam } from 'src/utils/utilities';
import Notification from './notification';
import UserSettings from './profile';
import SearchBar from './search';

const Header = () => {
    const { pathname } = useLocation();
    const [query = null] = useQueryParam('sn');

    const hideSideBar = () => {
        document.getElementsByClassName("page-wrapper")[0].classList.toggle("toggled");
    }

    return (
        <Fragment>
            <div className="top-header">
                <div className="header-bar d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                        <Link to={'/'} className="logo-icon me-3">
                            <img src={logoIcon} height="30" className="small" alt="" />
                            <span className="big">
                                <img src={logoIcon} height="30" className="logo-light-mode" alt="" />
                                <img src={logoIcon} height="30" className="logo-dark-mode" alt="" />
                            </span>
                        </Link>
                        <div
                            onClick={hideSideBar}
                            id="close-sidebar"
                            className="btn btn-icon-toggle text-dark fw-22"
                        >
                            <i className="ti ti-menu"></i>
                        </div>
                        <nav aria-label="breadcrumb" className="d-inline-block ms-2 mt-sm-0">
                            <ul className="breadcrumb bg-transparent rounded mb-0 p-0">
                                <li className="breadcrumb-item text-capitalize">
                                    <Link to={'/dashboard'}>
                                        Dashboard
                                    </Link>
                                </li>
                                {query && <li className="breadcrumb-item text-capitalize active" aria-current="page">{query}</li>}
                            </ul>
                        </nav>
                    </div>

                    <ul className="list-unstyled mb-0">
                        {/* <li className="list-inline-item mb-0">
                            <SearchBar />
                        </li> */}

                        <li className="list-inline-item mb-0 ms-1">
                            <Link to={`${pathname}/create-stream/details`}>
                                <div className="btn btn-xs btn-primary"><i className="ti ti-plus"></i> New Stream</div>
                            </Link>
                        </li>

                        {/* <li className="list-inline-item mb-0 ms-1">
                            <Link to={'/'}>
                                <div className="btn btn-icon-nav btn-nav-light fw-18">
                                    <i className="mdi mdi-help-circle-outline"></i>
                                </div>
                            </Link>
                        </li> */}

                        <li className="list-inline-item mb-0 ms-1">
                            <Notification />
                        </li>

                        <li className="list-inline-item mb-0 ms-1">
                            <UserSettings />
                        </li>
                    </ul>
                </div>
            </div>
        </Fragment>
    )
}
export default Header;