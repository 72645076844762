import { Fragment } from 'react';
import { Observer, useLocalObservable } from 'mobx-react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useMainStore } from 'src/container';
import { isValidArray } from 'src/utils/utilities';
import { toJS } from 'mobx';

import moment from 'moment-timezone';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ConfirmDeleteModal from './deleteConfirmModal';

import Avatar from 'react-avatar';

const SingleUpcomingStream = (props) => {
    const {
        created_at, start_time, end_time, images, schedule, status, title, _id, str_public_link, total_attendees, str_guest_link, str_host_link, form_doc
    } = props;

    let navigate = useNavigate();
    let { pathname } = useLocation();

    const mainStore = useMainStore();
    const { streamsStore } = mainStore;

    const localStore = useLocalObservable(() => ({
        deleteModal: false,
        isDeleteModal(val) {
            this.deleteModal = val;
        }
    }));

    const goToUpdateStreamPage = (sid) => {
        navigate(`${pathname}/update-stream/details/${sid}`);
    }

    const handleOpenDeleteModal = () => {
        localStore.isDeleteModal(true);
    }

    const handleDeleteStreamModalSuccess = () => {
        const { handleDeleteUpcomingStream } = streamsStore;
        const { _id } = props;
        handleDeleteUpcomingStream(_id);
    }

    const handleDeleteStreamModalClose = () => {
        localStore.isDeleteModal(false);
    }

    const handleManageForm = (str_form_link) => {
        window.open(str_form_link, "_blank");
    }

    return (
        <Observer key={_id}>
            {() => {
                const { handleCopySuccess, isUpcomingStreamDeleteLoading, upcomingStreamDeleteSuccess } = streamsStore;

                const [{ str_form_save_link = null, str_form_link = null }] = isValidArray(toJS(form_doc)) ? form_doc : [{}];

                if (upcomingStreamDeleteSuccess && !isUpcomingStreamDeleteLoading) {
                    localStore.isDeleteModal(false);
                }

                return (
                    <Fragment>
                        <Fragment>
                            <div className="card shadow border border-primary" >
                                <div className="card-body" >
                                    <div className="row" >
                                        <div className="col-lg-8 col-sm-12 border-right">
                                            <div className="d-lg-flex">
                                                <Avatar
                                                    src={images[0]}
                                                    alt="stream image"
                                                    name={title}
                                                    className="img-fluid card-img me-3 avatar avatar-stream_p"
                                                />
                                                <div className="upcoming_description d-flex flex-column justify-content-between">
                                                    <Link to={`/stream-details/analytics/${_id}?sn=${title}`} className='text-muted'>
                                                        <p className="mb-2 text-capitalize text-break text-manual-white">
                                                            {title}
                                                        </p>
                                                    </Link>
                                                    <div className="d-flex">
                                                        <a href={str_host_link} target="_blank">
                                                            <div className="btn btn-xs btn-primary me-2">
                                                                <i className="mdi mdi-television icon-sm me-1"></i>
                                                                Studio
                                                            </div>
                                                        </a>
                                                        <ul className="list-unstyled mb-0">
                                                            <li className="dropdown dropdown-primary list-inline-item">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-icon btn-soft-light dropdown-toggle p-0"
                                                                    data-bs-toggle="dropdown"
                                                                    aria-haspopup="true"
                                                                    aria-expanded="false"
                                                                >
                                                                    <i className="mdi mdi-dots-horizontal"></i>
                                                                </button>
                                                                <div
                                                                    className="dropdown-menu dd-menu dropdown-menu-end bg-dropdown shadow border-0 mt-3 py-3"
                                                                >
                                                                    <p
                                                                        className="dropdown-item text-manual-white btn p-1 m-1"
                                                                        onClick={() => goToUpdateStreamPage(_id)}
                                                                    >
                                                                        Edit Stream
                                                                    </p>

                                                                    <CopyToClipboard
                                                                        text={str_form_link}
                                                                        onCopy={() => handleCopySuccess(str_form_link ? { success: true, message: 'Manage Form copied' } : { success: false, message: 'Please edit the stream to create a form.' })}
                                                                    >
                                                                        <p
                                                                            className="dropdown-item text-manual-white btn p-1 m-1"
                                                                            onClick={() => str_form_link && handleManageForm(str_form_link)}
                                                                        >
                                                                            Manage Form
                                                                        </p>
                                                                    </CopyToClipboard>

                                                                    <CopyToClipboard
                                                                        text={str_form_save_link}
                                                                        onCopy={() => handleCopySuccess(str_form_save_link ? { success: true, message: 'Invitation form link copied' } : { success: false, message: 'No Invitation form link, Please Generate Link To Going in Edit&Preview Section' })}
                                                                    >
                                                                        <p
                                                                            className="dropdown-item text-manual-white btn p-1 m-1"
                                                                            onClick={() => { }}
                                                                        >
                                                                            Invitation form link
                                                                        </p>
                                                                    </CopyToClipboard>

                                                                    <CopyToClipboard
                                                                        text={str_host_link}
                                                                        onCopy={() => handleCopySuccess({ success: true, message: 'Host Link copied' })}
                                                                    >
                                                                        <p
                                                                            className="dropdown-item text-manual-white btn p-1 m-1"
                                                                            onClick={() => { }}
                                                                        >
                                                                            Host Link
                                                                        </p>
                                                                    </CopyToClipboard>

                                                                    <CopyToClipboard
                                                                        text={str_guest_link}
                                                                        onCopy={() => handleCopySuccess({ success: true, message: 'Guest Link copied' })}
                                                                    >
                                                                        <p
                                                                            className="dropdown-item text-manual-white btn p-1 m-1"
                                                                            onClick={() => { }}
                                                                        >
                                                                            Guest Link
                                                                        </p>
                                                                    </CopyToClipboard>

                                                                    <CopyToClipboard
                                                                        text={str_public_link}
                                                                        onCopy={() => handleCopySuccess({ success: true, message: 'Attendees Link copied' })}
                                                                    >
                                                                        <p
                                                                            className="dropdown-item text-manual-white btn p-1 m-1"
                                                                        >
                                                                            Attendees Link
                                                                        </p>
                                                                    </CopyToClipboard>
                                                                    <p
                                                                        className="dropdown-item text-manual-white btn p-1 m-1"
                                                                        onClick={handleOpenDeleteModal}
                                                                    >
                                                                        Delete
                                                                    </p>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-sm-12">
                                            <div className="upcoming_details ms-2 d-flex justify-content-between">
                                                <div>
                                                    <div>
                                                        <h5 className='text-manual-white'>Created</h5>
                                                        <p className="mb-2">{moment(created_at).format('MM/DD/YYYY')}</p>
                                                    </div>
                                                    <div className="mt-3">
                                                        <h5 className='text-manual-white'>Attendees</h5>
                                                        <p className="mb-0">{total_attendees}</p>
                                                    </div>
                                                </div>

                                                <div>
                                                    <div>
                                                        <h5 className='text-manual-white'>Scheduled</h5>
                                                        <p className="small">
                                                            {moment(start_time).format("hh:mm A")} - {moment(end_time).format("hh:mm A")}
                                                            <br/> 
                                                            {moment(schedule).format('MM/DD/YYYY')}
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <h5 className='text-manual-white'>Status</h5>
                                                        {
                                                            status === 'D' ?
                                                                <span className="badge bg-warning h-50 w-50 small">Draft</span>
                                                                :
                                                                <span className="badge bg-success h-50 w-50 small">Created</span>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                        <Fragment>
                            {localStore.deleteModal && <ConfirmDeleteModal
                                message={'Are you sure you want to delete this stream?'}
                                title={'Stream Delete'}
                                isLoading={isUpcomingStreamDeleteLoading}
                                onSuccess={handleDeleteStreamModalSuccess}
                                onClose={handleDeleteStreamModalClose}
                            />}
                        </Fragment>
                    </Fragment>

                )
            }}
        </Observer>
    )
}

export default SingleUpcomingStream;