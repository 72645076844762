import { Fragment, memo } from 'react';
import { Observer } from 'mobx-react';
import { useMainStore } from 'src/container';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import RegisterNowHeader from './registerNowHeader';
import SocialMediaSection from './socialMediaSection';
import FormSubmitField from './formSubmitField';

import TextArea from 'src/components/common/textarea';
import InputSelect from 'src/components/common/inputSelect';
import InputText from 'src/components/common/inputText';
import SmallLoader from 'src/components/common/smallLoader';

import PreviewModeWrapper from '../../../components/common/previewModeWrapper';
import { sendNotification } from 'src/utils/utilities';

const RegisterNow = (
    { onOpenOrCloseAddFieldModal, onOpenOrCloseEditFieldModal, handleSelectEditModal, handleInputEditModal, handleSocialEditModal }
) => {
    const mainStore = useMainStore();
    const { streamFormStore } = mainStore;

    const onDragEnd = (result) => {
        const { sortFormRegisterNowSection } = streamFormStore;

        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        const { destination: { index: destinationIndex }, source: { index: sourceIndex } } = result;
        sortFormRegisterNowSection({ destinationIndex, sourceIndex });
    }

    const FormFields = memo(({ data }) => {
        const { fields = [] } = data;

        return fields
            .map((field, index) => {
                const { tag, _id, order_by, placeholder, label, options = [], is_required } = field;

                if (tag === "textarea") {
                    return <TextArea
                        uk={_id}
                        index={index}
                        deleteIndex={order_by}
                        is_required={is_required}
                        passingProps={{ placeholder, label, options }}
                        handleInputEditModal={handleInputEditModal}
                    />
                } else if (tag === "input") {
                    return <InputText
                        uk={_id}
                        index={index}
                        deleteIndex={order_by}
                        is_required={is_required}
                        passingProps={{ placeholder, label, options }}
                        handleInputEditModal={handleInputEditModal}
                    />
                } else if (tag === "select") {
                    return <InputSelect
                        uk={_id}
                        index={index}
                        deleteIndex={order_by}
                        is_required={is_required}
                        passingProps={{ placeholder, label, options }}
                        handleSelectEditModal={handleSelectEditModal}
                    />
                }
                return null;
            })
    });

    let breakNotification = false;
    const onChangeFormTitle = ({ target: { value } }) => {
        const { updateFormRegisterNowTitleSection } = streamFormStore;
        if (value?.trim().length <= 20) {
            updateFormRegisterNowTitleSection(value);
            breakNotification = false;
        } else {
            if (!breakNotification) {
                breakNotification = true;
                sendNotification({ message: "Text too long, should be in twenty characters.", notificationType: 'warning' });
            }
        }
    }

    return (
        <Observer>
            {() => {
                const { formRegisterNowSection, getStreamFormDetailsLoading, formRegisterNowTitleSection } = streamFormStore;
                const { fields = [{}] } = formRegisterNowTitleSection;
                const { value } = fields[0];

                return (
                    <Fragment>
                        <div className="col-xl-5 col-12 order-1 order-xl-2">
                            <div className="border-0 sidebar sticky-bar ms-xl-4 form-position">
                                <div className="card-body">
                                    <div className="reg_form">
                                        <div className="card bg-dark text-white shadow rounded border-0">
                                            <div className="card-body">

                                                <RegisterNowHeader />

                                                <PreviewModeWrapper
                                                    offPreviewMode={
                                                        <input
                                                            className="form-control form-control2 ps-3 mb-4"
                                                            placeholder="Give a name to your form..."
                                                            // value={value}
                                                            onChange={onChangeFormTitle}
                                                        />
                                                    }
                                                    onPreviewMode={
                                                        <h5 className="card-title fw-bold mb-3">{value}</h5>
                                                    }
                                                />

                                                {
                                                    getStreamFormDetailsLoading ?
                                                        <SmallLoader color={'default'} />
                                                        :
                                                        (
                                                            <form className="login-form">
                                                                <div className="row">

                                                                    <DragDropContext onDragEnd={onDragEnd}>
                                                                        <Droppable droppableId="fields">
                                                                            {(provided) => (
                                                                                <div ref={provided.innerRef} {...provided.droppableProps}>
                                                                                    <FormFields data={formRegisterNowSection} />
                                                                                    {provided.placeholder}
                                                                                </div>
                                                                            )}
                                                                        </Droppable>
                                                                    </DragDropContext>

                                                                    <div class="form-check gdrp_concent"> 
                                                                        <input class="form-check-input" type="checkbox" value="" id="gdrp_concent" defaultChecked />
                                                                        <label class="form-check-label text-muted" for="gdrp_concent">
                                                                            I agree to the collection and processing of my personal data.
                                                                        </label>
                                                                    </div>

                                                                    <PreviewModeWrapper
                                                                        offPreviewMode={
                                                                            <div className="col-md-12 mt-3 mb-3">
                                                                                <div
                                                                                    className="text-muted h6 manual-pointer"
                                                                                    onClick={onOpenOrCloseAddFieldModal}
                                                                                >
                                                                                    + Add form field
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                        onPreviewMode={null}
                                                                    />

                                                                    <div className="col-md-12 mb-3">
                                                                        <FormSubmitField
                                                                            onOpenOrCloseEditFieldModal={onOpenOrCloseEditFieldModal}
                                                                        />
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        <small
                                                                            className="text-muted"
                                                                        >
                                                                            By Registering you are accepting terms and privacy.
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        )
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <SocialMediaSection
                                        handleSocialEditModal={handleSocialEditModal}
                                    />
                                </div>
                            </div>
                        </div>
                    </Fragment>
                )
            }}
        </Observer>
    )
}

export default RegisterNow;