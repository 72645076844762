import { useEffect, useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import { Observer } from 'mobx-react';
import { useMainStore } from 'src/container';
import { isValidStoredKey, useQueryParam } from 'src/utils/utilities';
import SmallLoader from 'src/components/common/smallLoader';

const UnRestrictedRoutes = ({ children }) => {
    const [query] = useQueryParam('redirect');
    const mainStore = useMainStore();
    const { loginStore } = mainStore;

    useEffect(() => {
        const { checkUserAuth } = loginStore;
        setTimeout(() => {
            // Check user already loggedIn
            checkUserAuth();
        }, 1000);
        return () => { }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isAuthenticated = useMemo(() => {
        // check localStorage values
        const isValidKeysWithValues = isValidStoredKey(['token', 'oid', 'ouid', 'uid']);
        return isValidKeysWithValues;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [children]);

    return (
        <Observer>
            {() => {
                const { isUserAlreadyLoggedIn, isUserAlreadyLoggedLoading } = loginStore;

                if (!isUserAlreadyLoggedIn && isUserAlreadyLoggedLoading) {
                    return (
                        <section className="bg-home d-flex align-items-center">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-signin">
                                            <div className="col-md-12">
                                                <div className="mb-3 text-center">
                                                    <SmallLoader color={'default'} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    )
                };

                return !isAuthenticated ? children : <Navigate to={query || `/dashboard`} replace={true} />;
            }}
        </Observer>
    )
}

export default UnRestrictedRoutes;