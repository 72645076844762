import { Fragment } from 'react';
import { Observer, useLocalObservable } from 'mobx-react';
import { useMainStore } from 'src/container';

import BGChange from './changeBackground';
import moment from 'moment-timezone';
import SmallLoader from 'src/components/common/smallLoader';

import PreviewModeWrapper from 'src/components/common/previewModeWrapper';
import { currentTimeZone } from 'src/utils/utilities';
import { autorun } from 'mobx';

const SectionHeader = () => {
    const mainStore = useMainStore();
    const { streamFormStore } = mainStore;

    const localStore = useLocalObservable(() => ({
        isBgModal: false,
        colorModal: false,
        handleBgModal() {
            this.isBgModal = !this.isBgModal;
        },
        handleColorModal() {
            this.colorModal = !this.colorModal;
        },
    }));

    autorun(() => {
        const { defaultSetBgImageAndBgColor, getStreamFormDetailsLoading, getStreamFormDetailsSuccess } = streamFormStore;
        if (getStreamFormDetailsLoading && getStreamFormDetailsSuccess) {
            defaultSetBgImageAndBgColor();
        }
    });

    return (
        <Observer>
            {() => {
                const { colorModal, handleColorModal, handleBgModal, isBgModal } = localStore;
                const { selectedBgImage, streamDetailsData, getStreamFormDetailsLoading, handleChangeComplete, selectedColor } = streamFormStore;
                const [{ title = null, description = null, start_time = null, timezone = null }] = streamDetailsData;

                const selectedStyle = selectedBgImage ? { background: `url(${selectedBgImage})`, backgroundRepeat: "no-repeat", backgroundSize: "2000px auto"} : { backgroundColor: selectedColor };

                if (getStreamFormDetailsLoading) {
                    return <SmallLoader color={'dark'} />;
                }

                return (
                    <Fragment>
                        {/* bg-light */}
                        <section
                            className="bg-half-120 d-table w-100 btn_show"
                            style={{ ...selectedStyle }}
                        >
                            <div className="container-lg">
                                <div className="row mt-5">
                                    <div className="col-lg-6 col-xl-5">
                                        <div className="land_bg_detail">

                                            <PreviewModeWrapper
                                                offPreviewMode={
                                                    <div className="change_bg_dropdown">
                                                        <div
                                                            onClick={handleBgModal}
                                                            className="btn btn-secondary2 mb-2"
                                                        >
                                                            Change Background
                                                        </div>
                                                        {isBgModal && (
                                                            <BGChange
                                                                colorModal={colorModal}
                                                                handleColorModal={handleColorModal}
                                                                handleChangeComplete={handleChangeComplete}
                                                                selectedColor={selectedColor}
                                                                handleBgModal={handleBgModal}
                                                            />
                                                        )}
                                                    </div>
                                                }
                                                onPreviewMode={null}
                                            />

                                            <h2 className="title mb-0">
                                                {title}
                                            </h2>
                                            <p className="tag_line">
                                                {description}
                                            </p>
                                            <p className="tag_line">
                                                {moment(start_time).tz(timezone || currentTimeZone).format('LLLL')}
                                                &nbsp;&nbsp;
                                                {moment(start_time).tz(timezone || currentTimeZone).format('z')}
                                                &nbsp;
                                                {moment(start_time).tz(timezone || currentTimeZone).format('Z')}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </Fragment>
                )
            }}
        </Observer>
    );
}

export default SectionHeader;