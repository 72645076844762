import { Fragment, useEffect, useState } from "react";
import { Observer, useLocalObservable } from "mobx-react";
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { trimString, currentTimeZone, getDefaultSelectedTimeZoneUTC, getDefaultTimeZoneValue, countExactlyMinutesForEnd, countExactlyMinutesForStart, isValidArray } from "src/utils/utilities";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { firstStepSchema } from "./firstStep.schema";
import { useMainStore } from 'src/container/index';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import { DateUtils } from 'react-day-picker';

import dateFnsFormat from 'date-fns/format';
import dateFnsParse from 'date-fns/parse';

import UploadSvg from "src/components/common/uploadSvg";
import SmallLoader from "src/components/common/smallLoader";

import { timeZones } from 'src/utils/timezone';
import moment from "moment";
import { autorun, toJS } from "mobx";
import { Tooltip } from "antd";
import ImagePreview from "src/components/common/imagePreview";


const FirstStep = () => {
    const mainStore = useMainStore();
    let navigate = useNavigate();
    const { pathname } = useLocation();

    const [imageSrc, setImageSrc] = useState('')

    const firstStepLocalStore = useLocalObservable(() => (
        {
            isImageListModal: false,
            isTimeZoneBox: false,
            isTimeZoneSubBox: false,
            selectedTimeZoneUTC: [...getDefaultSelectedTimeZoneUTC(currentTimeZone)],
            defaultTimeZoneValue: getDefaultTimeZoneValue(currentTimeZone),
            defaultSubTimeZoneValue: currentTimeZone,
            handleImageListModal: function (val) {
                this.isImageListModal = val;
            },
            handleTimeZoneBox: function () {
                this.isTimeZoneBox = !this.isTimeZoneBox;
                this.isTimeZoneSubBox = !this.isTimeZoneSubBox;
            },
            handleTimeZoneSubBox: function (val) {
                this.isTimeZoneSubBox = val;
            },
            addSubTimeZoneUTCValues: function (value) {
                this.selectedTimeZoneUTC = [...value];
            },
            setTimeZoneValue: function (value) {
                this.defaultTimeZoneValue = value;
            },
            isGoLive: false,
            handleIsGoLiveModal: function () {
                this.isGoLive = !this.isGoLive;
            }
        }
    ));

    const { register, formState: { errors }, handleSubmit, control, setValue, getValues } = useForm({
        resolver: yupResolver(firstStepSchema),
        defaultValues: {
            timezone: firstStepLocalStore.defaultSubTimeZoneValue
        }
    });

    const { createStreamStore } = mainStore;

    autorun(() => {
        const { defaultTimezoneData = [], defaultTimezoneSuccess } = createStreamStore;

        const { setTimeZoneValue, addSubTimeZoneUTCValues } = firstStepLocalStore;

        if (defaultTimezoneSuccess && isValidArray(toJS(defaultTimezoneData))) {
            const { timezone = currentTimeZone } = defaultTimezoneData[0];
            setValue('timezone', timezone);
            setTimeZoneValue(timezone);
            addSubTimeZoneUTCValues(getDefaultSelectedTimeZoneUTC(timezone));
            return;
        }
    });

    useEffect(() => {
        const { getDefaultTimeZone } = createStreamStore;
        getDefaultTimeZone();

        return () => { }
    }, [createStreamStore]);

    const goToBackPage = () => {
        navigate(-1);
    }

    const openUploadedImageListModal = (val) => {
        firstStepLocalStore.handleImageListModal(val);
    }

    const openOrCloseTimeZoneBox = () => {
        firstStepLocalStore.handleTimeZoneBox();
    }

    const onSelectOpenTimeZoneSubBox = (timezoneValue) => {
        firstStepLocalStore.setTimeZoneValue(timezoneValue);
        timeZones.forEach(({ value, utc }) => {
            if (value === timezoneValue) {
                firstStepLocalStore.addSubTimeZoneUTCValues(utc)
                setValue('timezone', utc[0]);
            }
        })
    }

    const parseDate = (str, format, locale) => {
        const parsed = dateFnsParse(str, format, new Date(), { locale });
        if (DateUtils.isDate(parsed)) {
            return parsed;
        }
        return undefined;
    }

    const formatDate = (date, format, locale) => {
        return dateFnsFormat(date, format, { locale });
    }

    const handleCreateStream = (data) => {
        let streamBody = {
            ...data,
            is_created: false,
            is_updated: false
        }
        const { handleStreamForm } = createStreamStore;
        handleStreamForm(streamBody);
    }

    const onLiveChangeFormValue = ({ target: { checked } }) => {
        if (checked) {

            const currentTime = moment().format('MM/DD/YYYY')

            const currentMinutes = countExactlyMinutesForStart(moment().format('mm'));
            const CurrentHours = currentMinutes === '00' ? moment().add(1, 'h').format('hh') : moment().format('hh')
            const CurrentAmPm = currentMinutes === '00' ? moment().add(1, 'h').format('A') : moment().format('A')

            const startTime = moment(`${currentTime} ${CurrentHours + ':' + currentMinutes} ${CurrentAmPm}`);
            const endTime = moment(`${currentTime} ${CurrentHours + ':' + currentMinutes} ${CurrentAmPm}`).add(1, 'h');

            // set Start Date
            setValue('schedule', moment().format('MM/DD/YYYY'));

            // set Start timings
            setValue('start_time_h', startTime.format('hh'));
            setValue('start_time_m', startTime.format('mm'));
            setValue('start_time_a', startTime.format('A'));

            // set End Timings
            setValue('end_time_h', endTime.format('hh'));
            setValue('end_time_m', endTime.format('mm'));
            setValue('end_time_a', endTime.format('A'));

        }
        // else {
        //     setValue('schedule', null);

        //     // start time reset
        //     setValue('start_time_h', "01");
        //     setValue('start_time_m', "00");

        //     // end time reset
        //     setValue('end_time_h', "01");
        //     setValue('end_time_m', "15");
        // }
    }

    const handleTimeChange = ({target}) => {

        let updatedEndTime;
        
        const [scheduledOn, startHour, startMinutes, amOrPm ] = getValues(['schedule', 'start_time_h', 'start_time_m', 'start_time_a'])

        if(!scheduledOn){
            setValue('schedule', moment().format('MM/DD/YYYY'))
        }

        const startDate = scheduledOn ? moment(scheduledOn).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY')

        switch(target.id){
            case 'start_time_h':
                updatedEndTime = moment(`${startDate} ${target.value +  ":" + startMinutes} ${amOrPm}`)
                break;
            case 'start_time_m':
                updatedEndTime = moment(`${startDate} ${startHour +  ":" + target.value} ${amOrPm}`)
                break;
            case 'start_time_a':
                updatedEndTime = moment(`${startDate} ${startHour +  ":" + startMinutes} ${target.value}`)
            break;
            default:
                return
        }

        setValue('end_time_h', moment(updatedEndTime).add(1, "h").format('hh'))
        setValue('end_time_m', moment(updatedEndTime).add(1, "h").format('mm'))
        setValue('end_time_a', moment(updatedEndTime).add(1, "h").format('A'))
    }

    const handleImageClick = (event, callBack) => {
        setImageSrc(event.target.src)
        callBack()
    }

    return (
        <Fragment>
            <div className="bg-white p-2 rounded box-shadow">
                <Observer>
                    {() => {
                        const {
                            isImageListModal, isTimeZoneBox, isTimeZoneSubBox, selectedTimeZoneUTC, defaultTimeZoneValue,
                            isGoLive, handleIsGoLiveModal
                        } = firstStepLocalStore;

                        const {
                            handleFileUpload, uploadedFileStreamImages, handleRemoveFileFromList, streamCreateLoading,
                            streamCreateSuccessRedirect, removeAllUpdatedFiles
                        } = createStreamStore;

                        if (streamCreateSuccessRedirect) {
                            const latestPathName = trimString(pathname, 'details', 1);
                            return <Navigate to={`${latestPathName}/settings`} />
                        }

                        return (
                            <Fragment>
                                <form onSubmit={handleSubmit(handleCreateStream)}>
                                    <div className="row">

                                        <div className="col-md-12">
                                            <div className="mb-3">
                                                <label className="form-label">Title<span className="requiredFields">*</span></label>
                                                <input
                                                    name="title"
                                                    id="title"
                                                    type="text"
                                                    className="form-control form-control-lg ps-2"
                                                    placeholder="Give your event a title"
                                                    autoComplete="off"
                                                    {...register("title")}
                                                />
                                                <p className="text-danger">{errors.title?.message}</p>
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="mb-3">
                                                <label className="form-label">Description<span className="requiredFields">*</span></label>
                                                <textarea
                                                    name="description"
                                                    id="description"
                                                    rows="4"
                                                    className="form-control ps-2"
                                                    placeholder="Short description about your stream"
                                                    autoComplete="off"
                                                    {...register('description')}
                                                >
                                                </textarea>
                                                <p className="text-danger">{errors.description?.message}</p>
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="mb-3">
                                                <label className="form-label d-flex justify-content-between">
                                                    <div>TimeZone</div>
                                                    <div>
                                                        {defaultTimeZoneValue || "?"}
                                                        <small
                                                            className="text-primary pe-auto manual-pointer"
                                                            onClick={openOrCloseTimeZoneBox}
                                                        >
                                                            &nbsp;Change
                                                        </small>
                                                    </div>
                                                </label>
                                                {
                                                    isTimeZoneBox &&
                                                    (<select
                                                        className="form-select form-control form-control-lg form-time"
                                                        onChange={({ target: { value } }) => onSelectOpenTimeZoneSubBox(value)}
                                                        defaultValue={defaultTimeZoneValue}
                                                    >
                                                        {timeZones.map(({ text, abbr, value }) => (
                                                            <option
                                                                value={value}
                                                                id={abbr}
                                                                key={text}
                                                            >
                                                                {text}
                                                            </option>
                                                        ))}
                                                    </select>)
                                                }
                                            </div>
                                            {
                                                isTimeZoneSubBox && (
                                                    <select
                                                        className="form-select form-control form-control-lg form-time mb-2"
                                                        {...register('timezone')}
                                                    >
                                                        {selectedTimeZoneUTC.map((value) => (
                                                            <option
                                                                value={value}
                                                                key={value}
                                                            >
                                                                {value}
                                                            </option>
                                                        ))}
                                                    </select>
                                                )
                                            }

                                            <p className="text-danger">{errors.timezone?.message}</p>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="d-flex justify-content-between mb-3">
                                                <div className="form-check form-switch">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="wowza_live"
                                                        defaultChecked={false}
                                                        {...register('wowza_live', {
                                                            onChange: (e) => {
                                                                onLiveChangeFormValue(e);
                                                                handleIsGoLiveModal();
                                                            }
                                                        })}
                                                    />
                                                    <label className="form-check-label label-color fw-15" htmlFor="wowza_live">
                                                        Go live now
                                                    </label>
                                                    <p className="text-danger">{errors.wowza_live?.message}</p>
                                                </div>
                                                <div className="form-check form-switch">
                                                <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="is_recording"
                                                        defaultChecked={false}
                                                        {...register('is_recording', {
                                                           
                                                        })}
                                                    />
                                                    <label className="form-check-label label-color fw-15" htmlFor="is_recording">
                                                        Want to record stream?
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        {/* schedule time section start */}
                                        {!isGoLive && (<div className="col-md-12">
                                            <div className="mb-3 d-grid">
                                                <label className="form-label">Schedule<span className="requiredFields">*</span></label>
                                                <Controller
                                                    name={"schedule"}
                                                    control={control}
                                                    render={({ field: { onChange, value } }) => (
                                                        <DayPickerInput
                                                            onDayChange={onChange}
                                                            value={value}
                                                            formatDate={formatDate}
                                                            format={'MM/dd/yyyy'}
                                                            parseDate={parseDate}
                                                            component={(props) => (
                                                                <input
                                                                    {...props}
                                                                    name="schedule"
                                                                    id="schedule"
                                                                    type="text"
                                                                    placeholder=""
                                                                    autoComplete="off"
                                                                    className="form-control form-control-lg ps-2"
                                                                />
                                                            )}
                                                        />
                                                    )}
                                                />
                                                <p className="text-danger">{errors.schedule?.message}</p>
                                            </div>
                                        </div>)}
                                        {/* schedule time section end */}

                                        {/* start time section start */}
                                        {!isGoLive && (<div className="col-md-6">
                                            <div className="mb-3">
                                                <label className="form-label">Start Time<span className="requiredFields">*</span></label>
                                                <div className="row gx-1">
                                                    <div className="col-4">
                                                        <select
                                                            className="form-select form-control form-control-lg form-time"
                                                            id="start_time_h"
                                                            {...register('start_time_h', {
                                                                onChange: (e) => handleTimeChange(e)
                                                            })}
                                                        >
                                                            {
                                                                Array(12).fill().map((_, i) => {
                                                                    return (
                                                                        <option value={`${++i}`.length === 1 ? `0${i}` : `${i}`}>
                                                                            {`${i}`.length === 1 ? `0${i}` : `${i}`}
                                                                        </option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                    </div>

                                                    <div className="col-4">
                                                        <select
                                                            className="form-select form-control form-control-lg form-time"
                                                            id="start_time_m"
                                                            {...register('start_time_m', {
                                                                onChange: (e) => handleTimeChange(e)
                                                            })}
                                                        >
                                                            {
                                                                Array(60).fill().map((_, i) => {
                                                                    if ([0, 15, 30, 45].includes(i)) {
                                                                        return (
                                                                            <option value={`${i}`.length === 1 ? `0${i}` : `${i}`}>
                                                                                {`${i}`.length === 1 ? `0${i}` : `${i}`}
                                                                            </option>
                                                                        )
                                                                    }
                                                                    return null;
                                                                })
                                                            }
                                                        </select>
                                                    </div>

                                                    <div className="col-4">
                                                        <select
                                                            className="form-select form-control form-control-lg form-time"
                                                            id="start_time_a"
                                                            {...register('start_time_a', { value: "AM", onChange: (e) => handleTimeChange(e) })}
                                                        >
                                                            <option value="AM">AM</option>
                                                            <option value="PM">PM</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <p className="text-danger">{errors.start_time?.message}</p>
                                            </div>
                                        </div>)}
                                        {/* start time section end */}

                                        {/* end time section start */}
                                        {!isGoLive && (<div className="col-md-6">
                                            <div className="mb-3">
                                                <label className="form-label">End Time<span className="requiredFields">*</span></label>
                                                <div className="row gx-1">
                                                    <div className="col-4">
                                                        <select
                                                            className="form-select form-control form-control-lg form-time"
                                                            {...register('end_time_h', { value : "02" })}
                                                        >
                                                            {
                                                                Array(12).fill().map((_, i) => {
                                                                    return (
                                                                        <option value={`${++i}`.length === 1 ? `0${i}` : `${i}`}>
                                                                            {`${i}`.length === 1 ? `0${i}` : `${i}`}
                                                                        </option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                    </div>

                                                    <div className="col-4">
                                                        <select
                                                            className="form-select form-control form-control-lg form-time"
                                                            {...register('end_time_m')}
                                                        >
                                                            {
                                                                Array(60).fill().map((_, i) => {
                                                                    if ([0, 15, 30, 45].includes(i)) {
                                                                        return (
                                                                            <option value={`${i}`.length === 1 ? `0${i}` : `${i}`}>
                                                                                {`${i}`.length === 1 ? `0${i}` : `${i}`}
                                                                            </option>
                                                                        )
                                                                    }
                                                                    return null;
                                                                })
                                                            }
                                                        </select>
                                                    </div>

                                                    <div className="col-4">
                                                        <select
                                                            className="form-select form-control form-control-lg form-time"
                                                            {...register('end_time_a', { value: "AM" })}
                                                        >
                                                            <option value="AM">AM</option>
                                                            <option value="PM">PM</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <p className="text-danger">{errors.end_time?.message}</p>
                                            </div>
                                        </div>)}
                                        {/* end time section end */}

                                        {/* Upload image section start */}
                                        <div className="col-5 col-sm-4">
                                            <div className="mb-3">
                                                <div className="file-input">
                                                    <input
                                                        type="file"
                                                        name="pictures"
                                                        id="file-input"
                                                        className="file-input__input"
                                                        multiple
                                                        onChange={e => handleFileUpload(e)}
                                                    />
                                                    <label className="file-input__label" htmlFor="file-input">
                                                        <UploadSvg />
                                                        <span>Upload Images</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-xl-3 col-7">
                                            <div className='uploaded_images'>
                                                {toJS(uploadedFileStreamImages).length > 0 && (
                                                    <>
                                                    <Tooltip placement="right" title="Enlarge">
                                                        <img 
                                                            data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Tooltip on top"
                                                            className="cursorPointer position-relative preview_image" 
                                                            onClick={(e) => handleImageClick(e, () => openUploadedImageListModal(true))} 
                                                            src={URL.createObjectURL(toJS(uploadedFileStreamImages)[toJS(uploadedFileStreamImages).length - 1])} 
                                                            alt={toJS(uploadedFileStreamImages)[toJS(uploadedFileStreamImages).length - 1].name} 
                                                        />
                                                    </Tooltip>
                                                        <i className="bi bi-x-circle close_ico cursorPointer" onClick={() => removeAllUpdatedFiles() } />
                                                    </>
                                                )}                  
                                            </div>
                                        </div>
                                        {/* Upload image section end */}

                                        {/* Category section start */}
                                        <div className="col-md-12">
                                            <div className="mb-3">
                                                <label className="form-label">Category<span className="requiredFields">*</span></label>
                                                <select
                                                    className="form-select form-control form-control-lg form-time"
                                                    {...register('category', { value: "Software" })}
                                                >
                                                    <option value="Software">Software</option>
                                                    <option value="Education">Education</option>
                                                    <option value="Technology">Technology</option>
                                                    <option value="How to">How to</option>
                                                    <option value="Gaming">Gaming</option>
                                                    <option value="Music">Music</option>
                                                    <option value="Sports">Sports</option>
                                                    <option value="Collectibles">Collectibles</option>
                                                    <option value="Fashion">Fashion</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                            </div>
                                            <p className="text-danger">{errors.category?.message}</p>
                                        </div>
                                        {/* Category section end */}

                                    </div>

                                    <div className="row text-right">
                                        <div className="col-md-12">
                                            <button
                                                type="submit"
                                                className="btn btn-footers btn-primary"
                                            >
                                                {streamCreateLoading ? <SmallLoader color={'dark'} /> : "Next"}
                                            </button>
                                            <button
                                                onClick={goToBackPage}
                                                type="button"
                                                className="btn btn-footers btn-dark-footer"
                                            >
                                                Close
                                            </button>
                                        </div>
                                    </div>

                                </form>
                                {isImageListModal &&
                                    (
                                        <ImagePreview
                                            headerName={toJS(uploadedFileStreamImages).pop().name}
                                            onCloseModal={openUploadedImageListModal}
                                            imageSrc={imageSrc}
                                            onRemoveFile={handleRemoveFileFromList}
                                        />
                                    )
                                }
                            </Fragment>
                        )
                    }}
                </Observer>
            </div>
        </Fragment>
    )
}

export default FirstStep;
