import { Observer } from 'mobx-react';
import { isValidArray, isValidObject } from 'src/utils/utilities';

const ImagePreview = ({ onCloseModal, imageSrc, onRemoveFile, headerName }) => {
    return (
        <Observer>
            {() => {
                return (
                    <div
                        className="modal fade modal-open show"
                        id="uploaded_profile"
                        tabIndex="-1"
                        style={{ display: 'block' }}
                        role='dialog'
                        aria-hidden="true"
                    >
                        <div className="modal-dialog modal-xl modal-dialog-centered">
                            <div className="modal-content rounded shadow border-0">
                                <div className="modal-header border-bottom">
                                    <div className="d-flex align-items-center">
                                        <h5 className="modal-title me-3" id="uploaded_profile-title">Uploaded Image</h5>
                                    </div>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        onClick={() => onCloseModal(false)}
                                    >

                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="bg-white p-2 rounded box-shadow">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className='uploaded-image-list preview_img' id="uploaded-image-list">

                                                    <div className="d-flex align-items-center preview_icon">
                                                        <img
                                                            src={imageSrc}
                                                            className="img-fluid me-2 rounded"
                                                            // alt={file.name}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row text-right mt-4">
                                            <div className="col-md-12">
                                                <button
                                                    onClick={() => onCloseModal(false)}
                                                    type="button"
                                                    className="btn btn-footers btn-dark-footer"
                                                >
                                                    Close
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }}
        </Observer>
    )
}

ImagePreview.defaultProps = {
    imageSrc: null,
    onCloseModal: Function,
    onRemoveFile: Function,
    headerName: null
}

export default ImagePreview;