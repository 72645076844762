import { makeAutoObservable, observable, runInAction } from 'mobx';
import { postRequest } from 'src/utils/api';
import { getValidDataFromResponse, isValidArray } from 'src/utils/utilities';

class ForgotPasswordStore {
    isUserForgotPasswordLoading = false;
    // for redirect to dashboard
    isUserPasswordRedirectSuccess = false;

    isResetPasswordLoading = false;
    shouldRedirectAfterResetPasswordSuccess = false;

    constructor() {
        makeAutoObservable(this, {
            isResetPasswordLoading : observable
        });
    }

    setIsUserPasswordRedirectSuccess = (value = false) => {
        setTimeout(() => {
            this.isUserPasswordRedirectSuccess = value;
        }, 1000);
    }

    resetAfterResetPasswordRedirectFlag = ( value = false ) => {
        setTimeout(() => {
            this.shouldRedirectAfterResetPasswordSuccess = value;
        }, 1000)
    }

    handleUserForgotPassword = async (userEmailData) => {
        try {
            this.isUserForgotPasswordLoading = true;

            const { response } = await postRequest('/user/forgot/password', userEmailData);
            const { data, error } = getValidDataFromResponse(response);

            if (!error && isValidArray(data)) {
                runInAction(() => {
                    this.isUserPasswordRedirectSuccess = true;
                })
            }
            runInAction(() => {
                this.isUserForgotPasswordLoading = false;
            })
        } catch (error) {
            runInAction(() => {
                this.isUserForgotPasswordLoading = false;
            });
        }
    }

    handleResetPassword = async (userFormData) => {
        try {
            this.isResetPasswordLoading = true;

            const { response } = await postRequest('/user/reset_password', userFormData);
            const { data, error } = getValidDataFromResponse(response);

            if(!error && isValidArray(data)){
                runInAction(() => {
                    this.shouldRedirectAfterResetPasswordSuccess = true;
                })
            }


            runInAction(() => {
                this.isResetPasswordLoading = false;
            })
            
        } catch (error) {
            runInAction(() => {
                this.isResetPasswordLoading = false;
            })
        }
    }
}

export default ForgotPasswordStore;
