import { Fragment } from "react";

const UnderDevelopment = () => {
    return (
        <Fragment>
            <div className="">
                <p className="fs-1 text-manual-white">
                    This page is in underdevelopment.
                </p>
            </div>
        </Fragment>
    );
}

export default UnderDevelopment;