const isProduction = process.env.REACT_APP_NODE_ENV_DEV !== 'development';

const localUrl = "http://localhost:3000";
const devApiEndPoint = process.env.REACT_APP_DEV_API_ENDPOINT;

const envConfig = {
    apiEndPoint: function () {
        return isProduction ? devApiEndPoint : localUrl
    },
}

export default envConfig;
