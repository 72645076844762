import { Fragment } from "react";
import { Observer, useLocalObservable } from "mobx-react";
import { isValidArray, sendNotification } from "src/utils/utilities";
import { useMainStore } from "src/container";
import { toJS } from "mobx";

const InputTextEdit = ({ onModalClose, isInputEditModalOrderByIndex }) => {
    const mainStore = useMainStore();
    const { streamFormStore } = mainStore;
    const { formRegisterNowSection } = streamFormStore;

    const getDefaultValue = (data) => {
        if (isValidArray(toJS(data.fields))) {
            const [{ is_required = false }] = data.fields.filter(({ order_by }) => order_by === isInputEditModalOrderByIndex);
            return is_required;
        }
        return false;
    }

    const defaultValue = getDefaultValue(formRegisterNowSection);

    const localStore = useLocalObservable(() => ({
        placeHolderValue: '',
        handlePlaceHolderValue(value) {
            this.placeHolderValue = value;
        },
        isRequiredField: defaultValue,
        handleIsRequiredField() {
            this.isRequiredField = !this.isRequiredField;
        }
    }))

    const onUpdateButtonText = () => {
        const { placeHolderValue, isRequiredField } = localStore;
        const { updateInputTextFieldPlaceHolder, setIsRequiredField } = streamFormStore;

        if (placeHolderValue?.trim()?.length < 40 && placeHolderValue?.trim()?.length > 1) {
            updateInputTextFieldPlaceHolder(placeHolderValue, isInputEditModalOrderByIndex);
        }

        if (placeHolderValue?.trim()?.length > 40) {
            sendNotification({ message: "Text too long, should be in fourteen characters.", notificationType: 'warning' });
        }

        setIsRequiredField(isRequiredField, isInputEditModalOrderByIndex);
        onModalClose();
    }

    return (
        <Observer>
            {() => {
                const { handlePlaceHolderValue, placeHolderValue, isRequiredField, handleIsRequiredField } = localStore;

                return (
                    <Fragment>
                        <div
                            className="modal fade modal-open show d-block"
                            id="reg_popup"
                        >
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content rounded shadow border-0">
                                    <div className="modal-header border-0">
                                        <div className="d-flex align-items-center p-2">
                                            <h5 className="modal-title me-3" id="reg_popup-title">Edit Input</h5>
                                        </div>
                                    </div>
                                    <div className="modal-body pt-0">
                                        <div className="p-2 rounded box-shadow">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="mb-5">
                                                        <div className="form-icon btn_txt">

                                                            {
                                                                [16, 17, 18].includes(isInputEditModalOrderByIndex) && (
                                                                    <Fragment>
                                                                        <label className="label">Edit placeholder name</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control form-control-lg ps-3"
                                                                            value={placeHolderValue}
                                                                            onChange={({ target: { value } }) => handlePlaceHolderValue(value)}
                                                                        />
                                                                    </Fragment>
                                                                )
                                                            }

                                                            <div className="col-md-12">
                                                                <div className="mb-5 bg-req">
                                                                    <div className="form-check form-switch">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            id="live"
                                                                            onChange={({ target: { value } }) => handleIsRequiredField(value)}
                                                                            checked={isRequiredField}
                                                                            defaultChecked={isRequiredField}
                                                                        />
                                                                        <label
                                                                            className="form-check-label label-color fw-15"
                                                                            for="live"
                                                                        >
                                                                            Required
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-12">
                                                    <button
                                                        onClick={onUpdateButtonText}
                                                        type="button"
                                                        className="btn btn-footers btn-primary"
                                                    >
                                                        Update
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-footers btn-dark-footer"
                                                        onClick={onModalClose}
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                )
            }}
        </Observer>
    );
}

export default InputTextEdit;