import { Fragment, useEffect } from "react";
import { Observer, useLocalObservable } from "mobx-react";
import { Outlet } from "react-router-dom";
import { useMainStore } from "src/container";

import ActiveUsersList from "./activeUsers";
import MarketingProcesses from "./marketingProcesses";
import SocialConnect from "./socialConnect";
import UpcomingStream from "./upcomingStream";
import BlankStream from "./blankStream";
import FooterChart from "./chart";
import Analytics from "./analytics";

import SmallLoader from "src/components/common/smallLoader";
import ConfirmDeleteModal from "../streams/deleteConfirmModal";

const Dashboard = () => {
    const mainStore = useMainStore();
    const { dashboardStore, streamsStore } = mainStore;

    const localStore = useLocalObservable(() => ({
        streamId: null,
        deleteModal: false,
        isDeleteModal(val) {
            this.deleteModal = val;
        }
    }))

    useEffect(() => {
        const {
            fetchRecentStreams, resetRecentStreamData, resetRecentStreamSuccess,
            fetchActiveUsers, activeUsersData, resetActiveUsersSuccess,
            fetchStreamSummary, streamSummaryData, resetStreamSummarySuccess,
            fetchStreamAdsClickedAnalytics
        } = dashboardStore;

        fetchRecentStreams();
        fetchActiveUsers();
        fetchStreamSummary();
        fetchStreamAdsClickedAnalytics();

        return () => {
            resetRecentStreamData();
            resetRecentStreamSuccess();
            activeUsersData();
            resetActiveUsersSuccess();
            streamSummaryData();
            resetStreamSummarySuccess();
        }
    }, [dashboardStore]);

    const handleOpenDeleteModal = (id) => {
        localStore.streamId = id;
        localStore.isDeleteModal(true);
    }

    const handleDeleteStreamModalSuccess = () => {
        const { handleDeleteUpcomingStream } = streamsStore;
        const { streamId } = localStore;
        handleDeleteUpcomingStream(streamId);
    }

    const handleDeleteStreamModalClose = () => {
        localStore.isDeleteModal(false);
    }

    return (
        <Observer>
            {() => {
                const { recentStreamLoading, recentStreamSuccess } = dashboardStore;
                const { isUpcomingStreamDeleteLoading, upcomingStreamDeleteSuccess } = streamsStore;

                if (upcomingStreamDeleteSuccess && !isUpcomingStreamDeleteLoading) {
                    localStore.isDeleteModal(false);
                }

                const StreamComponent = recentStreamSuccess ? UpcomingStream : BlankStream;

                return (
                    <Fragment>
                        <Fragment>
                            <div className="layout-specing">
                                <div className="row">
                                    <div className="col-xl-9 col-lg-7 mt-4">
                                        <div className="row full_border">
                                            <StreamComponent handleOpenDeleteModal={handleOpenDeleteModal} />

                                            {/* analytics */}
                                            <div className="col-12">
                                                <Analytics />
                                            </div>

                                            {/* chart */}
                                            <div className="col-xl-12 mt-3">
                                                <FooterChart />
                                            </div>

                                        </div>
                                    </div>

                                    <div className="col-xl-3 col-lg-5 mt-4 rounded">
                                        <div className="stremas_summary">
                                            <div className="summary_heading border-bottom">
                                                <h6>Streams Summary</h6>
                                                {false && <p>No streams yet</p>}
                                                <MarketingProcesses />
                                            </div>
                                            <ActiveUsersList />
                                            {/*<SocialConnect /> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Outlet />
                        </Fragment>
                        <Fragment>
                            {localStore.deleteModal && (
                                <ConfirmDeleteModal
                                    message={'Are you sure you want to delete this stream?'}
                                    title={'Stream Delete'}
                                    isLoading={isUpcomingStreamDeleteLoading}
                                    onSuccess={handleDeleteStreamModalSuccess}
                                    onClose={handleDeleteStreamModalClose}
                                />
                            )}
                        </Fragment>
                    </Fragment>
                )
            }}
        </Observer>
    )
}

export default Dashboard;