import { Fragment } from "react";
import { Observer } from "mobx-react";
import { useMainStore } from "src/container";

const AddFormField = ({ onOpenOrCloseAddFieldModal }) => {
    const mainStore = useMainStore();
    const { streamFormStore } = mainStore;

    return (
        <Observer>
            {() => {
                const {
                    extraFields, addNewFieldToFormRegisterNowSection
                } = streamFormStore;

                return (
                    <Fragment>
                        <div
                            className="modal fade modal-open show d-block"
                            id="addform_landing"
                        >
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content rounded shadow border-0">
                                    <div className="modal-header border-0">
                                        <div className="d-flex align-items-center p-2">
                                            <h5 className="modal-title me-3" id="addform_landing-title">Add Form Field</h5>
                                        </div>
                                    </div>
                                    <div className="modal-body pt-0">
                                        <div className="p-2 rounded box-shadow">
                                            <form>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="mb-3">
                                                            <div className="btn-group dropdown-primary">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-secondary2 dropdown-toggle"
                                                                    data-bs-toggle="dropdown"
                                                                    aria-haspopup="true"
                                                                    aria-expanded="false"
                                                                >
                                                                    Choose a field type
                                                                </button>
                                                                <div className="dropdown-menu scroll-menu">
                                                                    {
                                                                        extraFields['fields'].map(({ label, tag, order_by }, index) => {
                                                                            return (
                                                                                <div
                                                                                    className="dropdown-item manual-pointer"
                                                                                    key={index}
                                                                                    onClick={() => addNewFieldToFormRegisterNowSection(order_by)}
                                                                                >
                                                                                    {label ? `${label} (${tag})` : `New ${tag}`}
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* <div className="col-md-12">
                                                        <div className="mb-5 bg-req">
                                                            <div className="form-check form-switch">
                                                                <input className="form-check-input" type="checkbox" id="live" />
                                                                <label className="form-check-label label-color fw-15" for="live">Required</label>
                                                            </div>
                                                        </div>
                                                    </div> */}

                                                </div>
                                                <div className="row text-right">
                                                    <div className="col-md-12">
                                                        {/* <button
                                                            type="button"
                                                            className="btn btn-footers btn-primary"
                                                        >
                                                            Next
                                                        </button> */}
                                                        <button
                                                            onClick={onOpenOrCloseAddFieldModal}
                                                            type="button"
                                                            className="btn btn-footers btn-dark-footer"
                                                        >
                                                            Close
                                                        </button>
                                                    </div>
                                                </div>

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                )
            }}
        </Observer>
    );
}

export default AddFormField;