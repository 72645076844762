import { Fragment } from "react";
import { NavLink, useLocation } from "react-router-dom";
import AdtendeesLogo from "src/assets/images/logo.png";
import { checkValidUrlPath } from "src/utils/utilities";

const SideBar = () => {
    const { pathname } = useLocation();

    const isActivePath = checkValidUrlPath(pathname, "past");
    const isActiveProfilesPath = checkValidUrlPath(pathname, "profiles");
    const isActiveEmailNotificationPath = checkValidUrlPath(pathname, "email-notification");
    const isActiveAdsPath = checkValidUrlPath(pathname, "ads");

    return (
        <Fragment>
            <nav id="sidebar" className="sidebar-wrapper sidebar-dark">
                <div className="sidebar-content" data-simplebar style={{ "height": "calc(100% - 60px)" }}>
                    <div className="sidebar-brand">
                        <NavLink to={'/'}>
                            <img src={AdtendeesLogo} height="38" className="logo-light-mode" alt="" />
                            <img src={AdtendeesLogo} height="38" className="logo-dark-mode" alt="" />
                            <span className="sidebar-colored">
                                <img src={AdtendeesLogo} height="38" alt="" />
                            </span>
                        </NavLink>
                    </div>

                    <ul className="sidebar-menu">
                        <li>
                            <NavLink
                                to={'/dashboard'}
                                style={({ isActive }) => isActive ? { color: "#3192D4" } : {}}
                            >
                                <i className="ti ti-bolt me-2"></i>
                                Dashboard
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to={'/streams/upcoming'}
                                style={({ isActive }) => isActive || isActivePath ? { color: "#3192D4" } : {}}
                            >
                                <i className="ti ti-video-camera me-2"></i>
                                Streams
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to={'/setting/general'}
                                style={({ isActive }) => isActive || isActiveProfilesPath || isActiveEmailNotificationPath || isActiveAdsPath ? { color: "#3192D4" } : {}}
                            >
                                <i className="ti ti-settings me-2"></i>
                                Settings
                            </NavLink>
                        </li>
                    </ul>

                </div>
            </nav>
        </Fragment>
    )
}
export default SideBar;