import { Fragment, useEffect } from "react";
import { Observer, useLocalObservable } from "mobx-react";
import { Outlet } from "react-router-dom";
import { useMainStore } from "src/container";
import { autorun } from "mobx";

import SmallLoader from "src/components/common/smallLoader";

const numbers = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60];

const AdsSection = () => {
    const mainStore = useMainStore();
    const { settingStore } = mainStore;

    const localStore = useLocalObservable(() => ({
        timeStatus: null,
        time: null,
        handleTimeStatus(val) {
            this.timeStatus = val;
        },
        handleTime(val) {
            this.time = val;
        },
        stayTimeStatus: null,
        stayTime: null,
        handleStayTimeStatus(val) {
            this.stayTimeStatus = val;
        },
        handleStayTime(val) {
            this.stayTime = val;
        }
    }));

    useEffect(() => {
        const { getOrganizerInfo } = settingStore;
        getOrganizerInfo();
        return () => { }
    }, [settingStore]);

    autorun(() => {
        const { generalSettingSuccess, adsSetting } = settingStore;
        const { handleTime, handleTimeStatus, handleStayTime, handleStayTimeStatus } = localStore;

        if (generalSettingSuccess) {
            const { seconds = 0, ads_stay_seconds } = adsSetting[0] || {};
            handleTime(seconds);
            handleTimeStatus(seconds > 60 ? 'm' : 's');

            handleStayTime(ads_stay_seconds);
            handleStayTimeStatus(ads_stay_seconds > 60 ? 'm' : 's');
        }
    });

    const handleAdsBannerStatus = (e) => {
        const { handleAdsSection } = settingStore;
        const { target: { checked } } = e;
        handleAdsSection({ is_ads: checked, seconds: null });
    }

    const handleVideoAdsStatus = (e) => {
        const { handleAdsSection } = settingStore;
        const { target: { checked } } = e;
        handleAdsSection({ is_video_ads: checked, seconds: null });
    }

    const handleAdsTimeStatus = (e) => {
        const { handleTimeStatus } = localStore;
        const { target: { value } } = e;
        handleTimeStatus(value);

        handleAdsTimeChange();
    }

    const handleAdsTime = (e) => {
        const { handleTime } = localStore;
        const { target: { value } } = e;
        handleTime(value);

        handleAdsTimeChange();
    }

    const handleAdsTimeChange = () => {
        const { handleAdsSection } = settingStore;
        const { time, timeStatus } = localStore;

        if (timeStatus === 's') {
            handleAdsSection({ is_ads: null, seconds: time > 60 ? Math.abs(time / 60) : time });
        } else if (timeStatus === 'm') {
            handleAdsSection({ is_ads: null, seconds: 60 <= time ? time : Math.abs(time * 60) });
        }
    }

    // stay break time

    const handleAdsStayTime = (e) => {
        const { handleStayTime } = localStore;
        const { target: { value } } = e;
        handleStayTime(value);

        handleAdsStayTimeChange();
    }

    const handleAdsStayTimeStatus = (e) => {
        const { handleStayTimeStatus } = localStore;
        const { target: { value } } = e;
        handleStayTimeStatus(value);

        handleAdsStayTimeChange();
    }

    const handleAdsStayTimeChange = () => {
        const { handleStayAdsSection } = settingStore;
        const { stayTime, stayTimeStatus } = localStore;

        if (stayTimeStatus === 's') {
            handleStayAdsSection({ is_ads: null, ads_stay_seconds: stayTime > 60 ? Math.abs(stayTime / 60) : stayTime });
        } else if (stayTimeStatus === 'm') {
            handleStayAdsSection({ is_ads: null, ads_stay_seconds: 60 <= stayTime ? stayTime : Math.abs(stayTime * 60) });
        }
    }

    return (
        <Observer>
            {() => {
                const { adsSetting = [], adsSettingLoading = false } = settingStore;
                const { seconds = 0, is_ads = false, is_video_ads = false, ads_stay_seconds = 0 } = adsSetting[0] || {};

                const timeStatus = seconds > 60 ? 'm' : 's';
                const time = seconds > 60 ? seconds / 60 : seconds;

                const timeStayStatus = ads_stay_seconds > 60 ? 'm' : 's';
                const timeStay = ads_stay_seconds > 60 ? ads_stay_seconds / 60 : ads_stay_seconds;

                if (adsSettingLoading) {
                    return (
                        <div className="loader_center mt-5">
                            <SmallLoader color={'black'} />
                        </div>
                    )
                }

                return (
                    <Fragment>
                        <div className="content active" id="ads">
                            <div className="row">
                                <div className="col-12 mb-3">
                                    {/* Banner Ads */}
                                    <div className="mb-5">
                                        <h6 className="text-muted2 text-uppercase">Banner Ads</h6>
                                        <ul className="list-unstyled">
                                            <li className="mb-2">
                                                <div className="form-check form-switch">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="bannerAds"
                                                        defaultChecked={is_ads}
                                                        onChange={handleAdsBannerStatus}
                                                    />
                                                    <label className="form-check-label text-muted2" for="bannerAds">
                                                        Turn on the banner advertisement
                                                    </label>
                                                </div>
                                            </li>
                                            <li className="mb-5">
                                                <div className="form-check form-switch ps-0">
                                                    <label className="form-check-label text-muted2 d-flex" for="flexSwitchCheckDefault3">
                                                        <span className="me-2">Change banner ad every</span>
                                                        <span className="me-2">
                                                            <select
                                                                className="form-select form-control form-select-sm form-time2"
                                                                onChange={handleAdsTime}
                                                                defaultValue={time}
                                                            >
                                                                {numbers.map((val, i) => (<option key={i} value={val}>{val}</option>))}
                                                            </select>
                                                        </span>
                                                        <span className="me-2">
                                                            <select
                                                                className="form-select form-control form-select-sm form-time2"
                                                                onChange={handleAdsTimeStatus}
                                                                defaultValue={timeStatus}
                                                            >
                                                                <option value="s">Seconds</option>
                                                                <option value="m">Minutes</option>
                                                            </select>
                                                        </span>
                                                    </label>
                                                </div>
                                            </li>
                                            <li className="mb-2">
                                                <div className="form-check form-switch ps-0">
                                                    <label className="form-check-label text-muted2 d-flex" for="flexSwitchCheckDefault3">
                                                        <span className="me-2">Keep banner ad for &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                        <span className="me-2">
                                                            <select
                                                                className="form-select form-control form-select-sm form-time2"
                                                                onChange={handleAdsStayTime}
                                                                defaultValue={timeStay}
                                                            >
                                                                {numbers.map((val, i) => (<option key={i} value={val}>{val}</option>))}
                                                            </select>
                                                        </span>
                                                        <span className="me-2">
                                                            <select
                                                                className="form-select form-control form-select-sm form-time2"
                                                                onChange={handleAdsStayTimeStatus}
                                                                defaultValue={timeStayStatus}
                                                            >
                                                                <option value="s">Seconds</option>
                                                                <option value="m">Minutes</option>
                                                            </select>
                                                        </span>
                                                    </label>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    {/* Video Ads */}
                                    <div className="mb-5">
                                        <h6 className="text-muted2 text-uppercase">Video Ads</h6>
                                        <ul className="list-unstyled">
                                            <li className="mb-2">
                                                <div className="form-check form-switch">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="videoAds"
                                                        defaultChecked={is_video_ads}
                                                        onChange={handleVideoAdsStatus}
                                                    />
                                                    <label className="form-check-label text-muted2" for="videoAds">
                                                        Turn on the video advertisement
                                                    </label>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Outlet />
                    </Fragment>
                )
            }}
        </Observer>
    );
}

export default AdsSection;