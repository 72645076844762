import { Fragment } from 'react';

const StreamUserFilter = ({ onChange }) => {
    return (
        <Fragment>
            <button
                type="button"
                className="btn btn-icon btn-soft-light dropdown-toggle p-0"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
            >
                <i className="mdi mdi-view-column"></i>
            </button>
            <div className="dropdown-menu dd-menu dropdown-menu-end bg-dropdown shadow border-0 mt-3 py-3">
                <div className="form-check">
                    <p className="dropdown-item text-dark m-0">
                        <input className="form-check-input" type="checkbox" value="full_name" id="full_name" defaultChecked onChange={onChange} />
                        <label className="form-check-label" htmlFor="full_name">FULL NAME</label>
                    </p>
                </div>
                <div className="form-check">
                    <p className="dropdown-item text-dark m-0">
                        <input className="form-check-input" type="checkbox" value="email" defaultChecked id="email" onChange={onChange} />
                        <label className="form-check-label" htmlFor="email">EMAIL</label>
                    </p>
                </div>
                <div className="form-check">
                    <p className="dropdown-item text-dark m-0">
                        <input className="form-check-input" type="checkbox" value="signed_up" defaultChecked id="signed" onChange={onChange} />
                        <label className="form-check-label" htmlFor="signed">SIGNED UP AT</label>
                    </p>
                </div>
                <div className="form-check">
                    <p className="dropdown-item text-dark m-0">
                        <input className="form-check-input" type="checkbox" value="ads" defaultChecked id="ads" onChange={onChange} />
                        <label className="form-check-label" htmlFor="ads">ADS CLICKED</label>
                    </p>
                </div>
                {/* <div className="form-check">
                    <p className="dropdown-item text-dark m-0">
                        <input className="form-check-input" type="checkbox" value="attendance" defaultChecked id="attendance" onChange={onChange} />
                        <label className="form-check-label" htmlFor="attendance">% Attendance </label>
                    </p>
                </div> */}
                <div className="form-check">
                    <p className="dropdown-item text-dark m-0">
                        <input className="form-check-input" type="checkbox" value="country" defaultChecked id="country" onChange={onChange} />
                        <label className="form-check-label" htmlFor="country">COUNTRY</label>
                    </p>
                </div>
                <div className="form-check">
                    <p className="dropdown-item text-dark m-0">
                        <input className="form-check-input" type="checkbox" value="city" defaultChecked id="city" onChange={onChange} />
                        <label className="form-check-label" htmlFor="city">CITY</label>
                    </p>
                </div>
                <div className="form-check">
                    <p className="dropdown-item text-dark m-0">
                        <input className="form-check-input" type="checkbox" value="browser" defaultChecked id="browser" onChange={onChange} />
                        <label className="form-check-label" htmlFor="browser">BROWSER</label>
                    </p>
                </div>
                <div className="form-check">
                    <p className="dropdown-item text-dark m-0">
                        <input className="form-check-input" type="checkbox" value="os_name" defaultChecked id="os" onChange={onChange} />
                        <label className="form-check-label" htmlFor="os">OS</label>
                    </p>
                </div>
            </div>
        </Fragment>
    )
}
export default StreamUserFilter;