export const timeZones = [
    {
        value: "Dateline Standard Time",
        abbr: "DST",
        offset: -12,
        isdst: false,
        text: "International Date Line West (UTC-12:00)",
        utc: ["Etc/GMT+12"]
    },
    {
        value: "UTC-11",
        abbr: "U",
        offset: -11,
        isdst: false,
        text: "Coordinated Universal Time-11 (UTC-11:00)",
        utc: ["Etc/GMT+11", "Pacific/Midway", "Pacific/Niue", "Pacific/Pago_Pago"]
    },
    {
        value: "Hawaiian Standard Time",
        abbr: "HST",
        offset: -10,
        isdst: false,
        text: "Hawaii (UTC-10:00)",
        utc: [
            "Etc/GMT+10",
            "Pacific/Honolulu",
            "Pacific/Johnston",
            "Pacific/Rarotonga",
            "Pacific/Tahiti"
        ]
    },
    {
        value: "Alaskan Standard Time",
        abbr: "AKDT",
        offset: -8,
        isdst: true,
        text: "Alaska (UTC-09:00)",
        utc: [
            "America/Anchorage",
            "America/Juneau",
            "America/Nome",
            "America/Sitka",
            "America/Yakutat"
        ]
    },
    {
        value: "Pacific Standard Time (Mexico)",
        abbr: "PDT",
        offset: -7,
        isdst: true,
        text: "Baja California (UTC-08:00)",
        utc: ["America/Santa_Isabel"]
    },
    {
        value: "Pacific Daylight Time",
        abbr: "PDT",
        offset: -7,
        isdst: true,
        text: "Pacific Time (US & Canada (UTC-07:00)",
        utc: ["America/Los_Angeles", "America/Tijuana", "America/Vancouver"]
    },
    {
        value: "Pacific Standard Time",
        abbr: "PST",
        offset: -8,
        isdst: false,
        text: "Pacific Time (US & Canada (UTC-08:00)",
        utc: [
            "America/Los_Angeles",
            "America/Tijuana",
            "America/Vancouver",
            "PST8PDT"
        ]
    },
    {
        value: "US Mountain Standard Time",
        abbr: "UMST",
        offset: -7,
        isdst: false,
        text: "Arizona (UTC-07:00)",
        utc: [
            "America/Creston",
            "America/Dawson",
            "America/Dawson_Creek",
            "America/Hermosillo",
            "America/Phoenix",
            "America/Whitehorse",
            "Etc/GMT+7"
        ]
    },
    {
        value: "Mountain Standard Time (Mexico)",
        abbr: "MDT",
        offset: -6,
        isdst: true,
        text: "Chihuahua, La Paz, Mazatlan (UTC-07:00)",
        utc: ["America/Chihuahua", "America/Mazatlan"]
    },
    {
        value: "Mountain Standard Time",
        abbr: "MDT",
        offset: -6,
        isdst: true,
        text: "Mountain Time (US & Canada (UTC-07:00)",
        utc: [
            "America/Boise",
            "America/Cambridge_Bay",
            "America/Denver",
            "America/Edmonton",
            "America/Inuvik",
            "America/Ojinaga",
            "America/Yellowknife",
            "MST7MDT"
        ]
    },
    {
        value: "Central America Standard Time",
        abbr: "CAST",
        offset: -6,
        isdst: false,
        text: "Central America (UTC-06:00)",
        utc: [
            "America/Belize",
            "America/Costa_Rica",
            "America/El_Salvador",
            "America/Guatemala",
            "America/Managua",
            "America/Tegucigalpa",
            "Etc/GMT+6",
            "Pacific/Galapagos"
        ]
    },
    {
        value: "Central Standard Time",
        abbr: "CDT",
        offset: -5,
        isdst: true,
        text: "Central Time (US & Canada (UTC-06:00)",
        utc: [
            "America/Chicago",
            "America/Indiana/Knox",
            "America/Indiana/Tell_City",
            "America/Matamoros",
            "America/Menominee",
            "America/North_Dakota/Beulah",
            "America/North_Dakota/Center",
            "America/North_Dakota/New_Salem",
            "America/Rainy_River",
            "America/Rankin_Inlet",
            "America/Resolute",
            "America/Winnipeg",
            "CST6CDT"
        ]
    },
    {
        value: "Central Standard Time (Mexico)",
        abbr: "CDT",
        offset: -5,
        isdst: true,
        text: "Guadalajara, Mexico City, Monterrey (UTC-06:00)",
        utc: [
            "America/Bahia_Banderas",
            "America/Cancun",
            "America/Merida",
            "America/Mexico_City",
            "America/Monterrey"
        ]
    },
    {
        value: "Canada Central Standard Time",
        abbr: "CCST",
        offset: -6,
        isdst: false,
        text: "Saskatchewan (UTC-06:00)",
        utc: ["America/Regina", "America/Swift_Current"]
    },
    {
        value: "SA Pacific Standard Time",
        abbr: "SPST",
        offset: -5,
        isdst: false,
        text: "Bogota, Lima, Quito (UTC-05:00)",
        utc: [
            "America/Bogota",
            "America/Cayman",
            "America/Coral_Harbour",
            "America/Eirunepe",
            "America/Guayaquil",
            "America/Jamaica",
            "America/Lima",
            "America/Panama",
            "America/Rio_Branco",
            "Etc/GMT+5"
        ]
    },
    {
        value: "Eastern Standard Time",
        abbr: "EST",
        offset: -5,
        isdst: false,
        text: "Eastern Time (US & Canada (UTC-05:00)",
        utc: [
            "America/Detroit",
            "America/Havana",
            "America/Indiana/Petersburg",
            "America/Indiana/Vincennes",
            "America/Indiana/Winamac",
            "America/Iqaluit",
            "America/Kentucky/Monticello",
            "America/Louisville",
            "America/Montreal",
            "America/Nassau",
            "America/New_York",
            "America/Nipigon",
            "America/Pangnirtung",
            "America/Port-au-Prince",
            "America/Thunder_Bay",
            "America/Toronto"
        ]
    },
    {
        value: "Eastern Daylight Time",
        abbr: "EDT",
        offset: -4,
        isdst: true,
        text: "Eastern Daylight Time (US & Canada (UTC-04:00)",
        utc: [
            "America/Detroit",
            "America/Havana",
            "America/Indiana/Petersburg",
            "America/Indiana/Vincennes",
            "America/Indiana/Winamac",
            "America/Iqaluit",
            "America/Kentucky/Monticello",
            "America/Louisville",
            "America/Montreal",
            "America/Nassau",
            "America/New_York",
            "America/Nipigon",
            "America/Pangnirtung",
            "America/Port-au-Prince",
            "America/Thunder_Bay",
            "America/Toronto"
        ]
    },
    {
        value: "US Eastern Standard Time",
        abbr: "UEDT",
        offset: -5,
        isdst: false,
        text: "Indiana (East (UTC-05:00)",
        utc: [
            "America/Indiana/Marengo",
            "America/Indiana/Vevay",
            "America/Indianapolis"
        ]
    },
    {
        value: "Venezuela Standard Time",
        abbr: "VST",
        offset: -4.5,
        isdst: false,
        text: "Caracas (UTC-04:30)",
        utc: ["America/Caracas"]
    },
    {
        value: "Paraguay Standard Time",
        abbr: "PYT",
        offset: -4,
        isdst: false,
        text: "Asuncion (UTC-04:00)",
        utc: ["America/Asuncion"]
    },
    {
        value: "Atlantic Standard Time",
        abbr: "ADT",
        offset: -3,
        isdst: true,
        text: "Atlantic Time (Canada (UTC-04:00)",
        utc: [
            "America/Glace_Bay",
            "America/Goose_Bay",
            "America/Halifax",
            "America/Moncton",
            "America/Thule",
            "Atlantic/Bermuda"
        ]
    },
    {
        value: "Central Brazilian Standard Time",
        abbr: "CBST",
        offset: -4,
        isdst: false,
        text: "Cuiaba (UTC-04:00)",
        utc: ["America/Campo_Grande", "America/Cuiaba"]
    },
    {
        value: "SA Western Standard Time",
        abbr: "SWST",
        offset: -4,
        isdst: false,
        text: "Georgetown, La Paz, Manaus, San Juan (UTC-04:00)",
        utc: [
            "America/Anguilla",
            "America/Antigua",
            "America/Aruba",
            "America/Barbados",
            "America/Blanc-Sablon",
            "America/Boa_Vista",
            "America/Curacao",
            "America/Dominica",
            "America/Grand_Turk",
            "America/Grenada",
            "America/Guadeloupe",
            "America/Guyana",
            "America/Kralendijk",
            "America/La_Paz",
            "America/Lower_Princes",
            "America/Manaus",
            "America/Marigot",
            "America/Martinique",
            "America/Montserrat",
            "America/Port_of_Spain",
            "America/Porto_Velho",
            "America/Puerto_Rico",
            "America/Santo_Domingo",
            "America/St_Barthelemy",
            "America/St_Kitts",
            "America/St_Lucia",
            "America/St_Thomas",
            "America/St_Vincent",
            "America/Tortola",
            "Etc/GMT+4"
        ]
    },
    {
        value: "Pacific SA Standard Time",
        abbr: "PSST",
        offset: -4,
        isdst: false,
        text: "Santiago (UTC-04:00)",
        utc: ["America/Santiago", "Antarctica/Palmer"]
    },
    {
        value: "Newfoundland Standard Time",
        abbr: "NDT",
        offset: -2.5,
        isdst: true,
        text: "Newfoundland (UTC-03:30)",
        utc: ["America/St_Johns"]
    },
    {
        value: "E. South America Standard Time",
        abbr: "ESAST",
        offset: -3,
        isdst: false,
        text: "Brasilia (UTC-03:00)",
        utc: ["America/Sao_Paulo"]
    },
    {
        value: "Argentina Standard Time",
        abbr: "AST",
        offset: -3,
        isdst: false,
        text: "Buenos Aires (UTC-03:00)",
        utc: [
            "America/Argentina/La_Rioja",
            "America/Argentina/Rio_Gallegos",
            "America/Argentina/Salta",
            "America/Argentina/San_Juan",
            "America/Argentina/San_Luis",
            "America/Argentina/Tucuman",
            "America/Argentina/Ushuaia",
            "America/Buenos_Aires",
            "America/Catamarca",
            "America/Cordoba",
            "America/Jujuy",
            "America/Mendoza"
        ]
    },
    {
        value: "SA Eastern Standard Time",
        abbr: "SEST",
        offset: -3,
        isdst: false,
        text: "Cayenne, Fortaleza (UTC-03:00)",
        utc: [
            "America/Araguaina",
            "America/Belem",
            "America/Cayenne",
            "America/Fortaleza",
            "America/Maceio",
            "America/Paramaribo",
            "America/Recife",
            "America/Santarem",
            "Antarctica/Rothera",
            "Atlantic/Stanley",
            "Etc/GMT+3"
        ]
    },
    {
        value: "Greenland Standard Time",
        abbr: "GDT",
        offset: -3,
        isdst: true,
        text: "Greenland (UTC-03:00)",
        utc: ["America/Godthab"]
    },
    {
        value: "Montevideo Standard Time",
        abbr: "MST",
        offset: -3,
        isdst: false,
        text: "Montevideo (UTC-03:00)",
        utc: ["America/Montevideo"]
    },
    {
        value: "Bahia Standard Time",
        abbr: "BST",
        offset: -3,
        isdst: false,
        text: "Salvador (UTC-03:00)",
        utc: ["America/Bahia"]
    },
    {
        value: "UTC-02",
        abbr: "U",
        offset: -2,
        isdst: false,
        text: "Coordinated Universal Time-02 (UTC-02:00)",
        utc: ["America/Noronha", "Atlantic/South_Georgia", "Etc/GMT+2"]
    },
    {
        value: "Mid-Atlantic Standard Time",
        abbr: "MDT",
        offset: -1,
        isdst: true,
        text: "Mid-Atlantic - Old (UTC-02:00)",
        utc: []
    },
    {
        value: "Azores Standard Time",
        abbr: "ADT",
        offset: 0,
        isdst: true,
        text: "Azores (UTC-01:00)",
        utc: ["America/Scoresbysund", "Atlantic/Azores"]
    },
    {
        value: "Cape Verde Standard Time",
        abbr: "CVST",
        offset: -1,
        isdst: false,
        text: "Cape Verde Is. (UTC-01:00)",
        utc: ["Atlantic/Cape_Verde", "Etc/GMT+1"]
    },
    {
        value: "Morocco Standard Time",
        abbr: "MDT",
        offset: 1,
        isdst: true,
        text: "Casablanca (UTC)",
        utc: ["Africa/Casablanca", "Africa/El_Aaiun"]
    },
    {
        value: "UTC",
        abbr: "UTC",
        offset: 0,
        isdst: false,
        text: "Coordinated Universal Time (UTC)",
        utc: ["America/Danmarkshavn", "Etc/GMT"]
    },
    {
        value: "GMT Standard Time",
        abbr: "GMT",
        offset: 0,
        isdst: false,
        text: "Edinburgh, London (UTC)",
        utc: [
            "Europe/Isle_of_Man",
            "Europe/Guernsey",
            "Europe/Jersey",
            "Europe/London"
        ]
    },
    {
        value: "British Summer Time",
        abbr: "BST",
        offset: 1,
        isdst: true,
        text: "Edinburgh, London (UTC+01:00)",
        utc: [
            "Europe/Isle_of_Man",
            "Europe/Guernsey",
            "Europe/Jersey",
            "Europe/London"
        ]
    },
    {
        value: "GMT Standard Time",
        abbr: "GDT",
        offset: 1,
        isdst: true,
        text: "Dublin, Lisbon (UTC)",
        utc: [
            "Atlantic/Canary",
            "Atlantic/Faeroe",
            "Atlantic/Madeira",
            "Europe/Dublin",
            "Europe/Lisbon"
        ]
    },
    {
        value: "Greenwich Standard Time",
        abbr: "GST",
        offset: 0,
        isdst: false,
        text: "Monrovia, Reykjavik (UTC)",
        utc: [
            "Africa/Abidjan",
            "Africa/Accra",
            "Africa/Bamako",
            "Africa/Banjul",
            "Africa/Bissau",
            "Africa/Conakry",
            "Africa/Dakar",
            "Africa/Freetown",
            "Africa/Lome",
            "Africa/Monrovia",
            "Africa/Nouakchott",
            "Africa/Ouagadougou",
            "Africa/Sao_Tome",
            "Atlantic/Reykjavik",
            "Atlantic/St_Helena"
        ]
    },
    {
        value: "W. Europe Standard Time",
        abbr: "WEDT",
        offset: 2,
        isdst: true,
        text: "Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna (UTC+01:00)",
        utc: [
            "Arctic/Longyearbyen",
            "Europe/Amsterdam",
            "Europe/Andorra",
            "Europe/Berlin",
            "Europe/Busingen",
            "Europe/Gibraltar",
            "Europe/Luxembourg",
            "Europe/Malta",
            "Europe/Monaco",
            "Europe/Oslo",
            "Europe/Rome",
            "Europe/San_Marino",
            "Europe/Stockholm",
            "Europe/Vaduz",
            "Europe/Vatican",
            "Europe/Vienna",
            "Europe/Zurich"
        ]
    },
    {
        value: "Central Europe Standard Time",
        abbr: "CEDT",
        offset: 2,
        isdst: true,
        text: "Belgrade, Bratislava, Budapest, Ljubljana, Prague (UTC+01:00)",
        utc: [
            "Europe/Belgrade",
            "Europe/Bratislava",
            "Europe/Budapest",
            "Europe/Ljubljana",
            "Europe/Podgorica",
            "Europe/Prague",
            "Europe/Tirane"
        ]
    },
    {
        value: "Romance Standard Time",
        abbr: "RDT",
        offset: 2,
        isdst: true,
        text: "Brussels, Copenhagen, Madrid, Paris (UTC+01:00)",
        utc: [
            "Africa/Ceuta",
            "Europe/Brussels",
            "Europe/Copenhagen",
            "Europe/Madrid",
            "Europe/Paris"
        ]
    },
    {
        value: "Central European Standard Time",
        abbr: "CEDT",
        offset: 2,
        isdst: true,
        text: "Sarajevo, Skopje, Warsaw, Zagreb (UTC+01:00)",
        utc: ["Europe/Sarajevo", "Europe/Skopje", "Europe/Warsaw", "Europe/Zagreb"]
    },
    {
        value: "W. Central Africa Standard Time",
        abbr: "WCAST",
        offset: 1,
        isdst: false,
        text: "West Central Africa (UTC+01:00)",
        utc: [
            "Africa/Algiers",
            "Africa/Bangui",
            "Africa/Brazzaville",
            "Africa/Douala",
            "Africa/Kinshasa",
            "Africa/Lagos",
            "Africa/Libreville",
            "Africa/Luanda",
            "Africa/Malabo",
            "Africa/Ndjamena",
            "Africa/Niamey",
            "Africa/Porto-Novo",
            "Africa/Tunis",
            "Etc/GMT-1"
        ]
    },
    {
        value: "Namibia Standard Time",
        abbr: "NST",
        offset: 1,
        isdst: false,
        text: "Windhoek (UTC+01:00)",
        utc: ["Africa/Windhoek"]
    },
    {
        value: "GTB Standard Time",
        abbr: "GDT",
        offset: 3,
        isdst: true,
        text: "Athens, Bucharest (UTC+02:00)",
        utc: [
            "Asia/Nicosia",
            "Europe/Athens",
            "Europe/Bucharest",
            "Europe/Chisinau"
        ]
    },
    {
        value: "Middle East Standard Time",
        abbr: "MEDT",
        offset: 3,
        isdst: true,
        text: "Beirut (UTC+02:00)",
        utc: ["Asia/Beirut"]
    },
    {
        value: "Egypt Standard Time",
        abbr: "EST",
        offset: 2,
        isdst: false,
        text: "Cairo (UTC+02:00)",
        utc: ["Africa/Cairo"]
    },
    {
        value: "Syria Standard Time",
        abbr: "SDT",
        offset: 3,
        isdst: true,
        text: "Damascus (UTC+02:00)",
        utc: ["Asia/Damascus"]
    },
    {
        value: "E. Europe Standard Time",
        abbr: "EEDT",
        offset: 3,
        isdst: true,
        text: "E. Europe (UTC+02:00)",
        utc: [
            "Asia/Nicosia",
            "Europe/Athens",
            "Europe/Bucharest",
            "Europe/Chisinau",
            "Europe/Helsinki",
            "Europe/Kiev",
            "Europe/Mariehamn",
            "Europe/Nicosia",
            "Europe/Riga",
            "Europe/Sofia",
            "Europe/Tallinn",
            "Europe/Uzhgorod",
            "Europe/Vilnius",
            "Europe/Zaporozhye"
        ]
    },
    {
        value: "South Africa Standard Time",
        abbr: "SAST",
        offset: 2,
        isdst: false,
        text: "Harare, Pretoria (UTC+02:00)",
        utc: [
            "Africa/Blantyre",
            "Africa/Bujumbura",
            "Africa/Gaborone",
            "Africa/Harare",
            "Africa/Johannesburg",
            "Africa/Kigali",
            "Africa/Lubumbashi",
            "Africa/Lusaka",
            "Africa/Maputo",
            "Africa/Maseru",
            "Africa/Mbabane",
            "Etc/GMT-2"
        ]
    },
    {
        value: "FLE Standard Time",
        abbr: "FDT",
        offset: 3,
        isdst: true,
        text: "Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius (UTC+02:00)",
        utc: [
            "Europe/Helsinki",
            "Europe/Kiev",
            "Europe/Mariehamn",
            "Europe/Riga",
            "Europe/Sofia",
            "Europe/Tallinn",
            "Europe/Uzhgorod",
            "Europe/Vilnius",
            "Europe/Zaporozhye"
        ]
    },
    {
        value: "Turkey Standard Time",
        abbr: "TDT",
        offset: 3,
        isdst: false,
        text: "Istanbul (UTC+03:00)",
        utc: ["Europe/Istanbul"]
    },
    {
        value: "Israel Standard Time",
        abbr: "JDT",
        offset: 3,
        isdst: true,
        text: "Jerusalem (UTC+02:00)",
        utc: ["Asia/Jerusalem"]
    },
    {
        value: "Libya Standard Time",
        abbr: "LST",
        offset: 2,
        isdst: false,
        text: "Tripoli (UTC+02:00)",
        utc: ["Africa/Tripoli"]
    },
    {
        value: "Jordan Standard Time",
        abbr: "JST",
        offset: 3,
        isdst: false,
        text: "Amman (UTC+03:00)",
        utc: ["Asia/Amman"]
    },
    {
        value: "Arabic Standard Time",
        abbr: "AST",
        offset: 3,
        isdst: false,
        text: "Baghdad (UTC+03:00)",
        utc: ["Asia/Baghdad"]
    },
    {
        value: "Kaliningrad Standard Time",
        abbr: "KST",
        offset: 3,
        isdst: false,
        text: "Kaliningrad (UTC+02:00)",
        utc: ["Europe/Kaliningrad"]
    },
    {
        value: "Arab Standard Time",
        abbr: "AST",
        offset: 3,
        isdst: false,
        text: "Kuwait, Riyadh (UTC+03:00)",
        utc: [
            "Asia/Aden",
            "Asia/Bahrain",
            "Asia/Kuwait",
            "Asia/Qatar",
            "Asia/Riyadh"
        ]
    },
    {
        value: "E. Africa Standard Time",
        abbr: "EAST",
        offset: 3,
        isdst: false,
        text: "Nairobi (UTC+03:00)",
        utc: [
            "Africa/Addis_Ababa",
            "Africa/Asmera",
            "Africa/Dar_es_Salaam",
            "Africa/Djibouti",
            "Africa/Juba",
            "Africa/Kampala",
            "Africa/Khartoum",
            "Africa/Mogadishu",
            "Africa/Nairobi",
            "Antarctica/Syowa",
            "Etc/GMT-3",
            "Indian/Antananarivo",
            "Indian/Comoro",
            "Indian/Mayotte"
        ]
    },
    {
        value: "Moscow Standard Time",
        abbr: "MSK",
        offset: 3,
        isdst: false,
        text: "Moscow, St. Petersburg, Volgograd, Minsk (UTC+03:00)",
        utc: [
            "Europe/Kirov",
            "Europe/Moscow",
            "Europe/Simferopol",
            "Europe/Volgograd",
            "Europe/Minsk"
        ]
    },
    {
        value: "Samara Time",
        abbr: "SAMT",
        offset: 4,
        isdst: false,
        text: "Samara, Ulyanovsk, Saratov (UTC+04:00)",
        utc: ["Europe/Astrakhan", "Europe/Samara", "Europe/Ulyanovsk"]
    },
    {
        value: "Iran Standard Time",
        abbr: "IDT",
        offset: 4.5,
        isdst: true,
        text: "Tehran (UTC+03:30)",
        utc: ["Asia/Tehran"]
    },
    {
        value: "Arabian Standard Time",
        abbr: "AST",
        offset: 4,
        isdst: false,
        text: "Abu Dhabi, Muscat (UTC+04:00)",
        utc: ["Asia/Dubai", "Asia/Muscat", "Etc/GMT-4"]
    },
    {
        value: "Azerbaijan Standard Time",
        abbr: "ADT",
        offset: 5,
        isdst: true,
        text: "Baku (UTC+04:00)",
        utc: ["Asia/Baku"]
    },
    {
        value: "Mauritius Standard Time",
        abbr: "MST",
        offset: 4,
        isdst: false,
        text: "Port Louis (UTC+04:00)",
        utc: ["Indian/Mahe", "Indian/Mauritius", "Indian/Reunion"]
    },
    {
        value: "Georgian Standard Time",
        abbr: "GET",
        offset: 4,
        isdst: false,
        text: "Tbilisi (UTC+04:00)",
        utc: ["Asia/Tbilisi"]
    },
    {
        value: "Caucasus Standard Time",
        abbr: "CST",
        offset: 4,
        isdst: false,
        text: "Yerevan (UTC+04:00)",
        utc: ["Asia/Yerevan"]
    },
    {
        value: "Afghanistan Standard Time",
        abbr: "AST",
        offset: 4.5,
        isdst: false,
        text: "Kabul (UTC+04:30)",
        utc: ["Asia/Kabul"]
    },
    {
        value: "West Asia Standard Time",
        abbr: "WAST",
        offset: 5,
        isdst: false,
        text: "Ashgabat, Tashkent (UTC+05:00)",
        utc: [
            "Antarctica/Mawson",
            "Asia/Aqtau",
            "Asia/Aqtobe",
            "Asia/Ashgabat",
            "Asia/Dushanbe",
            "Asia/Oral",
            "Asia/Samarkand",
            "Asia/Tashkent",
            "Etc/GMT-5",
            "Indian/Kerguelen",
            "Indian/Maldives"
        ]
    },
    {
        value: "Yekaterinburg Time",
        abbr: "YEKT",
        offset: 5,
        isdst: false,
        text: "Yekaterinburg (UTC+05:00)",
        utc: ["Asia/Yekaterinburg"]
    },
    {
        value: "Pakistan Standard Time",
        abbr: "PKT",
        offset: 5,
        isdst: false,
        text: "Islamabad, Karachi (UTC+05:00)",
        utc: ["Asia/Karachi"]
    },
    {
        value: "India Standard Time",
        abbr: "IST",
        offset: 5.5,
        isdst: false,
        text: "Chennai, Kolkata, Mumbai, New Delhi (UTC+05:30)",
        utc: ["Asia/Kolkata", "Asia/Calcutta"]
    },
    {
        value: "Sri Lanka Standard Time",
        abbr: "SLST",
        offset: 5.5,
        isdst: false,
        text: "Sri Jayawardenepura (UTC+05:30)",
        utc: ["Asia/Colombo"]
    },
    {
        value: "Nepal Standard Time",
        abbr: "NST",
        offset: 5.75,
        isdst: false,
        text: "Kathmandu (UTC+05:45)",
        utc: ["Asia/Katmandu"]
    },
    {
        value: "Central Asia Standard Time",
        abbr: "CAST",
        offset: 6,
        isdst: false,
        text: "Nur-Sultan (Astana (UTC+06:00)",
        utc: [
            "Antarctica/Vostok",
            "Asia/Almaty",
            "Asia/Bishkek",
            "Asia/Qyzylorda",
            "Asia/Urumqi",
            "Etc/GMT-6",
            "Indian/Chagos"
        ]
    },
    {
        value: "Bangladesh Standard Time",
        abbr: "BST",
        offset: 6,
        isdst: false,
        text: "Dhaka (UTC+06:00)",
        utc: ["Asia/Dhaka", "Asia/Thimphu"]
    },
    {
        value: "Myanmar Standard Time",
        abbr: "MST",
        offset: 6.5,
        isdst: false,
        text: "Yangon (Rangoon (UTC+06:30)",
        utc: ["Asia/Rangoon", "Indian/Cocos"]
    },
    {
        value: "SE Asia Standard Time",
        abbr: "SAST",
        offset: 7,
        isdst: false,
        text: "Bangkok, Hanoi, Jakarta (UTC+07:00)",
        utc: [
            "Antarctica/Davis",
            "Asia/Bangkok",
            "Asia/Hovd",
            "Asia/Jakarta",
            "Asia/Phnom_Penh",
            "Asia/Pontianak",
            "Asia/Saigon",
            "Asia/Vientiane",
            "Etc/GMT-7",
            "Indian/Christmas"
        ]
    },
    {
        value: "N. Central Asia Standard Time",
        abbr: "NCAST",
        offset: 7,
        isdst: false,
        text: "Novosibirsk (UTC+07:00)",
        utc: ["Asia/Novokuznetsk", "Asia/Novosibirsk", "Asia/Omsk"]
    },
    {
        value: "China Standard Time",
        abbr: "CST",
        offset: 8,
        isdst: false,
        text: "Beijing, Chongqing, Hong Kong, Urumqi (UTC+08:00)",
        utc: ["Asia/Hong_Kong", "Asia/Macau", "Asia/Shanghai"]
    },
    {
        value: "North Asia Standard Time",
        abbr: "NAST",
        offset: 8,
        isdst: false,
        text: "Krasnoyarsk (UTC+08:00)",
        utc: ["Asia/Krasnoyarsk"]
    },
    {
        value: "Singapore Standard Time",
        abbr: "MPST",
        offset: 8,
        isdst: false,
        text: "Kuala Lumpur, Singapore (UTC+08:00)",
        utc: [
            "Asia/Brunei",
            "Asia/Kuala_Lumpur",
            "Asia/Kuching",
            "Asia/Makassar",
            "Asia/Manila",
            "Asia/Singapore",
            "Etc/GMT-8"
        ]
    },
    {
        value: "W. Australia Standard Time",
        abbr: "WAST",
        offset: 8,
        isdst: false,
        text: "Perth (UTC+08:00)",
        utc: ["Antarctica/Casey", "Australia/Perth"]
    },
    {
        value: "Taipei Standard Time",
        abbr: "TST",
        offset: 8,
        isdst: false,
        text: "Taipei (UTC+08:00)",
        utc: ["Asia/Taipei"]
    },
    {
        value: "Ulaanbaatar Standard Time",
        abbr: "UST",
        offset: 8,
        isdst: false,
        text: "Ulaanbaatar (UTC+08:00)",
        utc: ["Asia/Choibalsan", "Asia/Ulaanbaatar"]
    },
    {
        value: "North Asia East Standard Time",
        abbr: "NAEST",
        offset: 8,
        isdst: false,
        text: "Irkutsk (UTC+08:00)",
        utc: ["Asia/Irkutsk"]
    },
    {
        value: "Japan Standard Time",
        abbr: "JST",
        offset: 9,
        isdst: false,
        text: "Osaka, Sapporo, Tokyo (UTC+09:00)",
        utc: [
            "Asia/Dili",
            "Asia/Jayapura",
            "Asia/Tokyo",
            "Etc/GMT-9",
            "Pacific/Palau"
        ]
    },
    {
        value: "Korea Standard Time",
        abbr: "KST",
        offset: 9,
        isdst: false,
        text: "Seoul (UTC+09:00)",
        utc: ["Asia/Pyongyang", "Asia/Seoul"]
    },
    {
        value: "Cen. Australia Standard Time",
        abbr: "CAST",
        offset: 9.5,
        isdst: false,
        text: "Adelaide (UTC+09:30)",
        utc: ["Australia/Adelaide", "Australia/Broken_Hill"]
    },
    {
        value: "AUS Central Standard Time",
        abbr: "ACST",
        offset: 9.5,
        isdst: false,
        text: "Darwin (UTC+09:30)",
        utc: ["Australia/Darwin"]
    },
    {
        value: "E. Australia Standard Time",
        abbr: "EAST",
        offset: 10,
        isdst: false,
        text: "Brisbane (UTC+10:00)",
        utc: ["Australia/Brisbane", "Australia/Lindeman"]
    },
    {
        value: "AUS Eastern Standard Time",
        abbr: "AEST",
        offset: 10,
        isdst: false,
        text: "Canberra, Melbourne, Sydney (UTC+10:00)",
        utc: ["Australia/Melbourne", "Australia/Sydney"]
    },
    {
        value: "West Pacific Standard Time",
        abbr: "WPST",
        offset: 10,
        isdst: false,
        text: "Guam, Port Moresby (UTC+10:00)",
        utc: [
            "Antarctica/DumontDUrville",
            "Etc/GMT-10",
            "Pacific/Guam",
            "Pacific/Port_Moresby",
            "Pacific/Saipan",
            "Pacific/Truk"
        ]
    },
    {
        value: "Tasmania Standard Time",
        abbr: "TST",
        offset: 10,
        isdst: false,
        text: "Hobart (UTC+10:00)",
        utc: ["Australia/Currie", "Australia/Hobart"]
    },
    {
        value: "Yakutsk Standard Time",
        abbr: "YST",
        offset: 9,
        isdst: false,
        text: "Yakutsk (UTC+09:00)",
        utc: ["Asia/Chita", "Asia/Khandyga", "Asia/Yakutsk"]
    },
    {
        value: "Central Pacific Standard Time",
        abbr: "CPST",
        offset: 11,
        isdst: false,
        text: "Solomon Is., New Caledonia (UTC+11:00)",
        utc: [
            "Antarctica/Macquarie",
            "Etc/GMT-11",
            "Pacific/Efate",
            "Pacific/Guadalcanal",
            "Pacific/Kosrae",
            "Pacific/Noumea",
            "Pacific/Ponape"
        ]
    },
    {
        value: "Vladivostok Standard Time",
        abbr: "VST",
        offset: 11,
        isdst: false,
        text: "Vladivostok (UTC+11:00)",
        utc: ["Asia/Sakhalin", "Asia/Ust-Nera", "Asia/Vladivostok"]
    },
    {
        value: "New Zealand Standard Time",
        abbr: "NZST",
        offset: 12,
        isdst: false,
        text: "Auckland, Wellington (UTC+12:00)",
        utc: ["Antarctica/McMurdo", "Pacific/Auckland"]
    },
    {
        value: "UTC+12",
        abbr: "U",
        offset: 12,
        isdst: false,
        text: "Coordinated Universal Time+12 (UTC+12:00)",
        utc: [
            "Etc/GMT-12",
            "Pacific/Funafuti",
            "Pacific/Kwajalein",
            "Pacific/Majuro",
            "Pacific/Nauru",
            "Pacific/Tarawa",
            "Pacific/Wake",
            "Pacific/Wallis"
        ]
    },
    {
        value: "Fiji Standard Time",
        abbr: "FST",
        offset: 12,
        isdst: false,
        text: "Fiji (UTC+12:00)",
        utc: ["Pacific/Fiji"]
    },
    {
        value: "Magadan Standard Time",
        abbr: "MST",
        offset: 12,
        isdst: false,
        text: "Magadan (UTC+12:00)",
        utc: ["Asia/Anadyr", "Asia/Kamchatka", "Asia/Magadan", "Asia/Srednekolymsk"]
    },
    {
        value: "Kamchatka Standard Time",
        abbr: "KDT",
        offset: 13,
        isdst: true,
        text: "Petropavlovsk-Kamchatsky - Old (UTC+12:00)",
        utc: ["Asia/Kamchatka"]
    },
    {
        value: "Tonga Standard Time",
        abbr: "TST",
        offset: 13,
        isdst: false,
        text: "Nuku'alofa (UTC+13:00)",
        utc: [
            "Etc/GMT-13",
            "Pacific/Enderbury",
            "Pacific/Fakaofo",
            "Pacific/Tongatapu"
        ]
    },
    {
        value: "Samoa Standard Time",
        abbr: "SST",
        offset: 13,
        isdst: false,
        text: "Samoa (UTC+13:00)",
        utc: ["Pacific/Apia"]
    }
];