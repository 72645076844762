import { makeObservable, runInAction, action, observable } from 'mobx';
import { getRequest, patchRequest } from 'src/utils/api';
import {
    getLocalStorageItems, getValidDataFromResponse, isValidArray, currentTimeZone, sendNotification,
    isValidObject, validBodyFieldsForUpdate
} from 'src/utils/utilities';

class SettingStore {

    userUploadedImageAcceptedFormats = ["image/jpeg", "image/jpg", "image/png"];

    generalSettingSuccess = false;
    adsSettingLoading = false;
    emailSettingLoading = false;

    generalSetting = [];
    profileSetting = [];
    adsSetting = [];
    emailSetting = [];

    userProfileDataLoading = false;
    userProfileDataSuccess = false;
    userProfileData = [];

    uploadedProfileImage = null;

    updateSuccess = false;

    constructor() {
        makeObservable(this, {
            handleGeneralSection: action,
            handleAdsSection: action,
            handleEmailSection: action,
            getOrganizerInfo: action,
            generalSetting: observable,
            profileSetting: observable,
            adsSetting: observable,
            emailSetting: observable,
            generalSettingSuccess: observable,
            userProfileDataLoading: observable,
            userProfileData: observable,
            userProfileDataSuccess: observable,
            uploadedProfileImage: observable,
            updateSuccess: observable,
            adsSettingLoading: observable,
            emailSettingLoading: observable,
            handleStayAdsSection: action,
        });
    }

    getOrganizerInfo = async () => {
        try {

            runInAction(() => {
                this.adsSettingLoading = true;
                this.emailSettingLoading = true;
            });

            const { oid } = getLocalStorageItems({ getAll: true, keys: ["oid"] });

            const { response } = await getRequest(`/organizer/${oid}`);
            const { data, error } = getValidDataFromResponse(response, false, true, false);

            if (!error && isValidArray(data)) {
                runInAction(() => {
                    this.setGeneralSettingSection(data[0]);
                    this.setAdsSettingSection(data[0]);
                    this.setProfileSettingSection(data[0]);
                    this.setEmailSettingSection(data[0])
                    this.generalSettingSuccess = true;
                    this.adsSettingLoading = false;
                    this.emailSettingLoading = false;
                });
            }

            runInAction(() => {
                this.generalSettingSuccess = false;
                this.adsSettingLoading = false;
                this.emailSettingLoading = false;
            });

        } catch (error) {
            runInAction(() => {
                this.generalSettingSuccess = false;
                this.adsSettingLoading = false;
                this.emailSettingLoading = false;
            });
        }
    }

    handleGeneralSection = async ({ timezone }) => {
        try {

            const { uid, oid } = getLocalStorageItems({ getAll: true, keys: ["uid", "oid"] });

            const sendReqData = {
                user_id: uid,
                org_id: oid,
                timezone: timezone
            }

            const { response } = await patchRequest('/organizer/update', sendReqData);
            const { data, error } = getValidDataFromResponse(response, true);

            if (!error && isValidArray(data)) {
                runInAction(() => {
                    this.setGeneralSettingSection(data[0]);
                    this.setAdsSettingSection(data[0]);
                    this.setProfileSettingSection(data[0]);
                });
            }

        } catch (error) {

        }
    }

    handleAdsSection = async ({ is_ads, is_video_ads, seconds }) => {
        try {
            const { uid, oid } = getLocalStorageItems({ getAll: true, keys: ["uid", "oid"] });

            const sendReqData = {
                user_id: uid,
                org_id: oid,
                seconds: seconds,
                is_ads: is_ads,
                is_video_ads: is_video_ads
            }

            const setData = validBodyFieldsForUpdate(sendReqData, []);

            const { response } = await patchRequest('/organizer/update', setData);
            const { data, error } = getValidDataFromResponse(response, true);

            if (!error && isValidArray(data)) {
                runInAction(() => {
                    this.setGeneralSettingSection(data[0]);
                    this.setAdsSettingSection(data[0]);
                    this.setProfileSettingSection(data[0]);
                });
            }
        } catch (error) {

        }
    }

    handleEmailSection = async ({name, checked, value }) => {
        try {
            const { uid, oid } = getLocalStorageItems({ getAll: true, keys: ["uid", "oid"]});

            const sendReqData = { 
                user_id: uid,
                org_id: oid,
                // Publishers Flags
                is_new_user_register : name === 'is_new_user_register' ? checked : "",
                is_stream_created_or_updated : name === 'is_stream_created_or_updated' ? checked : "",
                is_hours_before_stream : name === 'is_hours_before_stream' ? checked : "",
                email_organizer_hours_before : name === 'email_organizer_hours_before' ? value : "",
                is_days_before_stream : name === 'is_days_before_stream' ? checked : "",
                email_organizer_days_before : name === 'email_organizer_days_before' ? value : "",
                is_recording_ready : name === 'is_recording_ready' ? checked : "",
                // Attendees Flags
                is_attendee_register : name === 'is_attendee_register' ? checked : "",
                is_hours_before_stream_attendee : name === 'is_hours_before_stream_attendee' ? checked : "",
                email_attendee_hours_before : name === 'email_attendee_hours_before' ? value : "",
                is_days_before_stream_attendee : name === 'is_days_before_stream_attendee' ? checked : "",
                email_attendee_days_before : name === 'email_attendee_days_before' ? value : "",
                is_recording_ready_attendee : name === 'is_recording_ready_attendee' ? checked : "",
            }

            const setData = validBodyFieldsForUpdate(sendReqData, []);

            const { response } = await patchRequest('/organizer/update', setData);
            const { data, error } = getValidDataFromResponse(response, true);

            if (!error && isValidArray(data)) {
                runInAction(() => {
                    this.setGeneralSettingSection(data[0]);
                    this.setAdsSettingSection(data[0]);
                    this.setProfileSettingSection(data[0]);
                    this.setEmailSettingSection(data[0]);
                });
            }

        } catch (error) {
            
        }
    }

    setGeneralSettingSection = (data) => {
        runInAction(() => {
            const { timezone = currentTimeZone } = data;
            this.generalSetting = [{ timezone }];
        });
    }

    setProfileSettingSection = (data) => {
        runInAction(() => { });
    }

    setAdsSettingSection = (data) => {
        runInAction(() => {
            const { seconds, is_ads, ads_stay_seconds, is_video_ads } = data;
            this.adsSetting = [{ seconds, is_ads, ads_stay_seconds, is_video_ads }];
        });
    }

    setEmailSettingSection = (data) => {
        runInAction(() => {
            this.emailSetting = [{ ...data }]
        })
    }

    // check image size
    bytesToSize = (bytes, maxSizeAccepted = 5) => {
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) return '0 Byte';
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        if (sizes[i] === 'MB') {
            return Math.round(bytes / Math.pow(1024, i), 2) < maxSizeAccepted;
        } else if (sizes[i] === 'KB') {
            return Math.round(bytes / Math.pow(1024, i), 2) < maxSizeAccepted * 1024;
        }
        return false;
    }

    // get base 64 form
    previewFile = (file, callback) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            callback(reader.result);
        };
        reader.readAsDataURL(file);
    }

    // remove first image from uploaded file form
    removeFileInputValue = () => {
        document.getElementById('file-input').value = '';
    }

    // When user upload latest image
    handleFileUpload = (file) => {
        try {
            if (isValidObject(file?.target?.files)) {
                const { type, size } = file.target.files['0'];
                if (!this.userUploadedImageAcceptedFormats.includes(type)) {
                    sendNotification({ notificationType: 'warning', message: 'Unsupported file type. Only JPG,JPEG,PNG are available.', duration: 3000 });
                } else if (!this.bytesToSize(size)) {
                    sendNotification({ notificationType: 'warning', message: 'File size too Small or Bigger than 5 MB.', duration: 3000 });
                } else {
                    this.previewFile(file.target.files['0'], (f) => {
                        this.uploadedProfileImage = f;
                    })
                }
            } else {
                throw new Error('Something went wrong.');
            }
            this.removeFileInputValue();
        } catch (error) {
            this.removeFileInputValue();
            sendNotification({ notificationType: 'error', message: 'Something wrong' });
        }
    }

    getUserProfileData = async () => {
        try {
            this.userProfileDataLoading = true;

            const { uid } = getLocalStorageItems({ get: true, key: "uid" });
            const { response } = await getRequest(`/user/info/${uid}`);
            const { data, error } = getValidDataFromResponse(response, false, true, false);

            if (!error && isValidArray(data)) {
                runInAction(() => {
                    this.userProfileData = [...data];
                    this.setProfileImage(data);
                    this.userProfileDataLoading = false;
                    this.userProfileDataSuccess = true;
                })
            }
            runInAction(() => {
                this.userProfileDataSuccess = false;
                this.userProfileDataLoading = false;
            })
        } catch (error) {
            runInAction(() => {
                this.userProfileDataLoading = false;
            })
        }
    }

    setProfileImage = (data) => {
        runInAction(() => {
            this.uploadedProfileImage = data[0].profile_image;
        });
    }

    removeUploadedImage = () => {
        runInAction(() => {
            this.uploadedProfileImage = null;
        });
    }

    // When user click on save button
    handleUserProfileFormForSave = async (profileData) => {
        try {

            const { uid } = getLocalStorageItems({ get: true, key: "uid" });
            const setData = validBodyFieldsForUpdate({ ...profileData, profile_image: this.uploadedProfileImage, user_id: uid }, ['profile_image']);

            const { response } = await patchRequest(`/user/save/profile`, setData);
            const { data, error } = getValidDataFromResponse(response, false, true, false);

            if (!error && isValidArray(data)) {
                runInAction(() => {
                    this.updateSuccess = true;
                })
            }
            runInAction(() => {
                this.updateSuccess = false;
            })
        } catch (err) {
            runInAction(() => {
                this.updateSuccess = false;
            })
        }
    }

    handleStayAdsSection = async ({ is_ads, ads_stay_seconds }) => {
        try {
            const { uid, oid } = getLocalStorageItems({ getAll: true, keys: ["uid", "oid"] });

            const sendReqData = {
                user_id: uid,
                org_id: oid,
                ads_stay_seconds: ads_stay_seconds,
                is_ads: is_ads
            }

            const setData = validBodyFieldsForUpdate(sendReqData, []);

            const { response } = await patchRequest('/organizer/update', setData);
            const { data, error } = getValidDataFromResponse(response, true);

            if (!error && isValidArray(data)) {
                runInAction(() => {
                    this.setGeneralSettingSection(data[0]);
                    this.setAdsSettingSection(data[0]);
                    this.setProfileSettingSection(data[0]);
                });
            }
        } catch (error) {

        }
    }

}

export default SettingStore;
