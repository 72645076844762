import { Fragment } from 'react';
import { Observer, useLocalObservable } from 'mobx-react';

import RegisterNow from './registerNow';
import StreamDetailsForm from './streamDetails';
import AddFormField from './addFormField';

import EditFormField from './editFormField';
import EditSelectField from 'src/components/common/inputSelectEdit';
import InputTextEdit from 'src/components/common/inputTextEdit';
import SocialMediaEdit from './socialMedialEdit';
import { autorun } from 'mobx';
import { useMainStore } from 'src/container';



const SectionMain = () => {
    const mainStore = useMainStore();
    const { streamFormStore } = mainStore;

    const localStore = useLocalObservable(() => ({
        isAddFieldModal: false,
        handleAddFieldModal() {
            this.isAddFieldModal = !this.isAddFieldModal;
        },
        isEditFieldModal: false,
        handleEditFieldModal() {
            this.isEditFieldModal = !this.isEditFieldModal;
        },
        isEditSelectModal: false,
        isEditSelectModalOrderByIndex: null,
        handleSelectEditModal(index) {
            if (!this.isEditSelectModal) {
                this.isEditSelectModalOrderByIndex = index;
            } else {
                this.isEditSelectModalOrderByIndex = null;
            }
            this.isEditSelectModal = !this.isEditSelectModal;
        },
        isInputEditModal: false,
        isInputEditModalOrderByIndex: null,
        handleInputEditModal(index) {
            if (!this.isInputEditModal) {
                this.isInputEditModalOrderByIndex = index;
            } else {
                this.isInputEditModalOrderByIndex = null;
            }
            this.isInputEditModal = !this.isInputEditModal;
        },
        isSocialEditModal: false,
        isSocialEditModalOrderByIndex: null,
        socialName: null,
        handleSocialEditModal(index, name) {
            if (!this.isSocialEditModal) {
                this.isSocialEditModalOrderByIndex = index;
                this.socialName = name;
            } else {
                this.isSocialEditModalOrderByIndex = null;
                this.socialName = null;
            }
            this.isSocialEditModal = !this.isSocialEditModal;
        },
        isBgModal: false,
        colorModal: false,
        handleBgModal() {
            this.isBgModal = !this.isBgModal;
        },
        handleColorModal() {
            this.colorModal = !this.colorModal;
        },
    }));

    autorun(() => {
        const { defaultSetBgImageAndBgColor, getStreamFormDetailsLoading, getStreamFormDetailsSuccess } = streamFormStore;
        if (getStreamFormDetailsLoading && getStreamFormDetailsSuccess) {
            defaultSetBgImageAndBgColor();
        }
    });

    return (
        <Observer>
            {() => {
                const {
                    handleAddFieldModal, isAddFieldModal, handleEditFieldModal, isEditFieldModal, isEditSelectModal,
                    handleSelectEditModal, isEditSelectModalOrderByIndex, isInputEditModal, handleInputEditModal,
                    isInputEditModalOrderByIndex, isSocialEditModal, handleSocialEditModal,
                    isSocialEditModalOrderByIndex, socialName,colorModal, handleColorModal, handleBgModal, isBgModal
                } = localStore;

                return (
                    <Fragment>
                        <section className="section">
                            <div className="container-lg">

                                <div className="row">
                                    <StreamDetailsForm />
                                    <RegisterNow
                                        onOpenOrCloseAddFieldModal={handleAddFieldModal}
                                        onOpenOrCloseEditFieldModal={handleEditFieldModal}
                                        handleSelectEditModal={handleSelectEditModal}
                                        handleInputEditModal={handleInputEditModal}
                                        handleSocialEditModal={handleSocialEditModal}
                                    />
                                </div>

                            </div>
                        </section>

                        {isAddFieldModal &&
                            <AddFormField
                                onOpenOrCloseAddFieldModal={handleAddFieldModal}
                            />
                        }

                        {isEditFieldModal &&
                            <EditFormField
                                onOpenOrCloseEditFieldModal={handleEditFieldModal}
                                colorModal={colorModal}
                                handleColorModal={handleColorModal}
                                handleBgModal={handleBgModal}
                            />
                        }

                        {isEditSelectModal &&
                            <EditSelectField
                                handleSelectEditModal={handleSelectEditModal}
                                isEditSelectModalOrderByIndex={isEditSelectModalOrderByIndex}
                            />
                        }

                        {isInputEditModal &&
                            <InputTextEdit
                                onModalClose={handleInputEditModal}
                                isInputEditModalOrderByIndex={isInputEditModalOrderByIndex}
                            />
                        }

                        {isSocialEditModal &&
                            <SocialMediaEdit
                                onModalClose={handleSocialEditModal}
                                isSocialEditModalOrderByIndex={isSocialEditModalOrderByIndex}
                                socialName={socialName}
                            />
                        }

                    </Fragment>
                )
            }}
        </Observer>
    );
}


export default SectionMain;