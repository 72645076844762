import { makeAutoObservable, runInAction } from 'mobx';
import { postRequest } from 'src/utils/api';
import { getValidDataFromResponse, isValidArray, setLocalStorageItems } from 'src/utils/utilities';
class RegisterStore {
	isUserRegisterLoading = false;
	isSocialUserRegisterLoading = false;
	isRegisterOfUserAndOrgRedirectSuccess = false;

	constructor() {
		makeAutoObservable(this)
	}

	setUserTokenAndId = ({ _id, token }) => {
		setLocalStorageItems({ setAll: true, items: { uid: _id, token } });
	}

	setOrganizerIdAndOrgUserId = ({ org_user_id, org_id }) => {
		setLocalStorageItems({ setAll: true, items: { oid: org_id, ouid: org_user_id } });
	}

	handleOrganizerAndOrganizerUserRegister = async ({ _id }) => {
		try {
			const { response } = await postRequest('/organizer/create/org/user', { user_id: _id });
			const { data, error } = getValidDataFromResponse(response, false);
			if (!error && isValidArray(data)) {
				const { org_user = [] } = data[0];
				if (isValidArray(org_user)) {
					const { _id: org_user_id, org_id } = org_user[0];
					this.setOrganizerIdAndOrgUserId({ org_user_id, org_id });
					return { orgAndUserCreated: true }
				}
			}
			return { orgAndUserCreated: false }
		} catch (error) {
			return { orgAndUserCreated: false }
		}
	}

	handleRegisterUser = async (userFormData) => {
		try {
			// start loading
			runInAction(() => {
				this.isUserRegisterLoading = true;
			});

			const { response } = await postRequest('/user/register', userFormData);
			const { data, error } = getValidDataFromResponse(response);

			if (!error && isValidArray(data)) {
				this.setUserTokenAndId(data[0]);
				const { orgAndUserCreated } = await this.handleOrganizerAndOrganizerUserRegister(data[0]);
				if (orgAndUserCreated) {
					// stop loading and send to dashboard
					runInAction(() => {
						this.isUserRegisterLoading = false;
						this.isRegisterOfUserAndOrgRedirectSuccess = true;
					})
				}
				runInAction(() => {
					this.isRegisterOfUserAndOrgRedirectSuccess = false;
				})
			}
			throw new Error("Something going wrong!!");
		} catch (error) {
			runInAction(() => {
				this.isUserRegisterLoading = false;
			});
		}
	}

	handleSocialRegisterUser = async (userFormData) => {
		try {
			// start loading
			runInAction(() => {
				this.isSocialUserRegisterLoading = true;
			});

			const { response } = await postRequest('/user/social/register', userFormData);
			const { data, error } = getValidDataFromResponse(response, false);

			if (!error && isValidArray(data)) {
				this.setUserTokenAndId(data[0]);
				const { orgAndUserCreated } = await this.handleOrganizerAndOrganizerUserRegister(data[0]);
				if (orgAndUserCreated) {
					// stop loading and send to dashboard
					runInAction(() => {
						this.isSocialUserRegisterLoading = false;
						this.isRegisterOfUserAndOrgRedirectSuccess = true;
					})
				}
				runInAction(() => {
					this.isSocialUserRegisterLoading = false;
					this.isRegisterOfUserAndOrgRedirectSuccess = false;
				})
			} else {
				throw new Error("Something going wrong!!");
			}
		} catch (error) {
			runInAction(() => {
				this.isSocialUserRegisterLoading = false;
				this.isRegisterOfUserAndOrgRedirectSuccess = false;
			});
		}
	}
}

export default RegisterStore;
