import AdtendeesLogo from "src/assets/images/logo.png";
import RegisterForm from "./form";
import { Observer } from 'mobx-react';
import RegisterFooter from "./footer";

const UserRegister = () => {

    return (
        <section className="bg-home bg-login d-flex align-items-center">
            <div className="bg-overlay bg-overlay-white"></div>
            <div className="container">
                <div className="row">
                    <Observer>
                        {() => {
                            return (
                                <div className="col-12">
                                    <img src={AdtendeesLogo}
                                        className="avatar mb-4 d-block mx-auto"
                                        alt="adtendees-logo"
                                    />

                                    <div className="form-signin p-4 bg-white rounded shadow">
                                        <h5 className="mb-3 text-left text-manual-white">Let’s Get you Started</h5>
                                        <RegisterForm />
                                        <RegisterFooter />
                                    </div>
                                </div>
                            )
                        }}
                    </Observer>
                </div>
            </div>
        </section>
    )
}

export default UserRegister;