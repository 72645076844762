import { Fragment } from "react";
import { Observer, useLocalObservable } from "mobx-react";
import { sendNotification, socialMediaUrlRegEx } from "src/utils/utilities";
import { useMainStore } from "src/container";

const SocialMediaEdit = ({ onModalClose, isSocialEditModalOrderByIndex, socialName }) => {
    const mainStore = useMainStore();
    const { streamFormStore } = mainStore;

    const localStore = useLocalObservable(() => ({
        socialUrlValue: null,
        handleSocialUrlValue(value) {
            this.socialUrlValue = value;
        }
    }))

    const onUpdateButtonText = () => {
        const { socialUrlValue } = localStore;
        const { handleSocialMediaUrl } = streamFormStore;

        const { regex, errorMessage } = socialMediaUrlRegEx[socialName];

        const reg = new RegExp(regex);

        if (!reg.test(socialUrlValue)) {
            sendNotification({ message: errorMessage, notificationType: 'warning' });
            return;
        }

        if (socialUrlValue.length < 80) {
            handleSocialMediaUrl({ index: isSocialEditModalOrderByIndex, value: socialUrlValue });
            onModalClose();
        } else {
            sendNotification({ message: "Text too long, should be in eighteen characters.", notificationType: 'warning' });
        }
    }

    return (
        <Observer>
            {() => {
                const { handleSocialUrlValue, socialUrlValue } = localStore;

                return (
                    <Fragment>
                        <div
                            className="modal fade modal-open show d-block"
                            id="reg_popup"
                        >
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content rounded shadow border-0">
                                    <div className="modal-header border-0">
                                        <div className="d-flex align-items-center p-2">
                                            <h5 className="modal-title me-3" id="reg_popup-title">Edit Social Input</h5>
                                        </div>
                                    </div>
                                    <div className="modal-body pt-0">
                                        <div className="p-2 rounded box-shadow">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="mb-5">
                                                        <div className="form-icon btn_txt">
                                                            <label className="label text-capitalize">Edit {socialName} Url</label>
                                                            <input
                                                                type="text"
                                                                className="form-control form-control-lg ps-3"
                                                                value={socialUrlValue}
                                                                onChange={({ target: { value } }) => handleSocialUrlValue(value)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-12">
                                                    <button
                                                        onClick={onUpdateButtonText}
                                                        type="button"
                                                        className="btn btn-footers btn-primary"
                                                    >
                                                        Update
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-footers btn-dark-footer"
                                                        onClick={onModalClose}
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                )
            }}
        </Observer>
    );
}

export default SocialMediaEdit;