import { Fragment, useEffect, useRef, useState } from 'react';
import { toJS } from 'mobx';
import { Observer } from 'mobx-react';
import { useMainStore } from 'src/container';
import { isValidArray } from 'src/utils/utilities';

import Avatar from 'react-avatar';
import SmallLoader from 'src/components/common/smallLoader';

const ActiveUsersList = () => {
	const mainStore = useMainStore();
	const { dashboardStore } = mainStore;
	const divRef = useRef();
	const [toggled, setToggled] = useState(false);
	const [activeUserLength, setActiveUserLength] = useState(5);

	useEffect(() => {
		setActiveUserLength(() => {
			if(toggled) {
				return 25;
			} else {
				return 5;
			}
		} )
	}, [toggled])

	const getUsersList = () => {
		const { activeUsers = [], activeUsersLoading } = dashboardStore;
		const usersList = isValidArray(toJS(activeUsers)) ? activeUsers.filter(user => !!user.email) : [];

		if (activeUsersLoading) {
			return (
				<div className="loader_center">
					<SmallLoader />
				</div>
			)
		}

		if (!usersList.length) {
			return (
				<p className="fs-5 text-manual-white ms-5">No Active Users Found</p>
			)
		}

		return (
			<Fragment>
				<div ref={divRef} className="dashboard_active_users">
					{
						usersList.slice(0, activeUserLength).map((user, index) => {
							const { full_name, count, profile_image } = user;
							return (
								(
									<div className='px-3 py-2' key={index}>
										<div
											className="features feature-primary key-feature d-flex align-items-center justify-content-between"
										>
											<div className="d-flex align-items-center">
												<div className="icon text-center rounded-circle me-2">
													<Avatar
														src={profile_image}
														name={full_name}
														className="avatar avatar-md-sm rounded-circle"
														alt="image"
														size="50"
													/>
												</div>
												<div className="flex-1">
													<h6 className="mb-0 text-dark">{full_name}</h6>
													<small className="text-muted">{count} Streams</small>
												</div>
											</div>
										</div>
									</div>
								)
							)
						})				
					}
				</div>
			</Fragment>
		)
	}

	const handleActiveUserToggle = () => {
		divRef.current.classList.toggle('dashboard_active_users_toggle')
		setToggled((toggled) => !toggled)
	}

	return (
		<Observer>
			{() => {
				const { activeUsers = [] } = dashboardStore;
				const usersList = isValidArray(toJS(activeUsers)) ? activeUsers.filter(user => !!user.email) : [];
				return (
					<Fragment>
						<div className="stremas_summary mt-4">
							<div className="summary_heading border-bottom position-relative">
								<h6>ACTIVE USERS</h6>
								{getUsersList()}
								<div onClick={() => handleActiveUserToggle()} className="text-dark-light mt-3 mb-2 d-block mx-auto text-center manual-pointer">
									{!toggled ? (
										<>
											<i className="mdi mdi-chevron-down align-middle"></i>
											See More ({usersList.length})
										</>
									) : (
										<>
											<i className="mdi mdi-chevron-up align-middle"></i>
											See Less
										</>
									)}
								</div>
							</div>
						</div>
					</Fragment>
				);
			}}
		</Observer>
	);
};

export default ActiveUsersList;
