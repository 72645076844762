import { Fragment } from "react";
import ProfileForm from './form';

const UserProfile = () => {

    return (
        <Fragment>
            <div
                className="modal fade modal-open show"
                tabIndex="-1"
                aria-hidden="true"
                style={{ display: 'block' }}
                role='dialog'
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content rounded shadow border-0">
                        <div className="modal-header border-bottom">
                            <div className="d-flex align-items-center">
                                <h5 className="modal-title me-3" id="edit_profile-title">Edit Profile</h5>
                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="bg-white p-2 rounded box-shadow">
                                <ProfileForm />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
export default UserProfile;