import { useMainStore } from 'src/container';
import { GoogleLogin } from 'react-google-login';

import GoogleSvg from 'src/components/common/googleSvg';
import { useEffect } from 'react';
import { gapi } from 'gapi-script';

const UserGoogleLogin = () => {
    const mainStore = useMainStore();
    const { loginStore } = mainStore;
    const { handleSocialUserLogin } = loginStore;

    useEffect(() => {
        gapi.load('client:auth2', () => {
            gapi.auth2.init({clientId: process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID})
        })
    })

    const responseGoogle = (response) => {
        const { profileObj = {} } = response;
        const { email } = profileObj;

        const userData = {
            email: email
        }

        handleSocialUserLogin(userData);
    };

    const onResponseFail = (response) => {
        console.log(response, "response")
    }

    return (
        <GoogleLogin
            clientId={process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID}
            buttonText="Login"
            render={renderProps => {
                return (
                    <GoogleSvg normalMessage={'Login using Google'} onClick={renderProps.onClick} />
                )
            }}
            onSuccess={responseGoogle}
            onFailure={onResponseFail}
            cookiePolicy={'single_host_origin'}
            // isSignedIn={false}
        />
    )
}

export default UserGoogleLogin;