import { Observer } from "mobx-react";
import { useMainStore } from "src/container";

const PreviewModeWrapper = ({ onPreviewMode, offPreviewMode }) => {
    const mainStore = useMainStore();
    const { streamFormStore } = mainStore;

    return (
        <Observer>
            {() => {
                const { isPreviewMode } = streamFormStore;

                if (isPreviewMode) {
                    return onPreviewMode;
                }
                return offPreviewMode;
            }}
        </Observer>
    )
}

export default PreviewModeWrapper;