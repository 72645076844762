import { Observer } from 'mobx-react';
import { isValidArray, isValidObject } from 'src/utils/utilities';

const UploadedImages = ({ onCloseModal, imageList, onRemoveFile, headerName }) => {
    return (
        <Observer>
            {() => {
                return (
                    <div
                        className="modal fade modal-open show"
                        id="uploaded_profile"
                        tabIndex="-1"
                        style={{ display: 'block' }}
                        role='dialog'
                        aria-hidden="true"
                    >
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content rounded shadow border-0">
                                <div className="modal-header border-bottom">
                                    <div className="d-flex align-items-center">
                                        <h5 className="modal-title me-3" id="uploaded_profile-title">{headerName}</h5>
                                    </div>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        onClick={() => onCloseModal(false)}
                                    >

                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="bg-white p-2 rounded box-shadow">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className='uploaded-image-list' id="uploaded-image-list">

                                                    {isValidArray(imageList) ? imageList.map((file, i) => {
                                                        if (isValidObject(file)) {
                                                            return (
                                                                <div
                                                                    key={i}
                                                                    className="mb-2 d-flex align-items-center justify-content-between border rounded"
                                                                >
                                                                    <div className="d-flex align-items-center">
                                                                        <img
                                                                            src={file.link}
                                                                            className="img-fluid avatar avatar-small me-2 rounded"
                                                                            alt={file?.name}
                                                                        />
                                                                        <div className="uploaded_profile">{file?.name}</div>
                                                                    </div>
                                                                    <span
                                                                        onClick={() => onRemoveFile(i)}
                                                                        className="uploaded_profile me-2"
                                                                    >
                                                                        <i className="mdi mdi-window-close"></i>
                                                                    </span>
                                                                </div>
                                                            )
                                                        }
                                                        return (
                                                            <div
                                                                key={i}
                                                                className="mb-2 d-flex align-items-center justify-content-between border rounded"
                                                            >
                                                                <div className="d-flex align-items-center">
                                                                    <img
                                                                        src={URL.createObjectURL(file)}
                                                                        className="img-fluid avatar avatar-small me-2 rounded"
                                                                        alt={file.name}
                                                                    />
                                                                    <div className="uploaded_profile">{file.name}</div>
                                                                </div>
                                                                <span
                                                                    onClick={() => onRemoveFile(i)}
                                                                    className="uploaded_profile me-2"
                                                                >
                                                                    <i className="mdi mdi-window-close"></i>
                                                                </span>
                                                            </div>
                                                        )
                                                    }) : null}

                                                </div>
                                            </div>
                                        </div>
                                        <div className="row text-right mt-4">
                                            <div className="col-md-12">
                                                <button
                                                    onClick={() => onCloseModal(false)}
                                                    type="button"
                                                    className="btn btn-footers btn-dark-footer"
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }}
        </Observer>
    )
}

UploadedImages.defaultProps = {
    imageList: [],
    onCloseModal: Function,
    onRemoveFile: Function,
    headerName: null
}

export default UploadedImages;