import { isNull, isString, isUndefined, isNumber, isBoolean, isEmpty } from "lodash";
import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import localStore from 'store2';
import { deleteRequest } from "./api";
import JSURL from 'jsurl';
import moment from "moment-timezone";
import { ObjectID } from 'bson';
import { timeZones } from "./timezone";

export const getValidDataFromResponse = (response, isRequired = true, disableCatchErrorMessage = true, isWarning = true) => {
    try {
        if (isValidObject(response, ["success", "message", "data"])) {
            const { message, data, success, isLogout = false } = response;

            if (isLogout) {
                throw new Error("Go For Logout.");
            }

            if (success && isRequired) {
                sendNotification({ message: message, notificationType: "default" });
            } else if (!success && isWarning) {
                sendNotification({ message: message, notificationType: "warning" });
            }
            return { data, error: !success };
        }
        throw new Error("Not a valid response");
    } catch (error) {
        const isValidKey = isValidStoredKey(['uid']);
        const { isLogout = false } = response;
        if (isValidKey && isLogout) {
            const { uid } = getLocalStorageItems({ get: true, key: 'uid' });
            deleteRequest(`/user/logout/${uid}`);
            localStore.clear();
        }
        // if (disableCatchErrorMessage) {
        // sendNotification({ message: "Something went wrong.", notificationType: "error" });
        // }
        return { data: [], error: true }
    }
}

export const sendNotification = ({ notificationType, message, duration }) => {
    const options = {
        progress: 0,
        autoClose: duration || 3000
    };

    if (notificationType === 'error') {
        toast.error(message, options);
    } else if (notificationType === 'default') {
        toast(message, options);
    } else if (notificationType === 'warning') {
        toast.warning(message, options);
    } else if (notificationType === 'success') {
        toast.success(message, options);
    }
}

export const isValidArray = (arr) => {
    return arr && Array.isArray(arr) && arr.length > 0;
}

export const isValidObject = (obj, keys) => {
    if (isValidArray(keys)) {
        const newObjKeys = isValidObject(obj, []) ? Object.keys(obj) : [];

        if (!isValidArray(newObjKeys)) {
            return false;
        }

        let isValid = keys.length;
        keys.forEach(key => {
            if (newObjKeys.includes(key)) {
                isValid -= 1;
            }
        });
        return isValid === 0;
    }
    return obj && Object.keys(obj).length > 0;
}

export const getAllRequiredHeaders = () => {
    const token = localStore.get("token") || "undefined";
    const user_id = localStore.get("uid") || "undefined";
    return {
        "Authorization": token,
        "X-Authorization": user_id
    };
}

export const setLocalStorageItems = ({ set = false, setAll = false, item = null, items = {} }) => {
    if (set) {
        const { key, value } = item;
        localStore.set(key, value);
    } else if (setAll) {
        localStore.setAll(items);
    }
}

export const getLocalStorageItems = ({ get = false, getAll = false, key = null, keys = {} }) => {
    if (get) {
        const value = localStore.get(key);
        return { [key]: value };
    } else if (getAll) {
        return localStore.getAll(keys);
    }
    return null;
}

export const isValidStoredKey = (keys = []) => {
    let isValidKey = true;

    for (let i = 0; i < keys.length; i++) {
        const key = keys[i];

        if (localStore.has(key)) {
            const value = localStore.get(key);
            if (isUndefined(value) && isNull(value)) {
                isValidKey = false;
                break;
            } else if (isString(value) && value.length === 0) {
                isValidKey = false;
                break;
            }
        } else {
            isValidKey = false;
            break;
        }
    }

    return isValidKey;
}

export const trimString = (str, removedStr, extraRemoveLength = 0) => {
    if (isString(str) && str.length > 0) {
        const strLength = str.search(removedStr) - extraRemoveLength;
        const latestStr = str.slice(0, strLength);
        return latestStr;
    }
    return "";
}

export const validBodyFieldsForUpdate = (obj, skipKeys) => {
    let newObj = {};

    if (isValidObject(obj)) {
        const keys = Object.keys(obj);

        for (let index = 0; index < keys.length; index++) {
            let isValid = true;
            const value = obj[keys[index]];
            if (!skipKeys.includes(keys[index])) {
                if (!isNumber(value) && !isBoolean(value) && isEmpty(value)) {
                    isValid = false;
                } else if (!isNumber(value) && !isBoolean(value) && isUndefined(value)) {
                    isValid = false;
                } else if (isString(value) && (value.length === 0 || value.charAt(0) === ' ')) {
                    isValid = false;
                } else if (typeof value === 'object') {
                    if (!(isValidArray(value) || isValidObject(value))) {
                        isValid = false;
                    }
                } else if (isNumber(value) && value === 0) {
                    isValid = false;
                } else if (isNull(value)) {
                    isValid = false;
                }
            }

            if (isValid) {
                newObj[keys[index]] = value;
            }
        }
    }
    return { ...newObj };
}

export const useQueryParam = (key) => {
    let [searchParams, setSearchParams] = useSearchParams();
    let paramValue = searchParams.get(key);

    let value = useMemo(() => JSURL.parse(JSURL.stringify(paramValue)), [paramValue]);

    let setValue = useCallback(
        (newValue) => {
            let newSearchParams = new URLSearchParams(searchParams);
            newSearchParams.set(key, JSURL.stringify(newValue));
            setSearchParams(newSearchParams);
        },
        [key, searchParams, setSearchParams]
    );

    return [value, setValue];
}

export const isValidURL = (str) => {
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
}

export const checkValidUrlPath = (pathname = [], key) => {
    const paths = pathname.split('/');
    let isValid = false;

    paths?.forEach(u => {
        if (u === key) {
            isValid = true;
        }
    });

    return isValid;
}

export const isValidMongoId = (id) => {
    const checkMongoId = new RegExp("^[0-9a-fA-F]{24}$");
    return checkMongoId.test(id);
}

export const getTimeWithFormat = (time) => {
    const now = moment();
    const expiration = moment(time);

    const hours = parseInt(expiration.diff(now, 'hours'));
    const minutes = parseInt(expiration.diff(now, 'minutes'));

    if (hours === 0) {
        return `${minutes} Minutes`;
    }
    return `${hours} Hours`;
}

export const isDayToday = (date) => {
    const now = moment();
    const expiration = moment(date);

    return now.isSame(expiration, 'day');
}

export const getDays = (date, timezone) => {
    const now = moment();
    const expiration = moment.tz(date, timezone);

    const diff = expiration.diff(now);

    const diffDuration = moment.duration(diff);

    return diffDuration.days();

}

export const getHours = (date, timezone) => {
    const now = moment();
    const expiration = moment.tz(date, timezone);

    const diff = expiration.diff(now);

    const diffDuration = moment.duration(diff);
    return diffDuration.hours();
}

export const getMinutes = (date, timezone) => {
    const now = moment();
    const expiration = moment.tz(date, timezone);

    const diff = expiration.diff(now);

    const diffDuration = moment.duration(diff);
    return diffDuration.minutes();
}

export const getSeconds = (date, timezone) => {
    const now = moment();
    const expiration = moment.tz(date, timezone);

    const diff = expiration.diff(now);

    const diffDuration = moment.duration(diff);
    return diffDuration.seconds();
}

export const currentTimeZone = moment.tz.guess();

export const getRandomMongoID = () => {
    const id = new ObjectID().toString();
    return id;
};

export const isBase64 = (str) => {
    if (str === '' || str.trim() === '') { return false; }
    try {
        return btoa(atob(str)) === str;
    } catch (err) {
        return false;
    }
}

export const getDefaultSelectedTimeZoneUTC = (val) => {
    for (let index = 0; index < timeZones.length; index++) {
        const { utc } = timeZones[index];
        for (let i = 0; i < utc.length; i++) {
            const u = utc[i];
            if (u === val) {
                return utc;
            }
        }
    }
}

export const getDefaultTimeZoneValue = (val) => {
    let latestValue = null;
    for (let index = 0; index < timeZones.length; index++) {
        const { utc, value } = timeZones[index];
        for (let i = 0; i < utc.length; i++) {
            const u = utc[i];
            if (u === val) {
                latestValue = value;
            }
        }
    }
    return latestValue;
}

export const isTimeGone = (time, timezone) => {
    return moment().tz(timezone)?.isAfter(time);
}

export const socialMediaUrlRegEx = {
    // eslint-disable-next-line no-useless-escape
    twitter: { regex: "http(?:s)?:\/\/(?:www\.)?twitter\.com\/([a-zA-Z0-9_]+)", errorMessage: "Please enter a Twitter URL" },
    // eslint-disable-next-line no-useless-escape
    linkedIn: { regex: "(https?:\/\/(www.)|(www.))?linkedin.com\/(mwlite\/|m\/)?in\/[a-zA-Z0-9_.-]+\/?", errorMessage: "Please enter a LinkedIn URL" },
    // eslint-disable-next-line no-useless-escape
    whatsApp: { regex: "^(https?:\/\/)?chat\.whatsapp\.com\/(?:invite\/)?([a-zA-Z0-9_-]{22})$", errorMessage: "Please enter a WhatsApp URL" },
    // eslint-disable-next-line no-useless-escape
    facebook: { regex: "(?:(?:http|https):\/\/)?(?:www.)?facebook.com\/?", errorMessage: "Please enter a Facebook URL" },
    // eslint-disable-next-line no-useless-escape
    email: { regex: "([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\"\(\[\]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\[[\t -Z^-~]*])", errorMessage: "Please enter a Email" }
};

export const countExactlyMinutesForStart = (minutes) => {
    const a = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14"];
    const b = ["15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29"];
    const c = ["30", "31", "32", "33", "34", "35", "36", "37", "38", "39", "40", "41", "42", "43", "44"];
    const d = ["45", "46", "47", "48", "49", "50", "51", "52", "53", "54", "55", "56", "57", "58", "59"];

    return a.includes(minutes) ? '15' : b.includes(minutes) ? '30' : c.includes(minutes) ? '45' : d.includes(minutes) ? '00' : '0';
}

export const countExactlyMinutesForEnd = (minutes) => {
    const a = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14"];
    const b = ["15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29"];
    const c = ["30", "31", "32", "33", "34", "35", "36", "37", "38", "39", "40", "41", "42", "43", "44"];
    const d = ["45", "46", "47", "48", "49", "50", "51", "52", "53", "54", "55", "56", "57", "58", "59"];

    return a.includes(minutes) ? '30' : b.includes(minutes) ? '45' : c.includes(minutes) ? '00' : d.includes(minutes) ? '15' : '0';
}

export const arrayValueReplacer = (data, key, currentValue, replaceValue) => {
    if (isValidArray(data)) {
        data.forEach((item, index) => {
            if (item[key] === currentValue) {
                data[index][key] = replaceValue;
            }
        })
    }
    return data;
}

export const handleInput = ({target}) => {
    // console.log(e);
     return target.value/* .split(" ").join("") */;
}

export const handleKeyPress = (e) => {
    if (e.charCode === 32) {
        e.preventDefault();
    }
}