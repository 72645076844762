import { Fragment } from "react";
import { ToastContainer } from 'react-toastify';
import AppRoutes from 'src/routes';
import ErrorBoundary from './utils/errorHandler';

function App() {
	return (
		<Fragment>
			<ToastContainer
				position="top-right"
				hideProgressBar={true}
				theme='dark'
				rtl={false}
				closeOnClick={true}
				draggable={true}
			/>
			<ErrorBoundary>
				<AppRoutes />
			</ErrorBoundary>
		</Fragment>
	);
}

export default App;
