import { Fragment } from "react";
import { Link } from "react-router-dom";
import { useQueryParam } from "src/utils/utilities";

const LoginFooter = () => {
    const [query] = useQueryParam('redirect');

    return (
        <Fragment>
            {/* <div className="col-md-12 mb-2"> */}
                {/* <p className="forgot-pass mb-0 text-center">
                    <Link
                        to={`/login/forgot-password?redirect=${query}`}
                        className="text-primary small"
                    >
                        Forgot Password?
                    </Link>
                </p> */}
                {/* <p className="forgot-pass mb-0 text-center">
                    <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#forgot_password" className="text-primary small">
                        Forgot password ?
                    </a>
                </p> */}

            {/* </div> */}

            <div className="col-md-12">
                <div className="dropdown-divider border-top"></div>
            </div>

            <div className="col-12 text-center mt-3">
                <p className="mb-0 mt-3">
                    <small className="text-dark-light me-1">
                        New to Adtendees,
                    </small>
                    <Link to={`/register?redirect=${query}`} className="text-primary ">
                        Create Account
                    </Link>
                </p>
            </div>
        </Fragment>
    );
}

export default LoginFooter;