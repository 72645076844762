import { Fragment, useEffect } from "react";
import { Observer } from "mobx-react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { useMainStore } from "src/container";

const Streams = () => {
    const mainStore = useMainStore();
    const { streamsStore } = mainStore;

    const { pathname } = useLocation();

    const activeClassNameForUpcoming = pathname.includes('upcoming') && "active";
    const activeClassNameForPast = pathname.includes('past') && "active";

    useEffect(() => {
        const allTabMenu = document.querySelectorAll('.tab-menu li');
        const line = document.querySelector('.tab-menu .line');

        const validIndex = activeClassNameForUpcoming ? 0 : activeClassNameForPast ? 1 : 0;

        let width = allTabMenu[validIndex].offsetWidth;
        let left = allTabMenu[validIndex].offsetLeft;
        line.style.width = width + 'px';
        line.style.left = left + 'px';

    }, [activeClassNameForUpcoming, activeClassNameForPast]);

    useEffect(() => {
        const { fetchAllStreamsByType } = streamsStore;
        fetchAllStreamsByType('upcoming');
        fetchAllStreamsByType('past');
        return () => { }
    }, [streamsStore])


    return (
        <Observer>
            {() => {
                const { allPastStreamList = [], allUpcomingStreamList = [] } = streamsStore;

                return (
                    <Fragment>
                        <div className="layout-specing">
                            <div className="row">
                                <div className="col-12">
                                    <ul className="tab-menu">
                                        <Link to={`/streams/upcoming`} className={`${activeClassNameForUpcoming ? 'active_stream_tab' : 'deActive_stream_tab'}`}>
                                            <li className={activeClassNameForUpcoming || ''}>
                                                Upcoming <span>({allUpcomingStreamList.length})</span>
                                            </li>
                                        </Link>
                                        <Link to={`/streams/past`} className={`${activeClassNameForPast ? 'active_stream_tab' : 'deActive_stream_tab'}`}>
                                            <li className={activeClassNameForPast || ''}>
                                                Past <span>({allPastStreamList.length})</span>
                                            </li>
                                        </Link>
                                        <span className={`line active`}></span>
                                    </ul>
                                    <div className="contents">
                                        <Outlet />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                )
            }}
        </Observer>
    );
}
export default Streams;