import { Fragment } from "react";

const NormalModal = ({ message, display, callOnClose, headerMessage, children, style, className }) => {

    const goToLoginPage = () => {
        callOnClose();
    }

    return (
        <Fragment>
            <div
                className="modal fade modal-open show"
                tabIndex="-1"
                aria-hidden="true"
                style={{ display: display ? 'block' : 'none' }}
                role="dialog"
            >
                <div className={`modal-dialog modal-dialog-centered ${className}`} style={{ ...style }}>
                    <div className="modal-content rounded shadow border-0">
                        <div className="modal-header border-bottom">
                            <div className="d-flex align-items-center">
                                <h5 className="modal-title me-3" id="thank_you-title">{headerMessage}</h5>
                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="bg-white p-2 rounded box-shadow">

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="mb-3">
                                            <p className="mb-0 text-terms">
                                                <span className="me-1">
                                                    {message || children}
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="row text-right">
                                    <div className="col-md-12">
                                        <button
                                            onClick={goToLoginPage}
                                            type="button"
                                            className="btn btn-footers btn-danger"
                                            data-bs-dismiss="modal"
                                        >
                                            Close
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

NormalModal.defaultProps = {
    message: null,
    display: false,
    headerMessage: null,
    children: null,
    className: null,
    style: {}
}

export default NormalModal;