import { observable, makeObservable, runInAction, action } from 'mobx';
import { patchRequest } from 'src/utils/api';
import { getLocalStorageItems, getValidDataFromResponse, isValidArray } from 'src/utils/utilities';

class ChangePasswordStore {

    changePasswordLoading = false;
    changePasswordSuccess = false;

    constructor() {
        makeObservable(this, {
            changePasswordLoading: observable,
            changePasswordSuccess: observable,
            handleChangePassword: action
        });
    }

    handleChangePassword = async (passwords) => {
        try {
            runInAction(() => {
                this.changePasswordLoading = true;
            });

            const { uid } = getLocalStorageItems({ get: true, key: 'uid' });

            const passData = { ...passwords, user_id: uid };

            const { response } = await patchRequest('/user/update/password', passData);
            const { data, error } = getValidDataFromResponse(response, true);

            if (!error && isValidArray(data)) {
                runInAction(() => {
                    this.changePasswordSuccess = true;
                    this.changePasswordLoading = false;
                });
            }

            runInAction(() => {
                this.changePasswordLoading = false;
                this.changePasswordSuccess = false;
            });
        } catch (error) {
            runInAction(() => {
                this.changePasswordLoading = false;
                this.changePasswordSuccess = false;
            });
        }
    }


}

export default ChangePasswordStore;
