import { Fragment, useRef } from "react";
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller } from "react-hook-form";
import { loginFormSchema } from "./form.schema";
import { Observer, useLocalObservable } from 'mobx-react';
import { Link, Navigate } from "react-router-dom";
import { useMainStore } from "src/container";
import { handleInput, handleKeyPress, useQueryParam } from "src/utils/utilities";

// Common Components
import SmallLoader from "src/components/common/smallLoader";
import UserGoogleLogin from './googleLogin';

const LoginForm = () => {
    const [query] = useQueryParam('redirect');
    const mainStore = useMainStore();
    const { loginStore } = mainStore;

    const localStore = useLocalObservable(() => ({
        isPassword: true,
        isEye: true,
        changeToText() {
            this.isPassword = !this.isPassword;
            this.isEye = !this.isEye;
        }
    }))

    const { register, formState: { errors }, handleSubmit, control } = useForm({
        resolver: yupResolver(loginFormSchema)
    });

    const handleLogin = (data) => {
        const { handleUserLogin } = loginStore;
        handleUserLogin(data);
    }

    const changeType = () => {
        const { changeToText } = localStore;
        changeToText();
    }

    return (
        <Fragment>
            <Observer>
                {() => {
                    const { isUserLoginLoading, isLoginOfUserRedirectSuccess, isSocialUserLoginLoading } = loginStore;
                    const { isPassword, isEye } = localStore;

                    if (isLoginOfUserRedirectSuccess) {
                        return <Navigate to={query || '/dashboard'} replace={true} />
                    }

                    return (
                        <form onSubmit={handleSubmit(handleLogin)}>
                            <div className="col-md-12">
                                <div className="mb-3">
                                    <label className="form-label">Email<span className="requiredFields">*</span></label>
                                    <Controller
                                        control={control}
                                        name="email"
                                        render={({ field: { value, onChange } }) =>
                                            <input
                                                autoFocus
                                                autoComplete="off"
                                                name="email"
                                                id="email"
                                                onChange={(e) => onChange(handleInput(e))}
                                                onKeyPress={(e) => handleKeyPress(e)}
                                                value={value}
                                                type="email"
                                                className="form-control form-control-lg ps-2"
                                                placeholder="e.g john@gmail.com"
                                            />
                                        }
                                    />
                                    <p className="text-danger">{errors.email?.message}</p>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="mb-4 password">
                                    <label className="form-label">Password<span className="requiredFields">*</span></label>
                                    <Controller
                                        control={control}
                                        name="password"
                                        render={({ field: { value, onChange } }) =>
                                            <input
                                                name="password"
                                                id="password"
                                                onChange={(e) => onChange(handleInput(e))}
                                                onKeyPress={(e) => handleKeyPress(e)}
                                                value={value}
                                                type={isPassword ? "password" : "text"}
                                                className="form-control form-control-lg ps-2 inputPassword"
                                                placeholder="e.g *******"
                                            />
                                        }
                                    />
                                    <i className={`bi ${isEye ? "bi-eye" : "bi-eye-slash"}`} onClick={() => changeType()}></i>

                                    <p className="text-danger mb-0">{errors.password?.message}</p>
                                    <p className="mb-0">
                                        <Link
                                            to={`/login/forgot-password?redirect=${query}`}
                                            className="text-primary small"
                                        >
                                            Forgot Password?
                                        </Link>
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-12 mb-2">

                            </div>

                            <div className="col-12 mb-3">
                                <button className="btn btn-primary w-100 mb-3" type="submit">
                                    {isUserLoginLoading ? <SmallLoader color={'dark'} /> : "Login"}
                                </button>
                                <p className="text-center text-muted small">OR</p>
                                <div className="text-center">
                                    {!isSocialUserLoginLoading ? <UserGoogleLogin /> : <SmallLoader color={'dark'} />}
                                </div>
                            </div>
                        </form>
                    )
                }}
            </Observer>
        </Fragment>
    );
}
export default LoginForm;