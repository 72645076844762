import { Fragment, useEffect } from "react";
import { Observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { useMainStore } from "src/container";
import { getLocalStorageItems } from "src/utils/utilities";

import Avatar from 'react-avatar';

import SmallLoader from "src/components/common/smallLoader";
import { autorun } from "mobx";

const ProfileForm = () => {
    let navigate = useNavigate();
    const mainStore = useMainStore();

    const { profileStore } = mainStore;

    const goToRecentPage = () => {
        navigate(-1);
    }

    useEffect(() => {
        const { getUserProfileData, resetUserProfileForm } = profileStore;
        const { uid } = getLocalStorageItems({ get: true, key: "uid" });
        getUserProfileData(uid);
        return () => {
            resetUserProfileForm();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    autorun(() => {
        const { userProfileUpdateSuccessRedirect } = profileStore;
        if (userProfileUpdateSuccessRedirect) {
            navigate(-1);
        }
    });

    return (
        <Observer>
            {() => {
                const {
                    handleFileUpload, userProfileData, handleUserProfileFormForSave,
                    userProfileDataLoading, handleUserProfileForm, removeUploadedImage,
                } = profileStore;

                if (userProfileDataLoading) {
                    return <SmallLoader color={'default'} />
                }

                return (
                    <Fragment>
                        <div className="row">
                            {
                                userProfileData.map(({ full_name = '', title = '', profile_image = '', password = '' }, i) => {
                                    return (
                                        <Fragment key={i}>
                                            <div className="col-md-12">
                                                <div className="mb-3 d-flex align-items-start">
                                                    <Avatar
                                                        name={full_name}
                                                        src={profile_image}
                                                        className="img-fluid avatar avatar-medium me-2 rounded"
                                                        alt="Profile"
                                                    />
                                                    <div className="edit_profile_remove">
                                                        <div className="file-input2">
                                                            <input
                                                                type="file"
                                                                name="file-input-profile"
                                                                id="file-input-profile"
                                                                className="file-input__input2"
                                                                onChange={e => handleFileUpload(e)}
                                                            />
                                                            <label className="file-input__label2" htmlFor="file-input-profile">
                                                                {profile_image ? <span>Replace</span> : <span>Upload</span>}
                                                            </label>
                                                        </div>
                                                        <div className="file-input__label2 pe-auto" onClick={removeUploadedImage}>Remove</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-12" >
                                                <div className="mb-3">
                                                    <label className="form-label" htmlFor="profile_name">Full Name</label>
                                                    <input
                                                        value={full_name}
                                                        name="profile_name"
                                                        id="profile_name"
                                                        type="text"
                                                        className="form-control form-control-lg ps-2"
                                                        placeholder="e.g Ray Cooper"
                                                        autoComplete="off"
                                                        onChange={e => handleUserProfileForm(e.target.value, 'full_name')}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="mb-3">
                                                    <label className="form-label" htmlFor="profile_title">Title</label>
                                                    <input
                                                        value={title}
                                                        name="title"
                                                        id="profile_title"
                                                        type="text"
                                                        className="form-control form-control-lg ps-2"
                                                        placeholder="e.g Marketing Manager"
                                                        autoComplete="off"
                                                        onChange={e => handleUserProfileForm(e.target.value, 'title')}
                                                    />
                                                </div>
                                            </div>
                                            {/* <div className="col-md-12">
                                                <div className="mb-3">
                                                    <label className="form-label" htmlFor="new_password">New Password (Optional)</label>
                                                    <input
                                                        value={password}
                                                        name="Password"
                                                        id="new_password"
                                                        type="text"
                                                        autoComplete="off"
                                                        className="form-control form-control-lg ps-2"
                                                        placeholder="e.g **********"
                                                        onChange={e => handleUserProfileForm(e.target.value, 'password')}
                                                    />
                                                </div>
                                            </div> */}
                                        </Fragment>
                                    )
                                })
                            }
                        </div>

                        <div className="row text-right">
                            <div className="col-md-12">
                                <button
                                    onClick={handleUserProfileFormForSave}
                                    type="button"
                                    className="btn btn-footers btn-primary"
                                >
                                    {userProfileDataLoading ? <SmallLoader color={'default'} /> : 'Save'}
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-footers btn-dark-footer"
                                    onClick={goToRecentPage}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </Fragment>
                )
            }}
        </Observer>
    )
}

export default ProfileForm;