import { Fragment, useEffect } from "react";
import { Observer } from "mobx-react";
import { Link, Outlet, useLocation, useParams } from "react-router-dom";
import { useQueryParam } from "src/utils/utilities";
import { useMainStore } from "src/container";
// import { useMainStore } from "src/container";

const StreamDetails = () => {
    // const mainStore = useMainStore();
    const [query] = useQueryParam('sn');
    const mainStore = useMainStore();
    const { streamDetailsStore } = mainStore;

    const { pathname } = useLocation();
    const params = useParams();

    const activeClassNameForAnalytics = pathname.includes('analytics') && "active";
    const activeClassNameForAttendees = pathname.includes('attendees') && "active";

    useEffect(() => {
        const allTabMenu = document.querySelectorAll('.tab-menu li');
        const line = document.querySelector('.tab-menu .line');

        const validIndex = activeClassNameForAnalytics ? 0 : activeClassNameForAttendees ? 1 : 0;

        let width = allTabMenu[validIndex].offsetWidth;
        let left = allTabMenu[validIndex].offsetLeft;
        line.style.width = width + 'px';
        line.style.left = left + 'px';

    }, [activeClassNameForAnalytics, activeClassNameForAttendees])

    useEffect(() => {
        const { fetchSingleStreamUser, resetStreamUserListData } = streamDetailsStore;
        const { sid = null } = params;
        fetchSingleStreamUser(sid);

        return () => {
            resetStreamUserListData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Observer>
            {() => {
                const { sid } = params;
                const { streamUsersList = [] } = streamDetailsStore;

                return (
                    <Fragment>
                        <div className="container-fluid">
                            <div className="layout-specing">
                                <div className="row">
                                    <div className="col-12">
                                        <ul className="tab-menu">
                                            <Link
                                                to={`/stream-details/analytics/${sid}?sn=${query}`}
                                                className={`${activeClassNameForAnalytics ? 'active_stream_tab' : 'deActive_stream_tab'}`}
                                            >
                                                <li className={activeClassNameForAnalytics || ''}>
                                                    Analytics
                                                </li>
                                            </Link>
                                            <Link
                                                to={`/stream-details/attendees/${sid}?sn=${query}`}
                                                className={`${activeClassNameForAttendees ? 'active_stream_tab' : 'deActive_stream_tab'}`}
                                            >
                                                <li className={activeClassNameForAttendees || ''}>
                                                    Attendees
                                                    <span>({streamUsersList.length})</span>
                                                </li>
                                            </Link>
                                            {/* <li>Form</li> */}
                                            <span className="line"></span>
                                        </ul>
                                        <div className="contents">
                                            <Outlet />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                )
            }}
        </Observer>
    )
}

export default StreamDetails;