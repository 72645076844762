import { useMemo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { isValidStoredKey } from 'src/utils/utilities';

const ProtectedRoutes = ({ children }) => {
    const { pathname } = useLocation();

    const isAuthenticated = useMemo(() => {

        // check localStorage values
        const isValidKeysWithValues = isValidStoredKey(['token', 'oid', 'ouid', 'uid']);

        return isValidKeysWithValues;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [children]);

    return isAuthenticated ? children : <Navigate to={`/login?redirect=${pathname}`} replace={true} />;
}

export default ProtectedRoutes;