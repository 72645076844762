import { Fragment } from "react";
import { Link } from "react-router-dom";

const Notification = () => {
    return (
        <Fragment>
            <div className="dropdown dropdown-primary">
                {/*<button
                    type="button"
                    className="btn btn-icon-nav btn-nav-light dropdown-toggle p-0"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false">
                    <i className="mdi mdi-bell-outline fw-18"></i>
                </button> */}

                <div className="dropdown-menu dd-menu bg-white shadow rounded border-0 mt-3 p-0" data-simplebar style={{ "height": "320px", "width": "290px" }}>
                    <div className="d-flex align-items-center justify-content-between p-3 border-bottom">
                        <h6 className="mb-0 text-dark">Notifications</h6>
                        <span className="badge bg-soft-danger rounded-pill">0</span>
                    </div>
                    <div className="p-3">
                        <Link to={'/'} className="dropdown-item features feature-primary key-feature p-0">
                            <div className="d-flex align-items-center">
                                {/* <div className="icon text-center rounded-circle me-2">
                                <i className="ti ti-shopping-cart"></i>
                                </div> */}
                                {/* <div className="flex-1"> */}
                                {/* <h6 className="mb-0 text-dark title">Order Complete</h6> */}
                                {/* <small className="text-muted">15 min ago</small> */}
                                {/* </div> */}
                                <div className="flex-1">
                                    <h6 className="mb-0 text-dark title">Under development</h6>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Notification;