import { action, configure, makeObservable, observable, runInAction, toJS } from 'mobx';
import moment from 'moment';
import { deleteRequest, getRequest } from 'src/utils/api';
import { arrayValueReplacer, getLocalStorageItems, getValidDataFromResponse, isValidArray, isValidObject, sendNotification } from 'src/utils/utilities';

configure({ useProxies: 'always' });
class StreamsStore {
    allUpcomingStreamList = [];
    allPastStreamList = [];

    isUpcomingStreamLoading = false;
    isPastStreamLoading = false;

    isUpcomingStreamDeleteLoading = false;
    upcomingStreamDeleteSuccess = false;

    downloadStreamLoading = false;
    downloadStreamSuccess = false;
    downloadStreamData = [];

    viewStreamLoading = false;
    viewStreamSuccess = false;
    viewStreamData = [];

    interval = 0;

    constructor() {
        makeObservable(this, {
            allUpcomingStreamList: observable,
            allPastStreamList: observable,
            isPastStreamLoading: observable,
            isUpcomingStreamLoading: observable,
            isUpcomingStreamDeleteLoading: observable,
            upcomingStreamDeleteSuccess: observable,
            handleDeleteUpcomingStream: action,
            downloadStreamLoading: observable,
            downloadStreamData: observable,
            viewStreamLoading: observable,
            viewStreamData: observable,
            downloadStreamSuccess: observable,
            viewStreamSuccess: observable,
        });
    }

    fetchAllStreamsByType = async (type = 'upcoming') => {
        try {
            runInAction(() => {
                if (type === 'upcoming') {
                    this.isUpcomingStreamLoading = true;
                } else if (type === 'past') {
                    this.isPastStreamLoading = true;
                }
            })

            const { oid } = getLocalStorageItems({ get: true, key: "oid" });
            const { response } = await getRequest(`/stream/all/${oid}?type=${type}`);
            const { data, error } = getValidDataFromResponse(response, false, false, false);

            if (!error && isValidArray(data)) {
                const newData = arrayValueReplacer(data, 'images', null, []);
                runInAction(() => {
                    if (type === 'upcoming') {
                        clearInterval(this.interval)
                        this.allUpcomingStreamList = [...newData];
                        this.checkStreamStartTime()
                    } else if (type === 'past') {
                        this.allPastStreamList = [...newData];
                    }
                });
            }

            runInAction(() => {
                if (type === 'upcoming') {
                    this.isUpcomingStreamLoading = false;
                } else if (type === 'past') {
                    this.isPastStreamLoading = false;
                }
            })
        } catch (error) {
            runInAction(() => {
                if (type === 'upcoming') {
                    this.isUpcomingStreamLoading = false;
                } else if (type === 'past') {
                    this.isPastStreamLoading = false;
                }
            })
        }

    }

    handleDeleteUpcomingStream = async (stream_id) => {
        try {
            runInAction(() => {
                this.isUpcomingStreamDeleteLoading = true;
            });

            const { oid, ouid } = getLocalStorageItems({ getAll: true, keys: ["oid", "ouid"] });

            const { response } = await deleteRequest(`/stream/delete?stream_id=${stream_id}&org_id=${oid}&org_user_id=${ouid}`);
            const { data, error } = getValidDataFromResponse(response, true);

            if (!error && isValidArray(data)) {
                runInAction(() => {
                    this.allUpcomingStreamList = [...this.allUpcomingStreamList.filter(({ _id }) => _id !== stream_id)];
                    this.isUpcomingStreamDeleteLoading = false;
                    this.upcomingStreamDeleteSuccess = true;
                });
            }
            runInAction(() => {
                this.isUpcomingStreamDeleteLoading = false;
                this.upcomingStreamDeleteSuccess = false;
            });
        } catch (error) {
            runInAction(() => {
                this.isUpcomingStreamDeleteLoading = false;
                this.upcomingStreamDeleteSuccess = false;
            });
        }
    }

    handleCopySuccess = ({ success, message }) => {
        if (success) {
            sendNotification({ message: message, notificationType: "success", duration: 2000 });
        }

        if (!success) {
            sendNotification({ message: message, notificationType: "error", duration: 4000 });
        }
    }

    fetchDownloadStream = async (stream_id) => {
        try {
            runInAction(() => {
                this.downloadStreamLoading = true;
            });

            const { response } = await getRequest(`/stream/recording/download/${stream_id}`);
            const { data, error } = getValidDataFromResponse(response, false);

            if (!error && isValidObject(data)) {
                runInAction(() => {
                    this.downloadStreamData = toJS(data);
                    this.downloadStreamSuccess = true;
                    this.downloadStreamLoading = false;
                });
            }
            runInAction(() => {
                this.downloadStreamSuccess = false;
                this.downloadStreamLoading = false;
            });
        } catch (error) {
            runInAction(() => {
                this.downloadStreamLoading = false;
            });
        }
    }

    fetchViewStream = async (stream_id) => {
        try {
            runInAction(() => {
                this.viewStreamLoading = true;
            });

            const { response } = await getRequest(`/stream/recording/view/${stream_id}`);
            const { data, error } = getValidDataFromResponse(response, false);

            if (!error && isValidObject(data)) {
                runInAction(() => {
                    this.viewStreamData = toJS(data);
                    this.viewStreamSuccess = true;
                    this.viewStreamLoading = false;
                });
            }
            runInAction(() => {
                this.viewStreamSuccess = false;
                this.viewStreamLoading = false;
            });
        } catch (error) {
            runInAction(() => {
                this.viewStreamLoading = false;
            });
        }
    }

    checkStreamStartTime = () => {
        var self = this;
        
        if(this.allUpcomingStreamList.length > 0){

            this.interval = setInterval(() => {

                this.allUpcomingStreamList.map((str,i) => {

                    let { start_time } = str;

                    let isBefore = moment().isBefore(start_time);    
                    
                    if(!isBefore){
                        this.allUpcomingStreamList.splice(i,1)
                        this.fetchAllStreamsByType('past')
                        clearInterval(this.interval)
                        self.checkStreamStartTime()
                    }

                })

            }, 1000)
        } else{
            clearInterval(this.interval)
        }
    }
}

export default StreamsStore;
