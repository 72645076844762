import { Fragment } from "react";
import { Observer, useLocalObservable } from "mobx-react";
import { getTimeWithFormat, isDayToday } from "src/utils/utilities";
import { Carousel, CarouselItem } from 'reactstrap';

import { Link, useLocation, useNavigate } from "react-router-dom";
import { useMainStore } from "src/container";

import moment from "moment";

import Avatar from 'react-avatar';
import ConfirmDeleteModal from "../streams/deleteConfirmModal";
import { toJS } from "mobx";

const UpcomingStream = ({ handleOpenDeleteModal }) => {
    let navigate = useNavigate();
    let { pathname } = useLocation();
    const mainStore = useMainStore();
    const { dashboardStore, streamsStore } = mainStore;
    const { recentStreams = [{}] } = dashboardStore;

    const localStore = useLocalObservable(() => ({
        activeIndex: 0,
        deleteModal: false,
        setActiveIndex(i) {
            this.activeIndex = i;
        },
        isDeleteModal(val) {
            this.deleteModal = val;
        }
    }));

    const next = () => {
        const { setActiveIndex, activeIndex } = localStore;
        const nextIndex = activeIndex === recentStreams.length ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };

    const previous = () => {
        const { setActiveIndex, activeIndex } = localStore;
        const nextIndex = activeIndex === 0 ? recentStreams.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };

    const goToUpdateStreamPage = (sid) => {
        navigate(`${pathname}/update-stream/details/${sid}`);
    }

    return (
        <Observer>
            {() => {
                const { recentStreams = [{}], recentLiveStreams = [{}] } = dashboardStore;
                const { activeIndex } = localStore;
                return (
                    <Fragment>
                        <Fragment>
                            <div className="col-xl-12 mb-3">
                                <Carousel
                                    activeIndex={activeIndex}
                                    next={next}
                                    previous={previous}
                                    interval={4000}
                                    ride={ [...recentLiveStreams, ...recentStreams].length > 0 ? 'carousel' : "false"}
                                >
                                    {[...recentLiveStreams, ...recentStreams].map(({ start_time, title, total_attendees = 0, images = [], schedule, is_live, _id, str_host_link }, index) => {
                                        const isDayIsToday = isDayToday(schedule);

                                        const totalLeftTime = getTimeWithFormat(start_time);
                                        const totalDate = moment(start_time).calendar();

                                        return (
                                            <CarouselItem
                                                key={index}
                                                className="card shadow border-0"
                                            >
                                                <div
                                                    className="d-flex justify-content-between align-items-center p-3 bg-white shadow rounded-top border-bottom"
                                                >
                                                    <h6 className="text-uppercase mb-0 text-manual-white">Upcoming Streams</h6>
                                                    <ul className="list-unstyled mb-0">
                                                        <li className="dropdown dropdown-primary list-inline-item">
                                                            <button
                                                                type="button"
                                                                className="btn btn-icon btn-soft-light dropdown-toggle p-0"
                                                                data-bs-toggle="dropdown"
                                                                aria-haspopup="true"
                                                                aria-expanded="false"
                                                            >
                                                                <i className="mdi mdi-dots-horizontal"></i>
                                                            </button>
                                                            <div className="dropdown-menu dd-menu dropdown-menu-end bg-dropdown shadow border-0 mt-3 py-3">
                                                                <div
                                                                    className="dropdown-item text-manual-white cursorPointer"
                                                                    // onClick={() => goToUpdateStreamPage(_id)}
                                                                >
                                                                    <p
                                                                        className="dropdown-item text-manual-white btn p-1 m-1"
                                                                        onClick={() => goToUpdateStreamPage(_id)}
                                                                    >
                                                                        Edit
                                                                    </p>
                                                                </div>
                                                                {/* <Link to={'/under-development'} className="dropdown-item text-manual-white">Edit</Link> */}
                                                                <div
                                                                    className="dropdown-item text-manual-white cursorPointer"
                                                                    // onclick={() => handleOpenDeleteModal(_id)}
                                                                >
                                                                    <p
                                                                        className="dropdown-item text-manual-white btn p-1 m-1"
                                                                        onClick={() => handleOpenDeleteModal(_id)}
                                                                    >
                                                                        Delete
                                                                    </p>
                                                                </div>
                                                                {/* <Link to={'/under-development'} className="dropdown-item text-manual-white">Delete</Link> */}
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>

                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-xl-8 border-right">
                                                            <div className="d-lg-flex">
                                                                <Avatar
                                                                    src={images[0]}
                                                                    name={title}
                                                                    alt="stream image"
                                                                    className="img-fluid card-img me-3 avatar avatar-stream_p"
                                                                />
                                                                <div className="upcoming_description">
                                                                    <small className="text-manual-white">
                                                                        {isDayIsToday ?
                                                                            moment().isAfter(start_time) ? "Stream started" : `Will start in ${totalLeftTime}.`
                                                                            :
                                                                            `Next Stream will be on ${totalDate}.`}
                                                                    </small>

                                                                    <div className="d-flex">
                                                                        <h5 className="mb-2 text-capitalize text-manual-white">{title}</h5>
                                                                        &nbsp;&nbsp;&nbsp;
                                                                        {is_live && (
                                                                            <small
                                                                                className="sub_head"
                                                                                style={{
                                                                                    backgroundColor: 'red',
                                                                                    borderRadius: '10%',
                                                                                    height: "25px",
                                                                                    fontSize: '15px'
                                                                                }}
                                                                            >
                                                                                <span className="badge bg-danger2"> LIVE </span>
                                                                            </small>
                                                                        )}
                                                                    </div>

                                                                    <a href={str_host_link} target="_blank">
                                                                        <div className="btn btn-xs btn-primary mb-4">
                                                                            <i className="mdi mdi-television  icon-sm me-1"></i>
                                                                            Go to Studio
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-4">
                                                            <div className="upcoming_details ms-2">
                                                                <div>
                                                                    <h5 className='text-manual-white'>Attendees</h5>
                                                                    <p className='text-manual-white'>{total_attendees}</p>
                                                                </div>
                                                                {/*<div>
                                                                    <h5 className='text-manual-white'>Sponsors</h5>
                                                                    <p className='text-manual-white'>{0}</p>
                                                                  </div>*/}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </CarouselItem>
                                        )
                                    })}
                                </Carousel>
                            </div>
                        </Fragment>
                    </Fragment>
                )
            }}
        </Observer>
    );
}

export default UpcomingStream;