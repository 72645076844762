import { Observer } from "mobx-react";
import { Draggable } from "react-beautiful-dnd";
import { useMainStore } from "src/container";

import ToolTip from "src/pages/form/private/fieldToolTip";

import PreviewModeWrapper from './previewModeWrapper';

const TextArea = ({ uk, passingProps, index, deleteIndex, handleInputEditModal, is_required }) => {
    const mainStore = useMainStore();
    const { streamFormStore } = mainStore;

    const onDeleteClick = (index) => {
        const { removeFormRegisterNowSection } = streamFormStore;
        removeFormRegisterNowSection(index);
    }

    return (
        <Observer>
            {() => {
                return (
                    <PreviewModeWrapper
                        offPreviewMode={
                            <Draggable draggableId={`id-${index}`} index={index}>
                                {(provided) => (
                                    <div
                                        className="col-md-12"
                                        uk={uk}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                    >
                                        <div className="mb-3">
                                            <div className="filed_edit_btn">

                                                <div className="form-icon position-relative">
                                                    <textarea
                                                        {...passingProps}
                                                        className="form-control form-control2 form-control-lg ps-3"
                                                    >
                                                    </textarea>
                                                </div>

                                                <div
                                                    className="filed_edit_icon"
                                                >
                                                    <ToolTip
                                                        type='filed'
                                                        ref={provided.innerRef}
                                                        draggableProps={provided.draggableProps}
                                                        dragHandleProps={provided.dragHandleProps}
                                                        onDeleteClick={() => onDeleteClick(deleteIndex)}
                                                        onPencilClick={() => handleInputEditModal(deleteIndex)}
                                                        isPencilIconHide={[1, 2].includes(deleteIndex)}
                                                        isDeleteIconHide={[1, 2].includes(deleteIndex)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Draggable>
                        }
                        onPreviewMode={
                            <div
                                className="col-md-12"
                                uk={uk}
                            >
                                <div className="mb-3">
                                    <div className="filed_edit_btn_hover_off">

                                        <div className="form-icon position-relative d-flex">
                                            <textarea
                                                {...passingProps}
                                                className="form-control form-control2 form-control-lg ps-3"
                                            >
                                            </textarea>
                                            {is_required && (<span className="text-danger">*</span>)}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        }
                    />

                )
            }}
        </Observer>
    )
}

export default TextArea;