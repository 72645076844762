import { Fragment, useRef } from "react";
import { Observer, useLocalObservable } from 'mobx-react';
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { Link, Navigate, useLocation } from "react-router-dom";
import { registerFormSchema } from "./form.schema";
import { useMainStore } from "src/container";
import { handleInput, handleKeyPress, useQueryParam } from "src/utils/utilities";

// Common components
import UserGoogleRegister from "./googleRegister";
import SmallLoader from "src/components/common/smallLoader";

const RegisterForm = () => {
    const [query] = useQueryParam('redirect');
    const { pathname } = useLocation();

    const mainStore = useMainStore();

    const localStore = useLocalObservable(() => ({
        isPassword: true,
        isEye: true,
        changeToText() {
            this.isPassword = !this.isPassword;
            this.isEye = !this.isEye;
        }
    }))

    const { registerStore } = mainStore;

    const { register, formState: { errors }, handleSubmit, control } = useForm({
        resolver: yupResolver(registerFormSchema)
    });

    const handleRegister = (data) => {
        const { handleRegisterUser } = registerStore;
        handleRegisterUser(data);
    }

    const changeType = () => {
        const { changeToText } = localStore;
        changeToText();
    }

    return (
        <Fragment>
            <Observer>
                {() => {
                    const { isUserRegisterLoading, isRegisterOfUserAndOrgRedirectSuccess, isSocialUserRegisterLoading } = registerStore;
                    const { isPassword, isEye } = localStore;

                    if (isRegisterOfUserAndOrgRedirectSuccess) {
                        return <Navigate to={query || '/dashboard'} replace={true} />;
                    }

                    return (<form onSubmit={handleSubmit(handleRegister)}>
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="full_name">Name<span className="requiredFields">*</span></label>
                                <input
                                    autoFocus
                                    name="full_name"
                                    id="full_name"
                                    type="text"
                                    className="form-control form-control-lg ps-2"
                                    placeholder="e.g John Smith"
                                    {...register("full_name")}
                                />
                                <p className="text-danger">{errors.full_name?.message}</p>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="mb-4">
                                <label className="form-label" htmlFor="email">Email<span className="requiredFields">*</span></label>
                                <Controller
                                    control={control}
                                    name="email"
                                    render={({ field: { value, onChange } }) =>
                                        <input
                                            name="email"
                                            id="email"
                                            type="email"
                                            onChange={(e) => onChange(handleInput(e))}
                                            onKeyPress={(e) => handleKeyPress(e)}
                                            value={value}
                                            className="form-control form-control-lg ps-2"
                                            placeholder="e.g john@emai.com"
                                        />
                                    }
                                />
                                <p className="text-danger">{errors.email?.message}</p>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="mb-4 password">
                                <label className="form-label" htmlFor="password">Password<span className="requiredFields">*</span></label>
                                <Controller
                                    control={control}
                                    name="password"
                                    render={({ field: { value, onChange } }) =>
                                        <input
                                            name="password"
                                            id="password"
                                            onChange={(e) => onChange(handleInput(e))}
                                            onKeyPress={(e) => handleKeyPress(e)}
                                            value={value}
                                            type={isPassword ? "password" : "text"}
                                            className="form-control form-control-lg ps-2 inputPassword"
                                            placeholder="e.g *******"
                                        />
                                    }
                                />
                                <i className={`bi ${isEye ? "bi-eye" : "bi-eye-slash"}`} onClick={() => changeType()}></i>
                                <p className="text-danger">{errors.password?.message}</p>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="mb-4">
                                <p className="mb-0 mt-3 text-terms">
                                    <span className="me-1">
                                        By signing up, I accept the Adtendees&nbsp;
                                        <Link to={`${pathname}?redirect=${query}`} className="text-primary ">
                                            Terms of Service</Link> and acknowledge the&nbsp;
                                        <Link to={`${pathname}?redirect=${query}`} className="text-primary ">
                                            Privacy Policy.
                                        </Link>
                                    </span>
                                </p>
                            </div>
                        </div>

                        <div className="col-12 mb-4">
                            <button className="btn btn-primary w-100 mb-3" type="submit">
                                {isUserRegisterLoading ? <SmallLoader color={'dark'} /> : "Sign up"}
                            </button>

                            <p className="text-center text-muted small">OR</p>
                            <div className="text-center">
                                {!isSocialUserRegisterLoading ? <UserGoogleRegister /> : <SmallLoader color={'dark'} classNames='text-center' />}
                            </div>
                        </div>
                    </form>)
                }}
            </Observer>
        </Fragment>
    )
};

export default RegisterForm;