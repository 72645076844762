import { Fragment } from "react";

import Logo from "src/assets/images/Light-blue.svg";

const FormFooter = () => {
    return (
        <Fragment>
            <footer
                className="footer bg-dark pb-0"
                style={{
                    "backgroundImage": "url('images/svg-map.svg')",
                    "backgroundRepeat": "no-repeat",
                    "backgroundPosition": "center"
                }}
            >
                <div className="footer-lead">
                    <div className="container text-center">
                        <div className="row justify-content-center">
                            <div className="col-12">
                                <div className="text-center">
                                    <p className="mb-0 h6 d-inline-flex align-items-center">
                                        Powered by
                                        <img
                                            src={Logo}
                                            alt=""
                                            className="img-fluid ms-2"
                                        />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </Fragment>
    )
}

export default FormFooter;