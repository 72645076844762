import { Fragment } from "react";

const UploadSvg = () => {
    return (
        <Fragment>
            <svg width="22" height="20" viewBox="0 0 22 20" fill="none">
                <path
                    d="M1.54407 14.0782L7.11154 9.22518C7.56686 8.82841 8.18433 8.60552 8.82816 8.60552C9.47198 8.60552 10.0895 8.82841 10.5448 9.22518L16.1122 14.0782M13.6842 11.9617L15.6096 10.2834C16.065 9.88663 16.6824 9.66374 17.3263 9.66374C17.9701 9.66374 18.5876 9.88663 19.0429 10.2834L20.9683 11.9617M13.6842 5.61241H13.6964M3.9721 18.3111H18.5403C19.1842 18.3111 19.8018 18.0881 20.2572 17.6912C20.7125 17.2943 20.9683 16.756 20.9683 16.1946V3.49596C20.9683 2.93465 20.7125 2.39632 20.2572 1.99941C19.8018 1.6025 19.1842 1.37952 18.5403 1.37952H3.9721C3.32814 1.37952 2.71056 1.6025 2.25522 1.99941C1.79988 2.39632 1.54407 2.93465 1.54407 3.49596V16.1946C1.54407 16.756 1.79988 17.2943 2.25522 17.6912C2.71056 18.0881 3.32814 18.3111 3.9721 18.3111Z"
                    stroke="#9CA1AD" strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </Fragment>
    )
};

export default UploadSvg;