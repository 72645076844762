import { configure, makeObservable, observable, runInAction } from 'mobx';
import { getRequest } from 'src/utils/api';
import { getValidDataFromResponse, isValidArray } from 'src/utils/utilities';
import updateHelper from 'immutability-helper';

configure({ useProxies: 'never' });

class UserSettingStore {
    // data comes from api
    userProfileData = [{}];
    userProfileDataLoading = false;

    constructor() {
        makeObservable(this, {
            userProfileData: observable,
            userProfileDataLoading: observable
        });
    }

    // When user comes on the profile page
    getUserProfileData = async (user_id) => {
        try {
            this.userProfileDataLoading = true;

            const { response } = await getRequest(`/user/info/${user_id}`);
            const { data, error } = getValidDataFromResponse(response, false);

            if (!error && isValidArray(data)) {
                runInAction(() => {
                    this.userProfileData = updateHelper(this.userProfileData, {
                        $set: data
                    });
                })
            }
            runInAction(() => {
                this.userProfileDataLoading = false;
            })
        } catch (error) {
            runInAction(() => {
                this.userProfileDataLoading = false;
            })
        }
    }
}

export default UserSettingStore;
