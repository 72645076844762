import { Observer } from "mobx-react";
import { Fragment } from "react";
import { Outlet, useLocation, Navigate } from 'react-router-dom';

import Footer from "./footer";
import Header from "./header";
import SideBar from "./sidebar";

const MainLayout = () => {
    const { pathname } = useLocation();

    if (pathname === '/') {
        return <Navigate to={'/dashboard'} />
    }
    return (
        <Observer>
            {() => {
                return (
                    <Fragment>
                        <div className="page-wrapper landrick-theme toggled">
                            <SideBar />

                            <main className="page-content bg">
                                <Header />

                                <div className="container-fluid">
                                    <Outlet />
                                </div>

                                <Footer />
                            </main>
                        </div>
                    </Fragment>
                )
            }}
        </Observer>
    );
};

export default MainLayout;