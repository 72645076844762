import { Fragment, useCallback, useEffect, useState } from "react";
import { Observer, useLocalObservable } from "mobx-react";
import { toJS } from "mobx";
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import { countExactlyMinutesForEnd, countExactlyMinutesForStart, isValidArray, trimString } from "src/utils/utilities";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { firstStepSchema } from "./firstStep.schema";
import { useMainStore } from 'src/container/index';
import { timeZones } from 'src/utils/timezone';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import moment from "moment";
import { DateUtils } from 'react-day-picker';

import dateFnsFormat from 'date-fns/format';
import dateFnsParse from 'date-fns/parse';

import UploadSvg from "src/components/common/uploadSvg";
import UploadedImages from 'src/components/common/uploadedImageList';
import SmallLoader from "src/components/common/smallLoader";
import { Tooltip } from "antd";
import ImagePreview from "src/components/common/imagePreview";


const UpdateFirstStep = () => {
    const mainStore = useMainStore();

    const params = useParams();
    let navigate = useNavigate();
    const { pathname } = useLocation();

    const [imageSrc, setImageSrc] = useState('')

    let recentSchedule = null;
    let recentStartTime = null;
    let recentEndTime = null;

    const firstStepLocalStore = useLocalObservable(() => (
        {
            isImageListModal: false,
            isTimeZoneBox: false,
            isTimeZoneSubBox: false,
            selectedTimeZoneUTC: [],
            defaultTimeZoneValue: null,
            handleImageListModal: function (val) {
                this.isImageListModal = val;
            },
            handleTimeZoneBox: function () {
                this.isTimeZoneBox = !this.isTimeZoneBox;
                this.isTimeZoneSubBox = !this.isTimeZoneSubBox;
            },
            handleTimeZoneSubBox: function (val) {
                this.isTimeZoneSubBox = val;
            },
            addSubTimeZoneUTCValues: function (value) {
                this.selectedTimeZoneUTC = [...value];
            },
            setTimeZoneValue: function (value) {
                this.defaultTimeZoneValue = value;
            },
            isGoLive: false,
            handleIsGoLiveModal: function (val) {
                this.isGoLive = val;
            }
        }
    ));

    const { updateStreamStore } = mainStore;

    const { register, formState: { errors }, handleSubmit, control, setValue, getValues } = useForm({
        resolver: yupResolver(firstStepSchema),
        defaultValues: {}
    });

    useEffect(() => {
        const { getStreamDetailsById, resetSingleStreamData } = updateStreamStore;
        const { sid = null } = params;
        getStreamDetailsById(sid);
        return () => {
            resetSingleStreamData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const goToBackPage = () => {
        navigate(-1);
    }

    const openUploadedImageListModal = (val) => {
        firstStepLocalStore.handleImageListModal(val);
    }

    const openOrCloseTimeZoneBox = () => {
        firstStepLocalStore.handleTimeZoneBox();
    }

    const onSelectOpenTimeZoneSubBox = (timezoneValue) => {
        // firstStepLocalStore.handleTimeZoneSubBox(true);
        firstStepLocalStore.setTimeZoneValue(timezoneValue);
        timeZones.forEach(({ abbr, utc }) => {
            if (abbr === timezoneValue) {
                firstStepLocalStore.addSubTimeZoneUTCValues(utc)
                setValue('timezone', utc[0]);
            }
        })
    }

    const parseDate = (str, format, locale) => {
        const parsed = dateFnsParse(str, format, new Date(), { locale });
        if (DateUtils.isDate(parsed)) {
            return parsed;
        }
        return undefined;
    }

    const formatDate = (date, format, locale) => {
        return dateFnsFormat(date, format, { locale });
    }

    const handleUpdateStream = (data) => {
        let updatedStreamData = {
            ...data,
            is_created: false,
            is_updated: false,
        };
        const { handleUpdateStreamFirstForm } = updateStreamStore;
        handleUpdateStreamFirstForm(updatedStreamData);
    }

    const getDefaultTimeZoneValue = (val) => {
        let latestValue = null;
        for (let index = 0; index < timeZones.length; index++) {
            const { utc, abbr } = timeZones[index];
            for (let i = 0; i < utc.length; i++) {
                const u = utc[i];
                if (u === val) {
                    latestValue = abbr;
                }
            }
        }
        return latestValue;
    }

    const getDefaultSelectedTimeZoneUTC = (val) => {
        const { selectedTimeZoneUTC, defaultTimeZoneValue } = firstStepLocalStore;
        if (!defaultTimeZoneValue) {
            for (let index = 0; index < timeZones.length; index++) {
                const { utc } = timeZones[index];
                for (let i = 0; i < utc.length; i++) {
                    const u = utc[i];
                    if (u === val) {
                        return utc;
                    }
                }
            }
        }
        return selectedTimeZoneUTC;
    }

    const onLiveChangeFormValue = ({ target: { checked } }) => {

        const currentTime = moment().format('MM/DD/YYYY')

        const currentMinutes = countExactlyMinutesForStart(moment().format('mm'));
        const CurrentHours = currentMinutes === '00' ? moment().add(1, 'h').format('hh') : moment().format('hh')
        const CurrentAmPm = currentMinutes === '00' ? moment().add(1, 'h').format('A') : moment().format('A')

        const startTime = moment(`${currentTime} ${CurrentHours + ':' + currentMinutes} ${CurrentAmPm}`);
        const endTime = moment(`${currentTime} ${CurrentHours + ':' + currentMinutes} ${CurrentAmPm}`).add(1, 'h');

        // set Start Date
        setValue('schedule', moment().format('MM/DD/YYYY'));

        // set Start timings
        setValue('start_time_h', startTime.format('hh'));
        setValue('start_time_m', startTime.format('mm'));
        setValue('start_time_a', startTime.format('A'));

        // set End Timings
        setValue('end_time_h', endTime.format('hh'));
        setValue('end_time_m', endTime.format('mm'));
        setValue('end_time_a', endTime.format('A'));

        // if (checked) {
        //     // start time change
        //     setValue('schedule', moment().format('MM/DD/YYYY'));
        //     const start_minutes = countExactlyMinutesForStart(moment().format('mm'));
        //     setValue('start_time_m', start_minutes);
        //     const start_hours = start_minutes === '00' ? moment(moment()).add(1, 'hours').format('hh') : moment().format('hh');
        //     setValue('start_time_h', start_hours);
        //     setValue('start_time_a', moment().format('A'));

        //     // end time change
        //     const end_minutes = countExactlyMinutesForEnd(moment().format('mm'));
        //     setValue('end_time_m', end_minutes);
        //     const end_hours = end_minutes === '15' || end_minutes === '00' ? moment(moment()).add(1, 'hours').format('hh') : moment().format('hh');
        //     setValue('end_time_h', end_hours);
        //     setValue('end_time_a', moment().format('A'));
        // } else {
        //     setValue('schedule', moment(recentSchedule).format('MM/DD/YYYY'));

        //     // start time reset
        //     setValue('start_time_h', moment(recentStartTime).format('hh'));
        //     setValue('start_time_m', moment(recentStartTime).format('mm'));

        //     // end time reset
        //     setValue('end_time_h', moment(recentEndTime).format('hh'));
        //     setValue('end_time_m', moment(recentEndTime).format('mm'));
        // }
    }

    const handleTimeChange = ({target}) => {

        let updatedEndTime;
        
        const [scheduledOn, startHour, startMinutes, amOrPm ] = getValues(['schedule', 'start_time_h', 'start_time_m', 'start_time_a'])

        if(!scheduledOn){
            setValue('schedule', moment().format('MM/DD/YYYY'))
        }

        const startDate = scheduledOn ? moment(scheduledOn).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY')

        switch(target.id){
            case 'start_time_h':
                updatedEndTime = moment(`${startDate} ${target.value +  ":" + startMinutes} ${amOrPm}`)
                break;
            case 'start_time_m':
                updatedEndTime = moment(`${startDate} ${startHour +  ":" + target.value} ${amOrPm}`)
                break;
            case 'start_time_a':
                updatedEndTime = moment(`${startDate} ${startHour +  ":" + startMinutes} ${target.value}`)
            break;
            default:
                return
        }

        setValue('end_time_h', moment(updatedEndTime).add(1, "h").format('hh'))
        setValue('end_time_m', moment(updatedEndTime).add(1, "h").format('mm'))
        setValue('end_time_a', moment(updatedEndTime).add(1, "h").format('A'))
    }

    const setDefaultIsLiveGoModal = useCallback(() => {
        const { handleIsGoLiveModal } = firstStepLocalStore;
        const { singleStreamData } = updateStreamStore;

        if (isValidArray(toJS(singleStreamData))) {
            const { schedule, start_time, end_time, wowza_live = false } = singleStreamData[0];

            wowza_live && handleIsGoLiveModal(wowza_live);

            setValue('schedule', moment(schedule).format('MM/DD/YYYY'));
            setValue('start_time_h', moment(start_time).format('hh'));
            setValue('start_time_m', moment(start_time).format('mm'));
            setValue('start_time_a', moment(start_time).format('A'));
            setValue('end_time_h', moment(end_time).format('hh'));
            setValue('end_time_m', moment(end_time).format('mm'));
            setValue('end_time_a', moment(end_time).format('A'));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [firstStepLocalStore.isGoLive]);

    const getValidImageURL = () =>{
        const { uploadedFileStreamImages } = updateStreamStore;

        let imageData = toJS(uploadedFileStreamImages)[toJS(uploadedFileStreamImages).length - 1];

        if(imageData.link){
            return imageData.link;
        } else {
            return URL.createObjectURL(imageData)
        }
    }

    const handleImageClick = (event, callBack) => {
        setImageSrc(event.target.src)
        callBack()
    }

    return (
        <Fragment>
            <div className="bg-white p-2 rounded box-shadow">
                <Observer>
                    {() => {
                        const {
                            isImageListModal, isTimeZoneBox, isTimeZoneSubBox, defaultTimeZoneValue,
                            isGoLive, handleIsGoLiveModal
                        } = firstStepLocalStore;

                        const {
                            handleFileUpload, uploadedFileStreamImages, handleRemoveFileFromList, streamFirstFormUpdateLoading,
                            streamFirstFormSuccessRedirect, singleStreamData, singleStreamLoading, removeAllUpdatedFiles
                        } = updateStreamStore;

                        if (streamFirstFormSuccessRedirect) {
                            const { sid = null } = params;
                            const latestPathName = trimString(pathname, 'details', 1);
                            return <Navigate to={`${latestPathName}/settings/${sid}`} />;
                        }

                        if (singleStreamLoading) {
                            setDefaultIsLiveGoModal();
                            return (
                                <div className="d-flex justify-content-center align-items-center">
                                    <SmallLoader color={'default'} />
                                </div>
                            )
                        }

                        if(uploadedFileStreamImages.length > 0) {
                            setImageSrc(() => {
                                let imageData = uploadedFileStreamImages[uploadedFileStreamImages.length - 1]

                                if(imageData.link){
                                    return imageData.link;
                                } else {
                                    return imageSrc;
                                }
                            });
                        }

                        return (
                            <Fragment>
                                <form onSubmit={handleSubmit(handleUpdateStream)}>
                                    <div className="row">
                                        {singleStreamData.map((
                                            {
                                                title = '',
                                                description = '',
                                                schedule = '',
                                                start_time = '',
                                                end_time = '',
                                                category = '',
                                                timezone = null,
                                                _id,
                                                wowza_live = false,
                                                is_recording = false,
                                            }
                                            , i) => {

                                            recentSchedule = schedule;
                                            recentStartTime = start_time;
                                            recentEndTime = end_time;

                                            return (
                                                <Fragment key={i}>
                                                    <div>
                                                        <div>
                                                            <input
                                                                name="title"
                                                                id="title"
                                                                type="hidden"
                                                                {...register("start_time", { value: moment(start_time).format('hh:mm') })}
                                                            />
                                                            <input
                                                                name="title"
                                                                id="title"
                                                                type="hidden"
                                                                {...register("end_time", { value: moment(end_time).format('hh:mm') })}
                                                            />
                                                            <input
                                                                name="title"
                                                                id="title"
                                                                type="hidden"
                                                                {...register("stream_id", { value: _id })}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <div className="mb-3">
                                                            <label className="form-label">Title<span className="requiredFields">*</span></label>
                                                            <input
                                                                name="title"
                                                                id="title"
                                                                type="text"
                                                                className="form-control form-control-lg ps-2"
                                                                placeholder="Give your event a title"
                                                                autoComplete="off"
                                                                {...register("title", { value: title })}
                                                            />
                                                            <p className="text-danger">{errors.title?.message}</p>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <div className="mb-3">
                                                            <label className="form-label">Description<span className="requiredFields">*</span></label>
                                                            <textarea
                                                                name="description"
                                                                id="description"
                                                                rows="4"
                                                                autoComplete="off"
                                                                className="form-control ps-2"
                                                                placeholder="Short description about your stream"
                                                                {...register('description', { value: description })}
                                                            >
                                                            </textarea>
                                                            <p className="text-danger">{errors.description?.message}</p>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <div className="mb-3">
                                                            <label className="form-label d-flex justify-content-between">
                                                                <div>TimeZone</div>
                                                                <div>
                                                                    {defaultTimeZoneValue || timezone}&nbsp;
                                                                    <small
                                                                        className="text-primary pe-auto manual-pointer"
                                                                        onClick={openOrCloseTimeZoneBox}
                                                                    >
                                                                        &nbsp;Change
                                                                    </small>
                                                                </div>
                                                            </label>
                                                            {
                                                                isTimeZoneBox &&
                                                                (<select
                                                                    className="form-select form-control form-control-lg form-time"
                                                                    onChange={({ target: { value } }) => onSelectOpenTimeZoneSubBox(value)}
                                                                    defaultValue={defaultTimeZoneValue || getDefaultTimeZoneValue(timezone)}
                                                                >
                                                                    {timeZones.map(({ text, abbr }) => (
                                                                        <option
                                                                            value={abbr}
                                                                            id={abbr}
                                                                            key={abbr}
                                                                        >
                                                                            {text}
                                                                        </option>
                                                                    )
                                                                    )}
                                                                </select>)
                                                            }
                                                        </div>
                                                        {
                                                            isTimeZoneSubBox && (
                                                                <select
                                                                    className="form-select form-control form-control-lg form-time mb-2"
                                                                    {...register('timezone', { value: timezone })}
                                                                >
                                                                    {getDefaultSelectedTimeZoneUTC(timezone).map((value) => (
                                                                        <option
                                                                            value={value}
                                                                            key={value}
                                                                        >
                                                                            {value}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            )
                                                        }
                                                        <input
                                                            name="timezone"
                                                            id="timezone"
                                                            type="hidden"
                                                            className="form-control form-control-lg ps-2"
                                                            {...register('timezone_default', { value: timezone })}
                                                        />
                                                    </div>

                                                    <div className="col-md-12">
                                                        <div className="d-flex justify-content-between mb-3">
                                                            <div className="form-check form-switch">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="wowza_live"
                                                                    defaultChecked={false}
                                                                    {...register('wowza_live', {
                                                                        value: wowza_live,
                                                                        onChange: (e) => {
                                                                            onLiveChangeFormValue(e);
                                                                            handleIsGoLiveModal(e.target.checked);
                                                                        }
                                                                    })}
                                                                />
                                                                <label className="form-check-label label-color fw-15" htmlFor="wowza_live">
                                                                    Go live now
                                                                </label>
                                                                <p className="text-danger">{errors.wowza_live?.message}</p>
                                                            </div>
                                                            <div className="form-check form-switch">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="is_recording"
                                                                    // defaultChecked={false}
                                                                    {...register('is_recording', {
                                                                        value: is_recording
                                                                    })}
                                                                />
                                                                <label className="form-check-label label-color fw-15" htmlFor="is_recording">
                                                                    Want to record stream?
                                                                </label>
                                                                <p className="text-danger">{errors.is_recording?.message}</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* schedule time section start */}
                                                    {!isGoLive && (<div className="col-md-12">
                                                        <div className="mb-3 d-grid">
                                                            <label className="form-label">Schedule<span className="requiredFields">*</span></label>
                                                            <Controller
                                                                name={"schedule"}
                                                                control={control}
                                                                defaultValue={moment(schedule).format('MM/DD/YYYY')}
                                                                render={({ field: { onChange, value } }) => (
                                                                    <DayPickerInput
                                                                        onDayChange={onChange}
                                                                        value={value}
                                                                        formatDate={formatDate}
                                                                        format={'MM/dd/yyyy'}
                                                                        parseDate={parseDate}
                                                                        component={(props) => {
                                                                            return (
                                                                                <input
                                                                                    {...props}
                                                                                    name="schedule"
                                                                                    id="schedule"
                                                                                    type="text"
                                                                                    placeholder=""
                                                                                    autoComplete="off"
                                                                                    className="form-control form-control-lg ps-2"
                                                                                />
                                                                            )
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                            <p className="text-danger">{errors.schedule?.message}</p>
                                                        </div>
                                                    </div>)}
                                                    {/* schedule time section end */}

                                                    {/* start time section start */}
                                                    {!isGoLive && (<div className="col-md-6">
                                                        <div className="mb-3">
                                                            <label className="form-label">Start Time<span className="requiredFields">*</span></label>
                                                            <div className="row gx-1">
                                                                <div className="col-4">
                                                                    <select
                                                                        className="form-select form-control form-control-lg form-time"
                                                                        id="start_time_h"
                                                                        {...register('start_time_h', { 
                                                                            value: moment(start_time).format("hh"),
                                                                            onChange: (e) => handleTimeChange(e)
                                                                        })}
                                                                    >
                                                                        {
                                                                            Array(12).fill().map((_, i) => {
                                                                                return (
                                                                                    <option value={`${++i}`.length === 1 ? `0${i}` : `${i}`}>
                                                                                        {`${i}`.length === 1 ? `0${i}` : `${i}`}
                                                                                    </option>
                                                                                )
                                                                            })
                                                                        }
                                                                    </select>
                                                                </div>

                                                                <div className="col-4">
                                                                    <select
                                                                        className="form-select form-control form-control-lg form-time"
                                                                        id="start_time_m"
                                                                        {...register('start_time_m', { 
                                                                            value: moment(start_time).format("mm"),
                                                                            onChange: (e) => handleTimeChange(e)
                                                                         })}
                                                                    >
                                                                        {
                                                                            Array(60).fill().map((_, i) => {
                                                                                if ([0, 15, 30, 45].includes(i)) {
                                                                                    return (
                                                                                        <option value={`${i}`.length === 1 ? `0${i}` : `${i}`}>
                                                                                            {`${i}`.length === 1 ? `0${i}` : `${i}`}
                                                                                        </option>
                                                                                    )
                                                                                }
                                                                                return null;
                                                                            })
                                                                        }
                                                                    </select>
                                                                </div>
                                                                <div className="col-4">
                                                                    <select
                                                                        className="form-select form-control form-control-lg form-time"
                                                                        id="start_time_a"
                                                                        {...register('start_time_a', { 
                                                                            value: moment(start_time).format("A"),
                                                                            onChange: (e) => handleTimeChange(e)
                                                                         })}
                                                                    >
                                                                        <option value="AM">AM</option>
                                                                        <option value="PM">PM</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <p className="text-danger">{errors.start_time?.message}</p>
                                                        </div>
                                                    </div>)}
                                                    {/* start time section end */}

                                                    {/* end time section start */}
                                                    {!isGoLive && (<div className="col-md-6">
                                                        <div className="mb-3">
                                                            <label className="form-label">End Time<span className="requiredFields">*</span></label>
                                                            <div className="row gx-1">
                                                                <div className="col-4">
                                                                    <select
                                                                        className="form-select form-control form-control-lg form-time"
                                                                        {...register('end_time_h', { value: moment(end_time).format("hh") })}
                                                                    >
                                                                        {
                                                                            Array(12).fill().map((_, i) => {
                                                                                return (
                                                                                    <option value={`${++i}`.length === 1 ? `0${i}` : `${i}`}>
                                                                                        {`${i}`.length === 1 ? `0${i}` : `${i}`}
                                                                                    </option>
                                                                                )
                                                                            })
                                                                        }
                                                                    </select>
                                                                </div>

                                                                <div className="col-4">
                                                                    <select
                                                                        className="form-select form-control form-control-lg form-time"
                                                                        {...register('end_time_m', { value: moment(end_time).format("mm") })}
                                                                    >
                                                                        {
                                                                            Array(60).fill().map((_, i) => {
                                                                                if ([0, 15, 30, 45].includes(i)) {
                                                                                    return (
                                                                                        <option value={`${i}`.length === 1 ? `0${i}` : `${i}`}>
                                                                                            {`${i}`.length === 1 ? `0${i}` : `${i}`}
                                                                                        </option>
                                                                                    )
                                                                                }
                                                                                return null;
                                                                            })
                                                                        }
                                                                    </select>
                                                                </div>
                                                                <div className="col-4">
                                                                    <select
                                                                        className="form-select form-control form-control-lg form-time"
                                                                        {...register('end_time_a', { value: moment(end_time).format("A") })}
                                                                    >
                                                                        <option value="AM">AM</option>
                                                                        <option value="PM">PM</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <p className="text-danger">{errors.end_time?.message}</p>
                                                        </div>
                                                    </div>)}
                                                    {/* end time section end */}

                                                    {/* Upload image section start */}
                                                    <div className="col-5 col-sm-4">
                                                        <div className="mb-3">
                                                            <div className="file-input">
                                                                <input
                                                                    type="file"
                                                                    name="pictures"
                                                                    id="file-input"
                                                                    className="file-input__input"
                                                                    multiple
                                                                    onChange={e => handleFileUpload(e)}
                                                                />
                                                                <label className="file-input__label" htmlFor="file-input">
                                                                    <UploadSvg />
                                                                    <span>Upload Images</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-7 ">
                                                        <div className='uploaded_images'>
                                                            {toJS(uploadedFileStreamImages).length > 0 && (
                                                                <>
                                                                <Tooltip placement="right" title="Enlarge">
                                                                    <img 
                                                                        className="cursorPointer position-relative preview_image" 
                                                                        onClick={(e) => handleImageClick(e, () => openUploadedImageListModal(true))} 
                                                                        src={getValidImageURL()} 
                                                                        alt={toJS(uploadedFileStreamImages)[toJS(uploadedFileStreamImages).length - 1].name} 
                                                                    />
                                                                </Tooltip>
                                                                    <i className="bi bi-x-circle close_ico cursorPointer" onClick={() => removeAllUpdatedFiles() } />
                                                                </>
                                                            )}                  
                                                        </div>
                                                    </div>
                                                    {/* Upload image section end */}

                                                    {/* Category section start */}
                                                    <div className="col-md-12">
                                                        <div className="mb-3">
                                                            <label className="form-label">Category<span className="requiredFields">*</span></label>
                                                            <select
                                                                className="form-select form-control form-control-lg form-time"
                                                                {...register('category', { value: category })}
                                                            >
                                                                <option value="Software">Software</option>
                                                                <option value="Education">Education</option>
                                                                <option value="Technology">Technology</option>
                                                                <option value="How to">How to</option>
                                                                <option value="Gaming">Gaming</option>
                                                                <option value="Music">Music</option>
                                                                <option value="Sports">Sports</option>
                                                                <option value="Collectibles">Collectibles</option>
                                                                <option value="Fashion">Fashion</option>
                                                                <option value="Other">Other</option>
                                                            </select>
                                                        </div>
                                                        <p className="text-danger">{errors.category?.message}</p>
                                                    </div>
                                                    {/* Category section end */}

                                                </Fragment>
                                            )
                                        })}

                                    </div>

                                    <div className="row text-right">
                                        <div className="col-md-12">
                                            <button
                                                type="submit"
                                                className="btn btn-footers btn-primary"
                                            >
                                                {streamFirstFormUpdateLoading ? <SmallLoader color={'dark'} /> : "Next"}
                                            </button>
                                            <button
                                                onClick={goToBackPage}
                                                type="button"
                                                className="btn btn-footers btn-dark-footer"
                                            >
                                                Close
                                            </button>
                                        </div>
                                    </div>

                                </form>
                                {isImageListModal &&
                                    (
                                        <ImagePreview
                                            headerName={toJS(uploadedFileStreamImages).pop().name}
                                            onCloseModal={openUploadedImageListModal}
                                            imageSrc={imageSrc}
                                            onRemoveFile={handleRemoveFileFromList}
                                        />
                                    )
                                }
                            </Fragment>
                        )
                    }}
                </Observer>
            </div>
        </Fragment>
    )
}

export default UpdateFirstStep;
