import { Fragment } from "react";
import { Observer } from "mobx-react";
import PassForm from "./form";

const ChangePasswordForm = () => {
    return (
        <Observer>
            {() => {
                return (
                    <Fragment>
                        <div
                            className="modal fade modal-open show"
                            tabIndex="-1"
                            aria-hidden="true"
                            style={{ display: 'block' }}
                            role='dialog'
                        >
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content rounded shadow border-0">
                                    <div className="modal-header border-bottom">
                                        <div className="d-flex align-items-center">
                                            <h5 className="modal-title me-3" id="edit_profile-title">Change Password</h5>
                                        </div>
                                    </div>
                                    <div className="modal-body">
                                        <div className="bg-white p-2 rounded box-shadow">
                                            <PassForm />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                )
            }}
        </Observer>
    )
}

export default ChangePasswordForm;