import { Fragment, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { Observer, useLocalObservable } from "mobx-react";
import { useMainStore } from "src/container";
import { timeZones } from 'src/utils/timezone';
import { currentTimeZone, getDefaultSelectedTimeZoneUTC, getDefaultTimeZoneValue, isValidArray } from 'src/utils/utilities';
import { autorun, toJS } from "mobx";
import SmallLoader from "src/components/common/smallLoader";

const GeneralSection = () => {
    const mainStore = useMainStore();
    const { settingStore } = mainStore;

    const localStore = useLocalObservable(() => ({
        defaultTimeZone: null,
        defaultTimeZoneSub: null,
        selectedTimeZone: null,
        selectedTimeZoneSub: null,
        handleTimeZoneChange(val) {
            this.selectedTimeZone = val;
        },
        handleTimeZoneSubChange(val) {
            this.selectedTimeZoneSub = val;
        },
        handleDefaultTimeZoneChange(val) {
            this.defaultTimeZone = val;
        },
        handleDefaultTimeZoneSubChange(val) {
            this.defaultTimeZoneSub = [...val];
        }
    }));

    useEffect(() => {
        const { generalSetting = [] } = settingStore;
        const { handleDefaultTimeZoneChange, handleTimeZoneSubChange, handleDefaultTimeZoneSubChange, handleTimeZoneChange } = localStore;

        if (isValidArray(toJS(generalSetting))) {
            const { timezone } = generalSetting[0];

            handleTimeZoneChange(getDefaultTimeZoneValue(timezone));
            handleDefaultTimeZoneChange(getDefaultTimeZoneValue(timezone));
            handleTimeZoneSubChange([timezone]);
            handleDefaultTimeZoneSubChange(getDefaultSelectedTimeZoneUTC(timezone));
        }

        return () => { }
    }, [settingStore, localStore]);

    autorun(() => {
        const { generalSetting = [] } = settingStore;
        const { handleDefaultTimeZoneChange, handleTimeZoneSubChange, handleDefaultTimeZoneSubChange, handleTimeZoneChange } = localStore;

        if (isValidArray(toJS(generalSetting))) {
            const { timezone } = generalSetting[0];
            handleTimeZoneChange(getDefaultTimeZoneValue(timezone));
            handleDefaultTimeZoneChange(getDefaultTimeZoneValue(timezone));
            handleTimeZoneSubChange([timezone]);
            handleDefaultTimeZoneSubChange(getDefaultSelectedTimeZoneUTC(timezone));
            return;
        }

        handleTimeZoneChange(getDefaultTimeZoneValue(currentTimeZone));
        handleDefaultTimeZoneChange(getDefaultTimeZoneValue(currentTimeZone));
        handleTimeZoneSubChange(getDefaultSelectedTimeZoneUTC(currentTimeZone)[0]);
        handleDefaultTimeZoneSubChange(getDefaultSelectedTimeZoneUTC(currentTimeZone));
    });

    const onSelectOpenTimeZoneSubBox = (e) => {
        const { handleGeneralSection } = settingStore;
        const { handleTimeZoneChange, handleDefaultTimeZoneSubChange } = localStore;
        const { target: { value: timezoneValue } } = e;

        handleTimeZoneChange(timezoneValue);
        timeZones.forEach(({ value, utc }) => {
            if (value === timezoneValue) {
                handleGeneralSection({ timezone: utc[0] });
                handleDefaultTimeZoneSubChange(utc)
            }
        })
    }

    const handleTimeZoneSub = (e) => {
        const { handleTimeZoneSubChange } = localStore;
        const { target: { value } } = e;
        handleTimeZoneSubChange(value);

        handleSaveTimeZone();
    }

    const handleSaveTimeZone = () => {
        const { handleGeneralSection } = settingStore;
        const { selectedTimeZoneSub } = localStore;
        handleGeneralSection({ timezone: selectedTimeZoneSub });
    }

    return (
        <Observer>
            {() => {
                const { adsSettingLoading = false } = settingStore;
                const { defaultTimeZone, defaultTimeZoneSub, selectedTimeZone, selectedTimeZoneSub } = localStore;

                if (adsSettingLoading) {
                    return (
                        <div className="loader_center mt-5">
                            <SmallLoader color={'black'} />
                        </div>
                    )
                }

                return (
                    <Fragment>
                        <div className="content active" id="general">
                            <div className="row">
                                <div className="col-md-5 col-lg-4 col-xl-3 mb-3">
                                    <h6 className="text-muted2">TIMEZONE</h6>

                                    <select
                                        className="form-select form-control form-control-sm form-time2 mb-3"
                                        defaultValue={defaultTimeZone}
                                        onChange={onSelectOpenTimeZoneSubBox}
                                        value={selectedTimeZone}
                                    >
                                        {timeZones.map(({ text, abbr, value }) => (
                                            <option
                                                value={value}
                                                id={abbr}
                                                key={text}
                                            >
                                                {text}
                                            </option>
                                        ))}
                                    </select>

                                    <select
                                        className="form-select form-control form-control-sm form-time2"
                                        defaultValue={defaultTimeZoneSub[0]}
                                        onChange={handleTimeZoneSub}
                                        value={selectedTimeZoneSub}
                                    >
                                        {defaultTimeZoneSub.map((value) => (
                                            <option
                                                value={value}
                                                key={value}
                                            >
                                                {value}
                                            </option>
                                        ))}
                                    </select>

                                </div>
                            </div>
                        </div>
                        <Outlet />
                    </Fragment>
                )
            }}
        </Observer>
    );
}

export default GeneralSection;