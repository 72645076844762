import { Observer } from "mobx-react";
import { Fragment, useCallback, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useMainStore } from "src/container";
import { getLocalStorageItems, useQueryParam } from "src/utils/utilities";

import Avatar from 'react-avatar';
import SmallLoader from "src/components/common/smallLoader";

const UserSettings = () => {
    const mainStore = useMainStore();
    const { userSettingStore, profileStore, settingStore } = mainStore;
    const { userProfileDataLoading } = profileStore;
    const [query] = useQueryParam('sn');

    const { pathname } = useLocation();

    const fetchUpdatedUserProfile = useCallback(() => {
        const { getUserProfileData } = userSettingStore;
        const { uid } = getLocalStorageItems({ get: true, key: 'uid' });
        getUserProfileData(uid);
    }, [userSettingStore]);

    useEffect(() => {
        const { getUserProfileData } = userSettingStore;
        const { uid } = getLocalStorageItems({ get: true, key: 'uid' });
        getUserProfileData(uid);
    }, [userSettingStore, userProfileDataLoading]);

    return (
        <Observer>
            {() => {
                const { userProfileData } = userSettingStore;
                const { userProfileDataLoading, userProfileDataSuccess } = profileStore;
                const { updateSuccess } = settingStore;

                if (userProfileDataSuccess) {
                    fetchUpdatedUserProfile();
                }

                if (updateSuccess) {
                    fetchUpdatedUserProfile();
                }

                if (userProfileDataLoading) {
                    return (
                        <button
                            type="button"
                            className="btn btn-soft-light p-0"
                            key={10}
                        >
                            <SmallLoader color='default' />;
                        </button>
                    )
                }

                return (
                    <Fragment>
                        <div className="dropdown dropdown-primary">
                            {userProfileData.map(({ profile_image, full_name, title }) => {
                                return (
                                    <Fragment key={0}>
                                        <button
                                            type="button"
                                            className="btn btn-soft-light dropdown-toggle p-0"
                                            data-bs-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            key={10}
                                        >
                                            <Avatar
                                                name={full_name}
                                                size={38}
                                                src={profile_image}
                                                className="avatar avatar-ex-small rounded"
                                                alt="PI"
                                            />
                                        </button>
                                        <div
                                            className="dropdown-menu dd-menu dropdown-menu-end bg-white shadow border-0 mt-3 py-3"
                                            style={{ "minWidth": "200px" }}
                                            key={11}
                                        >
                                            <div className="dropdown-item d-flex align-items-center text-dark pb-3" key={12}>
                                                <Avatar
                                                    src={profile_image}
                                                    className="rounded-circle"
                                                    alt=""
                                                    name={full_name}
                                                    size={45}
                                                />
                                                <div className="flex-1 ms-2">
                                                    <span className="d-block text-capitalize">{full_name}</span>
                                                    <small className="text-muted text-capitalize">{title}</small>
                                                </div>
                                            </div>
                                            <Link
                                                to={'dashboard'}
                                                className="dropdown-item text-dark"
                                                key={1}>
                                                <span className="mb-0 d-inline-block me-1">
                                                    <i className="ti ti-home"></i>
                                                </span>
                                                Dashboard
                                            </Link>
                                            <Link
                                                to={`${query ? `${pathname}/profile?sn=${query}` : `${pathname}/profile`}`}
                                                className="dropdown-item text-dark"
                                                key={2}
                                            >
                                                <span className="mb-0 d-inline-block me-1">
                                                    <i className="ti ti-settings"></i>
                                                </span>
                                                Profile
                                            </Link>
                                            {/* <Link
                                                to={`${query ? `${pathname}/change-password?sn=${query}` : `${pathname}/change-password`}`}
                                                className="dropdown-item text-dark"
                                                key={2}
                                            >
                                                <span className="mb-0 d-inline-block me-1">
                                                    <i className="mdi mdi-lock-question"></i>
                                                </span>
                                                Change Password
                                            </Link> */}
                                            <Link
                                                to={'/logout'}
                                                className="dropdown-item text-dark"
                                                key={3}
                                            >
                                                <span className="mb-0 d-inline-block me-1">
                                                    <i className="mdi-logout-variant mdi"></i>
                                                </span>
                                                Logout
                                            </Link>
                                        </div>
                                    </Fragment>
                                )
                            })}

                        </div>
                    </Fragment>
                )
            }}
        </Observer>
    )
}
export default UserSettings;