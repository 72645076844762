import * as yup from 'yup';
import moment from 'moment';

export const firstStepSchema = yup.object({
    title: yup.string().trim().required("Title is required!").min(5, "Minimum five characters required!"),
    description: yup.string().trim().required("Description is required!").min(5, "Minimum five characters!"),

    schedule: yup.string().test('schedule', 'Please don`t select past date!', function (schedule) {
        const { timezone } = this.parent;

        const latest = moment().tz(timezone);
        const scheduleSelected = moment(schedule).tz(timezone);

        return scheduleSelected.isSameOrAfter(latest.format('MM/DD/YYYY'));
    }).required("Schedule is required!"),
    start_time: yup.string().test("start", "Please don`t select past time", function () {

        const { start_time_a, schedule, timezone, start_time_h, start_time_m } = this.parent;

        const joinStartTime = start_time_h + ":" + start_time_m;

        const latest = moment().tz(timezone);
        const scheduleSelected = moment(schedule).tz(timezone);
        const selectedSchedule = moment(schedule).format("MM/DD/YYYY");

        const latestDateWithStartDate = moment(`${selectedSchedule} ${joinStartTime} ${start_time_a}`);
        const latestDateWithFormat = moment().tz(timezone).format('MM/DD/YYYY hh:mm A');

        if (scheduleSelected.isSameOrAfter(latest.format('MM/DD/YYYY'))) {
            return latestDateWithStartDate.isSameOrAfter(latestDateWithFormat);
        }
        return false;
    }),
    end_time: yup.string().test("end", "Please don`t select past time", function () {
        const { end_time_a, schedule, start_time_a, timezone, start_time_h, start_time_m, end_time_h, end_time_m } = this.parent;

        const joinStartTime = start_time_h + ":" + start_time_m;
        const joinEndTime = end_time_h + ":" + end_time_m;

        const latest = moment().tz(timezone);
        const scheduleSelected = moment(schedule).tz(timezone);
        const selectedSchedule = moment(schedule).format("MM/DD/YYYY");

        const latestDateWithEndTime = moment(`${selectedSchedule} ${joinEndTime} ${end_time_a}`);
        const latestDateWithStartDateWithTimeZone = moment(`${selectedSchedule} ${joinStartTime} ${start_time_a}`).format('MM/DD/YYYY hh:mm A');

        if (scheduleSelected.isSameOrAfter(latest.format('MM/DD/YYYY'))) {
            return latestDateWithEndTime.isSameOrAfter(latestDateWithStartDateWithTimeZone);
        }
        return false;
    }),
    is_live: yup.bool().optional(),
    start_time_a: yup.string().required(),
    end_time_a: yup.string().required(),
    timezone: yup.string().required("Timezone is required!"),
    category: yup.string().required("Category is required!"),
    start_time_h: yup.string(),
    start_time_m: yup.string(),
    end_time_h: yup.string(),
    end_time_m: yup.string(),
    wowza_live: yup.bool().optional(),
});
