import { observable, configure, makeObservable } from 'mobx';

configure({ useProxies: 'never' });

// created local store for particular components

class MainStore {

	loginStore = {};
	registerStore = {};
	forgotPasswordStore = {};
	dashboardStore = {};
	streamsStore = {};
	createStreamStore = {};
	logoutStore = {};
	profileStore = {};
	userSettingStore = {};
	updateStreamStore = {};
	streamDetailsStore = {};
	streamFormStore = {};
	changePasswordStore = {};
	settingStore = {};

	constructor(stores) {
		makeObservable(this, {
			loginStore: observable,
			registerStore: observable,
			forgotPasswordStore: observable,
			dashboardStore: observable,
			streamsStore: observable,
			createStreamStore: observable,
			logoutStore: observable,
			profileStore: observable,
			userSettingStore: observable,
			updateStreamStore: observable,
			streamDetailsStore: observable,
			streamFormStore: observable,
			changePasswordStore: observable,
			settingStore: observable
		});

		this.loginStore = stores.loginStore;
		this.registerStore = stores.registerStore;
		this.forgotPasswordStore = stores.forgotPasswordStore;
		this.dashboardStore = stores.dashboardStore;
		this.streamsStore = stores.streamsStore;
		this.createStreamStore = stores.createStreamStore;
		this.logoutStore = stores.logoutStore;
		this.profileStore = stores.profileStore;
		this.userSettingStore = stores.userSettingStore;
		this.updateStreamStore = stores.updateStreamStore;
		this.streamDetailsStore = stores.streamDetailsStore;
		this.streamFormStore = stores.streamFormStore;
		this.changePasswordStore = stores.changePasswordStore;
		this.settingStore = stores.settingStore;
	}
}

export default MainStore;
