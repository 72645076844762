import { Fragment } from "react";
import { Observer } from "mobx-react";
import { SketchPicker } from 'react-color'
import { useMainStore } from "src/container";

import BlankBlack from 'src/assets/images/blankBlack.jpg';
import NormalModal from "src/components/common/normalModal";

const BGChange = ({ colorModal, handleColorModal, handleChangeComplete, selectedColor, handleBgModal }) => {
    const mainStore = useMainStore();
    const { streamFormStore } = mainStore;

    return (
        <Observer>
            {() => {
                const { handleFileUpload, selectedBgImage, removeUploadedBgImage } = streamFormStore;
                const selectedStyle = { backgroundColor: selectedColor || 'gray' };

                return (
                    <Fragment>
                        <div className="change_bg_box p-2 d-block" id="bg_change">
                            <button
                                type="button"
                                className="btn-close"
                                style={{ position: "relative", right: "-280px", top: "0px" }}
                                onClick={handleBgModal}
                            >
                            </button>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="mb-3 d-flex align-items-start">
                                        <img
                                            src={selectedBgImage || BlankBlack}
                                            className="img-fluid avatar avatar-small me-2 rounded"
                                            alt=""
                                        />
                                        <div className="edit_profile_remove">
                                            <div className="file-input2">
                                                <input
                                                    type="file"
                                                    name="file-input"
                                                    id="file-input"
                                                    className="file-input__input2"
                                                    onChange={e => handleFileUpload(e)}
                                                />
                                                <label className="file-input__label2" for="file-input">
                                                    <span>
                                                        {selectedBgImage ?
                                                            (
                                                                <div>
                                                                    <i className="mdi-file-replace mdi"></i>
                                                                    &nbsp;Replace
                                                                </div>
                                                            )
                                                            :
                                                            (
                                                                <div>
                                                                    <i className="mdi-upload-outline mdi"></i>
                                                                    &nbsp;Upload
                                                                </div>
                                                            )}
                                                    </span>
                                                </label>
                                            </div>

                                            <div>
                                                <label className="file-input__label2" onClick={removeUploadedBgImage}>
                                                    <span>
                                                        <i className="mdi-close mdi"></i>
                                                        &nbsp;Remove
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="mb-3 d-flex align-items-center">
                                        <h6 className="text-white-50 mb-0 me-3">Background color</h6>
                                        <p
                                            onClick={handleColorModal}
                                            style={{ ...selectedStyle }}
                                            className="avatar avatar-ex-small rounded mb-0 manual-pointer"
                                        >
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <NormalModal
                            display={colorModal}
                            callOnClose={handleColorModal}
                            style={{ width: '370px' }}
                            headerMessage="Please Select Background Color"
                            children={
                                <SketchPicker
                                    color={selectedColor}
                                    onChangeComplete={handleChangeComplete}
                                    onChange={handleChangeComplete}
                                    width={300}
                                />
                            } />
                    </Fragment>
                )
            }}
        </Observer>
    )
}

export default BGChange;