import { createContext, useContext } from 'react';

// Main store
import MainStore from 'src/container/main.store';

// Local store
import LoginStore from 'src/pages/login/login.store';
import ForgotPasswordStore from 'src/pages/forgotPassword/password.store';
import RegisterStore from 'src/pages/register/register.store';
import DashboardStore from 'src/pages/dashboard/dashboard.store';
import StreamsStore from 'src/pages/streams/streams.store';
import CreateStreamStore from 'src/pages/createStream/createStream.store';
import LogoutStore from 'src/pages/logout/logout.store';
import ProfileStore from 'src/pages/profile/profile.store';
import UserSettingStore from 'src/components/layouts/header/header.store';
import UpdateStreamStore from 'src/pages/updateStream/updateStream.store';
import StreamDetailsStore from 'src/pages/streamDetails/streamDetails.store';
import StreamFormStore from 'src/pages/form/form.store';
import ChangePasswordStore from 'src/pages/change-password/changePassword.store';
import SettingStore from 'src/pages/settings/setting.store';

const loginStore = new LoginStore();
const registerStore = new RegisterStore();
const forgotPasswordStore = new ForgotPasswordStore();
const dashboardStore = new DashboardStore();
const streamsStore = new StreamsStore();
const createStreamStore = new CreateStreamStore();
const logoutStore = new LogoutStore()
const profileStore = new ProfileStore();
const userSettingStore = new UserSettingStore();
const updateStreamStore = new UpdateStreamStore();
const streamDetailsStore = new StreamDetailsStore();
const streamFormStore = new StreamFormStore();
const changePasswordStore = new ChangePasswordStore();
const settingStore = new SettingStore();

export const mainStore = new MainStore({
    loginStore,
    registerStore,
    forgotPasswordStore,
    dashboardStore,
    streamsStore,
    createStreamStore,
    logoutStore,
    profileStore,
    userSettingStore,
    updateStreamStore,
    streamDetailsStore,
    streamFormStore,
    changePasswordStore,
    settingStore
});

const StoreContext = createContext({});
export const StoreProvider = StoreContext.Provider;

export const useMainStore = () => useContext(StoreContext);
