import * as yup from 'yup';

export const settingProfileSchema = yup.object({
    full_name: yup.string().min(3, "Full Name required")
        .required("Full Name required").matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field"),
    email: yup.string().email('Please Enter Valid Email').required("Email input required!"),
    title: yup.string().trim().nullable().min(0, "Minimum three character required!")
        .max(25, "Maximum twenty five character required!"),
    bio: yup.string().optional().trim().nullable().min(0, "Minimum three character required!"),
});
// // profile_image: yup.lazy((value) =>
// //     /^data/.test(value)
// //         ? yup.string()
// //             .trim()
// //             .matches(
// //                 /^data:([a-z]+\/[a-z0-9-+.]+(;[a-z-]+=[a-z0-9-]+)?)?(;base64)?,([a-z0-9!$&',()*+;=\-._~:@/?%\s]*)$/i,
// //                 'Must be a valid data URI',
// //             )
// //             .required()
// //         : yup.string().nullable().optional(),
// )