export const chartOptions = {
    chart: {
        id: 'apexchart-example',
        toolbar: {
            show: false
          },
          zoom:{
            enabled: false
          }
    },
    // yaxis: {
    //     tickAmount: 5,
    //     min: 0,
    //     max: 5
    // },
    plotOptions: {
        bar: {
          columnWidth: "25%"
        }
    },
    stroke: {
        width: [0, 0, 0]
    },
    tooltip: {
        enabled: true,
        followCursor: true,
        onDatasetHover: {
            highlightDataSeries: false,
        },
    },
    colors:['#3192D4'],
    fill: {
        colors: ['#3192D4']
      }
}