import { yupResolver } from "@hookform/resolvers/yup";
import { Observer } from "mobx-react";
import { Fragment } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import SmallLoader from "src/components/common/smallLoader";
import { useMainStore } from "src/container";
import { handleInput, handleKeyPress, useQueryParam } from "src/utils/utilities";
import { forgotPasswordFormSchema } from "./form.schema";

const ForgotPasswordForm = () => {
    const [query] = useQueryParam('redirect');
    const mainStore = useMainStore();
    let navigate = useNavigate();

    const { register, formState: { errors }, handleSubmit, control} = useForm({
        resolver: yupResolver(forgotPasswordFormSchema)
    });

    const goToLoginPage = () => {
        navigate(`/login?redirect=${query}`, { replace: true });
    }

    const handleForgotPasswordForm = (data) => {
        const { forgotPasswordStore: { handleUserForgotPassword } } = mainStore;
        handleUserForgotPassword(data);
    }

    return (
        <Observer>
            {() => {
                const { forgotPasswordStore: { isUserForgotPasswordLoading } } = mainStore;
                return (
                    <Fragment>
                        <div className="bg-white p-2 rounded box-shadow">
                            <form onSubmit={handleSubmit(handleForgotPasswordForm)}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="mb-3">
                                            <p className="mb-0 text-terms">
                                                <span className="me-1">
                                                    Please enter your email address. You will get a link for reset password in your email.
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="profile_name">Email<span className="requiredFields">*</span></label>
                                            <Controller
                                                control={control}
                                                name="email"
                                                render={({ field: { value, onChange } }) =>
                                                    <input
                                                        autoFocus
                                                        name="email"
                                                        id="email"
                                                        type="email"
                                                        onChange={(e) => onChange(handleInput(e))}
                                                        onKeyPress={(e) => handleKeyPress(e)}
                                                        value={value}
                                                        className="form-control form-control-lg ps-2"
                                                        placeholder="e.g john@gmail.com"
                                                    />
                                                }
                                            />
                                            <p className="text-danger">{errors.email?.message}</p>
                                        </div>
                                    </div>

                                </div>
                                <div className="row text-right">
                                    <div className="col-md-12">
                                        <button
                                            type="submit"
                                            className="btn btn-footers btn-primary"
                                        >
                                            {isUserForgotPasswordLoading ? <SmallLoader color={'dark'} /> : 'Send'}
                                        </button>

                                        <button
                                            onClick={goToLoginPage}
                                            className="btn btn-footers btn-dark-footer"
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </Fragment>
                )
            }}
        </Observer>
    )
}

export default ForgotPasswordForm;