import { Fragment } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';

import MainLayout from 'src/components/layouts';

import UserLogin from 'src/pages/login/index';
import UserRegister from 'src/pages/register';
import ForgotPassword from 'src/pages/forgotPassword';
import Dashboard from 'src/pages/dashboard';
import Streams from 'src/pages/streams';
import UserProfile from 'src/pages/profile';
import ChangePasswordForm from 'src/pages/change-password';
import UpcomingStreams from 'src/pages/streams/upcomingStreams';
import PastStreams from 'src/pages/streams/pastStreams';
import CreateStream from 'src/pages/createStream';
import FirstStep from 'src/pages/createStream/firstStep';
import SecondStep from 'src/pages/createStream/secondStep';
import ProtectedRoutes from './protectedRoutes';
import UserLogout from 'src/pages/logout';
import UnRestrictedRoutes from './unRestrictedRoutes';
import UpdateStream from 'src/pages/updateStream';
import UpdateFirstStep from 'src/pages/updateStream/firstStep';
import UpdateSecondStep from 'src/pages/updateStream/secondStep';
import StreamDetails from 'src/pages/streamDetails/index';
import StreamUsers from 'src/pages/streamDetails/streamUsers';
import StreamAnalytic from 'src/pages/streamDetails/streamAnalytic';
import UnderDevelopment from 'src/pages/underDevelopment';
import PrivateUserForm from 'src/pages/form/private';
import OrgSettings from 'src/pages/settings';
import AdsSection from 'src/pages/settings/ads';
import ProfileSection from 'src/pages/settings/profile';
import GeneralSection from 'src/pages/settings/general';
import EmailNotificationSection from 'src/pages/settings/emailNotification';
import ResetPassword from 'src/pages/forgotPassword/resetPassword';
import VideoPlayer from 'src/components/common/videoPlayer';

const AppRoutes = () => {
	return (
		<Fragment>
			<BrowserRouter>
				<Routes>
					<Route path="/register" element={<UnRestrictedRoutes><UserRegister /></UnRestrictedRoutes>} />
					<Route path="/reset-password/:token" element={<UnRestrictedRoutes><ResetPassword /></UnRestrictedRoutes>} />
					<Route path="/login" element={<UnRestrictedRoutes><UserLogin /></UnRestrictedRoutes>} >
						<Route path="forgot-password" element={<UnRestrictedRoutes><ForgotPassword /></UnRestrictedRoutes>} />
					</Route>
					<Route path="/logout" element={<UserLogout />} />

					{/* protected routes */}
					<Route path="/" element={<ProtectedRoutes><MainLayout /></ProtectedRoutes>} >
						<Route path="/under-development" element={<ProtectedRoutes><UnderDevelopment /></ProtectedRoutes>} />
						<Route path="/dashboard" element={<ProtectedRoutes><Dashboard /></ProtectedRoutes>} >
							<Route path="create-stream" element={<ProtectedRoutes><CreateStream /></ProtectedRoutes>} >
								<Route path="details" element={<ProtectedRoutes><FirstStep /></ProtectedRoutes>} />
								<Route path="settings" element={<ProtectedRoutes><SecondStep /></ProtectedRoutes>} />
							</Route>
							<Route path="update-stream" element={<ProtectedRoutes><UpdateStream /></ProtectedRoutes>} >
								<Route path="details/:sid" element={<ProtectedRoutes><UpdateFirstStep /></ProtectedRoutes>} />
								<Route path="settings/:sid" element={<ProtectedRoutes><UpdateSecondStep /></ProtectedRoutes>} />
							</Route>

							<Route path="profile" element={<ProtectedRoutes><UserProfile /></ProtectedRoutes>} />
							<Route path="change-password" element={<ProtectedRoutes><ChangePasswordForm /></ProtectedRoutes>} />

						</Route>
						<Route path="/stream-details" element={<ProtectedRoutes><StreamDetails /></ProtectedRoutes>} >
							<Route path="analytics/:sid" element={<ProtectedRoutes><StreamAnalytic /></ProtectedRoutes>} >

								<Route path="profile" element={<ProtectedRoutes><UserProfile /></ProtectedRoutes>} />
								<Route path="change-password" element={<ProtectedRoutes><ChangePasswordForm /></ProtectedRoutes>} />

								<Route path="create-stream" element={<ProtectedRoutes><CreateStream /></ProtectedRoutes>} >
									<Route path="details" element={<ProtectedRoutes><FirstStep /></ProtectedRoutes>} />
									<Route path="settings" element={<ProtectedRoutes><SecondStep /></ProtectedRoutes>} />
								</Route>
							</Route>
							<Route path="attendees/:sid" element={<ProtectedRoutes><StreamUsers /></ProtectedRoutes>} >

								<Route path="profile" element={<ProtectedRoutes><UserProfile /></ProtectedRoutes>} />
								<Route path="change-password" element={<ProtectedRoutes><ChangePasswordForm /></ProtectedRoutes>} />

								<Route path="create-stream" element={<ProtectedRoutes><CreateStream /></ProtectedRoutes>} >
									<Route path="details" element={<ProtectedRoutes><FirstStep /></ProtectedRoutes>} />
									<Route path="settings" element={<ProtectedRoutes><SecondStep /></ProtectedRoutes>} />
								</Route>
							</Route>
						</Route>
						<Route path="/streams" element={<ProtectedRoutes><Streams /></ProtectedRoutes>} >
							<Route path="upcoming" element={<ProtectedRoutes><UpcomingStreams /></ProtectedRoutes>} >
								<Route path="create-stream" element={<ProtectedRoutes><CreateStream /></ProtectedRoutes>} >
									<Route path="details" element={<ProtectedRoutes><FirstStep /></ProtectedRoutes>} />
									<Route path="settings" element={<ProtectedRoutes><SecondStep /></ProtectedRoutes>} />
								</Route>
								<Route path="update-stream" element={<ProtectedRoutes><UpdateStream /></ProtectedRoutes>} >
									<Route path="details/:sid" element={<ProtectedRoutes><UpdateFirstStep /></ProtectedRoutes>} />
									<Route path="settings/:sid" element={<ProtectedRoutes><UpdateSecondStep /></ProtectedRoutes>} />
								</Route>

								<Route path="profile" element={<ProtectedRoutes><UserProfile /></ProtectedRoutes>} />
								<Route path="change-password" element={<ProtectedRoutes><ChangePasswordForm /></ProtectedRoutes>} />

							</Route>
							<Route path="past" element={<ProtectedRoutes><PastStreams /></ProtectedRoutes>} >
								<Route path="create-stream" element={<ProtectedRoutes><CreateStream /></ProtectedRoutes>} >
									<Route path="details" element={<ProtectedRoutes><FirstStep /></ProtectedRoutes>} />
									<Route path="settings" element={<ProtectedRoutes><SecondStep /></ProtectedRoutes>} />
								</Route>

								<Route path="profile" element={<ProtectedRoutes><UserProfile />	</ProtectedRoutes>} />
								<Route path="change-password" element={<ProtectedRoutes><ChangePasswordForm /></ProtectedRoutes>} />

							</Route>
						</Route>
						<Route path="/setting" element={<ProtectedRoutes><OrgSettings /></ProtectedRoutes>} >

							<Route path="general" element={<ProtectedRoutes><GeneralSection /></ProtectedRoutes>} >
								<Route path="create-stream" element={<ProtectedRoutes><CreateStream /></ProtectedRoutes>} >
									<Route path="details" element={<ProtectedRoutes><FirstStep /></ProtectedRoutes>} />
									<Route path="settings" element={<ProtectedRoutes><SecondStep /></ProtectedRoutes>} />
								</Route>

								<Route path="profile" element={<ProtectedRoutes><UserProfile /></ProtectedRoutes>} />
								<Route path="change-password" element={<ProtectedRoutes><ChangePasswordForm /></ProtectedRoutes>} />
							</Route>

							<Route path="ads" element={<ProtectedRoutes><AdsSection /></ProtectedRoutes>} >
								<Route path="create-stream" element={<ProtectedRoutes><CreateStream /></ProtectedRoutes>} >
									<Route path="details" element={<ProtectedRoutes><FirstStep /></ProtectedRoutes>} />
									<Route path="settings" element={<ProtectedRoutes><SecondStep /></ProtectedRoutes>} />
								</Route>

								<Route path="profile" element={<ProtectedRoutes><UserProfile /></ProtectedRoutes>} />
								<Route path="change-password" element={<ProtectedRoutes><ChangePasswordForm /></ProtectedRoutes>} />
							</Route>

							<Route path="email-notification" element={<ProtectedRoutes><EmailNotificationSection /></ProtectedRoutes>} >
								<Route path="create-stream" element={<ProtectedRoutes><CreateStream /></ProtectedRoutes>} >
									<Route path="details" element={<ProtectedRoutes><FirstStep /></ProtectedRoutes>} />
									<Route path="settings" element={<ProtectedRoutes><SecondStep /></ProtectedRoutes>} />
								</Route>

								<Route path="profile" element={<ProtectedRoutes><UserProfile /></ProtectedRoutes>} />
								<Route path="change-password" element={<ProtectedRoutes><ChangePasswordForm /></ProtectedRoutes>} />
							</Route>

							<Route path="profiles" element={<ProtectedRoutes><ProfileSection /></ProtectedRoutes>} >
								<Route path="create-stream" element={<ProtectedRoutes><CreateStream /></ProtectedRoutes>} >
									<Route path="details" element={<ProtectedRoutes><FirstStep /></ProtectedRoutes>} />
									<Route path="settings" element={<ProtectedRoutes><SecondStep /></ProtectedRoutes>} />
								</Route>

								<Route path="profile" element={<ProtectedRoutes><UserProfile /></ProtectedRoutes>} />
								<Route path="change-password" element={<ProtectedRoutes><ChangePasswordForm /></ProtectedRoutes>} />
							</Route>

						</Route>
					</Route>

					<Route path='/view-video' element={<ProtectedRoutes> <VideoPlayer /> </ProtectedRoutes>}  />
					<Route path="/form" element={<ProtectedRoutes><PrivateUserForm /></ProtectedRoutes>} />
					<Route path="/under-development" element={<ProtectedRoutes><UnderDevelopment /></ProtectedRoutes>} />
					<Route path="*" element={<Navigate to={`/login?redirect=/`} />} />
				</Routes>
			</BrowserRouter>
		</Fragment>
	);
}

export default AppRoutes;