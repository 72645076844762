import React, { Fragment } from 'react';
import { useLocation } from 'react-router-dom';

const VideoPlayer = () => {

	return (
		<Fragment>
            <video controls className="videoPlayer" >
                <source src={localStorage.getItem('videoUrl')} type="video/mp4"/>
            </video>
		</Fragment>
	);
};

export default VideoPlayer;
