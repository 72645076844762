import { useMainStore } from 'src/container';
import { GoogleLogin } from 'react-google-login';

import GoogleSvg from 'src/components/common/googleSvg';

const UserGoogleRegister = () => {
    const mainStore = useMainStore();
    const { registerStore } = mainStore;
    const { handleSocialRegisterUser } = registerStore;

    const responseGoogle = (response) => {
        const { profileObj = {} } = response;
        const { email, imageUrl, name } = profileObj;

        const userData = {
            full_name: name,
            profile_image: imageUrl,
            email: email
        }

        handleSocialRegisterUser(userData);
    }

    const onResponseFail = (error) => {
    }

    return (
        <GoogleLogin
            clientId="142908275862-o2e8to2i24oipjtjm0u6d2qc8k0djm6r.apps.googleusercontent.com"
            buttonText="Login"
            render={renderProps => {
                return (
                    <GoogleSvg normalMessage={'Sign up using Google'} onClick={renderProps.onClick} />
                )
            }}
            onSuccess={responseGoogle}
            onFailure={onResponseFail}
            cookiePolicy={'single_host_origin'}
        />
    )
}

export default UserGoogleRegister;