import { Fragment } from "react";
import { Observer } from "mobx-react";
import { Navigate, Outlet } from "react-router-dom";
import { useMainStore } from 'src/container/index';
import AdtendeesLogo from "src/assets/images/logo.png";

// Components
import LoginForm from "./form";
import LoginFooter from "./footer";
import { useQueryParam } from "src/utils/utilities";

const UserLogin = () => {
    const [query] = useQueryParam('redirect');

    const mainStore = useMainStore();
    const { loginStore } = mainStore;

    return <Fragment>
        <section className="bg-home bg-login d-flex align-items-center">
            <div className="container">
                <div className="row">
                    <Observer>
                        {() => {
                            const { isUserAlreadyLoggedIn, isUserAlreadyLoggedLoading } = loginStore;
                            if (isUserAlreadyLoggedIn && !isUserAlreadyLoggedLoading) {
                                return <Navigate to={query || '/dashboard'} replace={true} />
                            }
                            return (
                                <div className="col-12">
                                    <img
                                        src={AdtendeesLogo}
                                        className="avatar mb-4 d-block mx-auto"
                                        alt="adtendees-logo"
                                    />
                                    <div className="form-signin p-4 bg-white rounded shadow">
                                        <h5 className="mb-3 text-left text-manual-white">Login</h5>
                                        <LoginForm />
                                        <LoginFooter />
                                    </div>
                                </div>
                            )
                        }}
                    </Observer>
                </div>
            </div>
        </section>
        <Outlet />
    </Fragment>
}

export default UserLogin;