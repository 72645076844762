import { Fragment } from 'react';
import { Observer, useLocalObservable } from 'mobx-react';
import { useMainStore } from 'src/container';
import { sendNotification } from 'src/utils/utilities';
import NormalModal from 'src/components/common/normalModal';
import { SketchPicker } from 'react-color';
import { toJS } from 'mobx';

const EditFormField = ({ onOpenOrCloseEditFieldModal, handleColorModal, colorModal }) => {
	const mainStore = useMainStore();
	const { streamFormStore } = mainStore;

	const onUpdateButtonText = () => {
		const { submitButtonText, submitButtonColor } = streamFormStore;
		const { updateRegisterNowSubmitSection } = streamFormStore;

		if (submitButtonText.length < 15) {
			updateRegisterNowSubmitSection([{ value: submitButtonText, index: 0 }, { value: submitButtonColor, index: 1 }]);
			onOpenOrCloseEditFieldModal();
		} else {
			sendNotification({ message: 'Text too long, should be in fifteen characters.', notificationType: 'warning' });
		}
	};

	const onCancel = () => {
		const { resetToPrevious } = streamFormStore;
		onOpenOrCloseEditFieldModal()
		resetToPrevious();
	}

	return (
		<Observer>
			{() => {
				const { handleSubmitButtonColorChange, submitButtonColor, handleSubmitButtonTextChange, submitButtonText } = streamFormStore;

				const selectedStyle = { backgroundColor: submitButtonColor || '#3192D4' };

				return (
					<Fragment>
						<div className="modal fade modal-open show d-block" id="reg_popup">
							<div className="modal-dialog modal-dialog-centered">
								<div className="modal-content rounded shadow border-0">
									<div className="modal-header border-0">
										<div className="d-flex align-items-center p-2">
											<h5 className="modal-title me-3" id="reg_popup-title">
												Edit Submit Button
											</h5>
										</div>
									</div>
									<div className="modal-body pt-0">
										<div className="p-2 rounded box-shadow">
											<div className="row">
												<div className="col-md-6">
													<div className="mb-5">
														<div className="form-icon btn_txt">
															<label className="label">Button Text</label>
															<input
																type="text"
																className="form-control form-control-lg ps-3"
																defaultValue={submitButtonText}
																onChange={({ target: { value } }) => handleSubmitButtonTextChange(value)}
															/>
														</div>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-md-12">
													<div className="mb-3 d-flex align-items-center">
														<h6 className="text-white-50 mb-0 me-3">Background color</h6>
														<p onClick={handleColorModal} style={{ ...selectedStyle }} className="avatar avatar-ex-small rounded mb-0 manual-pointer"></p>
													</div>
												</div>
											</div>
											<NormalModal
												display={colorModal}
												callOnClose={handleColorModal}
												style={{ width: '370px' }}
												headerMessage="Please Select Background Color"
												children={<SketchPicker color={submitButtonColor} onChangeComplete={handleSubmitButtonColorChange} onChange={handleSubmitButtonColorChange} width={300} />}
											/>
											<div className="row">
												<div className="col-md-12">
													<button onClick={onUpdateButtonText} type="button" className="btn btn-footers btn-primary">
														Update
													</button>
													<button onClick={onCancel} type="button" className="btn btn-footers btn-dark-footer">
														Cancel
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</Fragment>
				);
			}}
		</Observer>
	);
};

export default EditFormField;
