import { Observer } from "mobx-react";
import { Fragment } from "react";
import { Outlet, useLocation } from "react-router-dom";

const CreateStream = () => {
    const { pathname } = useLocation();

    // const isActiveClassNameForDetails = pathname.includes('details');
    const isActiveClassNameForSettings = pathname.includes('settings');

    return (
        <Observer>
            {() => {
                return (
                    <Fragment>
                        <div
                            className="modal fade modal-open show"
                            id="create_stream_setting"
                            tabIndex="-1"
                            aria-hidden="true"
                            style={{ display: 'block' }}
                            role="dialog"
                        >
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content rounded shadow border-0">
                                    <div className="modal-header border-bottom">
                                        <div className="d-flex align-items-center">
                                            <h5 className="modal-title me-3" id="create_stream_setting-title">New Stream</h5>
                                            <div className="p-2 bg-lights d-inline-flex rounded">
                                                <div className="custom-control custom-radio custom-control-inline me-2">
                                                    <div className="form-check mb-0">
                                                        <input
                                                            className="form-check-input"
                                                            defaultChecked={true}
                                                            checked={true}
                                                            type="radio"
                                                            name="detail"
                                                            id="details1"
                                                        />
                                                        <label className="form-check-label" htmlFor="details1">Details</label>
                                                    </div>
                                                </div>

                                                <div className="custom-control custom-radio custom-control-inline me-2">
                                                    <div className="form-check mb-0">
                                                        <input
                                                            className="form-check-input"
                                                            defaultChecked={isActiveClassNameForSettings}
                                                            checked={isActiveClassNameForSettings}
                                                            type="radio"
                                                            name="details"
                                                            id="settings1"
                                                        />
                                                        <label className="form-check-label" htmlFor="settings1">Settings</label>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="modal-body">
                                        <Outlet />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                )
            }}
        </Observer>
    )
};

export default CreateStream;