import { toJS } from "mobx";
import { Observer } from "mobx-react";
import { Fragment, useEffect } from "react";
import { Outlet } from "react-router-dom";
import SmallLoader from "src/components/common/smallLoader";
import { useMainStore } from "src/container";

const EmailNotificationSection = () => {

    const mainStore = useMainStore();
    const { settingStore } = mainStore;

    useEffect(() => {
        const { getOrganizerInfo } = settingStore;
        getOrganizerInfo();
        return () => { }
    }, [settingStore]);

    const handlePublisherEmailStatus = (e) => {
        const { handleEmailSection } = settingStore;
        const { target: { name, checked, value } } = e;
        handleEmailSection({ name, checked, value });
    }
    
    const handleAttendeesEmailStatus = (e) => {
        const { handleEmailSection } = settingStore;
        const { target: { name, checked, value } } = e;
        handleEmailSection({ name, checked, value });
    }

    const getFormattedTime = (i) => {

        if(i === 1){
            return `0${i} Hour`
        } else if(i <= 9){
            return `0${i} Hours`
        } else {
            return `${i} Hours`
        }

    }

    return (
        <Observer>
            {() => {
                const { emailSetting = [], emailSettingLoading = false } = settingStore;

                const { 
                    is_new_user_register = false, 
                    is_stream_created_or_updated = false, 
                    is_days_before_stream = false, 
                    is_attendee_register = false, 
                    is_hours_before_stream = false, 
                    email_organizer_hours_before = '', 
                    email_organizer_days_before = '' ,
                    is_hours_before_stream_attendee  = false,
                    email_attendee_hours_before = '',
                    is_days_before_stream_attendee = false,
                    email_attendee_days_before = '',
                    is_recording_ready  = false,
                    is_recording_ready_attendee = false
                } = toJS(emailSetting[0]) || {};

                if (emailSettingLoading) {
                    return (
                        <div className="loader_center mt-5">
                            <SmallLoader color={'black'} />
                        </div>
                    )
                }
                return (
                    <Fragment>
                        <div className="content active" id="notifications">
                            {/* <UnderDevelopment /> */}
                            <div className="row">
                                <div className="col-12 mb-3">
                                    {/* PUBLISHER NOTIFICATIONS */}
                                    <div className="mb-5">
                                        <h6 className="text-muted2 text-uppercase">Publisher Notifications</h6>
                                        <ul className="list-unstyled">
                                            <li className="mb-2">
                                                <div className="form-check form-switch">
                                                    <input 
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="is_new_user_register"
                                                        name="is_new_user_register"
                                                        defaultChecked={is_new_user_register}
                                                        onChange={handlePublisherEmailStatus}
                                                    />
                                                    <label className="form-check-label text-muted2">Notifiy the publisher when a new register sign up to my private streams.</label>
                                                </div>
                                            </li>
                                            <li className="mb-2">
                                                <div className="form-check form-switch">
                                                    <input 
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="is_stream_created_or_updated"
                                                        id="is_stream_created_or_updated"
                                                        defaultChecked={is_stream_created_or_updated}
                                                        onChange={handlePublisherEmailStatus}
                                                    />
                                                    <label className="form-check-label text-muted2">Notifiy the publisher with a calendar link in the email when stream has been created or updated</label>
                                                </div>
                                            </li>
                                            <li className="mb-2">
                                                <div className="form-check form-switch">
                                                    <input 
                                                        className="form-check-input"
                                                        type="checkbox" 
                                                        name='is_hours_before_stream' 
                                                        id="is_hours_before_stream" 
                                                        defaultChecked={is_hours_before_stream}
                                                        onChange={handlePublisherEmailStatus} 
                                                    />
                                                    <label className="form-check-label text-muted2 d-flex" for="is_hours_before_stream2">
                                                        <span className="me-2">Notify the publisher</span>
                                                        <span className="me-2">
                                                            <select 
                                                                name="email_organizer_hours_before"
                                                                id="email_organizer_hours_before"
                                                                onChange={handlePublisherEmailStatus}
                                                                disabled={!is_hours_before_stream} 
                                                                className="form-select form-control form-select-sm form-time2"
                                                            >
                                                                {
                                                                    Array(24).fill().map((_, i) => {
                                                                        return (
                                                                            <option value={++i} selected={email_organizer_hours_before === i}>
                                                                                {/* {`${i}`.length === 1 ? `0${i} Hour` : `${i} Hours`} */}
                                                                                {getFormattedTime(i)}
                                                                            </option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        </span>
                                                        <span className="me-2"> before the stream </span>
                                                        <div className="text-primary cursorPointer"> Edit Email</div>
                                                    </label>
                                                </div>
                                            </li>
                                            <li className="mb-2">
                                                <div className="form-check form-switch">
                                                    <input 
                                                        className="form-check-input" 
                                                        type="checkbox" 
                                                        id="is_days_before_stream" 
                                                        name='is_days_before_stream'
                                                        defaultChecked={is_days_before_stream}
                                                        onChange={handlePublisherEmailStatus}
                                                    />
                                                    <label className="form-check-label text-muted2 d-flex" for="is_hours_before_stream2">
                                                        <span className="me-2">Notify the publisher</span>
                                                        <span className="me-2">
                                                            <select name='email_organizer_days_before' id='email_organizer_days_before' disabled={!is_days_before_stream} onChange={handlePublisherEmailStatus} className="form-select form-control form-select-sm form-time2">
                                                                {
                                                                    Array(8).fill().map((_, i) => (
                                                                        <option value={`${++i}`. length === 1 ? `${i}` : `${i}`} selected={email_organizer_days_before === i} >
                                                                            {`${i}`== 1 ? `0${i} Day`: `0${i} Days`}
                                                                        </option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </span>
                                                        <span className="me-2"> before the stream </span>
                                                        <div className="text-primary cursorPointer"> Edit Email</div>
                                                    </label>
                                                </div>
                                            </li>
                                            <li className="mb-2">
                                                <div className="form-check form-switch">
                                                    <input 
                                                        className="form-check-input" 
                                                        type="checkbox" 
                                                        id="is_recording_ready"
                                                        name="is_recording_ready"
                                                        defaultChecked={is_recording_ready}
                                                        onChange={handlePublisherEmailStatus}
                                                    />
                                                    <label className="form-check-label text-muted2">
                                                        Notify the publisher when the recorded stream is ready
                                                        <span className="text-primary cursorPointer"> Edit Email</span>
                                                    </label>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>

                                    {/* ATTENDEES NOTIFICATIONS */}
                                    <div>
                                        <h6 className="text-muted2 text-uppercase">Attendees Notifications</h6>
                                        <ul className="list-unstyled">
                                            <li className="mb-2">
                                                <div className="form-check form-switch">
                                                    <input 
                                                        className="form-check-input" 
                                                        type="checkbox" 
                                                        id="is_attendee_register"
                                                        name="is_attendee_register"
                                                        defaultChecked={is_attendee_register}
                                                        onChange={handleAttendeesEmailStatus}
                                                    />
                                                    <label className="form-check-label text-muted2">Notify the attendee when he signs up with a calendar link in the email.</label>
                                                </div>
                                            </li>
                                            <li className="mb-2">
                                                <div className="form-check form-switch">
                                                    <input 
                                                        className="form-check-input" 
                                                        type="checkbox" 
                                                        name='is_hours_before_stream_attendee' 
                                                        id="is_hours_before_stream_attendee" 
                                                        defaultChecked={is_hours_before_stream_attendee}
                                                        onChange={handleAttendeesEmailStatus}
                                                    />
                                                    <label className="form-check-label text-muted2 d-flex" for="flexSwitchCheckDefault3">
                                                        <span className="me-2">Notify the atteendee </span>
                                                        <span className="me-2">
                                                            <select 
                                                                className="form-select form-control form-select-sm form-time2"
                                                                disabled={!is_hours_before_stream_attendee}
                                                                name="email_attendee_hours_before"
                                                                id="email_attendee_hours_before"
                                                                onChange={handleAttendeesEmailStatus}
                                                            >
                                                                {
                                                                    Array(24).fill().map((_, i) => (
                                                                        <option value={`${++i}`.length === 1 ? `${i}` : `${i}`} selected={ email_attendee_hours_before  === i }>
                                                                            {/* {`${i}`.length === 1 ? `0${i} Hour` : `${i} Hours`} */}
                                                                            {getFormattedTime(i)}
                                                                        </option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </span>
                                                        <span className="me-2"> before the stream</span>
                                                        <div className="text-primary cursorPointer"> Edit Email</div>
                                                    </label>
                                                </div>
                                            </li>
                                            <li className="mb-2">
                                                <div className="form-check form-switch">
                                                    <input 
                                                        className="form-check-input" 
                                                        type="checkbox" 
                                                        name="is_days_before_stream_attendee" 
                                                        id="is_days_before_stream_attendee" 
                                                        defaultChecked={is_days_before_stream_attendee}
                                                        onChange={handleAttendeesEmailStatus} 
                                                    />
                                                    <label className="form-check-label text-muted2 d-flex" for="flexSwitchCheckDefault3">
                                                        <span className="me-2">Notify the atteendee </span>
                                                        <span className="me-2">
                                                            <select 
                                                                className="form-select form-control form-select-sm form-time2" 
                                                                disabled={!is_days_before_stream_attendee}
                                                                name='email_attendee_days_before'
                                                                id='email_attendee_days_before'
                                                                onChange={handleAttendeesEmailStatus}
                                                            >
                                                                {
                                                                    Array(8).fill().map((_, i) => (
                                                                        <option value={`${++i}`.length === 1 ? `${i}` : `${i}`} selected={ email_attendee_days_before === i }>
                                                                            {`${i}` == 1 ? `0${i} Day` : `0${i} Days`}
                                                                        </option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </span>
                                                        <span className="me-2"> before the stream</span>
                                                        <div className="text-primary cursorPointer"> Edit Email</div>
                                                    </label>
                                                </div>
                                            </li>
                                            <li className="mb-2">
                                                <div className="form-check form-switch">
                                                    <input 
                                                        className="form-check-input" 
                                                        type="checkbox" 
                                                        id="is_recording_ready_attendee" 
                                                        name='is_recording_ready_attendee'
                                                        defaultChecked={is_recording_ready_attendee}
                                                        onChange={handleAttendeesEmailStatus}
                                                    />
                                                    <label className="form-check-label text-muted2">Notify the attendee when the recorded stream is ready
                                                        <span className="text-primary cursorPointer"> Edit Email</span>
                                                    </label>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Outlet />
                    </Fragment>
                )
            }}
        </Observer>
    )
}

export default EmailNotificationSection;