import { Fragment, useRef, memo } from "react";
import { Observer } from "mobx-react";
import { useMainStore } from "src/container";

import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';

import { sendNotification } from 'src/utils/utilities';

import Avatar from 'react-avatar';
import PreviewModeWrapper from 'src/components/common/previewModeWrapper';

const StreamDetailsForm = () => {
    const mainStore = useMainStore();
    const { streamFormStore } = mainStore;

    const Title = ({ data }) => {
        const { updateFormMainSection } = streamFormStore;
        const { value } = data;

        return (
            <PreviewModeWrapper
                offPreviewMode={
                    <div className="form-icon position-relative mb-4">
                        <input
                            {...data}
                            onChange={({ target: { value } }) => updateFormMainSection({ value: value, index: 0 })}
                            className="form-control form-control2 form-control-lg ps-3"
                        />
                    </div>
                }
                onPreviewMode={
                    <h4 className="title mb-4">{value}</h4>
                }
            />
        )
    }

    const Description = ({ data }) => {
        const { value } = data;

        const setEditorState = (v) => {
            if (v) {
                const contentBlock = htmlToDraft(v);
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                const editorState = EditorState.createWithContent(contentState);
                return editorState;
            }
            return EditorState.createEmpty();
        }

        const onChange = (e) => {
            const { updateFormMainSection } = streamFormStore;
            updateFormMainSection({ value: draftToHtml(convertToRaw(e.getCurrentContent())), index: 1 });
        }

        return (
            <Observer>
                {() => {
                    return (
                        <PreviewModeWrapper
                            offPreviewMode={
                                <div className="form-icon position-relative mb-4">
                                    <Editor
                                        onEditorStateChange={onChange}
                                        defaultEditorState={setEditorState(value)}
                                        editorStyle={{ height: '200px', width: '100%' }}
                                    />
                                </div>
                            }
                            onPreviewMode={
                                <div
                                    className="text-justify"
                                    dangerouslySetInnerHTML={{ __html: value }}
                                />
                            }
                        />
                    )
                }}
            </Observer>
        )
    }

    const Speakers = memo(({ data }) => {
        let selectedImageIndex = null;
        const inputFile = useRef(null);

        const onFileChange = (e) => {
            const { handleSpeakersUpload } = streamFormStore;
            handleSpeakersUpload(e, selectedImageIndex);
            selectedImageIndex = null;
            inputFile.current.value = null;
        }

        const onInputChange = ({ target: { name, value } }, index) => {
            const { handleSpeakersFullName, handleSpeakersDesignation } = streamFormStore;
            if (name === 'full_name') {
                if (value.trim().length <= 20) {
                    handleSpeakersFullName(value, index);
                } else {
                    sendNotification({ notificationType: 'warning', message: 'Text Should be in twenty characters !' });
                }
            }
            if (name === 'designation') {
                if (value.trim().length <= 30) {
                    handleSpeakersDesignation(value, index);
                } else {
                    sendNotification({ notificationType: 'warning', message: 'Text Should be in thirty characters !' });
                }
            }
        }

        const onRemoveProfile = (index) => {
            const { handleSpeakersUploadFiles } = streamFormStore;
            handleSpeakersUploadFiles(null, index);
        }

        return (
            <Observer>
                {() => {
                    return (
                        <PreviewModeWrapper
                            offPreviewMode={
                                <Fragment>
                                    <h4 className="title mb-4">
                                        Speakers
                                        <span style={{ fontSize: '12px', marginLeft: '5px' }}>
                                            (If you don't want to add other Speakers then just leave Speaker name Field. It will be removed.)
                                        </span>
                                    </h4>
                                    {data?.speakers?.map((item, index) => {
                                        return (
                                            <div className="d-flex align-items-start mb-2" key={index}>
                                                <div className="position-relative">
                                                    <input
                                                        type='file'
                                                        id='file-input'
                                                        ref={inputFile}
                                                        style={{ display: 'none' }}
                                                        onChange={onFileChange}
                                                    />
                                                    <Avatar
                                                        src={item.profile}
                                                        name={item.full_name}
                                                        alt="speakers"
                                                        height='120'
                                                        width='120'
                                                        value="Click to Upload"
                                                        className="img-fluid me-0 speaker-image"
                                                        onClick={() => {
                                                            inputFile.current.click();
                                                            selectedImageIndex = index;
                                                        }}
                                                    />
                                                    <i
                                                        className="mdi mdi-close-thick close-icon"
                                                        onClick={() => onRemoveProfile(index)}
                                                    >
                                                    </i>
                                                </div>
                                                <div className="ms-2">
                                                    <input
                                                        type='text'
                                                        value={item.full_name}
                                                        className="form-control form-control2 mb-1"
                                                        placeholder="Enter Speaker Name"
                                                        name="full_name"
                                                        onChange={(e) => onInputChange(e, index)}
                                                    >
                                                    </input>
                                                    <input
                                                        type='text'
                                                        value={item.designation}
                                                        className="form-control form-control2"
                                                        placeholder="Enter Speaker Designation"
                                                        name="designation"
                                                        onChange={(e) => onInputChange(e, index)}
                                                    >
                                                    </input>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </Fragment>
                            }
                            onPreviewMode={
                                <Fragment>
                                    {data?.speakers?.length > 0 && (
                                        <Fragment>
                                            {data?.speakers.filter(item => !!item.full_name).map(() => (
                                                <h4 className="title mb-4">Speakers</h4>
                                            ))}
                                            <div className="d-flex flex-column">
                                                {data?.speakers?.filter(item => !!item.full_name).map((item, index) => {
                                                    return (
                                                        <div className="d-flex align-items-start mb-2" key={index}>
                                                            <Avatar
                                                                src={item.profile}
                                                                name={item.full_name}
                                                                alt="speakers"
                                                                height='120'
                                                                width='120'
                                                                value="Click to Upload"
                                                                className="img-fluid me-3 speaker-image"
                                                            />
                                                            <div>
                                                                <h5>{item.full_name}</h5>
                                                                <p>{item.designation}</p>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </Fragment>

                                    )}
                                </Fragment>
                            }
                        />
                    )
                }}
            </Observer>
        )
    });

    return (
        <Observer>
            {() => {
                const { formMainSection } = streamFormStore;
                const { fields = [{}, {}, {}] } = formMainSection;

                return (
                    <Fragment>
                        <div className="col-xl-7 col-12 order-2 order-xl-1">
                            <div className="row">
                                <div className="col-12 mb-4 pb-2">
                                    <div className="card bg-dark text-white rounded border-0 overflow-hidden">
                                        <div className="row align-items-center g-0">
                                            <div className="col-md-12">
                                                <div className="section-title overflow-hidden">
                                                    <Title isEditable={true} data={fields[0]} />
                                                    <Description isEditable={true} data={fields[1]} />
                                                </div>
                                                <div className="section-title overflow-hidden">
                                                    <Speakers isEditable={false} data={fields[2]} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                )
            }}
        </Observer>
    );
}

export default StreamDetailsForm;