import { Fragment } from 'react';
import { Observer } from 'mobx-react';
import { Navigate } from "react-router-dom";
import { useMainStore } from 'src/container';
import { useQueryParam } from 'src/utils/utilities';

// components
import ForgotPasswordForm from './form';

const ForgotPassword = () => {
    const [query] = useQueryParam('redirect');
    const mainStore = useMainStore();

    return (
        <Observer>
            {() => {
                const { forgotPasswordStore: { isUserPasswordRedirectSuccess, setIsUserPasswordRedirectSuccess } } = mainStore;
                if (isUserPasswordRedirectSuccess) {
                    setIsUserPasswordRedirectSuccess(false);
                    return <Navigate to={`/login?redirect=${query}`} />;
                }
                return (
                    <Fragment>
                        <div
                            className="modal fade modal-open show"
                            tabIndex="-1"
                            aria-hidden="true"
                            style={{ display: 'block' }}
                            role='dialog'
                        >
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content rounded shadow border-0">
                                    <div className="modal-header border-bottom">
                                        <div className="d-flex align-items-center">
                                            <h5 className="modal-title me-3" id="forgot_password-title">Forgot Password</h5>
                                        </div>
                                    </div>
                                    <div className="modal-body open">
                                        <ForgotPasswordForm />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                )
            }}
        </Observer>
    )
}

export default ForgotPassword;