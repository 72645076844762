import { Fragment, useEffect } from 'react';
import { Observer } from 'mobx-react';
import { isValidMongoId, useQueryParam } from 'src/utils/utilities';
import { useNavigate } from 'react-router-dom';
import { useMainStore } from 'src/container';

import FormHeader from './formHeader';
import SectionHeader from './sectionHeader';
import SectionMain from './sectionMain';
import FormFooter from './formFooter';

import FormTimeOut from 'src/components/common/formTimeout';

import SmallLoader from 'src/components/common/smallLoader';

const PrivateUserForm = () => {
    const mainStore = useMainStore();
    const { streamFormStore } = mainStore;

    const navigate = useNavigate();

    const [sid] = useQueryParam('sid');
    const [ouid] = useQueryParam('ouid');
    const [oid] = useQueryParam('oid');
    const [fid] = useQueryParam('fid');


    useEffect(() => {
        const isValidQueryString = isValidMongoId(sid) && isValidMongoId(ouid) && isValidMongoId(oid) && isValidMongoId(fid);
        const { getStreamFormDetailsById, resetStreamFormDetails, setPreviewMode } = streamFormStore;

        if (!isValidQueryString) {
            navigate('/', { replace: true });
        } else {
            setPreviewMode();
            getStreamFormDetailsById(fid, sid);
        }

        return () => {
            resetStreamFormDetails();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const { getStreamTime } = streamFormStore;
        setInterval(() => getStreamTime(), 1000);
        return () => { }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <Observer>
            {() => {
                const { getStreamFormDetailsLoading, isStreamEnded } = streamFormStore;

                if (getStreamFormDetailsLoading) {
                    return (
                        <div className="loader_center">
                            <SmallLoader />
                        </div>
                    );
                }

                if (isStreamEnded) {
                    return (
                        <div className="loader_center">
                            <FormTimeOut />
                        </div>
                    );
                }

                return (
                    <Fragment>
                        <div className="bg-dark">
                            <FormHeader />
                            <SectionHeader />
                            <SectionMain />
                            <FormFooter />
                        </div>
                    </Fragment>
                )
            }}
        </Observer >
    );
};

export default PrivateUserForm;