import { Fragment, useEffect } from "react";
import { Observer } from "mobx-react";
import { Outlet, useLocation, Link } from "react-router-dom";
import { useMainStore } from "src/container";

const OrgSettings = () => {
    const { pathname } = useLocation();

    const mainStore = useMainStore();
    const { settingStore } = mainStore;

    const activeClassNameForGeneral = pathname.includes('general') && "active";
    const activeClassNameForProfile = pathname.includes('profile') && "active";
    const activeClassNameForEmailNotification = pathname.includes('email-notification') && "active";
    const activeClassNameForAds = pathname.includes('ads') && "active";

    useEffect(() => {
        const { getOrganizerInfo } = settingStore;
        getOrganizerInfo();
        return () => { }
    }, [settingStore]);


    useEffect(() => {
        const allTabMenu = document.querySelectorAll('.tab-menu li');
        const line = document.querySelector('.tab-menu .line');

        const validIndex = activeClassNameForGeneral ? 0 :
            activeClassNameForProfile ? 1 :
                activeClassNameForEmailNotification ? 2 :
                    activeClassNameForAds ? 3 :
                        0;

        let width = allTabMenu[validIndex].offsetWidth;
        let left = allTabMenu[validIndex].offsetLeft;
        line.style.width = width + 'px';
        line.style.left = left + 'px';

    }, [activeClassNameForGeneral, activeClassNameForProfile, activeClassNameForEmailNotification, activeClassNameForAds]);

    return (
        <Observer>
            {() => {
                return (
                    <Fragment>
                        <div className="container-fluid">
                            <div className="layout-specing">
                                <div className="row">
                                    <div className="col-12">
                                        <ul className="tab-menu">

                                            <Link to={`/setting/general`}
                                                className={`${activeClassNameForGeneral ? 'active_stream_tab' : 'deActive_stream_tab'}`}
                                            >
                                                <li data-target="general">General</li>
                                            </Link>

                                            <Link to={`/setting/profiles`}
                                                className={`${activeClassNameForProfile ? 'active_stream_tab' : 'deActive_stream_tab'}`}
                                            >
                                                <li data-target="profile">Profile</li>
                                            </Link>

                                            <Link to={`/setting/email-notification`}
                                                className={`${activeClassNameForEmailNotification ? 'active_stream_tab' : 'deActive_stream_tab'}`}
                                            >
                                                <li data-target="notifications">Email Notifications</li>
                                            </Link>

                                            <Link to={`/setting/ads`}
                                                className={`${activeClassNameForAds ? 'active_stream_tab' : 'deActive_stream_tab'}`}
                                            >
                                                <li data-target="ads">Ads</li>
                                            </Link>

                                            <span className="line"></span>

                                        </ul>

                                        <div className="contents">
                                            <Outlet />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                )
            }}
        </Observer>
    )
}

export default OrgSettings;