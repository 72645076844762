import { action, makeObservable, observable, runInAction, toJS } from 'mobx';
import { arrayValueReplacer, getLocalStorageItems, getValidDataFromResponse, isValidArray, isValidObject } from 'src/utils/utilities';
import { getRequest } from 'src/utils/api';

class DashboardStore {
	recentStreamLoading = false;
	recentStreamSuccess = false;

	activeUsersSuccess = false;
	activeUsersLoading = false;
	activeUsers = [{}];

	streamSummarySuccess = false;
	streamSummaryLoading = false;
	streamSummary = [{}];

	streamAdsRevenueLoading = false;
	streamAdsRevenue = [{}];

	streamAdsClickedAnalyticsLoading = false;
	streamAdsClickedAnalytics = [{}];

	streamAdsLast30DaysViewsLoading = false;
	streamAdsLast30DaysViews = [];

	streamAdsLast30DaysSubscriberLoading = false;
	streamAdsLast30DaysSubscriber = [];

	recentStreams = [
		{
			start_time: null,
			title: null,
			total_attendees: 0,
			images: [],
		},
	];

	constructor() {
		makeObservable(this, {
			recentStreamSuccess: observable,
			recentStreamLoading: observable,
			recentStreams: observable,
			fetchRecentStreams: action,
			activeUsersSuccess: observable,
			activeUsersLoading: observable,
			activeUsers: observable,
			fetchActiveUsers: action,
			streamSummarySuccess: observable,
			streamSummaryLoading: observable,
			streamSummary: observable,
			fetchStreamSummary: action,
			fetchStreamAdsRevenue: action,
			streamAdsRevenueLoading: observable,
			streamAdsRevenue: observable,
			fetchStreamAdsClickedAnalytics: action,
			streamAdsClickedAnalyticsLoading: observable,
			streamAdsClickedAnalytics: observable,
			fetchStreamLast30DaysViews: action,
			fetchStreamLast30DaysSubscriber: action,
			streamAdsLast30DaysViewsLoading: observable,
			streamAdsLast30DaysViews: observable,
			streamAdsLast30DaysSubscriberLoading: observable,
			streamAdsLast30DaysSubscriber: observable
		});
	}

	resetRecentStreamData = () => {
		this.recentStreams = [
			{
				start_time: null,
				title: null,
				total_attendees: 0,
				images: [],
			},
		];
	};
	resetRecentStreamSuccess = () => {
		this.recentStreamSuccess = false;
	};

	resetRecentLiveStreams = () => {
		this.recentLiveStreams = [
			{
				start_time: null,
				title: null,
				total_attendees: 0,
				images: [],
			},
		];
	};

	resetRecentStreamSuccess = () => {
		this.recentStreamSuccess = false;
	};

	fetchRecentStreams = async () => {
		try {
			runInAction(() => {
				this.recentStreamLoading = true;
			});
			const { oid } = getLocalStorageItems({ get: true, key: 'oid' });

			const { response } = await getRequest(`/stream/recent/${oid}`);
			const { data, error } = getValidDataFromResponse(response, false, true, false);

			if (!error && isValidArray(data)) {
				const newData = arrayValueReplacer(data, 'images', null, []);
				runInAction(() => {
					this.setRecentStreams(toJS([...newData]));
					this.setRecentLiveStreams(toJS([...newData]));
					this.recentStreamSuccess = true;
				});
			}

			runInAction(() => {
				this.recentStreamLoading = false;
			});
		} catch (error) {
			runInAction(() => {
				this.recentStreamLoading = false;
			});
		}
	};

	setRecentStreams = (data) => {
		runInAction(() => {
			this.recentStreams = [...data.filter((s) => !s.is_live)];
		});
	};

	setRecentLiveStreams = (data) => {
		runInAction(() => {
			this.recentLiveStreams = [...data.filter((s) => s.is_live)];
		});
	};

	activeUsersData = () => {
		this.activeUsers = [{}];
	};

	resetActiveUsersSuccess = () => {
		this.activeUsersSuccess = false;
	};

	fetchActiveUsers = async () => {
		try {
			runInAction(() => {
				this.activeUsersLoading = true;
			});

			const { oid } = getLocalStorageItems({ get: true, key: 'oid' });

			const { response } = await getRequest(`/stream/user/active/${oid}`);
			const { data, error } = getValidDataFromResponse(response, false, true, false);

			if (!error && isValidArray(data)) {
				runInAction(() => {
					this.activeUsers = [...data];
					this.activeUsersSuccess = true;
				});
			}

			runInAction(() => {
				this.activeUsersLoading = false;
			});
		} catch (error) {
			runInAction(() => {
				this.activeUsersLoading = false;
			});
		}
	};

	streamSummaryData = () => {
		this.streamSummary = [{}];
	};

	resetStreamSummarySuccess = () => {
		this.streamSummarySuccess = false;
	};

	fetchStreamSummary = async () => {
		try {
			runInAction(() => {
				this.streamSummaryLoading = true;
			});

			const { oid } = getLocalStorageItems({ get: true, key: 'oid' });

			const { response } = await getRequest(`/stream/summary/${oid}`);
			const { data, error } = getValidDataFromResponse(response, false, true, false);

			if (!error && isValidArray(data)) {
				const newData = arrayValueReplacer(data, 'images', null, []);
				runInAction(() => {
					this.streamSummary = [...newData];
					this.streamSummarySuccess = true;
				});
			}

			runInAction(() => {
				this.streamSummaryLoading = false;
			});
		} catch (error) {
			runInAction(() => {
				this.streamSummaryLoading = false;
			});
		}
	};

	setRecentStreams = (data) => {
		runInAction(() => {
			this.recentStreams = [...data.filter((s) => !s.is_live)];
		});
	};

	setRecentLiveStreams = (data) => {
		runInAction(() => {
			this.recentLiveStreams = [...data.filter((s) => s.is_live)];
		});
	};

	fetchStreamAdsRevenue = async () => {
		try {
			runInAction(() => {
				this.streamAdsRevenueLoading = true;
			});

			const { oid } = getLocalStorageItems({ get: true, key: 'oid' });

			const { response } = await getRequest(`/organizer/monthly_revenue/${oid}`);
			const { data, error } = getValidDataFromResponse(response, false, true, false);

			if (!error && isValidObject(data)) {
				runInAction(() => {
					this.streamAdsRevenue = [data];
					this.streamAdsRevenueLoading = false;
				});
			}

			runInAction(() => {
				this.streamAdsRevenueLoading = false;
			});
		} catch (error) {
			runInAction(() => {
				this.streamAdsRevenueLoading = false;
			});
		}
	};

	fetchStreamAdsClickedAnalytics = async () => {
		try {
			runInAction(() => {
				this.streamAdsClickedAnalyticsLoading = true;
			});

			const { oid } = getLocalStorageItems({ get: true, key: 'oid' });

			const { response } = await getRequest(`/organizer/ads_analytics/${oid}`);
			const { data, error } = getValidDataFromResponse(response, false, true, false);

			if (!error && isValidObject(data)) {
				runInAction(() => {
					this.streamAdsClickedAnalytics = [data];
					this.streamAdsClickedAnalyticsLoading = false;
				});
			}

			runInAction(() => {
				this.streamAdsClickedAnalyticsLoading = false;
			});
		} catch (error) {
			runInAction(() => {
				this.streamAdsClickedAnalyticsLoading = false;
			});
		}
	};

	fetchStreamLast30DaysViews = async () => {
		try {
			runInAction(() => {
				this.streamAdsLast30DaysViewsLoading = true;
			});

			const { oid } = getLocalStorageItems({ get: true, key: 'oid' });

			const { response } = await getRequest(`/organizer/impression/${oid}`);
			const { data, error } = getValidDataFromResponse(response, false, true, false);

			if (!error && isValidArray(data)) {
				runInAction(() => {
					this.streamAdsLast30DaysViews = [...data];
					this.streamAdsLast30DaysViewsLoading = false;
				});
			}

			runInAction(() => {
				this.streamAdsLast30DaysViewsLoading = false;
			});
		} catch (error) {
			runInAction(() => {
				this.streamAdsLast30DaysViewsLoading = false;
			});
		}
	};

	fetchStreamLast30DaysSubscriber = async () => {
		try {
			runInAction(() => {
				this.streamAdsLast30DaysSubscriberLoading = true;
			});

			const { oid } = getLocalStorageItems({ get: true, key: 'oid' });

			const { response } = await getRequest(`/stream/user/subscriber/${oid}`);
			const { data, error } = getValidDataFromResponse(response, false, true, false);

			if (!error && isValidArray(data)) {
				runInAction(() => {
					this.streamAdsLast30DaysSubscriber = [...data];
					this.streamAdsLast30DaysSubscriberLoading = false;
				});
			}

			runInAction(() => {
				this.streamAdsLast30DaysSubscriberLoading = false;
			});
		} catch (error) {
			runInAction(() => {
				this.streamAdsLast30DaysSubscriberLoading = false;
			});
		}
	};

}

export default DashboardStore;
