import { Fragment } from "react";

const FormTimeOut = () => {
    return (
        <Fragment>
            <div className='d-flex fs-1 justify-content-center'>
                Sorry, You Can't Setup Form now, This Event Has Started 🙂🖐.
            </div>
        </Fragment>
    )
}

export default FormTimeOut;
