import { observable, makeObservable, runInAction, toJS } from 'mobx';
import { getRequest, patchRequest, postRequest } from 'src/utils/api';
import {
    sendNotification, isValidObject, isValidStoredKey, getLocalStorageItems, getValidDataFromResponse, isValidArray,
    arrayValueReplacer
} from 'src/utils/utilities';
import moment from 'moment';

class UpdateStreamStore {
    userUploadedImageAcceptedFormats = ["image/jpeg", "image/jpg", "image/png"];

    uploadedBaseStreamImages = [];
    uploadedFileStreamImages = [];

    streamFirstFormUpdateLoading = false;
    streamSecondFormUpdateLoading = false;

    streamFirstFormSuccessRedirect = false;
    streamSecondFormSuccessRedirect = false;

    updateStreamData = [];

    singleStreamData = [];
    singleStreamLoading = false;

    createStreamFormSuccess = false;
    createStreamFormLoading = false;
    createStreamFormData = [{}];

    constructor() {
        makeObservable(this, {
            uploadedBaseStreamImages: observable,
            uploadedFileStreamImages: observable,
            streamFirstFormUpdateLoading: observable,
            streamFirstFormSuccessRedirect: observable,
            streamSecondFormUpdateLoading: observable,
            streamSecondFormSuccessRedirect: observable,
            singleStreamData: observable,
            singleStreamLoading: observable,
            createStreamFormSuccess: observable,
            createStreamFormLoading: observable,
            createStreamFormData: observable,
        });
    }

    // check image size
    bytesToSize = (bytes, maxSizeAccepted = 5) => {
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) return '0 Byte';
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        if (sizes[i] === 'MB') {
            return Math.round(bytes / Math.pow(1024, i), 2) < maxSizeAccepted;
        } else if (sizes[i] === 'KB') {
            return Math.round(bytes / Math.pow(1024, i), 2) < maxSizeAccepted * 1024;
        }
        return false;
    }

    // get base 64 form
    previewFile = (file, callback) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            callback(reader.result);
        };
        reader.readAsDataURL(file);
    }

    // When user upload latest image
    handleFileUpload = (file) => {
        this.hasError = false;
        try {
            const filesList = file.target.files;
            if (isValidObject(filesList)) {
                for (let i = 0; i < filesList.length; i++) {
                    const { type, size } = filesList[i];
                    if (!this.userUploadedImageAcceptedFormats.includes(type)) {
                        this.hasError = true;
                        // sendNotification({ notificationType: 'warning', message: 'Unsupported file type. Only JPG,JPEG,PNG are available.', duration: 3000 });
                    } else if (!this.bytesToSize(size)) {
                        this.hasError = true;
                        // sendNotification({ notificationType: 'warning', message: 'File size too Small or Bigger than 5 MB.', duration: 3000 });
                    } else {
                        this.previewFile(filesList[i], (f) => {
                            this.uploadedFileStreamImages.push(filesList[i]);
                            this.uploadedBaseStreamImages.push(f);
                            sendNotification({ notificationType: 'success', message: 'File uploaded successfully.' });
                        })
                    }
                }

                if (this.hasError) {
                    this.hasError = false;
                    sendNotification({
                        notificationType: 'warning',
                        message: 'Some Files are too big or Some are Invalid type Images. Those are going to remove in List.',
                        duration: 4000
                    });
                }

                // remove first image from uploaded file form
                setTimeout(() => {
                    if (filesList.length === 1) {
                        document.getElementById('file-input').value = '';
                    }
                }, 1000);

            } else {
                throw new Error('Something went wrong.');
            }
        } catch (error) {
            sendNotification({ notificationType: 'error', message: 'Something went wrong!' });
        }
    }

    handleRemoveFileFromList = (index) => {
        this.uploadedFileStreamImages = [...this.uploadedFileStreamImages.filter((f, i) => i !== index)];
        this.uploadedBaseStreamImages = [...this.uploadedBaseStreamImages.filter((f, i) => i !== index)];
    }

    handleUpdateStreamFirstForm = async (streamFormData) => {
        try {
            runInAction(() => {
                this.streamFirstFormUpdateLoading = true;
            });

            if (isValidStoredKey(['ouid'])) {
                const { ouid, oid } = getLocalStorageItems({ getAll: true, keys: ['ouid', 'oid'] });

                const {
                    stream_id, schedule, end_time_h, end_time_m, start_time_h, start_time_m, start_time_a, end_time_a, timezone, timezone_default, ...extraStreamFormData
                } = streamFormData;

                let selectedSchedule = moment(schedule).format("MM/DD/YYYY");

                const streamData = {
                    ...extraStreamFormData,
                    schedule: moment(schedule).toISOString(),
                    start_time: moment(`${selectedSchedule} ${start_time_h + ":" + start_time_m} ${start_time_a}`).toISOString(),
                    end_time: moment(`${selectedSchedule} ${end_time_h + ":" + end_time_m} ${end_time_a}`).toISOString(),
                    org_id: oid,
                    updated_by: ouid,
                    org_user_id: ouid,
                    stream_id: stream_id,
                    timezone: timezone || timezone_default
                }

                if (this.uploadedBaseStreamImages.length) {
                    streamData.images = [this.uploadedBaseStreamImages[this.uploadedBaseStreamImages.length - 1]];
                }

                if (!this.uploadedBaseStreamImages.length) {
                    streamData.images = null;
                }

                const { response } = await patchRequest('/stream/update', streamData);
                const { data, error } = getValidDataFromResponse(response, false);

                if (!error && isValidArray(data)) {
                    runInAction(() => {
                        this.updateStreamData = [...data];
                        this.streamFirstFormUpdateLoading = false;
                        this.streamFirstFormSuccessRedirect = true;
                        this.uploadedFileStreamImages = [];
                        this.uploadedBaseStreamImages = [];
                    })
                }
            }

            setTimeout(() => {
                runInAction(() => {
                    this.streamFirstFormUpdateLoading = false;
                })
            }, 1000)
        } catch (error) {
            runInAction(() => {
                this.streamFirstFormSuccessRedirect = false;
                this.streamFirstFormUpdateLoading = false;
            })
        }
    }

    resetCreateStreamRedirectSuccess = () => {
        this.streamFirstFormSuccessRedirect = false;
    }

    resetUpdateStreamRedirectSuccess = () => {
        this.streamSecondFormSuccessRedirect = false;
    }

    resetUpdateStreamData = () => {
        this.updateStreamData = [];
    }

    handleUpdateStreamSecondForm = async (linkType) => {
        try {
            this.streamSecondFormUpdateLoading = true;

            if (isValidStoredKey(['ouid'])) {
                const { ouid, oid } = getLocalStorageItems({ getAll: true, keys: ['ouid', 'oid'] });
                const { _id = '' } = this.updateStreamData[0];

                const streamData = {
                    status: 'C',
                    org_id: oid,
                    updated_by: ouid,
                    link_type: linkType,
                    stream_id: _id,
                    is_created: false,
                    is_updated: true
                }

                const { response } = await patchRequest('/stream/update', streamData);
                const { data, error } = getValidDataFromResponse(response, true);
                if (!error && isValidArray(data)) {

                    const [{ wowza_live, str_host_link }] = data;

                    if(wowza_live){
                        setTimeout(() => {
                            window.open(str_host_link, "_blank")
                        }, 2000)
                    }
                    
                    runInAction(() => {
                        this.streamSecondFormUpdateLoading = false;
                        this.streamSecondFormSuccessRedirect = true;
                    })
                }
            }

            setTimeout(() => {
                runInAction(() => {
                    this.streamSecondFormUpdateLoading = false;
                })
            }, 1000)
        } catch (error) {
            runInAction(() => {
                this.streamSecondFormSuccessRedirect = false;
                this.streamSecondFormUpdateLoading = false;
            })
        }
    }

    setUploadedImageList = ({ images = [] }) => {
        let newImagesList = [];
        images.forEach(image => {
            const name = image.substring(image.lastIndexOf('/') + 1);
            newImagesList.push({ link: image, name: name });
        });
        this.uploadedFileStreamImages = [...newImagesList];
        this.uploadedBaseStreamImages = [...images];
    }

    resetSingleStreamData = () => {
        this.singleStreamData = [];
    }

    getStreamDetailsById = async (stream_id) => {
        try {
            this.singleStreamLoading = true;

            if (isValidStoredKey(['oid'])) {
                const { oid } = getLocalStorageItems({ getAll: true, keys: ['oid'] });

                const { response } = await getRequest(`/stream/${stream_id}/org/${oid}`);
                const { data, error } = getValidDataFromResponse(response, false);

                if (!error && isValidArray(data)) {
                    runInAction(() => {
                        const newData = arrayValueReplacer(data, 'images', null, []);
                        this.singleStreamData = [...newData];
                        this.setUploadedImageList(newData[0]);
                    })
                }
            }

            setTimeout(() => {
                runInAction(() => {
                    this.singleStreamLoading = false;
                })
            }, 1000)
        } catch (error) {
            runInAction(() => {
                this.singleStreamLoading = false;
                this.singleStreamData = [];
            })
        }
    }

    handleCopySuccess = (message) => {
        sendNotification({ message: message, notificationType: "success", duration: 2000 });
    }

    handleCreateForm = async () => {
        try {
            if (isValidStoredKey(['ouid', 'oid'])) {

                runInAction(() => {
                    this.createStreamFormLoading = true;
                });

                const { ouid, oid } = getLocalStorageItems({ getAll: true, keys: ['ouid', 'oid'] });
                const { _id = '' } = this.updateStreamData[0];

                const streamData = {
                    org_id: oid,
                    stream_id: _id,
                    org_user_id: ouid
                }

                const { response } = await postRequest('/stream/form/create', streamData);
                const { data, error } = getValidDataFromResponse(response, true);

                if (!error && isValidArray(data)) {
                    runInAction(() => {
                        this.createStreamFormData = toJS([...data]);
                        this.createStreamFormSuccess = true;
                    })
                }
            }

            runInAction(() => {
                this.createStreamFormSuccess = false;
                this.createStreamFormLoading = false;
            })
        } catch (error) {
            runInAction(() => {
                this.createStreamFormSuccess = false;
                this.createStreamFormLoading = false;
            })
        }
    }

    removeAllUpdatedFiles = () => {
        runInAction(() => {
            this.uploadedFileStreamImages = [];
            this.uploadedBaseStreamImages = [];
        })
    };

}

export default UpdateStreamStore;
