import { Fragment } from 'react';
import { Observer } from 'mobx-react';
import { Link } from 'react-router-dom';

const BlankStream = () => {
    return (
        <Observer>
            {() => {
                return (
                    <Fragment>
                        <div className="col-12">
                            <div className="card shadow border-0 p-4 rounded">
                                <div className="stream_start">
                                    <p>Add your first stream</p>
                                    <p>Start earning by streaming and share your content with your audience</p>
                                    <Link to={'/dashboard/create-stream/details'}>
                                        <div className="btn btn-xs btn-primary mb-4">
                                            <i className="mdi mdi-television  icon-sm me-2"></i>
                                            Create stream
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                )
            }}
        </Observer>
    )
}

export default BlankStream;