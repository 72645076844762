import { Fragment } from "react";
import { Link } from "react-router-dom";
import { useQueryParam } from "src/utils/utilities";

const RegisterFooter = () => {
    const [query] = useQueryParam('redirect');

    return (
        <Fragment>
            <div className="col-md-12">
                <div className="dropdown-divider border-top"></div>
            </div>

            <div className="col-12 text-center mt-3">
                <p className="mb-0 mt-3">
                    <small className="text-dark-light me-1">Already a user?</small>
                    <Link to={`/login?redirect=${query}`} className="text-primary ">Log in</Link>
                </p>
            </div>
        </Fragment>
    )
}

export default RegisterFooter;