import { Observer } from "mobx-react";
import { Fragment, useEffect } from "react";
import moment from 'moment';

import Avatar from 'react-avatar';
import { Link, useLocation, useNavigate } from "react-router-dom";

const SinglePastStream = (props) => {
    const { created_at, start_time, end_time, images, schedule, status, title, _id, total_attendees = 0, fetchViewStream, fetchDownloadStream, is_recording = false, recording_url, net_revenue, totalclickcount} = props;

    useEffect(() => {
        return () => { 
            localStorage.removeItem('videoUrl')
         }

    }, [])

    const handleVideoPlay = (url, callback) => {
        localStorage.setItem('videoUrl', url)
        callback()
    }

    return (
        <Observer key={_id}>
            {() => {
                return (
                    <Fragment>
                        <div className="card shadow border border-primary" key={_id}>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="d-md-flex">
                                            <Avatar
                                                src={images[0]}
                                                alt="stream image"
                                                name={title}
                                                className="img-fluid card-img me-3 avatar avatar-stream_p"
                                            />
                                            <div className="upcoming_description d-flex flex-column justify-content-between">
                                                <Link to={`/stream-details/analytics/${_id}?sn=${title}`} className='text-muted'>
                                                    <p className="mb-2 text-capitalize text-break text-manual-white">{title}</p>
                                                </Link>
                                                {(is_recording && recording_url) ? (
                                                    <div className="d-flex">
                                                        <div>
                                                            {/* <Link to={`/view-video`} target="_blank"> */}
                                                                <div className="btn btn-xs btn-primary me-2" onClick={() => handleVideoPlay(recording_url, () => window.open(`${window.location.origin}/view-video`))}>
                                                                    <i className="mdi mdi-television icon-sm"></i>
                                                                    &nbsp;
                                                                    View Record
                                                                </div>
                                                            {/* </Link> */}
                                                        </div>
                                                        <div>
                                                            <div className="btn btn-xs btn-soft-light me-2" onClick={() => fetchDownloadStream(_id)}>
                                                                <i className="mdi mdi-television icon-sm"></i>
                                                                &nbsp;
                                                                Download
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (is_recording) ? (
                                                    <p className="m-0">Stream recording is in progress and will be available soon</p>
                                                ) : <p className="m-0">Stream recording not available</p> }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-2 border-left upcoming_details" >
                                        <div>
                                            <h5 className="text-manual-white">Net Revenue</h5>
                                            <p className="mb-2">$ {net_revenue}</p>
                                        </div>
                                        <div>
                                            <h5 className="text-manual-white">Total Clicks</h5>
                                            <p className="mb-2">{totalclickcount}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4 border-left">
                                        <div className="upcoming_details ms-2 d-flex justify-content-between">
                                            <div>
                                                <div>
                                                    <h5 className="text-manual-white">Created</h5>
                                                    <p className="mb-2">{moment(created_at).format('MM/DD/YYYY')}</p>
                                                </div>
                                                <div>
                                                    <h5 className="text-manual-white">Attendees</h5>
                                                    <p className="mb-0">{total_attendees}</p>
                                                </div>
                                            </div>

                                            <div>
                                                <div>
                                                    <h5 className="text-manual-white">Scheduled</h5>
                                                    <p className="small">
                                                        {moment(start_time).format("HH:mm A")} - {moment(end_time).format("HH:mm A")}
                                                        <br />
                                                        {moment(schedule).format('MM/DD/YYYY')}
                                                    </p>
                                                </div>
                                                <div>
                                                    <h5 className="text-manual-white">Status</h5>
                                                    {
                                                        status === 'D' ?
                                                            <span className="badge bg-warning h-50 w-50 small">Draft</span>
                                                            :
                                                            <span className="badge bg-success h-50 w-50 small">Created</span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                )
            }}
        </Observer>
    )
}

export default SinglePastStream;