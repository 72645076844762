import ReactDOM from 'react-dom';
import { StoreProvider, mainStore } from 'src/container/index';
import 'src/assets/index';

import App from './App';

ReactDOM.render(
	<StoreProvider value={mainStore}>
		<App />
	</StoreProvider>,
	document.getElementById('root'),
);
