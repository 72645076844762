import { Fragment, useEffect } from 'react';
import { Observer } from 'mobx-react';
import { useMainStore } from 'src/container';

import SmallLoader from 'src/components/common/smallLoader';

const Analytics = () => {
    const mainStore = useMainStore();
    const { dashboardStore } = mainStore;

    useEffect(() => {
        const { fetchStreamAdsRevenue, fetchStreamLast30DaysSubscriber, fetchStreamLast30DaysViews } = dashboardStore;

        fetchStreamAdsRevenue();
        fetchStreamLast30DaysSubscriber();
        fetchStreamLast30DaysViews();

        return () => { }
    }, [dashboardStore]);

    return (
        <Observer>
            {() => {
                const { streamAdsRevenue, streamAdsRevenueLoading, streamAdsLast30DaysViews, streamAdsLast30DaysSubscriber,
                    streamAdsLast30DaysSubscriberLoading, streamAdsLast30DaysViewsLoading
                } = dashboardStore;

                const { click_count = 0, impression_count = 0, net_revenue = 0 } = streamAdsRevenue[0] || {};
                const { impression_count: daysViewsCount = 0 } = streamAdsLast30DaysViews[0] || {};
                const { subscriber_count = 0 } = streamAdsLast30DaysSubscriber[0] || {};

                const revenue = Number(click_count + impression_count).toFixed(2);

                return (
                    <Fragment>
                        <div className="row">
                            <div className="col-12 col-sm-6 col-md-4 col-lg-6 col-xl-4 mt-4">
                                <div
                                    className="features feature-primary d-flex justify-content-between align-items-start bg-white rounded shadow p-3 h-100 m-h-200 border-green"
                                >
                                    <div className="d-flex align-items-start">
                                        <div className="icon icon-green text-center rounded-pill">
                                            <i className="mdi mdi-currency-usd fs-4 mb-0"></i>
                                        </div>
                                        <div className="flex-1 ms-3 dash_details">


                                            {streamAdsRevenueLoading ? (
                                                <SmallLoader color={'primary'} />
                                            ) : (
                                                <p className="fs-5 text-dark fw-bold mb-1">$<span className="counter-value" data-target="0">{net_revenue}</span></p>
                                            )}

                                            <h6 className="mb-0 text-uppercase">MRR (Monthly Recurring Revenue)</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-sm-6 col-md-4 col-lg-6 col-xl-4 mt-4">

                                <div
                                    className="features feature-primary d-flex justify-content-between align-items-start bg-white rounded shadow p-3 h-100 m-h-200 border-yellow"
                                >
                                    <div className="d-flex align-items-start">
                                        <div className="icon icon-yellow text-center rounded-pill">
                                            <i className="mdi mdi-chart-line fs-4 mb-1"></i>
                                        </div>
                                        <div className="flex-1 ms-3 dash_details">

                                            {streamAdsLast30DaysViewsLoading ? (
                                                <SmallLoader color={'primary'} />
                                            ) : (
                                                <p className="fs-5 text-dark fw-bold mb-1"><span className="counter-value" data-target="0">{daysViewsCount}</span></p>
                                            )}
                                            <h6 className="mb-0 text-uppercase">
                                                Views Last 30 Days
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-sm-6 col-md-4 col-lg-6 col-xl-4 mt-4">

                                <div
                                    className="features feature-primary d-flex justify-content-between align-items-start bg-white rounded shadow p-3 h-100 m-h-200 border-purple"
                                >
                                    <div className="d-flex align-items-start">
                                        <div className="icon icon-purple text-center rounded-pill">
                                            <i className="mdi mdi-account fs-4 mb-0"></i>
                                        </div>
                                        <div className="flex-1 ms-3 dash_details">

                                            {streamAdsLast30DaysSubscriberLoading ? (
                                                <SmallLoader color={'primary'} />
                                            ) : (
                                                <p className="fs-5 text-dark fw-bold mb-1">
                                                    <span className="counter-value" data-target="0">{subscriber_count}</span>
                                                </p>
                                            )}

                                            <h6 className="mb-0 text-uppercase">
                                                New Subscribers Last 30 Days
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                )
            }}
        </Observer>
    );
}

export default Analytics;