import { Fragment } from "react";

const SmallLoader = ({ color, style, classNames }) => {
    const colors = {
        "default": "text-primary",
        "secondary": "text-secondary",
        "success": "text-success",
        "danger": "text-danger",
        "warning": "text-warning",
        "info": "text-info",
        "light": "text-light",
        "dark": "text-dark",
    }

    return (
        <Fragment>
            <div className={`spinner-border ${colors[color]} ${classNames}`} role="status" style={{ ...style }}>
                <span className="visually-hidden">Loading...</span>
            </div>
        </Fragment>
    )
}

SmallLoader.defaultProps = {
    color: "default",
    style: {},
    classNames: ''
}

export default SmallLoader;