import { Observer } from "mobx-react";
import { toJS } from "mobx";
import { useMainStore } from "src/container";
import { isValidArray } from "src/utils/utilities";

import PreviewModeWrapper from 'src/components/common/previewModeWrapper';

const SocialMediaSection = ({ handleSocialEditModal }) => {
    const mainStore = useMainStore();
    const { streamFormStore } = mainStore;

    const getSocialValues = (data) => {
        let twitter = null;
        let whatsapp = null;
        let linkedin = null;
        let email = null;
        let facebook = null;

        if (isValidArray(toJS(data?.fields))) {
            for (let i = 0; i < data?.fields.length; i++) {
                const { value, name } = data?.fields[i];
                switch (name) {
                    case 'twitter':
                        twitter = value;
                        break;
                    case 'linkedin':
                        linkedin = value;
                        break;
                    case 'email':
                        email = value;
                        break;
                    case 'facebook':
                        facebook = value;
                        break;
                    case 'whatsapp':
                        whatsapp = value;
                        break;
                    default:
                        break;
                }
            }
        }
        return { twitter: twitter, whatsapp: whatsapp, linkedin: linkedin, email: email, facebook: facebook };
    }

    const gotoTheLink = (url) => {
        window.open(url, "_blank");
    }

    return (
        <Observer>
            {() => {
                const { formSocialMediaSection } = streamFormStore;
                const { twitter, whatsapp, linkedin, email, facebook } = getSocialValues(formSocialMediaSection);

                return (
                    <div className="widget mt-4 d-flex align-items-center">

                        <PreviewModeWrapper
                            offPreviewMode={
                                <span className="text-white d-block rounded h6 mb-0 me-2">
                                    Social Media :
                                </span>
                            }
                            onPreviewMode={
                                (twitter || whatsapp || linkedin || email || facebook) && (
                                    <span className="text-white d-block rounded h6 mb-0 me-2">
                                        Social Media : 
                                    </span>
                                )
                            }
                        />

                        <ul className="list-unstyled social-lead-icon text-center mb-0">
                            <PreviewModeWrapper
                                offPreviewMode={
                                    <li className="list-inline-item mb-0 manual-pointer" onClick={() => handleSocialEditModal(0, 'twitter')}>
                                        <div className="rounded">
                                            <i className="mdi mdi-twitter fea icon-sm fea-social"></i>
                                        </div>
                                    </li>
                                }
                                onPreviewMode={
                                    twitter && <li className="list-inline-item mb-0 manual-pointer" onClick={() => gotoTheLink(twitter)}>
                                        <div className="rounded">
                                            <i className="mdi mdi-twitter fea icon-sm fea-social"></i>
                                        </div>
                                    </li>
                                }
                            />

                            <PreviewModeWrapper
                                offPreviewMode={
                                    <li className="list-inline-item mb-0 manual-pointer" onClick={() => handleSocialEditModal(1, 'facebook')}>
                                        <div className="rounded">
                                            <i className="mdi mdi-facebook fea icon-sm fea-social"></i>
                                        </div>
                                    </li>
                                }
                                onPreviewMode={
                                    facebook && <li className="list-inline-item mb-0 manual-pointer" onClick={() => gotoTheLink(facebook)}>
                                        <div className="rounded">
                                            <i className="mdi mdi-facebook fea icon-sm fea-social"></i>
                                        </div>
                                    </li>
                                }
                            />

                            <PreviewModeWrapper
                                offPreviewMode={
                                    <li className="list-inline-item mb-0 manual-pointer" onClick={() => handleSocialEditModal(2, 'linkedIn')}>
                                        <div className="rounded">
                                            <i className="mdi mdi-linkedin fea icon-sm fea-social"></i>
                                        </div>
                                    </li>
                                }
                                onPreviewMode={
                                    linkedin && <li className="list-inline-item mb-0 manual-pointer" onClick={() => gotoTheLink(linkedin)}>
                                        <div className="rounded">
                                            <i className="mdi mdi-linkedin fea icon-sm fea-social"></i>
                                        </div>
                                    </li>
                                }
                            />

                            <PreviewModeWrapper
                                offPreviewMode={
                                    <li className="list-inline-item mb-0 manual-pointer" onClick={() => handleSocialEditModal(3, 'whatsApp')}>
                                        <div className="rounded">
                                            <i className="mdi mdi-whatsapp  fea icon-sm fea-social"></i>
                                        </div>
                                    </li>
                                }
                                onPreviewMode={
                                    whatsapp && <li className="list-inline-item mb-0 manual-pointer" onClick={() => gotoTheLink(whatsapp)}>
                                        <div className="rounded">
                                            <i className="mdi mdi-whatsapp  fea icon-sm fea-social"></i>
                                        </div>
                                    </li>
                                }
                            />

                            <PreviewModeWrapper
                                offPreviewMode={
                                    <li className="list-inline-item mb-0 manual-pointer" onClick={() => handleSocialEditModal(4, 'email')}>
                                        <div className="rounded">
                                            <i className="mdi mdi-email fea icon-sm fea-social"></i>
                                        </div>
                                    </li>
                                }
                                onPreviewMode={
                                    email && <li className="list-inline-item mb-0 manual-pointer" onClick={() => gotoTheLink(email)}>
                                        <div className="rounded">
                                            <i className="mdi mdi-email fea icon-sm fea-social"></i>
                                        </div>
                                    </li>
                                }
                            />

                        </ul>
                    </div>
                )
            }}
        </Observer>
    )
}

export default SocialMediaSection;