import { observable, makeObservable, runInAction, toJS, action } from 'mobx';
import { getRequest, patchRequest, postRequest } from 'src/utils/api';
import { sendNotification, isValidObject, isValidStoredKey, getLocalStorageItems, getValidDataFromResponse, isValidArray } from 'src/utils/utilities';
import moment from 'moment';
import { toPlainObject } from 'lodash';

class CreateStreamStore {
    userUploadedImageAcceptedFormats = ["image/jpeg", "image/jpg", "image/png"];
    uploadedBaseStreamImages = [];
    uploadedFileStreamImages = [];

    streamCreateLoading = false;
    streamCreateSuccessRedirect = false;
    streamUpdateLoading = false;
    streamUpdateSuccessRedirect = false;

    createdStreamData = [];

    createStreamFormLoading = false;
    createStreamFormData = [{}];
    createStreamFormSuccess = false;

    defaultTimezoneSuccess = false;
    defaultTimezoneData = [];

    constructor() {
        makeObservable(this, {
            uploadedBaseStreamImages: observable,
            uploadedFileStreamImages: observable,
            streamCreateLoading: observable,
            streamCreateSuccessRedirect: observable,
            streamUpdateLoading: observable,
            streamUpdateSuccessRedirect: observable,
            createStreamFormSuccess: observable,
            createStreamFormLoading: observable,
            createStreamFormData: observable,
            handleCreateForm: action,
            getDefaultTimeZone: action,
            defaultTimezoneSuccess: observable,
            defaultTimezoneData: observable,
        });
    }

    // check image size
    bytesToSize = (bytes, maxSizeAccepted = 5) => {
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) return '0 Byte';
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        if (sizes[i] === 'MB') {
            return Math.round(bytes / Math.pow(1024, i), 2) < maxSizeAccepted;
        } else if (sizes[i] === 'KB') {
            return Math.round(bytes / Math.pow(1024, i), 2) < maxSizeAccepted * 1024;
        }
        return false;
    }

    // get base 64 form
    previewFile = (file, callback) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            callback(reader.result);
        };
        reader.readAsDataURL(file);
    }

    // When user upload latest image
    handleFileUpload = (file) => {
        this.hasError = false;
        try {
            const filesList = file.target.files;
            if (isValidObject(filesList)) {
                for (let i = 0; i < filesList.length; i++) {
                    const { type, size } = filesList[i];
                    if (!this.userUploadedImageAcceptedFormats.includes(type)) {
                        this.hasError = true;
                        // sendNotification({ notificationType: 'warning', message: 'Unsupported file type. Only JPG,JPEG,PNG are available.', duration: 3000 });
                    } else if (!this.bytesToSize(size)) {
                        this.hasError = true;
                        // sendNotification({ notificationType: 'warning', message: 'File size too Small or Bigger than 5 MB.', duration: 3000 });
                    } else {
                        this.previewFile(filesList[i], (f) => {
                            this.uploadedFileStreamImages.push(filesList[i]);
                            this.uploadedBaseStreamImages.push(f);
                        })
                        sendNotification({ notificationType: 'success', message: 'File uploaded successfully'})
                    }
                }

                if (this.hasError) {
                    this.hasError = false;
                    sendNotification({
                        notificationType: 'warning',
                        message: 'Some files are too big or some are invalid type images. those are going to remove in list.',
                        duration: 4000
                    });
                }

                // remove first image from uploaded file form
                setTimeout(() => {
                    if (filesList.length === 1) {
                        document.getElementById('file-input').value = '';
                    }
                }, 1000);

            } else {
                throw new Error('Something went wrong.');
            }
        } catch (error) {
            sendNotification({ notificationType: 'error', message: 'Something went wrong!' });
        }
    }

    handleRemoveFileFromList = (index) => {
        runInAction(() => {
            this.uploadedFileStreamImages = [...this.uploadedFileStreamImages.filter((f, i) => i !== index)];
            this.uploadedBaseStreamImages = [...this.uploadedBaseStreamImages.filter((f, i) => i !== index)];
        });
    }

    handleStreamForm = async (streamFormData) => {
        try {
            runInAction(() => {
                this.streamCreateLoading = true;
            });

            if (isValidStoredKey(['ouid'])) {
                const { ouid, oid } = getLocalStorageItems({ getAll: true, keys: ['ouid', 'oid'] });

                const { schedule, end_time, end_time_a, end_time_h, end_time_m, start_time_h, start_time_m, start_time_a, ...extraStreamFormData } = streamFormData;

                let selectedSchedule = moment(schedule).format("MM/DD/YYYY");

                const streamData = {
                    ...extraStreamFormData,
                    schedule: moment(schedule).toISOString(),
                    start_time: moment(`${selectedSchedule} ${start_time_h + ":" + start_time_m} ${start_time_a}`).toISOString(),
                    end_time: moment(`${selectedSchedule} ${end_time_h + ":" + end_time_m} ${end_time_a}`).toISOString(),
                    org_id: oid,
                    updated_by: ouid,
                    created_by: ouid,
                    org_user_id: ouid,
                    status: 'D',
                    is_live: false,
                }

                if (this.uploadedBaseStreamImages.length) {
                    streamData.images = [this.uploadedBaseStreamImages[this.uploadedBaseStreamImages.length - 1]];
                }

                const { response } = await postRequest('/stream/create', streamData);
                const { data, error } = getValidDataFromResponse(response, false);

                if (!error && isValidArray(data)) {
                    runInAction(() => {
                        this.createdStreamData = [...data];
                        this.streamCreateLoading = false;
                        this.streamCreateSuccessRedirect = true;
                        this.uploadedFileStreamImages = [];
                        this.uploadedBaseStreamImages = [];
                    })
                }
            }

            setTimeout(() => {
                runInAction(() => {
                    this.streamCreateLoading = false;
                })
            }, 1000)
        } catch (error) {
            runInAction(() => {
                this.streamCreateSuccessRedirect = false;
                this.streamCreateLoading = false;
            })
        }
    }

    resetCreateStreamRedirectSuccess = () => {
        this.streamCreateSuccessRedirect = false;
    }

    resetUpdateStreamRedirectSuccess = () => {
        this.streamUpdateSuccessRedirect = false;
    }

    resetCreateStreamData = () => {
        this.createdStreamData = [];
    }

    handleUpdateStream = async (linkType) => {
        try {
            runInAction(() => {
                this.streamUpdateLoading = true;
            });

            if (isValidStoredKey(['ouid'])) {
                const { ouid, oid } = getLocalStorageItems({ getAll: true, keys: ['ouid', 'oid'] });
                const { _id = '' } = this.createdStreamData[0];

                const streamData = {
                    org_id: oid,
                    updated_by: ouid,
                    status: 'C',
                    link_type: linkType,
                    stream_id: _id,
                    is_created: true,
                    is_updated: false
                }

                const { response } = await patchRequest('/stream/update', streamData);

                if (isValidObject(toPlainObject(response), ["success", "message", "data"])) {
                    response.message = 'Stream created successfully!';
                }

                const { data, error } = getValidDataFromResponse(response, true);
                if (!error && isValidArray(data)) {
                    const [{ wowza_live, str_host_link }] = data;

                    if(wowza_live){
                        window.open(str_host_link, "_blank")
                    }
                    runInAction(() => {
                        this.streamUpdateSuccessRedirect = true;
                    })
                }
            }

            setTimeout(() => {
                runInAction(() => {
                    this.streamUpdateLoading = false;
                    this.streamUpdateSuccessRedirect = false;
                })
            }, 1000)
        } catch (error) {
            runInAction(() => {
                this.streamUpdateSuccessRedirect = false;
                this.streamUpdateLoading = false;
            })
        }
    }

    handleCopySuccess = (message) => {
        sendNotification({ message: message, notificationType: "success", duration: 2000 });
    }

    handleCreateForm = async () => {
        try {
            if (isValidStoredKey(['ouid', 'oid'])) {

                runInAction(() => {
                    this.createStreamFormLoading = true;
                });

                const { ouid, oid } = getLocalStorageItems({ getAll: true, keys: ['ouid', 'oid'] });
                const { _id = '' } = this.createdStreamData[0];

                const streamData = {
                    org_id: oid,
                    stream_id: _id,
                    org_user_id: ouid
                }

                const { response } = await postRequest('/stream/form/create', streamData);
                const { data, error } = getValidDataFromResponse(response, true);

                if (!error && isValidArray(data)) {
                    runInAction(() => {
                        this.createStreamFormData = toJS([...data]);
                        this.createStreamFormSuccess = true;
                    })
                }
            }

            runInAction(() => {
                this.createStreamFormSuccess = false;
                this.createStreamFormLoading = false;
            })
        } catch (error) {
            runInAction(() => {
                this.createStreamFormSuccess = false;
                this.createStreamFormLoading = false;
            })
        }
    }

    getDefaultTimeZone = async () => {
        try {
            const { oid } = getLocalStorageItems({ getAll: true, keys: ["oid"] });

            const { response } = await getRequest(`/organizer/${oid}`);
            const { data, error } = getValidDataFromResponse(response, false, false, false);

            if (!error && isValidArray(data)) {
                runInAction(() => {
                    this.defaultTimezoneData = toJS([...data]);
                    this.defaultTimezoneSuccess = true;
                });
            }

            runInAction(() => {
                this.defaultTimezoneSuccess = false;
            });

        } catch (error) {

        }
    }

    removeAllUpdatedFiles = () => {
        runInAction(() => {
            this.uploadedFileStreamImages = [];
            this.uploadedBaseStreamImages = [];
        })
    };

}

export default CreateStreamStore;
