import { yupResolver } from '@hookform/resolvers/yup';
import { Observer, useLocalObservable } from 'mobx-react';
import React, { Fragment } from 'react';
import { useForm } from 'react-hook-form';
import { Navigate, useParams } from 'react-router-dom';
import AdtendeesLogo from 'src/assets/images/logo.png';
import SmallLoader from 'src/components/common/smallLoader';
import { useMainStore } from 'src/container';
import { handleKeyPress } from 'src/utils/utilities';
import * as yup from 'yup';

const ResetPassword = () => {

    const mainStore = useMainStore();
    const { forgotPasswordStore } = mainStore;

    const { token } = useParams();

	const localStore = useLocalObservable(() => ({
        newPassword: true,
        newPasswordEye: true,

        inNewPassword: true,
        confirmNewPassword: true,

        changeToText({ field }) {
            if(field === 'new_password'){
                this.newPassword = !this.newPassword;
                this.newPasswordEye = !this.newPasswordEye;
            } else {
                this.inNewPassword = !this.inNewPassword;
                this.confirmNewPassword = !this.confirmNewPassword;
            }
        }
    }))

	const changeType = ({ field }) => {
        const { changeToText } = localStore;
        changeToText( { field });
    }


    // Form Validation
    const resetPasswordFormSchema = yup.object({
        new_password : yup.string().trim().required('New Password is required!'),
        confirm_password : yup.string().trim().required('Confirm Password is required').test('forSamePassword', "Password does't match!", function() {

            const { new_password, confirm_password } = this.parent;

            if( new_password === confirm_password ){
                return true;
            }

            return false
        })
    })

    const { handleSubmit, register, formState: { errors } } = useForm({
        resolver: yupResolver(resetPasswordFormSchema)
    })

    const handleResetPasswordForm = (data) => {
        const { new_password } = data;
        const { handleResetPassword } = forgotPasswordStore;

        handleResetPassword({password: new_password, resetPasswordToken: token })
    }

	return (
		<Fragment>
			<section className="bg-home bg-login d-flex align-items-center">
				<div className="container">
					<div className="row">
						<Observer>
							{() => {

                                const { isResetPasswordLoading, shouldRedirectAfterResetPasswordSuccess, resetAfterResetPasswordRedirectFlag } = forgotPasswordStore;
                                const { newPassword, newPasswordEye, inNewPassword, confirmNewPassword } = localStore;

                                if(shouldRedirectAfterResetPasswordSuccess){
                                    resetAfterResetPasswordRedirectFlag()
                                    return <Navigate to={`/login`} />;
                                }

								return (
									<div className="col-12">
                                        <form onSubmit={handleSubmit(handleResetPasswordForm)}>

                                            <img src={AdtendeesLogo} className="avatar mb-4 d-block mx-auto" alt="adtendees-logo" />

                                            <div className="form-signin p-4 bg-white rounded shadow">
                                                <h5 className="mb-3 text-left text-manual-white">Reset Password</h5>

                                                <div className="col-md-12">
                                                    <div className="mb-4 password">
                                                        <label className="form-label">
                                                            New Password<span className="requiredFields">*</span>
                                                        </label>
                                                        <input
                                                            name="new_password"
                                                            id="new_password"
                                                            onKeyPress={(e) => handleKeyPress(e)}
                                                            type={newPassword ? "password" : "text"}
                                                            className="form-control form-control-lg ps-2 inputPassword"
                                                            placeholder="e.g *******"
                                                            autoFocus
                                                            {...register('new_password')}
                                                        />
                                                        <i className={`bi ${newPasswordEye ? "bi-eye" : "bi-eye-slash"}`} onClick={() => changeType({ field : 'new_password'})}></i>

                                                        <p className="text-danger mb-0">{errors.new_password?.message}</p>
                                                    </div>
                                                </div>

                                                <div className="col-md-12">
                                                    <div className="mb-4 password">
                                                        <label className="form-label">
                                                            Confirm New Password<span className="requiredFields">*</span>
                                                        </label>
                                                        <input
                                                            name="confirm_password"
                                                            id="confirm_password"
                                                            onKeyPress={(e) => handleKeyPress(e)}
                                                            type={inNewPassword ? "password" : "text"}
                                                            className="form-control form-control-lg ps-2 inputPassword"
                                                            placeholder="e.g *******"
                                                            {...register('confirm_password')}
                                                        />
                                                        <i className={`bi ${confirmNewPassword ? "bi-eye" : "bi-eye-slash"}`} onClick={() => changeType({ field: 'confirm_password'})}></i>

                                                        <p className="text-danger mb-0">{errors.confirm_password?.message}</p>
                                                    </div>
                                                </div>

                                                <div className="col-12 mb-3">
                                                    <button className="btn btn-primary w-100 mb-3" type="submit">
                                                        {isResetPasswordLoading ? <SmallLoader color={'dark'} /> : 'Reset'}
                                                    </button>
                                                </div>

                                            </div>

                                        </form>
									</div>
								);
							}}
						</Observer>
					</div>
				</div>
			</section>
		</Fragment>
	);
};

export default ResetPassword;
