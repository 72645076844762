import { Fragment, useEffect } from "react";
import { Observer } from "mobx-react";
import { useMainStore } from "src/container";

const RegisterNowHeader = () => {
    const mainStore = useMainStore();
    const { streamFormStore } = mainStore;

    useEffect(() => {
        const { setStreamTime } = streamFormStore;
        setInterval(() => setStreamTime(), 1000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Observer>
            {() => {
                const { streamSeconds, streamMinutes, streamDays, streamHours } = streamFormStore;

                return (
                    <Fragment>
                        <div className="d-flex justify-content-between align-items-center mb-4">
                            <div className="h6 d-flex no-wrap align-items-center mb-0">
                                <span className="text-primary me-1">
                                    <i className="mdi mdi-calendar-range-outline"></i>
                                </span>
                                Stream Timing
                            </div>
                            <div className="form_time">
                                <ul>
                                    <li><span>{streamDays}</span><br />Day</li>
                                    <li><span>{streamHours}</span><br />Hours</li>
                                    <li><span>{streamMinutes}</span><br />Minutes</li>
                                    <li><span>{streamSeconds}</span><br />Seconds</li>
                                </ul>
                            </div>
                        </div>
                    </Fragment>
                )
            }}
        </Observer>
    )
}

export default RegisterNowHeader;