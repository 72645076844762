import { Fragment, useCallback, useMemo } from "react";
import { useLocalObservable, Observer } from 'mobx-react';
import { useNavigate, Navigate, useLocation } from "react-router-dom";
import { useMainStore } from "src/container";
import { checkValidUrlPath, getLocalStorageItems, trimString } from "src/utils/utilities";

import SmallLoader from "src/components/common/smallLoader";

import PrivateIcon from 'src/assets/images/icon/write.svg';
import PublicIcon from 'src/assets/images/icon/earth.svg';

import { CopyToClipboard } from 'react-copy-to-clipboard';

const SecondStep = () => {
    const mainStore = useMainStore();
    let navigate = useNavigate();
    let { pathname } = useLocation();

    const isUpcomingPage = useMemo(() => checkValidUrlPath(pathname, "upcoming"), [pathname]);
    const isPastPage = useMemo(() => checkValidUrlPath(pathname, "past"), [pathname]);

    let localSecondStepStore = useLocalObservable(() => ({
        isPrivate: true,
        isPublic: false,
        changeActiveStatus(key) {
            switch (key) {
                case 'private':
                    this.isPrivate = true;
                    this.isPublic = false;
                    break;
                case 'public':
                    this.isPrivate = false;
                    this.isPublic = true;
                    break;
                default:
                    this.isPrivate = false;
                    this.isPublic = false;
                    break;
            }
        }
    }))

    const { createStreamStore, streamsStore } = mainStore;

    const fetchStreamListOnCreateOrClose = () => {
        const { fetchAllStreamsByType } = streamsStore;
        if (isUpcomingPage) {
            fetchAllStreamsByType('upcoming');
        } else if (isPastPage) {
            fetchAllStreamsByType('past');
        }
    }

    const goToBackPage = () => {
        const { resetUpdateStreamRedirectSuccess, resetCreateStreamRedirectSuccess, resetCreateStreamData } = createStreamStore;
        navigate(-2);
        resetCreateStreamRedirectSuccess();
        resetUpdateStreamRedirectSuccess();
        resetCreateStreamData();
        fetchStreamListOnCreateOrClose();
    }

    const handleUpdateStreamForm = () => {
        const { handleUpdateStream } = createStreamStore;
        const { isPrivate, isPublic } = localSecondStepStore;
        const linkType = isPrivate ? 1 : isPublic ? 0 : 1;

        handleUpdateStream(linkType);
    };

    const goToFormPage = () => {
        const { handleCreateForm } = createStreamStore;
        handleCreateForm();
    }

    const redirectToForm = useCallback(() => {
        const { createStreamFormData } = createStreamStore;
        const { oid, ouid } = getLocalStorageItems({ getAll: true, keys: ['ouid', 'oid'] });
        const { _id: fid, stream_id: sid } = createStreamFormData[0];
        window.open(`/form?sid=${sid}&oid=${oid}&ouid=${ouid}&fid=${fid}`, "_blank");
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Observer>
            {() => {
                const { changeActiveStatus, isPrivate, isPublic } = localSecondStepStore;
                const {
                    resetUpdateStreamRedirectSuccess, resetCreateStreamRedirectSuccess, resetCreateStreamData,
                    streamCreateSuccessRedirect, streamUpdateSuccessRedirect, streamUpdateLoading,
                    handleCopySuccess, createStreamFormSuccess
                } = createStreamStore;

                if (createStreamFormSuccess) {
                    redirectToForm();
                }

                if (!streamCreateSuccessRedirect) {
                    const latestPathName = trimString(pathname, 'create-stream/settings', 1);
                    return <Navigate to={`/streams/upcoming`} />;
                }

                if (streamCreateSuccessRedirect && streamUpdateSuccessRedirect) {
                    resetCreateStreamRedirectSuccess();
                    resetUpdateStreamRedirectSuccess();
                    resetCreateStreamData();
                    fetchStreamListOnCreateOrClose();
                    return <Navigate to={-2} />;
                }

                const { createdStreamData = [{}] } = createStreamStore;
                const { str_private_link = '', str_public_link = '', str_host_link = '', _id } = createdStreamData[0];

                return (
                    <Fragment>
                        <div className="bg-white p-sm-2 rounded box-shadow">
                            <div className="row">
                                <div className="col-md-12" onClick={() => changeActiveStatus('private')}>
                                    Who can Attend
                                    <div className={`p-1 p-sm-3 mt-1 mb-3 d-flex align-items-center setting_card ${isPrivate && 'active'}`}>
                                        <img src={PrivateIcon} alt="" className="img-fluid me-3" />
                                        <div>
                                            <p className="text-label mb-1">Private (By filling the form below)</p>
                                            <div className="private_card">
                                                <span data-bs-toggle="tooltip" data-bs-placement="top" title={str_private_link}>
                                                    {str_private_link.slice(0, 31) || "https://www.attendees.io/loacmdlla"}
                                                </span>
                                                <div
                                                    onClick={() => goToFormPage(_id)}
                                                    className="badge bg-edit rounded manual-pointer h-blue"
                                                >
                                                    EDIT & PREVIEW
                                                </div>
                                                <CopyToClipboard
                                                    text={str_private_link || 'https://www.attendees.io/loacmdlla'}
                                                    className="public_copy badge bg-edit rounded ms-2"
                                                    onCopy={() => handleCopySuccess('Private Link copied')}
                                                >
                                                    <div> COPY </div>
                                                </CopyToClipboard>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12" onClick={() => changeActiveStatus('public')}>
                                    <div className={`p-1 p-sm-3 mb-3 d-flex align-items-center setting_card ${isPublic && 'active'}`}>
                                        <img src={PublicIcon} alt="" className="img-fluid me-3" />
                                        <div>
                                            <p className="text-label mb-1">Public (Anyone with this link)</p>
                                            <div className="private_card private_card_copy">
                                                <span data-bs-toggle="tooltip" data-bs-placement="top" title={str_public_link}>
                                                    {str_public_link || "https://www.attendees.io/loacmdlla"}
                                                </span>
                                                <CopyToClipboard
                                                    text={str_public_link || 'https://www.attendees.io/loacmdlla'}
                                                    className="public_copy badge bg-edit rounded ms-2"
                                                    onCopy={() => handleCopySuccess('Public Link copied')}
                                                >
                                                    <div> COPY </div>
                                                </CopyToClipboard>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>

                            <div className="row text-right mt-3">
                                <div className="col-md-12">
                                    <button
                                        onClick={handleUpdateStreamForm}
                                        className="btn btn-footers btn-primary"
                                    >
                                        {streamUpdateLoading ? <SmallLoader color={'black'} /> : 'Create'}
                                    </button>
                                    <button
                                        onClick={goToBackPage}
                                        type="button"
                                        className="btn btn-footers btn-dark-footer"
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                )
            }}
        </Observer>
    )
}

export default SecondStep;