import { Fragment, useEffect } from "react";
import { Observer } from 'mobx-react';
import { Outlet } from "react-router-dom";
import { useMainStore } from "src/container";

import SingleUpcomingStream from './singleUpcomingStream';
import SmallLoader from "src/components/common/smallLoader";

const UpcomingStreams = () => {
    const mainStore = useMainStore();
    const { streamsStore } = mainStore;

    // useEffect(() => {
    //     const { fetchAllStreamsByType } = streamsStore;
    //     fetchAllStreamsByType('upcoming');
    //     return () => { }
    // }, [streamsStore]);

    return (
        <Observer>
            {() => {
                const { allUpcomingStreamList = [], isUpcomingStreamLoading } = streamsStore;

                return (
                    <Fragment>
                        <div className="content active" id="upcoming">
                            <div className="row">
                                {isUpcomingStreamLoading ? (
                                    <div className="d-flex justify-content-center align-items-center">
                                        <SmallLoader color={'default'} />
                                    </div>
                                ) : (
                                    <div className="col-12 mb-3">
                                        {allUpcomingStreamList.length ? allUpcomingStreamList.map((stream, i) => {
                                            return <SingleUpcomingStream {...stream} key={i} />;
                                        }) : <p className="fs-1 text-manual-white">No Streams Found.</p>}
                                    </div>
                                )}
                            </div>
                        </div>
                        <Outlet />
                    </Fragment>
                )
            }}
        </Observer>
    )
}

export default UpcomingStreams;