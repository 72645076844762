import { makeAutoObservable, runInAction } from 'mobx';
import { deleteRequest } from 'src/utils/api';
import { getValidDataFromResponse } from 'src/utils/utilities';
import localStore from 'store2';

class LogoutStore {
    userLogoutLoading = false;

    constructor() {
        makeAutoObservable(this, {});
    }

    handleUserLogout = async (user_id) => {
        try {
            this.userLogoutLoading = true;
            const { response } = await deleteRequest(`/user/logout/${user_id}`);
            getValidDataFromResponse(response, false);
            runInAction(()=>{
                localStore.clearAll();
                this.userLogoutLoading = false;
            });
        } catch (error) {
            runInAction(()=>{
                localStore.clearAll();
                this.userLogoutLoading = false;
            });
        }
    }
}

export default LogoutStore;
