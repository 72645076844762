import { Fragment, useEffect } from 'react';
import { Observer } from 'mobx-react';
import { Outlet, useParams } from 'react-router-dom';
import { toJS } from 'mobx';
import { useMainStore } from 'src/container';
import { isValidObject } from 'src/utils/utilities';

import Chart from 'react-apexcharts';
import SmallLoader from 'src/components/common/smallLoader';
import { Empty } from 'antd';

const StreamAnalytic = () => {
    const { sid } = useParams();

    const mainStore = useMainStore();
    const { streamDetailsStore } = mainStore;

    useEffect(() => {
        const {
            fetchRegisteredAttendeeUsers, resetRegisteredUsersData, fetchStreamImpression, resetStreamImpression,
            fetchStreamAttendanceRate, resetStreamAttendanceRate, fetchStreamAdsClickedAnalytics, fetchStreamAdsNetRevenue, resetStreamAdsNetRevenue
        } = streamDetailsStore;

        fetchRegisteredAttendeeUsers(sid);
        fetchStreamImpression(sid);
        fetchStreamAttendanceRate(sid);
        fetchStreamAdsClickedAnalytics(sid);
        fetchStreamAdsNetRevenue(sid)

        return () => {
            resetRegisteredUsersData();
            resetStreamImpression();
            resetStreamAttendanceRate();
            resetStreamAdsNetRevenue();
        }
    }, [streamDetailsStore, sid]);

    const getKeysArray = (obj) => {
        return isValidObject(obj) ? Object.keys(obj) : [];
    }

    const getValuesArray = (obj) => {
        return isValidObject(obj) ? Object.values(obj) : [];
    }

    return (
        <Observer>
            {() => {
                const {
                    registeredUsers, registeredUsersLoading, streamImpressions, streamImpressionLoading,
                    streamAttendance, streamAttendanceLoading, streamAdsClickedAnalytics = [], streamAdsClickedAnalyticsLoading, streamAdsNetRevenueLoading, streamAdsNetRevenue
                } = streamDetailsStore;

                const { attendee_count = 0, registered_count = 0 } = registeredUsers[0] || {};
                const { click_count = 0, impression_count = 0 } = streamImpressions[0] || {};
                let { percentage = 0 } = streamAttendance[0] || {};
                const { click_count: click_revenue_count = 0, impression_count: impression_revenue_count = 0, net_revenue = 0 } = streamAdsNetRevenue[0] || {};

                const revenue = Number(click_revenue_count + impression_revenue_count).toFixed(2);

                return (
                    <Fragment>
                        <div className="content active" id="analytics">
                            <div className="row">
                                <div className="col-12 col-sm-6 col-md-4 col-lg-6 col-xl-4 mb-4">

                                    <div
                                        className="features feature-primary d-flex justify-content-between align-items-start bg-white rounded shadow p-3 h-100 m-h-150"
                                    >
                                        <div className="d-flex align-items-start">
                                            <div className="icon icon-green text-center rounded-pill">
                                                <i className="mdi mdi-currency-usd fs-4 mb-0"></i>
                                            </div>
                                            <div className="flex-1 ms-3 dash_details">
                                                {streamAdsNetRevenueLoading ?
                                                    (
                                                        <SmallLoader color={'primary'} />
                                                    ) :
                                                    <p className="fs-5 text-dark fw-bold mb-1">$
                                                        <span className="counter-value" data-target="0">{net_revenue}</span>
                                                    </p>
                                                }
                                                <h6 className="mb-0 text-uppercase">NET REVENUE</h6>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="col-12 col-sm-6 col-md-4 col-lg-6 col-xl-4 mb-4">

                                    <div
                                        className="features feature-primary d-flex justify-content-between align-items-start bg-white rounded shadow p-3 h-100 m-h-150"
                                    >
                                        <div className="d-flex align-items-start">
                                            <div className="icon icon-yellow text-center rounded-pill">
                                                <i className="mdi mdi-chart-line fs-4 mb-1"></i>
                                            </div>
                                            <div className="flex-1 ms-3 dash_details">
                                                {registeredUsersLoading ?
                                                    (
                                                        <SmallLoader color={'primary'} />
                                                    ) :
                                                    <p className="fs-5 text-dark fw-bold mb-0">
                                                        <span className="counter-value" data-target="89">
                                                            {registered_count}
                                                        </span>
                                                        /{attendee_count}
                                                    </p>
                                                }
                                                <h6 className="mb-0 text-uppercase">Registered / Attendees</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-sm-6 col-md-4 col-lg-6 col-xl-4 mb-4">
                                    <div
                                        className="features feature-primary d-flex justify-content-between align-items-start bg-white rounded shadow p-3 h-100 m-h-150"
                                    >
                                        <div className="d-flex align-items-start">
                                            <div className="icon icon-purple text-center rounded-pill">
                                                <i className="mdi mdi-account fs-4 mb-0"></i>
                                            </div>
                                            <div className="flex-1 ms-3 dash_details">
                                                {streamImpressionLoading ?
                                                    (
                                                        <SmallLoader color={"primary"} />
                                                    ) :
                                                    <p className="fs-5 text-dark fw-bold mb-0">
                                                        <span className="counter-value" data-target="50">
                                                            {click_count}
                                                        </span>
                                                        /{impression_count}
                                                    </p>
                                                }
                                                <h6 className="mb-0 text-uppercase">AD CLICKS / IMPRESSIONS</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="col-12 col-sm-6 col-md-4 col-lg-6 col-xl-3 mb-4">
                                    <div
                                        className="features feature-primary d-flex justify-content-between align-items-start bg-white rounded shadow p-3 h-100 m-h-150"
                                    >
                                        <div className="d-flex align-items-start">
                                            <div className="icon icon-yellow text-center rounded-pill">
                                                <i className="mdi mdi-chart-line fs-4 mb-1"></i>
                                            </div>
                                            <div className="flex-1 ms-3 dash_details">
                                                {streamAttendanceLoading ?
                                                    (
                                                        <SmallLoader color={"primary"} />
                                                    ) :
                                                    <p className="fs-5 text-dark fw-bold mb-0">
                                                        <span className="counter-value">{percentage}%</span>
                                                    </p>
                                                }
                                                <h6 className="mb-0 text-uppercase">Average Attendance Rate</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                            </div>

                            <div className="row">
                                <div className="col-xl-12 mt-3">
                                    <div className="card shadow border-0 pb-4 rounded">
                                        <div className="d-flex justify-content-between align-items-center p-3 bg-white shadow rounded-top border-bottom">
                                            <h6 className="text-uppercase mb-0 text-manual-white">Stream Details</h6>

                                            {/* <ul className="list-unstyled mb-0">
                                                <li className="dropdown dropdown-primary list-inline-item">
                                                    <button
                                                        type="button"
                                                        className="btn btn-icon btn-soft-light dropdown-toggle p-0"
                                                        data-bs-toggle="dropdown"
                                                        aria-haspopup="true"
                                                        aria-expanded="false"
                                                    >
                                                        <i className="mdi mdi-dots-horizontal"></i>
                                                    </button>
                                                    <div className="dropdown-menu dd-menu dropdown-menu-end bg-dropdown shadow border-0 mt-3 py-3">
                                                        <Link to={'/'} className="dropdown-item text-dark" href="#"> Edit</Link>
                                                        <Link to={'/'} className="dropdown-item text-dark" href="#"> Update</Link>
                                                        <Link to={'/'} className="dropdown-item text-dark" href="#"> Delete</Link>
                                                    </div>
                                                </li>
                                            </ul> */}
                                        </div>


                                        {!streamAdsClickedAnalyticsLoading ? (
                                            <>
                                                <div className='row'>

                                                    <div className='col-6' >
                                                        <div className='row p-3'>
                                                            <p className='m-0' >Registered vs Total Attendees</p>
                                                        </div>
                                                        <div className='row h-100' >
                                                            {(registered_count === 0 && attendee_count === 0) ? 
                                                                    <>
                                                                        <Empty description="No Data Available" />
                                                                    </>
                                                            : 
                                                                <Chart
                                                                    options={{
                                                                        chart: {
                                                                            id: 'apexchart-example'
                                                                        },
                                                                        stroke:{
                                                                            show: false
                                                                        },
                                                                        labels:['Registered Attendees', 'Total Attendees'],
                                                                        colors:['#846BFF', '#3192D4']
                                                                    }} 
                                                                    series={[registered_count, attendee_count]}
                                                                    type="donut"
                                                                    height={370}
                                                                    /> 

                                                            }
                                                        </div>
                                                    </div>

                                                    <div className='col-6'>
                                                        <div className='row p-3'>
                                                            <p className='m-0' >Clicks vs Impressions</p>
                                                        </div>
                                                        <div className='row h-100'>
                                                            {(click_count === 0 && impression_count ===0) ? 
                                                                    <>
                                                                        <Empty description="No Data Available" />
                                                                    </>
                                                            : 
                                                                <Chart
                                                                    options={{
                                                                        chart: {
                                                                            id: 'apexchart-example'
                                                                        },
                                                                        stroke:{
                                                                            show: false
                                                                        },
                                                                        labels:['Ad Clicks', 'Impressions'],
                                                                        colors:['#846BFF', '#3192D4']
                                                                    }} 
                                                                    series={[click_count, impression_count]}
                                                                    type="donut"
                                                                    height={370}
                                                                    /> 
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <div className="loader_center">
                                                <SmallLoader />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Outlet />
                    </Fragment>
                )
            }}
        </Observer>
    )
}

export default StreamAnalytic;