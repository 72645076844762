import { Fragment } from 'react';
import { Observer } from 'mobx-react';
import SmallLoader from 'src/components/common/smallLoader';

const ConfirmDeleteModal = ({ onClose, onSuccess, title, message, isLoading }) => {
    return (
        <Observer>
            {() => {
                return (
                    <Fragment>
                        <div
                            className="modal fade modal-open show"
                            id="exampleModal"
                            tabIndex="-1"
                            aria-hidden="true"
                            style={{ display: 'block' }}
                            role='dialog'
                        >
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">{title}</h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            aria-label="Close"
                                            data-bs-dismiss="modal"
                                            onClick={onClose}
                                        >
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        {message}
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={onSuccess}
                                        >
                                            {isLoading ? <SmallLoader color={'dark'} /> : "Yes"}
                                        </button>
                                        <button
                                            type="button"
                                            className="btn deleteBtnStyle"
                                            onClick={onClose}
                                        >
                                            No
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                )
            }}
        </Observer>
    );
};

ConfirmDeleteModal.defaultProps = {
    onClose: () => { },
    onSuccess: () => { },
    title: null,
    message: null,
    isLoading: false
}

export default ConfirmDeleteModal;