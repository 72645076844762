import { Fragment, useEffect } from 'react';
import { Observer, useLocalObservable } from 'mobx-react';
import { Link, Outlet, useParams } from 'react-router-dom';
import { useMainStore } from 'src/container';
import { Table } from 'antd';
import { CSVLink } from "react-csv";
import { sendNotification, useQueryParam } from 'src/utils/utilities';

import Avatar from 'react-avatar';

import StreamUserFilter from './streamUserFilter';
import SmallLoader from 'src/components/common/smallLoader';
import { toJS } from 'mobx';

const StreamUsers = () => {
    let params = useParams();
    const [streamName] = useQueryParam('sn');
    const mainStore = useMainStore();
    const { streamDetailsStore } = mainStore;
    const localStore = useLocalObservable(() => ({
        tableColumns: [
            {
                title: 'FULL NAME',
                dataIndex: 'full_name',
                key: 'full_name',
                width: '15%',
                visible: true,
                render: (value, data, index) => {
                    const { profile_image = null, full_name } = data;
                    return (
                        <div className="d-flex align-items-center" key={index}>
                            <Avatar
                                name={full_name}
                                src={profile_image}
                                className="avatar avatar-ex-small rounded-circle shadow"
                                alt="Profile"
                                size='40'
                            />
                            <span className="ms-2 text-none">{value}</span>
                        </div>
                    );
                }
            },
            {
                title: 'EMAIL',
                dataIndex: 'email',
                key: 'email',
                width: '22%',
                visible: true,
            },
            {
                title: 'SIGNED UP AT',
                dataIndex: 'signed_up',
                key: 'signed_up',
                width: '15%',
                visible: true,
            },
            {
                title: 'ADS CLICKED',
                dataIndex: 'click_count',
                key: 'ads',
                width: '10%',
                visible: true
            },
            // {
            //     title: '% Attendance',
            //     dataIndex: 'attendance',
            //     key: 'attendance',
            //     width: '13%',
            //     visible: true
            // },
            {
                title: 'COUNTRY',
                dataIndex: 'country',
                key: 'country',
                width: '10%',
                visible: true
            },
            {
                title: 'CITY',
                dataIndex: 'city',
                key: 'city',
                width: '13%',
                visible: true
            },
            {
                title: 'BROWSER',
                dataIndex: 'browser',
                key: 'browser',
                width: '10%',
                visible: true
            },
            {
                title: 'OS',
                dataIndex: 'os_name',
                key: 'os_name',
                width: '10%',
                visible: true,
                render: (value, data, index) => {
                    return value;
                }
            }
        ],
        userTableColumns() {
            return this.tableColumns.filter((col) => col.visible);
        },
        handleChangeHeaderVisiblity(key, visible) {
            this.tableColumns.forEach(col => {
                if (col['dataIndex'] === key) {
                    col['visible'] = visible;
                }
            })
        }
    }));

    const handleChangeSelectionHeaders = ({ target: { value, checked } }) => {
        localStore.handleChangeHeaderVisiblity(value, checked);
    }

    // useEffect(() => {
    //     const { fetchSingleStreamUser, resetStreamUserListData } = streamDetailsStore;
    //     const { sid = null } = params;
    //     fetchSingleStreamUser(sid);

    //     return () => {
    //         resetStreamUserListData();
    //     }
    //     eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    return (
        <Observer>
            {() => {
                const { streamUsersList = [], streamUsersLoading } = streamDetailsStore;

                if (streamUsersLoading) {
                    return (
                        <div className="d-flex justify-content-center align-items-center">
                            <SmallLoader color={'default'} />
                        </div>
                    );
                }

                return (
                    <Fragment>
                        <div className="content active" id="attendees">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="d-flex justify-content-end pb-3 shadow rounded-top">
                                        {/* <Link to='/' className="btn btn-xs btn-primary">Add attendee</Link> */}
                                        <div className="d-inline-flex align-items-center">
                                            <ul
                                                className="list-unstyled mb-0"
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="top"
                                                title="hide columns"
                                            >
                                                <li className="dropdown dropdown-primary list-inline-item">
                                                    <StreamUserFilter onChange={handleChangeSelectionHeaders} />
                                                </li>
                                            </ul>
                                            {
                                            toJS(streamUsersList).length > 0 ?
                                                (
                                                    <CSVLink
                                                        data={[...streamUsersList]}
                                                        filename={`${streamName}.csv`}
                                                        target="_blank"
                                                        data-bs-toggle="tooltip"
                                                        data-bs-placement="top"
                                                        title="export to csv"
                                                    >
                                                        <div className="btn btn-xs btn-dark-light ms-2">
                                                            <i className="mdi mdi-download icon-sm"></i>
                                                            Export
                                                        </div>
                                                    </CSVLink>
                                                ) :
                                                <button  onClick={() => sendNotification({ message: "Data not available", notificationType: 'warning' })} className="btn btn-xs btn-dark-light ms-2">
                                                    <i className="mdi mdi-download icon-sm"></i>
                                                    Export
                                                </button>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-xl-12">
                                        <div className="table-responsive shadow rounded-bottom custom_table">
                                            <Table
                                                rowKey="email"
                                                className='table-responsive'
                                                loading={{ spinning: streamUsersLoading, indicator: <SmallLoader color={'primary'} /> }}
                                                columns={localStore.userTableColumns()}
                                                dataSource={[...streamUsersList]}
                                                pagination={{ pageSize: 10 }}
                                                scroll={{ y: 500 }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Outlet />
                    </Fragment>
                )
            }}
        </Observer>
    )
}
export default StreamUsers;