import { makeAutoObservable, runInAction } from 'mobx';
import { getRequest, postRequest } from 'src/utils/api';
import { getLocalStorageItems, getValidDataFromResponse, isValidArray, isValidStoredKey, setLocalStorageItems } from 'src/utils/utilities';

class LoginStore {
	isUserLoginLoading = false;
	isSocialUserLoginLoading = false;
	isUserAlreadyLoggedLoading = true;
	isUserAlreadyLoggedIn = false;
	// for redirect to dashboard
	isLoginOfUserRedirectSuccess = false;

	constructor() {
		makeAutoObservable(this, {});
	}

	setUserTokenAndId = ({ user_id, token }) => {
		setLocalStorageItems({ setAll: true, items: { uid: user_id, token } });
	}

	setOrgIdAndOrgUserId = ({ org_id, org_user_id }) => {
		setLocalStorageItems({ setAll: true, items: { oid: org_id, ouid: org_user_id } });
	}

	getOrganizerAndOrganizerUserData = async ({ user_id }) => {
		try {
			const { response } = await getRequest(`organizer/user/u/${user_id}`);
			const { data, error } = getValidDataFromResponse(response, false);

			if (!error && isValidArray(data)) {
				const { org_id, _id: org_user_id } = data[0];
				this.setOrgIdAndOrgUserId({ org_user_id, org_id });
				return { isOrganizerAndOrgUserDetailsSuccess: true }
			}
			return { isOrganizerAndOrgUserDetailsSuccess: false }
		} catch (error) {
			return { isOrganizerAndOrgUserDetailsSuccess: false }
		}
	}

	handleUserLogin = async (userLoginData) => {
		try {
			this.isUserLoginLoading = true;

			const { response } = await postRequest('/user/login', userLoginData);
			const { data, error } = getValidDataFromResponse(response, false);

			if (!error && isValidArray(data)) {
				const { _id: user_id, token } = data[0];

				this.setUserTokenAndId({ user_id, token });
				const { isOrganizerAndOrgUserDetailsSuccess } = await this.getOrganizerAndOrganizerUserData({ user_id, token });

				if (isOrganizerAndOrgUserDetailsSuccess) {
					runInAction(() => {
						this.isLoginOfUserRedirectSuccess = true;
						this.isUserLoginLoading = false;
					})
				}
				runInAction(() => {
					this.isLoginOfUserRedirectSuccess = false;
				})
			}
			throw new Error("Something going wrong!!");
		} catch (error) {
			runInAction(() => {
				this.isUserLoginLoading = false;
			});
		}
	}

	checkUserAuth = async () => {
		try {
			// this.isUserAlreadyLoggedLoading = true;

			if (isValidStoredKey(['uid'])) {
				const { uid, token } = getLocalStorageItems({ getAll: true, keys: ['uid', 'token'] });
				const { response } = await postRequest(`/user/auth`, { user_id: uid, token: token });
				const { data, error } = getValidDataFromResponse(response, false, false);

				if (!error && isValidArray(data)) {
					runInAction(() => {
						this.isUserAlreadyLoggedIn = true;
						this.isUserAlreadyLoggedLoading = false;
					})
				}
			}

			setTimeout(() => {
				runInAction(() => {
					this.isUserAlreadyLoggedIn = false;
					this.isUserAlreadyLoggedLoading = false;
				})
			}, 1000);
		} catch (error) {
			runInAction(() => {
				this.isUserAlreadyLoggedIn = false;
				this.isUserAlreadyLoggedLoading = false;
			})
		}
	}

	handleSocialUserLogin = async (userLoginData) => {
		try {
			runInAction(() => {
				this.isSocialUserLoginLoading = true;
			});

			const { response } = await postRequest('/user/social/login', userLoginData);
			const { data, error } = getValidDataFromResponse(response, false);

			if (!error && isValidArray(data)) {
				const { _id: user_id, token } = data[0];

				this.setUserTokenAndId({ user_id, token });
				const { isOrganizerAndOrgUserDetailsSuccess } = await this.getOrganizerAndOrganizerUserData({ user_id, token });

				if (isOrganizerAndOrgUserDetailsSuccess) {
					runInAction(() => {
						this.isSocialUserLoginLoading = false;
						this.isLoginOfUserRedirectSuccess = true;
					})
				}
				runInAction(() => {
					this.isSocialUserLoginLoading = false;
					this.isLoginOfUserRedirectSuccess = false;
				})
			}
			throw new Error("Something going wrong!!");
		} catch (error) {
			runInAction(() => {
				this.isSocialUserLoginLoading = false;
				this.isLoginOfUserRedirectSuccess = false;
			});
		}
	}
}

export default LoginStore;
