import { Observer } from "mobx-react";
import { Draggable } from "react-beautiful-dnd";
import { useMainStore } from "src/container";

import ToolTip from "src/pages/form/private/fieldToolTip";

import PreviewModeWrapper from './previewModeWrapper';

const InputSelect = ({ uk, passingProps, index, handleSelectEditModal, deleteIndex, is_required }) => {
    const mainStore = useMainStore();
    const { streamFormStore } = mainStore;

    const onDeleteClick = (index) => {
        const { removeFormRegisterNowSection } = streamFormStore;
        removeFormRegisterNowSection(index);
    };

    return (
        <Observer>
            {() => {
                const { options, label } = passingProps;

                return (
                    <PreviewModeWrapper
                        uk={index}
                        offPreviewMode={
                            <Draggable draggableId={`id-${index}`} index={index}>
                                {(provided) => (
                                    <div
                                        uk={uk}
                                        className="col-md-12"
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                    >
                                        <div className="mb-3">
                                            <div className="filed_edit_btn">
                                                {/* <label className="form-label">{`Select your ${label}`}</label> */}
                                                <select
                                                    className="form-select form-control form-control2 form-select-lg"
                                                // defaultValue={options[0]?.value}
                                                >
                                                    <option value={'none'} uk={-1}>{`Select your ${label}`}</option>
                                                    {options?.map(({ value, name }, i) => {
                                                        return <option value={value} uk={i}>{name}</option>;
                                                    })}
                                                </select>
                                                <div className="filed_edit_icon">
                                                    <ToolTip
                                                        type='filed'
                                                        ref={provided.innerRef}
                                                        draggableProps={provided.draggableProps}
                                                        dragHandleProps={provided.dragHandleProps}
                                                        onDeleteClick={() => onDeleteClick(deleteIndex)}
                                                        onPencilClick={() => handleSelectEditModal(deleteIndex)}
                                                        isPencilIconHide={[1, 2].includes(deleteIndex)}
                                                        isDeleteIconHide={[1, 2].includes(deleteIndex)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Draggable>
                        }
                        onPreviewMode={
                            <div
                                uk={uk}
                                className="col-md-12"
                            >
                                <div className="mb-3">
                                    <div className="filed_edit_btn_hover_off d-flex">
                                        {/* <label className="form-label">{`Select your ${label}`}</label> */}
                                        <select
                                            className="form-select form-control form-control2 form-select-lg"
                                        // defaultValue={options[0].value}
                                        >
                                            <option value={'none'} uk={-1}>{`Select your ${label}`}</option>
                                            {options.map(({ value, name }, i) => {
                                                return <option value={value} uk={i}>{name}</option>;
                                            })}
                                        </select>
                                        
                                        {is_required && (<span className="text-danger">*</span>)}
                                        
                                    </div>
                                </div>
                            </div>
                        }
                    />

                )
            }}
        </Observer>
    )
}

export default InputSelect;