import { Observer } from "mobx-react";

const ToolTip = (
    { type, onPencilClick, onDeleteClick, draggableProps, dragHandleProps, ref, isPencilIconHide, isDragIconHide, isDeleteIconHide }
) => {

    return (
        <Observer>
            {() => {

                if (type === 'reg') {
                    return (
                        <span onClick={onPencilClick}>
                            <i className="mdi mdi-pencil"></i>
                        </span>
                    )
                }

                if (type === 'filed') {
                    return (
                        <ul>
                            {!isDragIconHide &&
                                <li>
                                    <span
                                        ref={ref}
                                        {...draggableProps}
                                        {...dragHandleProps}
                                    >
                                        <i className="mdi mdi-reorder-vertical"></i>
                                    </span>
                                </li>
                            }
                            {!isPencilIconHide &&
                                <li>
                                    <span onClick={onPencilClick}>
                                        <i className="mdi mdi-pencil"></i>
                                    </span>
                                </li>
                            }
                            {!isDeleteIconHide &&
                                <li>
                                    <span onClick={onDeleteClick}>
                                        <i className="mdi mdi-delete"></i>
                                    </span>
                                </li>
                            }
                        </ul>
                    )
                }

                return null;

            }}
        </Observer>
    );
}

ToolTip.defaultProps = {
    type: null,
    onPencilClick: () => { },
    onDeleteClick: () => { },
    isPencilIconHide: false,
    isDragIconHide: false,
    isDeleteIconHide: false,
    draggableProps: {},
    dragHandleProps: {},
    ref: {}
};

export default ToolTip;