import { Observer } from "mobx-react";
import { useMemo } from "react";
import { Navigate } from "react-router-dom";
import { useMainStore } from "src/container";
import { getLocalStorageItems, isValidObject } from 'src/utils/utilities';
import SmallLoader from "src/components/common/smallLoader";

const UserLogout = () => {
    const mainStore = useMainStore();
    const { logoutStore } = mainStore;
    const { handleUserLogout } = logoutStore;

    const keys = getLocalStorageItems({ keys: ['token', 'oid', 'ouid', 'uid'], getAll: true });

    useMemo(() => {
        if (isValidObject(keys)) {
            handleUserLogout(keys['uid']);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [keys]);

    return <Observer>
        {() => {
            const { userLogoutLoading } = logoutStore;
            if (!userLogoutLoading) {
                return <Navigate to={'/login?redirect=/'} replace={true} />
            }
            return (
                <section className="bg-home d-flex align-items-center">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="form-signin">
                                    <div className="col-md-12">
                                        <div className="mb-3 text-center">
                                            <SmallLoader color={'default'} />;
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            );
        }}
    </Observer>
}

export default UserLogout;