import { Fragment } from 'react';
import { toJS } from 'mobx';
import { Observer } from 'mobx-react';
import { isNumber } from "lodash";
import { useMainStore } from 'src/container';
import { isValidArray, isValidObject } from 'src/utils/utilities';

import Avatar from 'react-avatar';

import ReactApexChart from 'react-apexcharts';
import SmallLoader from 'src/components/common/smallLoader';
import { Tooltip } from 'antd';

const MarketingProcesses = () => {
	const mainStore = useMainStore();
	const { dashboardStore } = mainStore;

	const getStreamSummary = () => {
		const { streamSummary = [], streamSummaryLoading } = dashboardStore;
		const streamSummaryList = isValidArray(toJS(streamSummary)) && isValidObject(toJS(streamSummary[0])) ? streamSummary : [];

		if (streamSummaryLoading) {
			return (
				<div className="loader_center">
					<SmallLoader />
				</div>
			);
		}

		if (!streamSummaryList.length) {
			return <p className="fs-5 text-manual-white ms-5">No Streams Found</p>;
		}

		return streamSummaryList.slice(-3).reverse().map((summary, index) => {
			const { title, total_users, attended_users, images = [] } = summary;

			const percentage = (attended_users / total_users) * 100;
			const series = isNumber(percentage) ? [percentage] : [0];

			let options = {
				chart: {
					type: 'radialBar',
				},
				colors: index === 0 ? ['#3192d4'] : index === 1 ? ['#846bff'] : ['#ff9f43']
				//   responsive: [{
				//     breakpoint: 480,
				//     options: {
				//       chart: {
				//         width: 200
				//       },
				//       legend: {
				//         position: 'bottom'
				//       }
				//     }
				//   }]
			};

			return (
				<div className="mb-3 pr-2" key={index}>
					<div className="features feature-primary key-feature d-flex align-items-center justify-content-between">
						<div className="features feature-primary px-3 key-feature bg-white d-flex align-items-center justify-content-between">
							<div className="d-flex align-items-center">
								<div className="icon text-center me-2">
									<Avatar
										src={images[0] || null}
										name={title}
										className="avatar avatar-md-sm rounded"
										alt="image"
										size="50"
									/>
								</div>
								<div className="flex-1">
									<h6
										className="mb-0 text-dark summaryTitle"
										data-bs-toggle="tooltip"
										data-bs-placement="top"
										title={title}
									>
										{title}
									</h6>
									<small className="text-muted" id={`tooltip${index}`}>
										<Tooltip placement="bottom" title={"Attended Users / Total Users"}>
											{attended_users} / {total_users}
										</Tooltip>

									</small>
								</div>
							</div>

							<div id="chart" className="radialChart">
								<ReactApexChart
									options={options}
									series={series}
									type="radialBar"
									height="110"
								/>
							</div>
						</div>
					</div>
				</div>
			);
		});
	};

	return (
		<Observer>
			{() => {
				return (
					<Fragment>
						<div className="stremas_summary mt-4">
							<div className="summary_heading border-bottom position-relative">
								{getStreamSummary()}
							</div>
						</div>
					</Fragment>
				);
			}}
		</Observer>
	);
};

export default MarketingProcesses;
